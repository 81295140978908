export default (expenseverseCategories = [], action) => {
    switch (action.type) {
        case 'DELETE_EXPENSEVERSE_CATEGORY':
            return expenseverseCategories.filter((category) => category._id !== action.payload);
        case 'UPDATE_EXPENSEVERSE_CATEGORY':
            return expenseverseCategories.map((category) => category._id === action.payload._id ? action.payload : category);
        case 'FETCH_ALL_EXPENSEVERSE_CATEGORIES':
            return action.payload;
        case 'CREATE_EXPENSEVERSE_CATEGORY':
            return [...expenseverseCategories, action.payload];
        default:
            return expenseverseCategories;
    }
};