import React, { useEffect, useState, useCallback } from 'react';
import { Modal, FloatButton, Form, Select, Switch, Input, Button, Divider, Typography, ColorPicker, Segmented, Drawer } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { getUserSettings, updateUserSettings } from '../../actions/userSettingsActions';
import { debounce } from 'lodash';
import { presetColors } from './presetColors';
import DevVarDisplay from '../Other/DevVarDisplay';

const { Option } = Select;
const { Paragraph, Title } = Typography;

const enableEllipsisStyles = {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
};


const UserSettingsModal = ({ darkMode, setSelectedMode }) => {
    const dispatch = useDispatch();
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [changedFields, setChangedFields] = useState({}); // Track status of each field
    const [form] = Form.useForm();

    // State to track screen size
    const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth < 350);
    // Effect to update state on window resize
    useEffect(() => {
        const handleResize = () => setIsSmallScreen(window.innerWidth < 350);

        // Add event listener on mount
        window.addEventListener('resize', handleResize);

        // Remove event listener on unmount
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    // Get user settings from Redux state
    const userSettings = useSelector(state => state.userSettings?.userSettings || {});
    const [userPrefferedTheme, setUserPrefferedTheme] = useState(userSettings?.preferredTheme);

    // Load user settings when modal is opened
    useEffect(() => {
        if (isModalVisible) {
            dispatch(getUserSettings());
        }
    }, [isModalVisible, dispatch]);

    // Prefill the form with flattened user settings data
    useEffect(() => {
        form.setFieldsValue({
            ...userSettings,
            customThemeColorPrimary: userSettings.customTheme?.colorPrimary,
            customThemeBorderRadius: userSettings.customTheme?.borderRadius,
            layoutCompactMode: userSettings.layout?.compactMode,
            layoutMode: userSettings.layout?.layoutMode,
            layoutHeaderMode: userSettings.layout?.headerMode,
            layoutPageHeaderMode: userSettings.layout?.pageHeaderMode,
            layoutPrefferedColorScheme: userSettings.layout?.prefferedColorScheme,
        });
    }, [userSettings, form]);

    // Restructure form values before dispatching
    const restructureValues = (values) => {
        return {
            ...values,
            customTheme: {
                colorPrimary: values.customThemeColorPrimary,
                borderRadius: values.customThemeBorderRadius,
            },
            layout: {
                compactMode: values.layoutCompactMode,
                layoutMode: values.layoutMode,
                headerMode: values.layoutHeaderMode,
                pageHeaderMode: values.layoutPageHeaderMode,
                prefferedColorScheme: values.layoutPrefferedColorScheme,
            },
        };
    };

    // Debounced function to prevent dispatching on every keystroke
    const debouncedUpdate = useCallback(debounce(async (values) => {
        try {
            // Validate the form fields
            await form.validateFields();

            // Dispatch the update with restructured data and await its result
            const result = await dispatch(updateUserSettings(restructureValues(values)));

            // Only reset changedFields after successful update
            if (result.success) {
                setChangedFields({});
            }
        } catch (error) {
            console.log('Validation failed or update unsuccessful');
        }
    }, 500), [dispatch, form]);

    // Debounced function to mark fields as changed without losing focus immediately
    const debouncedFieldChange = useCallback(debounce((field) => {
        setChangedFields((prevChangedFields) => ({
            ...prevChangedFields,
            [field]: true,
        }));
    }, 300), []);

    // Handle field value changes and track which fields are changed
    const handleValuesChange = (changedValues, allValues) => {
        const changedFieldKey = Object.keys(changedValues)[0]; // Get the changed field name

        // Mark the field as changed after a delay (debounced) to avoid focus loss
        debouncedFieldChange(changedFieldKey);

        // Debounce the update for all fields
        debouncedUpdate(allValues);
    };

    // Open Modal
    const showModal = () => {
        setIsModalVisible(true);
    };

    // Close Modal
    const handleCancel = () => {
        setIsModalVisible(false);
        setChangedFields({}); // Reset changed fields when modal is closed
    };

    // Helper function to check if a field has been changed
    const isFieldChanged = (fieldName) => changedFields[fieldName];
    const formLayout = {
        variant: 'filled',
        colon: false,
        // style: {
        //     whiteSpace: 'nowrap',
        //     overflow: 'hidden',
        //     textOverflow: 'ellipsis',
        // },
        labelCol: {
            // span: isSmallScreen ? 24 : 8,
            xs: {
                span: isSmallScreen ? 24 : 8,
            },
            sm: {
                span: 8,
            },
        },
        wrapperCol: {
            // span: isSmallScreen ? 24 : 16,
            xs: {
                span: isSmallScreen ? 24 : 16,
            },
            sm: {
                span: 16,
            },
        },
    };

    const colorModeOptions = [
        {
            label: <><span className="material-symbols-rounded"
                style={{
                    fontVariationSettings: "'FILL' 1",
                    fontSize: 18,
                    verticalAlign: 'middle'
                }}>
                {/* settings_night_sight */}
                {/* settings_motion_mode */}
                {/* reset_brightness */}
                {/* brightness_6 */}
                {/* sync */}
                {/* flip_camera_android */}
                routine
            </span> System</>,
            value: 'system',
        },
        {
            label: <><span className="material-symbols-rounded"
                style={{
                    fontVariationSettings: "'FILL' 1",
                    fontSize: 18,
                    verticalAlign: 'middle'
                }}>
                light_mode
            </span> Light</>,
            value: 'light',
        },
        {
            label: <><span className="material-symbols-rounded"
                style={{
                    fontVariationSettings: "'FILL' 1",
                    fontSize: 18,
                    verticalAlign: 'middle'
                }}>
                dark_mode
            </span> Dark</>,
            value: 'dark',
        }
    ];

    // Buttons for Floating Button Group
    const ButtonDark = <FloatButton
        // tooltip='Switch to Dark Mode'
        icon={<span class="material-icons"
            style={{ fontSize: 16 }}
        >dark_mode</span>}
        onClick={() => (
            // setSelectedMode('dark'),
            dispatch(updateUserSettings({
                layout: {
                    prefferedColorScheme: 'dark',
                },
            }))
            // localStorage.setItem('selectedMode', 'dark')
        )}
    />
    const ButtonLight = <FloatButton
        // tooltip='Switch to Light Mode'
        icon={<span class="material-icons"
            style={{ fontSize: 16 }}
        >light_mode</span>}
        onClick={() => (
            // setSelectedMode('light'),
            dispatch(updateUserSettings({
                layout: {
                    prefferedColorScheme: 'light',
                },
            }))
            // setSelectedMode('light'), localStorage.setItem('selectedMode', 'light')
        )}
    />
    const ButtonMenu = <FloatButton
        // style={{ bottom: isBroken ? 24 : '' }}
        type='primary'
        icon={<span class="material-icons" style={{ fontSize: 16 }}>menu</span>}
    // onClick={() => setCollapsed(!collapsed)}
    />

    return (
        <>
            <FloatButton.Group shape='square'
            // style={{ bottom: 24 }}
            >
                {darkMode ? ButtonLight : ButtonDark}
                <FloatButton // icons set if outlined or two-toned or default is defined via class, fonts are imported via Google Font link in index.html
                    // tooltip=' Themes'
                    icon={<span class="material-icons " style={{ fontSize: 16 }}>auto_fix_high</span>}
                    onClick={showModal}
                />
                <FloatButton.BackTop tooltip='Scroll to Top' />
            </FloatButton.Group>
            {/* <Button type="primary" onClick={showModal}>
                Open User Settings
            </Button> */}

            <Drawer width={400} className='handleNotchTop handleNotchRight'
                title="Manage User Settings"
                visible={isModalVisible}
                onClose={handleCancel}
                footer={null}
            >

                {/* <DevVarDisplay value={isSmallScreen} /> */}
                <Form  {...formLayout}
                    form={form}
                    onValuesChange={handleValuesChange} // Track value changes
                >
                    <Form.Item
                        label={<Title level={5} style={{ ...enableEllipsisStyles, marginBottom: 0 }}>Enabbled apps</Title>}
                        required={false}
                        name="enabledApps"
                        rules={[{ required: true, message: 'Please select at least 1 app!' }]}
                        hasFeedback={isFieldChanged('enabledApps')}
                        validateStatus={isFieldChanged('enabledApps') ? 'warning' : ''}
                    >
                        <Select mode="multiple" placeholder="Select enabled apps">
                            <Option value="NoteVerse">NoteVerse</Option>
                            <Option value="ExpenseVerse">ExpenseVerse</Option>
                        </Select>
                    </Form.Item>

                    <Divider />
                    <Form.Item
                        label={<Title level={5} style={{ ...enableEllipsisStyles, marginBottom: 0 }}>Color Mode</Title>}
                        name="layoutPrefferedColorScheme"
                        hasFeedback={isFieldChanged('layoutPrefferedColorScheme')}
                        validateStatus={isFieldChanged('layoutPrefferedColorScheme') ? 'warning' : ''}
                    >
                        {!isSmallScreen ?
                            <Segmented
                                options={colorModeOptions}
                                onChange={(value) => {
                                    console.log(value); // string
                                }}
                            />
                            :
                            <Select placeholder="Select selected mode"
                                options={colorModeOptions} />}
                    </Form.Item>

                    <Form.Item
                        label={<Title level={5} style={{ ...enableEllipsisStyles, marginBottom: 0 }}>Theme</Title>}
                        name="preferredTheme"
                        hasFeedback={isFieldChanged('preferredTheme')}
                        validateStatus={isFieldChanged('preferredTheme') ? 'warning' : ''}
                    >
                        <Select placeholder="Select theme"
                            // onChange={(v) => setUserPrefferedTheme(v)}
                            popupMatchSelectWidth={false}>
                            <Option value="0">Ant Default</Option>
                            <Option value="1">Red</Option>
                            <Option value="2">Green</Option>
                            <Option value="3">Purple</Option>,
                            <Option value="custom">Custom</Option>
                        </Select>
                    </Form.Item>

                    <Form.Item
                        label={<Typography.Text disabled={userSettings.preferredTheme !== 'custom'} style={{ ...enableEllipsisStyles }}>Primary Color</Typography.Text>}
                        name="customThemeColorPrimary"
                        hasFeedback={isFieldChanged('customThemeColorPrimary')}
                        validateStatus={isFieldChanged('customThemeColorPrimary') ? 'warning' : ''}
                        getValueFromEvent={(color) => {
                            return color.toHexString();
                        }}
                    >
                        <ColorPicker disabled={userSettings.preferredTheme !== 'custom'}
                            presets={presetColors} />
                    </Form.Item>
                    <Form.Item
                        label={<Typography.Text disabled={userSettings.preferredTheme !== 'custom'} style={{ ...enableEllipsisStyles, }}>Border Radius</Typography.Text>}
                        name="customThemeBorderRadius"
                        hasFeedback={isFieldChanged('customThemeBorderRadius')}
                        validateStatus={isFieldChanged('customThemeBorderRadius') ? 'warning' : ''}
                    >
                        <Input type="number" placeholder="Enter border radius (e.g., 20)"
                            disabled={userSettings.preferredTheme !== 'custom'}
                        />
                    </Form.Item>

                    <Form.Item
                        label={<Title level={5} style={{ ...enableEllipsisStyles, marginBottom: 0 }}>Compact Mode</Title>}
                        name="layoutCompactMode"
                        valuePropName="checked"
                        hasFeedback={isFieldChanged('layoutCompactMode')}
                        validateStatus={isFieldChanged('layoutCompactMode') ? 'warning' : ''}
                    >
                        <Switch />
                    </Form.Item>

                    <Divider />

                    <Form.Item
                        label={<Title level={5} style={{ ...enableEllipsisStyles, marginBottom: 0 }}>Layout Mode</Title>}
                        name="layoutMode"
                        hasFeedback={isFieldChanged('layoutMode')}
                        validateStatus={isFieldChanged('layoutMode') ? 'warning' : ''}
                    >
                        <Select placeholder="Select layout mode">
                            <Option value="mix">Mix</Option>
                            {/* <Option value="side">Side</Option> */}
                            <Option value="top">Top</Option>
                        </Select>
                    </Form.Item>
                    {/* <Form.Item
                            label={<Typography.Text disabled={userPrefferedLayout !== 'top'} >App Header Sticky</Typography.Text>}
                            name="layoutHeaderMode"
                            valuePropName="checked"
                            hasFeedback={isFieldChanged('layoutHeaderMode')}
                            validateStatus={isFieldChanged('layoutHeaderMode') ? 'warning' : ''}
                        >
                            <Switch disabled={userPrefferedLayout !== 'top'} />
                        </Form.Item> */}
                    <Form.Item
                        label={<Typography.Text style={{ ...enableEllipsisStyles }}>Page Header Mode</Typography.Text>}
                        name="layoutPageHeaderMode"
                        valuePropName="checked"
                        hasFeedback={isFieldChanged('layoutPageHeaderMode')}
                        validateStatus={isFieldChanged('layoutPageHeaderMode') ? 'warning' : ''}
                    >
                        <Switch />
                    </Form.Item>
                </Form>
            </Drawer>
        </>
    );
};

export default UserSettingsModal;
