import React from 'react';
import { useSelector } from 'react-redux';
import Note from './Note/Note';
import { Col, Spin, Space, Empty } from 'antd';

const NotesCollections = ({ setCurrentId, receivedCollection, darkMode, tabID, treeTabs, distinguishBackground, requestedNoteId, noteRef, showVisitFolderLink }) => {
    const getNoteCollections = useSelector((state) => state.noteCollections);
    const getReceivedCollection = useSelector((state) => receivedCollection || null)
    const noteCollections = receivedCollection ? getReceivedCollection : getNoteCollections;
    // const classes = useStyles();

    console.log(noteCollections);
    return (
        !noteCollections.length ? <Empty description="Add first note!" image={Empty.PRESENTED_IMAGE_SIMPLE} /> : (
            <Space direction='vertical' size='large' style={{ width: '100%' }}>
                {noteCollections.map((noteCollection) => (
                    <div key={noteCollection._id} >
                        <Note noteCollection={noteCollection} setCurrentId={setCurrentId} darkMode={darkMode} tabID={tabID} treeTabs={treeTabs} distinguishBackground={distinguishBackground}
                            requestedNoteId={requestedNoteId} noteRef={noteRef} showVisitFolderLink={showVisitFolderLink} />
                    </div>
                ))}
            </Space>
        )
    );
}

export default NotesCollections;