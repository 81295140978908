import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Form, Input, Button, Typography, message, Modal, ConfigProvider } from 'antd';
import { createFolderCollection, updateFolderCollection } from '../../../actions/folderCollections';
import './FolderForm.css';
import CustomTreeSelect from '../../../Pages/Menu/CustomTreeSelect';

const FolderForm = ({ currentIdFolder, setCurrentIdFolder, addFirstFolder }) => {
    const dispatch = useDispatch();
    const [form] = Form.useForm();
    const [parentsIDList, setParentsIDList] = useState('');
    const [visible, setVisible] = useState(false); // state for modal visibility

    const folderCollection = useSelector((state) =>
        currentIdFolder ? state.folderCollections.find((r) => r._id === currentIdFolder) : null
    );

    useEffect(() => {
        if (folderCollection) {
            showModal(true);
            form.setFieldsValue({
                folderName: folderCollection.folderName,
                folderDescription: folderCollection.folderDescription,
                folderParents: folderCollection.folderParents.join(','),
                folderParentsFromParent: folderCollection.folderName,
            });
            setParentsIDList(folderCollection.folderParents.join(','));
        }
    }, [folderCollection]);

    const onFinish = async (values) => {
        values.folderParents = values.folderParents ? values.folderParents.split(',') : '';
        console.log('onFinish value :', form.getFieldsValue());
        let result;
        if (currentIdFolder) {
            console.log('dispatched Update');
            result = await dispatch(updateFolderCollection(currentIdFolder, values));
            if (result.status === 'success') {
                message.success('Folder updated successfully!');
            } else {
                message.error(`An error occurred while updating the folder: ${result.message}`);
                console.error(result.message);
            }
        } else {
            console.log('dispatched Create');
            result = await dispatch(createFolderCollection(values));
            if (result.status === 'success') {
                message.success('New folder created successfully!');
            } else {
                message.error('An error occurred while creating the folder. Please try again.');
                console.error(result.message);
            }
        }
        setCurrentIdFolder();
        form.resetFields();
        setVisible(false); // close the modal after submitting
    };

    const onReset = () => {
        form.resetFields();
        setCurrentIdFolder();
    };

    const onClearKeepParent = () => {
        setCurrentIdFolder();
        form.setFieldsValue({
            folderName: '',
            folderDescription: '',
            folderParentsFromParent: '',
        });
    };

    const showModal = () => {
        setVisible(true); // show the modal when the user clicks the button
    };

    const handleCancel = () => {
        // setCurrentIdFolder();
        if (typeof setCurrentIdFolder === 'function') {
            setCurrentIdFolder(false);
        }
        setVisible(false); // close the modal when the user clicks the cancel button
    };

    console.log('parentsIDList je ted ', parentsIDList);

    return (
        <>
            {addFirstFolder ? <ConfigProvider
                theme={{
                    components: {
                        Button: {
                            /* here is your component tokens */
                            defaultBg: 'none'
                        },
                    },
                }}
            >
                <Button type='dashed' style={{ width: '100%', height: '100%', minHeight: '100px' }} onClick={showModal} >Add First Folder</Button>
            </ConfigProvider> : <Button type="primary" onClick={showModal}>
                {currentIdFolder ? 'Edit Folder' : 'Create Folder'}
            </Button>}

            <Modal
                title={currentIdFolder ? 'Editting a Folder' : 'Creating a Folder'}
                visible={visible}
                onCancel={handleCancel}
                footer={null}
            >
                <Form form={form} name="folder_form" onFinish={onFinish}>
                    <Form.Item
                        name="folderName"
                        rules={[{ required: true, message: 'Please input the folder name!' }]}
                    >
                        <Input placeholder="Folder Name" />
                    </Form.Item>
                    <Form.Item
                        name="folderDescription"
                        rules={[{ required: true, message: 'Please input the folder description!' }]}
                    >
                        <Input placeholder="Folder Description" />
                    </Form.Item>
                    <CustomTreeSelect
                        form={form}
                        parentsIDList={parentsIDList}
                        setParentsIDList={setParentsIDList}
                        showFolderOnly={true}
                    />
                    <Form.Item>
                        <Button type="primary" htmlType="submit">
                            Submit
                        </Button>
                        <Button htmlType="button" onClick={onReset}>
                            Reset
                        </Button>
                        <Button htmlType="button" onClick={onClearKeepParent}>
                            Clear but keep Parents
                        </Button>
                    </Form.Item>
                </Form>
            </Modal>
        </>
    );
};

export default FolderForm;
