import React from 'react';
import { DatePicker, ConfigProvider } from 'antd';
// import './styles.css'

const { RangePicker } = DatePicker;

const DateRangePicker = ({
    dateRangeType,
    dateRange,
    onRangePickerChange,
    onDayPickerChange,
    onWeekPickerChange,
    onMonthPickerChange,
    onQuarterPickerChange,
    onYearPickerChange,
    locale,
    hideDatePickersPanel = false,
    hideDatePickersInputField = false,
    darkMode,
    noCardBackground,
}) => {
    const showPickerPanel = !hideDatePickersPanel && <div className={noCardBackground && 'hideBackground'} id="custom-datepicker-panel"></div>;
    const hideInputFieldConfig = hideDatePickersInputField && { style: { visibility: 'hidden', height: 0 }, popupClassName: 'pickerClass' }
    const popUpStyles = { position: 'relative', border: `1px solid ${darkMode ? 'rgb(48, 48, 48)' : 'rgba(217, 217, 217,.5)'}`, borderRadius: 8 }

    return (
        <>
            {dateRangeType === 'Custom' ? (
                <>
                    <RangePicker
                        // {...hideInputFieldConfig}
                        open={!hideDatePickersPanel}
                        value={dateRange}
                        getPopupContainer={() => document.getElementById('custom-datepicker-panel')}
                        popupStyle={popUpStyles}
                        onChange={onRangePickerChange}
                        format="YYYY-MM-DD"
                        locale={locale}
                    />
                    {showPickerPanel}
                </>
            ) : dateRangeType === 'Day' ? (
                <>
                    <DatePicker
                        {...hideInputFieldConfig}
                        open={!hideDatePickersPanel}
                        value={dateRange[0]}
                        getPopupContainer={() => document.getElementById('custom-datepicker-panel')}
                        popupStyle={popUpStyles}
                        onChange={onDayPickerChange}
                        format="YYYY-MM-DD"
                        locale={locale}
                    />
                    {showPickerPanel}
                </>
            ) : dateRangeType === 'Week' ? (
                <>
                    <DatePicker.WeekPicker
                        {...hideInputFieldConfig}
                        open={!hideDatePickersPanel}
                        value={dateRange[0]}
                        getPopupContainer={() => document.getElementById('custom-datepicker-panel')}
                        popupStyle={popUpStyles}
                        onChange={onWeekPickerChange}
                        format="YYYY-wo"
                        locale={locale}
                    />
                    {showPickerPanel}
                </>
            ) : dateRangeType === 'Month' ? (
                <>
                    <DatePicker.MonthPicker
                        {...hideInputFieldConfig}
                        open={!hideDatePickersPanel}
                        value={dateRange[0]}
                        getPopupContainer={() => document.getElementById('custom-datepicker-panel')}
                        popupStyle={popUpStyles}
                        onChange={onMonthPickerChange}
                        format="YYYY-MM"
                        locale={locale}
                    />
                    {showPickerPanel}
                </>
            ) : dateRangeType === 'Quarter' ? (
                <>
                    <DatePicker
                        {...hideInputFieldConfig}
                        picker="quarter"
                        open={!hideDatePickersPanel}
                        value={dateRange[0]}
                        getPopupContainer={() => document.getElementById('custom-datepicker-panel')}
                        popupStyle={popUpStyles}
                        onChange={onQuarterPickerChange}
                        format="YYYY-[Q]Q"
                        locale={locale}
                    />
                    {showPickerPanel}
                </>
            ) : dateRangeType === 'Year' ? (
                <>
                    <DatePicker.YearPicker
                        {...hideInputFieldConfig}
                        open={!hideDatePickersPanel}
                        value={dateRange[0]}
                        getPopupContainer={() => document.getElementById('custom-datepicker-panel')}
                        popupStyle={popUpStyles}
                        onChange={onYearPickerChange}
                        format="YYYY"
                        locale={locale}
                    />
                    {showPickerPanel}
                </>
            ) : null
            }
        </>
    );
};

export default DateRangePicker;
