const formLayout = {
    variant: 'filled',
    // style: {
    //     ellipsis: true
    // },
    labelCol: {
        xs: {
            span: 8,
        },
        sm: {
            span: 10,
        },
    },
    wrapperCol: {
        xs: {
            span: 16,
        },
        sm: {
            span: 14,
        },
    },
};

export default formLayout;