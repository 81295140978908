import { AppstoreOutlined, FileOutlined, FolderFilled, HomeOutlined, HomeFilled, QuestionCircleOutlined, QuestionCircleFilled } from '@ant-design/icons';
import { Link } from 'react-router-dom';

export function createDataTree({ folders, noteCollections, pageTabs }, { labelProperty = "label", linkProperty = "link" }, { parentId = "", useIconComponents = false, showHomeAsRoot = false, showFoldersOnly = false, showStats = false, showLostItems = false, showBreadcrumbHome = false, returnOnlyLostNode = false } = {}) {
    noteCollections = showFoldersOnly ? [] : noteCollections;
    pageTabs = showFoldersOnly ? [] : pageTabs;

    function createIndividualNodes({ folders, noteCollections, pageTabs }, { labelProperty, linkProperty }, { parentId = "", useIconComponents } = {}) {
        let filteredFolders = Object.values(folders).filter(folder => folder.folderParents[0] === parentId);

        let dataTree = [];
        filteredFolders.forEach(folder => {
            let node = {
                title: <Link to={`/folders/${folder._id}`}>{folder.folderName}</Link>, // Use 'title' instead of 'breadcrumbName' for root items
                key: folder._id,
                icon: useIconComponents ? <FolderFilled /> : "FolderFilled",
                [linkProperty]: `/folders/${folder._id}` // Use 'path' instead of 'link'
            };

            let nestedFolders = createIndividualNodes({ folders, noteCollections, pageTabs }, { labelProperty, linkProperty }, { parentId: folder._id, useIconComponents });

            let nestedNotesCount = Object.values(noteCollections).filter(note => note.parents?.includes(folder._id)).length;

            let folderTabs = Object.values(pageTabs).find(tab => tab.parentID === folder._id);
            let nestedTabsCount = 0;

            if (folderTabs && folderTabs.tabs) {
                nestedTabsCount = folderTabs.tabs.length;
            }

            node[labelProperty] = <Link to={`/folders/${folder._id}`}>{folder.folderName} {getStats({ foldersTotal: nestedFolders.length, tabsTotal: nestedTabsCount, notesTotal: nestedNotesCount }, showStats)}</Link>;

            if (nestedFolders.length > 0) {
                if (!node.children) {
                    node.children = [];
                }
                node.children = [...node.children, ...nestedFolders];
            }

            if (folderTabs && folderTabs.tabs) {
                folderTabs.tabs.forEach(tab => {
                    let tabNode = {
                        key: tab._id,
                        icon: tab.tabName === "Notes without tab" ? (useIconComponents ? <QuestionCircleOutlined /> : "QuestionCircleOutlined") : (useIconComponents ? <AppstoreOutlined /> : "AppstoreOutlined"),
                        [linkProperty]: `/folders/${folder._id}/${tab._id}` // Use 'path' instead of 'link'
                    };

                    let tabNotes = Object.values(noteCollections).filter(note => note.tabID === tab._id && note.parents.includes(folder._id));

                    tabNode[labelProperty] = <Link to={`/folders/${folder._id}/${tab._id}`}>{tab.tabName} {getStats({ foldersTotal: nestedFolders.length = 0, tabsTotal: nestedTabsCount = 0, notesTotal: tabNotes.length }, showStats)}</Link>; // Use 'label' instead of 'breadcrumbName' for nested items

                    if (tabNotes.length > 0) {
                        tabNode.children = tabNotes.map(note => ({
                            [labelProperty]: <Link to={`/folders/${folder._id}/${tab._id}?noteId=${note._id}`}>{note.message}</Link>, // Use 'label' instead of 'breadcrumbName' for nested items
                            key: note._id,
                            icon: useIconComponents ? <FileOutlined /> : "FileOutlined",
                            [linkProperty]: `/folders/${folder._id}/${tab._id}?noteId=${note._id}` // Use 'path' instead of 'link'
                        }));
                    }

                    if (!node.children) {
                        node.children = [];
                    }

                    node.children.push(tabNode);
                });

                let noTabNotes = Object.values(noteCollections).filter(note => note.parents?.includes(folder._id) && (!note.tabID || !folderTabs.tabs.find(tab => tab._id === note.tabID)));

                if (noTabNotes.length > 0) {
                    let allTab = folderTabs.tabs.find(tab => tab.tabName.toLowerCase() === "all");
                    let noTabNode = {
                        [labelProperty]: <Link to={`/folders/${folder._id}${allTab ? `/${allTab._id}` : ""}`}> Notes without tab {getStats({ foldersTotal: nestedFolders.length = 0, tabsTotal: nestedTabsCount = 0, notesTotal: noTabNotes.length }, showStats)}</Link>, // Use 'label' instead of 'breadcrumbName' for nested items
                        key: `${folder._id}notab`,
                        icon: useIconComponents ? <QuestionCircleOutlined /> : "QuestionCircleOutlined"
                    };


                    if (allTab) {
                        noTabNode.path = `/${allTab?._id}`; // Use 'path' instead of 'link'
                    }

                    noTabNode.children = noTabNotes.map(note => ({
                        [labelProperty]: <Link to={`/folders/${folder._id}/${allTab ? allTab._id : ""}?noteId=${note._id}`}>{note.message}</Link>, // Use 'label' instead of 'breadcrumbName' for nested items
                        key: note._id,
                        icon: useIconComponents ? <FileOutlined /> : "FileOutlined",
                        [linkProperty]: `/folders/${folder._id}/${allTab ? allTab._id : ""}?noteId=${note._id}` // Use 'path' instead of 'link'
                    }));

                    if (!node.children) {
                        node.children = [];
                    }

                    node.children.push(noTabNode);
                }
            } else {
                let folderNotes = Object.values(noteCollections).filter(note => note.parents?.includes(folder._id));

                if (folderNotes.length > 0) {
                    if (!node.children) {
                        node.children = [];
                    }

                    node.children = [...node.children, ...folderNotes.map(note => ({
                        [labelProperty]: <Link to={`/folders/${folder._id}?noteId=${note._id}`}>{note.message}</Link>, // Use 'label' instead of 'breadcrumbName' for nested items
                        key: note._id,
                        icon: useIconComponents ? <FileOutlined /> : "FileOutlined",
                        [linkProperty]: `/folders/${folder._id}?noteId=${note._id}` // Use 'path' instead of 'link'
                    }))];
                }
            }

            dataTree.push(node);
        });


        return dataTree;

    }


    // Create a set of all valid parent IDs
    let validParentIds = new Set(Object.values(folders).flatMap(folder => folder._id));

    // Create a "Lost Items" node
    let lostNode = {
        [labelProperty]: "Lost Items",
        key: "lost",
        icon: useIconComponents ? <QuestionCircleOutlined /> : "QuestionCircleFilled",
        [linkProperty]: "/lost",
        children: []
    };


    // Filter out Folders with invalid parentIDs
    let invalidFolders = Object.values(folders).filter(folder => folder.folderParents.some(parentId => !validParentIds.has(parentId) && parentId !== ""));

    invalidFolders.forEach(folder => {
        let foundFolder = createIndividualNodes({ folders, noteCollections, pageTabs }, { labelProperty: labelProperty, linkProperty }, { parentId: folder?.folderParents[0], useIconComponents });
        lostNode.children.push(...foundFolder);
    });

    // Filter out Notes with invalid parentIDs or with empty parent array
    let invalidNotes = Object.values(noteCollections).filter(note => note.parents?.length === 0 || note.parents?.some(parentId => !validParentIds.has(parentId)));
    console.log('invalidNotes su ', invalidNotes)
    // Include invalid notes
    invalidNotes.forEach(note => {
        lostNode.children.push({
            [labelProperty]: <Link to={`?noteId=${note._id}`}>{note.message || 'Note without title'} </Link>, // Adjust the link accordingly
            key: note._id,
            icon: useIconComponents ? <QuestionCircleOutlined /> : "QuestionCircleOutlined",
            [linkProperty]: `?noteId=${note._id}` // Use 'path' instead of 'link'
        });
    });

    lostNode[labelProperty] += `${getStats({ foldersTotal: invalidFolders.length, tabsTotal: 0, notesTotal: invalidNotes.length }, showStats = true)}`;

    if (returnOnlyLostNode) {
        return [lostNode];
    }

    let dataTree = createIndividualNodes({ folders, noteCollections, pageTabs }, { labelProperty, linkProperty }, { parentId, useIconComponents, showHomeAsRoot });
    console.log('showHomeAsRoot je', showHomeAsRoot)


    // add Home Button as very first Breadcrumb if showHomeAsRoot is set true
    let homeNode = {};
    if (showHomeAsRoot === true) {
        homeNode = {
            title: <Link to="/">{showBreadcrumbHome ? <HomeFilled /> : 'Home'}</Link>,
            key: "home",
            icon: <HomeFilled />,
            label: <Link to="/">Home</Link>,
            children: dataTree  // Assign the original data tree as the children of "Home"
        };
        dataTree = [homeNode];  // The final data tree with "Home" as the root
        // add Lost Folders to Home root folder
        if (showLostItems && lostNode.children.length > 0 && parentId == "") {
            dataTree.push(lostNode);
        }
        return dataTree;
    } else {
        // add Lost Folders to list of all root folders
        if (showLostItems && lostNode.children.length > 0 && parentId == "") {
            dataTree.push(lostNode);
        }
        return dataTree;
    }
}


function getStats({ foldersTotal, tabsTotal, notesTotal }, showStats) {
    let stats = [];
    if (foldersTotal !== 0) stats.push(` F:${foldersTotal}`);
    if (tabsTotal !== 0) stats.push(` T:${tabsTotal}`);
    if (notesTotal !== 0) stats.push(` N:${notesTotal}`);

    if (showStats === true) {
        return (stats.length === 0 ? '(empty)' : `(${stats.join(',')} )`);
    } else { return null };
}