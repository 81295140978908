// video tutorial https://www.youtube.com/watch?v=WpIDez53SK4

import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { doCreateUserWithEmailAndPassword } from '../../../firebase/auth'
import AuthModal from '../AuthModal'
import { Button, Checkbox, Divider, Flex, Form, Input, Typography } from 'antd'
import { LockOutlined, MailOutlined, UserOutlined, GoogleOutlined } from "@ant-design/icons";
const { Text, Title } = Typography;

const Register = () => {


    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [confirmPassword, setconfirmPassword] = useState('')
    const [isRegistering, setIsRegistering] = useState(false)
    const [errorMessage, setErrorMessage] = useState('')


    const firebaseErrorCodes = {
        'auth/network-request-failed': 'No internet connection. Please check your network and try again.',
        'auth/user-not-found': 'No user found with this email.',
        'auth/wrong-password': 'Incorrect password. Please try again.',
        'auth/email-already-in-use': <>User already exists. Please try again or continue to  {<Link to={'/login'} className="text-center text-sm hover:underline font-bold">Login</Link>}</>,
        'auth/invalid-email': 'Incorrect email. Please try again.',
        'auth/weak-password': 'Weak password. Please try again.',
        // Add other error codes as needed
    }


    const onSubmit = async (e) => {
        if (!isRegistering) {
            setIsRegistering(true)
            try {
                await doCreateUserWithEmailAndPassword(e.email, e.password)
            } catch (err) {
                const userFriendlyMessage = firebaseErrorCodes[err.code] || err.code
                setErrorMessage(userFriendlyMessage)
                setIsRegistering(false)
            }
        }
    }

    // Page Title visible in browser tabs
    useEffect(() => {
        document.title = 'Create Account';
    }, []);

    return (
        <>
            <AuthModal>
                <Title >Register</Title>
                <Text type='secondary' >
                    Welcome to NoteVerse! <br />Create your new account.
                    <br />
                    <br />
                </Text>
                {/* <Logo_NoteVerse fill={'#273343'} transparentFill={'rgba(0,0,0,0)'} /> */}
                <Form
                    name="normal_login"
                    className="login-form"
                    onFinish={onSubmit}
                    layout="vertical"
                    requiredMark="optional"
                >

                    <Form.Item
                        name={'email'}
                        rules={[
                            {
                                type: 'email',
                                required: true,
                                message: "Please input your Email!",
                            },
                        ]}
                    >
                        <Input autoComplete='email'
                            prefix={<UserOutlined className="site-form-item-icon" />}
                            placeholder="Email"
                        />
                    </Form.Item>




                    <Form.Item
                        name="password"
                        rules={[
                            {
                                required: true,
                                message: 'Please input your password!',
                                min: 0,
                                max: 99,
                            },
                        ]}
                    >
                        <Input.Password
                            autoComplete='new-password'
                            prefix={<LockOutlined className="site-form-item-icon" />}
                            type="password"
                            placeholder="Password"
                        />
                    </Form.Item>


                    {errorMessage && (
                        <Text type="danger">{errorMessage}</Text>
                    )}

                    <Form.Item>
                        <Button type="primary" htmlType="submit"
                            disabled={isRegistering} className="login-form-button">
                            {isRegistering ? 'Signing Up...' : 'Sign Up'}
                        </Button>

                    </Form.Item>





                    <div className="text-sm text-center">
                        Already have an account? {'   '}
                        <Link to={'/login'} className="text-center text-sm hover:underline font-bold">Continue</Link>
                    </div>
                </Form>
            </AuthModal >
        </>
    )
}

export default Register