import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import BreadcrumbNav from "../Menu/BreadcrumbNav";
import PageContentWrapper from "../PageContentWrapper/PageContentWrapper";
import "./FolderPage_styles.css";

// switching to ANTD from MUI
import { Button, Typography, } from "antd";
import NewPageTabsAnt from "../../components/Tabs/NewPageTabs/NewPageTabsAnt";
import PageWithTabs from "./PageWithTabs";
import PageWithoutTabs from "./PageWithoutTabs";
import NavigationTree from "../../components/Navigation/NavigationIncludingTabs/NavigationTree";
import BreadcrumbsTest from "../../components/Navigation/TestEdge/BreadcrumbsTest";
import NavigationTreeV2 from "../../components/Navigation/NavigationIncludingTabs/NavigationTreeV2";
import { PageContainer } from "@ant-design/pro-layout";
import DevVarDisplay from "../../components/Other/DevVarDisplay";
import NoteFormInTab from "../../components/NotesView/NoteForm/NoteFormInTab";
import { DoubleLeftOutlined, ArrowLeftOutlined } from '@ant-design/icons';



const FolderPage = ({ darkMode, setPageTitle, setPageHeaderMode, pageHeaderMode }) => {
    const [visible, setVisible] = useState(false); // state for modal visibility
    const [currentId, setCurrentId] = useState(null);
    const [ellipsis, setEllipsis] = useState(true);
    const { id } = useParams();


    var allNotesForCurrentFolder = [];
    const noteCollection = useSelector((state) => state.noteCollections);
    allNotesForCurrentFolder = noteCollection.filter((note) => note.parents.includes(id));
    const folderCollection = useSelector((state) =>
        id ? state.folderCollections.find((r) => r._id === id) : null
    );

    // detect whether PageTabs exist and serve proper layout accordingly
    const pageTabsCollectionsFromServer = useSelector((state) =>
        id ? state.pageTabsCollections.find((r) => r.parentID === id) : null
    );

    useEffect(() => {
        setPageTitle(folderCollection?.folderName);
    }, [folderCollection?.folderName]); // Empty array means this effect runs once on mount
    var folderParentId = folderCollection?.folderParents[0] != "" ? folderCollection?.folderParents[0] : null

    const treeTabs = pageTabsCollectionsFromServer?.tabs.map((tab) => ({
        title: tab.tabName,
        value: tab._id,
        disabled: tab.tabName.toLowerCase() === "all" ? true : false
    }));

    return (
        <PageContainer className="moveDown"
            title={< >{folderParentId ? <Link type="text" to={`/folders/${folderParentId}`} ><ArrowLeftOutlined /></Link> : null}  {folderCollection?.folderName}</>}
            breadcrumbRender={() => <BreadcrumbsTest />}
            fixedHeader={pageHeaderMode}
            content={
                <Typography.Paragraph
                    ellipsis={ellipsis ? { rows: 2, expandable: true, symbol: "more", } : false}
                > {folderCollection?.folderDescription} </Typography.Paragraph>
            }
            extra={[
                <NewPageTabsAnt parentID={id} visible={visible} setVisible={setVisible} />,
                <NoteFormInTab
                    currentId={currentId}
                    setCurrentId={setCurrentId}
                    createdFromParent={id}
                    darkMode={darkMode}
                    // tabID={elem._id} // this property is not possible to lift up from PageWithTabs because it is generated within useEffect
                    treeTabs={treeTabs}
                    formKey={'top'}
                />
            ]}
        >
            <div style={{ maxWidth: '900px', margin: '0 auto' }}>
                <br /> <br /> <br />

                {folderCollection?.folderChildren ? (
                    <Typography.Text type="secondary">  Total notes: {allNotesForCurrentFolder.length}{" "} </Typography.Text>
                ) : (
                    <Typography.Text>Neexistujem alebo ma niekto zmazal :</Typography.Text>
                )}
                <NavigationTreeV2 folderId={id} navigationType='menu' showHomeAsRoot={false} />
                <br />





                {pageTabsCollectionsFromServer ? (
                    <PageWithTabs
                        allNotesForCurrentFolder={allNotesForCurrentFolder}
                        currentId={currentId} setCurrentId={setCurrentId}
                        darkMode={darkMode} id={id}

                        pageTabsCollectionsFromServer={pageTabsCollectionsFromServer}
                        visible={visible}
                        setVisible={setVisible}
                    />
                ) : (
                    <>
                        <PageWithoutTabs
                            allNotesForCurrentFolder={allNotesForCurrentFolder}
                            currentId={currentId} setCurrentId={setCurrentId}
                            darkMode={darkMode} id={id}
                        />
                    </>
                )}
                <br />
                <br />
            </div>
        </PageContainer>
    );
};

export default FolderPage;