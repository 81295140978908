import React, { useState } from "react";
import { useSelector } from 'react-redux';
import { Empty, Row, Segmented, Spin } from 'antd';
import {
    BarsOutlined,
    AppstoreOutlined
} from '@ant-design/icons';
import GridCard from "./GridCard";
import ListCard from "./ListCard";


const GridListView = ({ setCurrentIdFolder }) => {
    const folderCollections = useSelector((state) => state.folderCollections);
    const [view, setView] = useState('list')
    console.log('view je: ' + view);

    const getParentReadable = (parentId) => {
        const parent = folderCollections.find(x => x._id == parentId)?.folderName;
        return parent !== undefined ? parent : 'root';
    }

    return (
        <>
            <div
                style={{
                    textAlign: "center", marginTop: "2rem", marginBottom: "2rem"
                }}>
                <Segmented
                    options={[
                        {
                            value: 'list',
                            icon: <BarsOutlined />,
                        },
                        {
                            value: 'grid',
                            icon: <AppstoreOutlined />,
                        },
                    ]}
                    value={view}
                    onChange={setView}
                />
            </div>
            {view === "grid" ?
                <Row gutter={[10, 10]}>
                    {!folderCollections.length ? <Empty description="Create first folder!" image={Empty.PRESENTED_IMAGE_SIMPLE} /> : (
                        folderCollections.map((folderCollection) => (
                            <GridCard folderCollection={folderCollection}
                                setCurrentIdFolder={setCurrentIdFolder}
                                parentReadable={getParentReadable(folderCollection.folderParents)}
                            />
                        ))
                    )}
                </Row>
                :
                <>
                    {!folderCollections.length ? <Empty description="Create first folder!" image={Empty.PRESENTED_IMAGE_SIMPLE} /> : (
                        folderCollections.map((folderCollection) => (
                            <ListCard folderCollection={folderCollection}
                                setCurrentIdFolder={setCurrentIdFolder}
                                parentReadable={getParentReadable(folderCollection.folderParents)}
                            />
                        ))
                    )}
                </>
            };
        </>
    );
}

export default GridListView;