import React, { useState, useEffect } from 'react';
import { Table, Button, Modal, Form, Input, Select, DatePicker, message, Typography, Space, Popconfirm, Dropdown, Menu, Checkbox, InputNumber, Card } from 'antd';
import { EditOutlined, DeleteOutlined, PlusOutlined, SettingOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import { getExpenseverseProducts, createExpenseverseProduct, updateExpenseverseProduct, deleteExpenseverseProduct } from '../../../actions/expenseverseProductActions';
import dayjs from 'dayjs';
import formLayout from '../../Shared UI/formLayout';

const { Option } = Select;
const { Title, Paragraph } = Typography;

const ProductList = () => {
    const dispatch = useDispatch();
    const products = useSelector(state => state.expenseverseProducts);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [currentProduct, setCurrentProduct] = useState(null);
    const [form] = Form.useForm();
    const [visibleColumns, setVisibleColumns] = useState(['name', 'cost', 'status', 'actions']);
    const [dropdownVisible, setDropdownVisible] = useState(false);

    useEffect(() => {
        dispatch(getExpenseverseProducts());
    }, [dispatch]);

    const showModal = (product = null) => {
        setCurrentProduct(product);
        form.resetFields();
        if (product) {
            form.setFieldsValue({
                ...product,
                startDate: product.startDate ? dayjs(product.startDate) : null,
                endDate: product.endDate ? dayjs(product.endDate) : null,
            });
        }
        setIsModalVisible(true);
    };

    const handleOk = async () => {
        try {
            const values = await form.validateFields();
            values.name = values.name.trim(); // Trim the name field
            let result;
            if (currentProduct) {
                result = await dispatch(updateExpenseverseProduct(currentProduct._id, values));
            } else {
                result = await dispatch(createExpenseverseProduct(values));
            }
            if (result.success) {
                setIsModalVisible(false);
                form.resetFields();
                setCurrentProduct(null);
            }
        } catch (info) {
            console.log('Validate Failed:', info);
        }
    };

    const handleCancel = () => {
        setIsModalVisible(false);
        form.resetFields();
        setCurrentProduct(null);
    };

    const handleDelete = async (productId) => {
        await dispatch(deleteExpenseverseProduct(productId));
    };

    const getStatus = (endDate) => {
        if (endDate && dayjs(endDate).isBefore(dayjs())) {
            return 'Ended';
        }
        return 'Active';
    };

    const columns = [
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            fixed: 'left',
            width: 150,
            sorter: (a, b) => a.name.localeCompare(b.name),
            ellipsis: true,
            visible: true
        },
        {
            title: 'Type',
            dataIndex: 'type',
            key: 'type',
            sorter: (a, b) => a.type.localeCompare(b.type),
            ellipsis: true,
            visible: false
        },
        {
            title: 'Owner',
            dataIndex: 'owner',
            key: 'owner',
            sorter: (a, b) => a.owner.localeCompare(b.owner),
            ellipsis: true,
            visible: false
        },
        {
            title: 'Paid By',
            dataIndex: 'paidBy',
            key: 'paidBy',
            sorter: (a, b) => a.paidBy.localeCompare(b.paidBy),
            ellipsis: true,
            visible: false
        },
        {
            title: 'Beneficiaries',
            dataIndex: 'beneficiaries',
            key: 'beneficiaries',
            render: beneficiaries => beneficiaries.join(', '),
            ellipsis: true,
            visible: false
        },
        {
            title: 'Cost',
            dataIndex: 'cost',
            key: 'cost',
            sorter: (a, b) => a.cost - b.cost,
            ellipsis: true,
            visible: true
        },
        {
            title: 'Payments Frequency (Yearly)',
            dataIndex: 'paymentsFrequencyYearly',
            key: 'paymentsFrequencyYearly',
            sorter: (a, b) => a.paymentsFrequencyYearly - b.paymentsFrequencyYearly,
            ellipsis: true,
            visible: false
        },
        {
            title: 'Start Date',
            dataIndex: 'startDate',
            key: 'startDate',
            render: date => (date ? dayjs(date).format('YYYY-MM-DD') : ''),
            ellipsis: true,
            visible: false
        },
        {
            title: 'End Date',
            dataIndex: 'endDate',
            key: 'endDate',
            render: date => (date ? dayjs(date).format('YYYY-MM-DD') : ''),
            ellipsis: true,
            visible: false
        },
        {
            title: 'Created At',
            dataIndex: 'createdAt',
            key: 'createdAt',
            render: date => (date ? dayjs(date).format('YYYY-MM-DD') : ''),
            ellipsis: true,
            visible: false
        },
        {
            title: 'Note',
            dataIndex: 'note',
            key: 'note',
            ellipsis: true,
            visible: false
        },
        {
            title: 'Tags',
            dataIndex: 'tags',
            key: 'tags',
            render: tags => tags.join(', '),
            ellipsis: true,
            visible: false
        },
        {
            title: 'Account to Pay',
            dataIndex: 'accountToPay',
            key: 'accountToPay',
            ellipsis: true,
            visible: false
        },
        {
            title: 'Status',
            key: 'status',
            render: (text, record) => getStatus(record.endDate),
            ellipsis: true,
            visible: true
        },
        {
            title: 'Actions',
            key: 'actions',
            fixed: 'right',
            align: 'right',
            width: 100,
            render: (text, record) => (
                <Space>
                    <Button icon={<EditOutlined />} onClick={() => showModal(record)} />
                    <Popconfirm
                        title="Are you sure you want to delete this product?"
                        onConfirm={() => handleDelete(record._id)}
                        okText="Yes"
                        cancelText="No"
                    >
                        <Button icon={<DeleteOutlined />} />
                    </Popconfirm>
                </Space>
            ),
            ellipsis: true,
            visible: true
        },
    ];

    const handleMenuClick = (e) => {
        const columnKey = e.key;
        setVisibleColumns((prevVisibleColumns) =>
            prevVisibleColumns.includes(columnKey)
                ? prevVisibleColumns.filter((key) => key !== columnKey)
                : [...prevVisibleColumns, columnKey]
        );
    };

    const menu = (
        <Menu onClick={handleMenuClick}>
            {columns.map((column) => (
                <Menu.Item key={column.key}>
                    <Checkbox checked={visibleColumns.includes(column.key)}>
                        {column.title}
                    </Checkbox>
                </Menu.Item>
            ))}
        </Menu>
    );

    const handleDropdownVisibleChange = (flag) => {
        setDropdownVisible(flag);
    };

    const filteredColumns = columns.filter(column => visibleColumns.includes(column.key));

    return (
        <>
            <Title level={3}>Products</Title>
            <Paragraph>
                <p> Manage your financial products and services including insurnance, mortgage, pension funds and so on.</p>
                <p>
                    Add new products, edit existing ones, and set inactive those that are no longer needed.
                    To set product as inactive fill out its End date.</p>
            </Paragraph>
            <br />
            <br />
            <Space style={{ marginBottom: 16 }}>
                <Button type="primary" icon={<PlusOutlined />} onClick={() => showModal()}>
                    Add Product
                </Button>
                <Dropdown overlay={menu} trigger={['click']} onVisibleChange={handleDropdownVisibleChange} visible={dropdownVisible}>
                    <Button icon={<SettingOutlined />}>Columns</Button>
                </Dropdown>
            </Space>
            <Table
                columns={filteredColumns}
                dataSource={products}
                rowKey="_id"
                scroll={{ x: 500 }}
            />
            <Modal title={currentProduct ? 'Edit Product' : 'Add Product'} visible={isModalVisible} onOk={handleOk} onCancel={handleCancel}>
                <Form form={form} {...formLayout}>
                    <Card
                        bordered={false}
                        style={{
                            background: 'rgba(150,150,150,.07)',
                            marginBottom: 16,
                        }}>
                        <Form.Item name="name" label="Name" rules={[{ required: true, message: 'Please input the product name!' }]}>
                            <Input />
                        </Form.Item>
                        <Form.Item name='cost' label='Cost' rules={[{ required: true, message: 'Please input the cost!' }]}>
                            <InputNumber type="number" step="0.01" inputMode='decimal' />
                        </Form.Item>
                        <Form.Item name='paymentsFrequencyYearly' label='Payments Frequency (Yearly)' rules={[{ required: true, message: 'Please input the payments frequency!' }]}>
                            <InputNumber type="number" step="0.01" inputMode='decimal' />
                        </Form.Item>
                        <Form.Item name='accountToPay' label='Account to Pay' rules={[{ required: true, message: 'Please input the account to pay!' }]}>
                            <Input />
                        </Form.Item>
                        <Form.Item name='startDate' label='Start Date' rules={[{ required: true, message: 'Please select the start date!' }]}>
                            <DatePicker style={{ width: '100%' }} />
                        </Form.Item>
                    </Card>

                    <Form.Item name="type" label="Type" rules={[{ required: false, message: 'Please input the type!' }]}>
                        <Input />
                    </Form.Item>
                    <Form.Item name='owner' label='Owner' rules={[{ required: false, message: 'Please input the owner!' }]}>
                        <Input />
                    </Form.Item>
                    <Form.Item name='paidBy' label='Paid By'>
                        <Input />
                    </Form.Item>
                    <Form.Item name='beneficiaries' label='Beneficiaries'>
                        <Select mode='tags' style={{ width: '100%' }} tokenSeparators={[',']} />
                    </Form.Item>
                    <Form.Item name='note' label='Note'>
                        <Input />
                    </Form.Item>
                    <Form.Item name='tags' label='Tags'>
                        <Select mode='tags' style={{ width: '100%' }} tokenSeparators={[',']} />
                    </Form.Item>
                    <Form.Item name='endDate' label='End Date'>
                        <DatePicker style={{ width: '100%' }} />
                    </Form.Item>
                </Form>
            </Modal>

        </>
    );
};

export default ProductList;