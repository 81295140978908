export default (expenseData = [], action) => {
    switch (action.type) {
        case 'DELETE_EXPENSE':
            return expenseData.filter((expenseItem) => expenseItem._id !== action.payload);
        case 'UPDATE_EXPENSE':
            return expenseData.map((expenseItem) => expenseItem._id === action.payload._id ? action.payload : expenseItem);
        case 'FETCH_ALL_EXPENSES':
            return action.payload;
        case 'FETCH_EXPENSES_BY_DATE_RANGE':
            return action.payload;
        case 'CREATE_EXPENSE':
            return [...expenseData, ...action.payload];
        default:
            return expenseData;
    }
}