import React, { useEffect, useState } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { useAuth } from '../../contexts/authContext';

// used these instructions for react-router-dom v6 https://stackoverflow.com/a/69869761

export default function PrivateRoute() {
    const { currentUser } = useAuth();
    const auth = currentUser; // determine if authorized, from context or however you're doing it

    const [forceClearing, setForceClearing] = useState(false);
    console.log('auth from PrivateRoute is null')
    useEffect(() => {
        if (auth == null) {
            console.log('auth from PrivateRoute is really null');
            setForceClearing(true);
        } else {
            console.log('auth from PrivateRoute is really something')
        }
    }, [])

    console.log('privateRoute user is : ', auth)
    // If authorized, return an outlet that will render child elements
    // If not, return element that will navigate to login page
    return !forceClearing ? <Outlet /> : <Navigate to="/login" />;
}