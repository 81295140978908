import React, { useEffect, useRef } from 'react';
import { Chart } from '@antv/g2';

const CategoryBarChart = ({ categories, darkMode, separateCharts }) => {
    const chartContainerRef = useRef(null);
    const chartContainerRef2 = useRef(null); // For the second chart

    useEffect(() => {
        if (!chartContainerRef.current) return;

        // Function to create a chart
        const createChart = (container, data, title) => {
            const chart = new Chart({
                container: container,
                autoFit: true,
                height: 400,
                theme: darkMode ? 'classicDark' : 'classic',
            });

            chart.options({
                type: 'interval',
                data,
                encode: { x: 'categoryName', y: 'total', color: 'categoryName' },
                // style: { minHeight: 50 },
                axis: {
                    x: { title: false }, // Hide the x-axis title
                    y: { title: false }, // Hide the y-axis title
                },
                legend: true, // Enable the legend
            });

            chart.render();
            return chart;
        };

        // Data for income and outcome
        const incomeData = categories.map(category => ({
            categoryName: category.name,
            total: category.income,
        }));

        const outcomeData = categories.map(category => ({
            categoryName: category.name,
            total: Math.abs(category.outcome),
        }));

        // Create combined chart if separateCharts is false
        const combinedData = categories.map(category => ({
            categoryName: category.name,
            total: Math.abs(category.total),
        }));

        let chart, chart2;

        if (separateCharts) {
            // Create two charts for Income and Outcome
            chart = createChart(chartContainerRef.current, incomeData, 'Income');
            if (chartContainerRef2.current) {
                chart2 = createChart(chartContainerRef2.current, outcomeData, 'Outcome');
            }
        } else {
            // Create a single combined chart
            chart = createChart(chartContainerRef.current, combinedData, 'Total');
        }

        return () => {
            chart?.destroy();
            chart2?.destroy();
        };
    }, [categories, darkMode, separateCharts]);

    return (
        <div>
            {separateCharts && <h3 style={{ textAlign: 'center' }}>Income</h3>}
            <div ref={chartContainerRef} style={{ width: '100%', height: '400px' }} />
            {separateCharts && <>
                <h3 style={{ textAlign: 'center' }}>Outcome</h3>
                <div ref={chartContainerRef2} style={{ width: '100%', height: '400px', marginTop: '20px' }} />
            </>}
        </div>
    );
};

export default CategoryBarChart;
