import React, { useState } from 'react';
import PageContentWrapper from '../../PageContentWrapper/PageContentWrapper';
import { Typography } from 'antd';

const Daily = ({ setPageTitle }) => {
    const [currentIdFolder, setCurrentIdFolder] = useState(null);

    setPageTitle('Daily');

    return (
        <PageContentWrapper >
            <br />
        </PageContentWrapper>
    )
}

export default Daily;