import * as api from '../api/index';
import { getAuth, getIdToken } from "firebase/auth";

// action creators
export const getFolderCollections = () => async (dispatch) => {
    try {
        const auth = getAuth();
        const token = await getIdToken(auth.currentUser);
        const { data } = await api.fetchFolderCollections(token);

        dispatch({ type: 'FETCH_ALL_FOLDERS', payload: data });
    } catch (error) {
        console.log(error.message)
    }
}

export const createFolderCollection = (folderCollection) => async (dispatch) => {
    try {
        const auth = getAuth();
        const token = await getIdToken(auth.currentUser);
        const { data } = await api.createFolderCollection(folderCollection, token);

        dispatch({ type: 'CREATE_FOLDER', payload: data });
        return { status: 'success' };
    } catch (error) {
        console.log(error);
        return { status: 'error', message: error.message };
    }
}

export const updateFolderCollection = (id, folderCollection) => async (dispatch) => {
    try {
        const auth = getAuth();
        const token = await getIdToken(auth.currentUser);
        const { data } = await api.updateFolderCollection(id, folderCollection, token);

        dispatch({ type: 'UPDATE_FOLDER', payload: data });
        return { status: 'success' };
    } catch (error) {
        console.log(error.message);
        return { status: 'error', message: error.message };
    }
}

export const deleteFolderCollection = (id) => async (dispatch) => {
    try {
        const auth = getAuth();
        const token = await getIdToken(auth.currentUser);
        await api.deleteFolderCollection(id, token);

        dispatch({ type: 'DELETE_FOLDER', payload: id })
    } catch (error) {
        console.log(error);
    }
}