import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Calendar, ConfigProvider, Typography, Tabs, Select } from 'antd';
import enUS from 'antd/lib/locale/en_US';
import moment from 'moment';
import 'moment/locale/en-gb'; // Import the moment locale for English
import './styles.css';
import { getExpenseData } from '../../../actions/expensesActions';

// Set the locale globally
moment.locale('en-gb');

const { TabPane } = Tabs;
const { Option } = Select;

const CustomCalendar = ({ setPageTitle }) => {
    const [currentYear, setCurrentYear] = useState(moment().year());
    const transactions = useSelector((state) => state.expensesCollection);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getExpenseData());
    }, [dispatch]);

    setPageTitle('Custom Calendar');

    const cellRender = (current, info) => {
        if (info.type === 'date') {
            const dateString = current.format('YYYY-MM-DD');
            const dailyTransactions = transactions.filter(transaction =>
                moment(transaction.transactionDate).format('YYYY-MM-DD') === dateString
            );

            const positiveSum = dailyTransactions
                .filter(transaction => transaction.amount > 0)
                .reduce((sum, transaction) => sum + transaction.amount, 0);

            const negativeSum = dailyTransactions
                .filter(transaction => transaction.amount < 0)
                .reduce((sum, transaction) => sum + transaction.amount, 0);

            if (positiveSum === 0 && negativeSum === 0) {
                return <div>&nbsp;</div>;
            }

            return (
                <div>
                    {positiveSum !== 0 && (
                        <Typography.Text type="secondary" style={{ color: 'green', fontSize: '9px', display: 'block' }}>
                            +{Math.round(positiveSum)}
                        </Typography.Text>
                    )}
                    {negativeSum !== 0 && (
                        <Typography.Text type="secondary" style={{ color: 'red', fontSize: '9px', display: 'block' }}>
                            {Math.round(negativeSum)}
                        </Typography.Text>
                    )}
                </div>
            );
        } else if (info.type === 'month') {
            const monthString = current.format('YYYY-MM');
            const monthlyTransactions = transactions.filter(transaction =>
                moment(transaction.transactionDate).format('YYYY-MM') === monthString
            );

            const positiveSum = monthlyTransactions
                .filter(transaction => transaction.amount > 0)
                .reduce((sum, transaction) => sum + transaction.amount, 0);

            const negativeSum = monthlyTransactions
                .filter(transaction => transaction.amount < 0)
                .reduce((sum, transaction) => sum + transaction.amount, 0);

            if (positiveSum === 0 && negativeSum === 0) {
                return null;
            }

            return (
                <div>
                    {positiveSum !== 0 && (
                        <Typography.Text type="secondary" style={{ color: 'green', fontSize: '9px', display: 'block' }}>
                            In: +{Math.round(positiveSum)}
                        </Typography.Text>
                    )}
                    {negativeSum !== 0 && (
                        <Typography.Text type="secondary" style={{ color: 'red', fontSize: '9px', display: 'block' }}>
                            Out: {Math.round(negativeSum)}
                        </Typography.Text>
                    )}
                </div>
            );
        }
    };

    const getWeeksInYear = (year) => {
        const startOfYear = moment().year(year).startOf('year');
        const endOfYear = moment().year(year).endOf('year');
        const weeks = [];

        let currentWeek = startOfYear.clone().startOf('isoWeek');

        while (currentWeek.isBefore(endOfYear)) {
            weeks.push(currentWeek.clone());
            currentWeek.add(1, 'week');
        }

        return weeks;
    };

    const renderWeeks = () => {
        const weeks = getWeeksInYear(currentYear);

        return weeks.map((week, index) => {
            const startOfWeek = week.clone().startOf('isoWeek').format('YYYY-MM-DD');
            const endOfWeek = week.clone().endOf('isoWeek').format('YYYY-MM-DD');

            const weeklyTransactions = transactions.filter(transaction =>
                moment(transaction.transactionDate).isBetween(startOfWeek, endOfWeek, null, '[]')
            );

            const positiveSum = weeklyTransactions
                .filter(transaction => transaction.amount > 0)
                .reduce((sum, transaction) => sum + transaction.amount, 0);

            const negativeSum = weeklyTransactions
                .filter(transaction => transaction.amount < 0)
                .reduce((sum, transaction) => sum + transaction.amount, 0);

            return (
                <div key={index} className="week-cell">
                    <Typography.Text type="secondary" style={{ fontSize: '12px' }}>
                        Week {index + 1}
                    </Typography.Text>
                    <div>
                        {positiveSum !== 0 && (
                            <Typography.Text type="secondary" style={{ color: 'green', fontSize: '12px', display: 'block' }}>
                                In: +{Math.round(positiveSum)}
                            </Typography.Text>
                        )}
                        {negativeSum !== 0 && (
                            <Typography.Text type="secondary" style={{ color: 'red', fontSize: '12px', display: 'block' }}>
                                Out: {Math.round(negativeSum)}
                            </Typography.Text>
                        )}
                    </div>
                    <div className="week-dates">
                        ({startOfWeek} - {endOfWeek})
                    </div>
                </div>
            );
        });
    };

    const handleYearChange = (year) => {
        setCurrentYear(year);
    };

    return (
        <ConfigProvider locale={enUS}>
            <Tabs defaultActiveKey="1" style={{ padding: 24 }}>
                <TabPane tab="Month" key="1">
                    <div style={{ border: '1px solid #d9d9d9', borderRadius: 4 }}>
                        <Calendar fullscreen={false} cellRender={cellRender} />
                    </div>
                </TabPane>
                <TabPane tab="Year" key="2">
                    <div style={{ border: '1px solid #d9d9d9', borderRadius: 4 }}>
                        <Calendar fullscreen={false} mode="year" cellRender={cellRender} />
                    </div>
                </TabPane>
                <TabPane tab="Weeks" key="3">
                    <div style={{ padding: '16px' }}>
                        <Select
                            defaultValue={currentYear}
                            onChange={handleYearChange}
                            style={{ marginBottom: '16px', width: '100px' }}
                        >
                            {Array.from({ length: 10 }, (_, i) => {
                                const year = moment().year() - i;
                                return <Option key={year} value={year}>{year}</Option>;
                            })}
                        </Select>
                        <div className="weeks-grid">
                            {renderWeeks()}
                        </div>
                    </div>
                </TabPane>
            </Tabs>
        </ConfigProvider>
    );
};

export default CustomCalendar;