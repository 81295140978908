import React from 'react';
import { Card, Row, Col, Typography, Divider } from 'antd';

const { Text, Title } = Typography;

function roundToTwo(num) {
    return +(Math.round(num + "e+2") + "e-2");
}

const TotalSummaryPanel = ({ expenses,
    noCardBackground,
    noCardTitle,
    selectedCurrency
}) => {
    const totalTransactions = expenses.length;
    const totalIncoming = roundToTwo(expenses
        .filter(expense => expense.amount > 0)
        .reduce((sum, expense) => sum + expense.amount, 0));
    const totalOutgoing = roundToTwo(expenses
        .filter(expense => expense.amount < 0)
        .reduce((sum, expense) => sum + Math.abs(expense.amount), 0));
    const propNoBackgroundStyles = noCardBackground && { style: { background: 'none', border: 'none' } };
    const propNoTitle = !noCardTitle && { title: "Summary" }

    if (totalTransactions === 0) {
        return (
            <Card {...propNoTitle} {...propNoBackgroundStyles}>
                <div style={{ textAlign: 'center' }}>No transactions found for the selected date range.</div>
            </Card >
        );
    }

    return (
        <Card {...propNoTitle} {...propNoBackgroundStyles} >
            <Row>
                <Col span={24}>
                    <Text type='secondary'>Total Transactions: {totalTransactions}</Text>
                </Col>
            </Row>
            <Row>
                <Col span={12}>
                    <strong>
                        <Text type="success">Incoming:</Text>
                    </strong>
                    <Title level={3} type="success"> +{totalIncoming} {selectedCurrency}</Title>
                </Col>
                <Col span={12}>
                    <strong>
                        <Text type="danger">Outgoing:</Text>
                    </strong>
                    <Title level={3} type="danger"> -{totalOutgoing} {selectedCurrency}</Title>
                </Col>
            </Row>
        </Card>
    );
};

export default TotalSummaryPanel;
