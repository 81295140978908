import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { createExpenseData, updateExpenseData } from "../../../actions/expensesActions";
import { createExpenseverseCategory, getExpenseverseCategories } from "../../../actions/expenseverseCategoryActions";
import { Button, ConfigProvider, DatePicker, Input, InputNumber, Form, message, Modal, Space, Select, Typography, Col, Row, Radio, Tooltip, theme, Card, Switch, Checkbox, Divider, Drawer, Grid } from "antd";
import dayjs from 'dayjs';
import useOptions from "../../../utils/getOptions";
import locale from 'antd/es/date-picker/locale/en_US';
import enUS from 'antd/locale/en_GB';
import { WalletFilled, BankFilled, CheckOutlined } from '@ant-design/icons';
import './styles.css';
import OptionalInputs from "./OptionalFormInputs/OptionalInputs";
import DevVarDisplay from "../../Other/DevVarDisplay";
import { getDefaultSettings } from "../../../actions/expenseverseDefaultSettingsActions";
import { getExpenseverseAccounts } from "../../../actions/expenseverseAccountActions";
import { validCurrencies } from "../../../utils/currencyList";
import DefaultTagsModal from "../PageSettings/DefaultTagsModal";
const { Option } = Select;
const { useBreakpoint } = Grid;

const ExpenseForm = ({ handleRemoveURLParamsRelatedToDrawer, currentExpenseID, setCurrentExpenseID, visible, setVisible,
    selectedDay, selectedCategory, setSelectedCategory, setDefaultTags,
    defaultCurrency, defaultAccount, defaultPaymentMethod, allAccounts, darkMode, LightToken, DarkToken }) => {
    const { useToken } = theme;
    const { token } = useToken(); // get the token
    const screens = useBreakpoint();
    const [shortForm, setShortForm] = useState(true);
    const allTags = useOptions('expensesCollection', 'tags');
    const allLocations = useOptions('expensesCollection', 'location');
    const [inOutType, setInOutType] = useState('expense');
    const [applyReviewRules, setApplyReviewRules] = useState(false);
    const expensesCollection = useSelector((state) =>
        currentExpenseID ? state.expensesCollection.find((r) => r._id === currentExpenseID) : null
    );
    const [transactionType, setTransactionType] = useState(null);
    const categories = useSelector((state) => state.expenseverseCategories);
    const [allCategories, setAllCategories] = useState([]);

    const dispatch = useDispatch();
    const [form] = Form.useForm();

    const adjustCategories = () => {
        setAllCategories(categories.map(category => ({ label: category.name, value: category._id })));
    }

    const [selectedAccountDetails, setSelectedAccountDetails] = useState(null);
    const defaultSettings = useSelector(state => state.expenseverseDefaultSettings?.defaultSettings || {});
    const accounts = useSelector(state => state.expenseverseAccounts);

    // State to track screen size
    const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth < 350);
    // Determine if it's a mobile (XS) screen based on Ant Design breakpoints
    const isMobile = !screens.md;


    const handleShowMore = () => {
        setShortForm(v => !v);
    };

    // Effect to update state on window resize
    useEffect(() => {
        const handleResize = () => setIsSmallScreen(window.innerWidth < 350);

        // Add event listener on mount
        window.addEventListener('resize', handleResize);

        // Remove event listener on unmount
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    // Function to get account based on transaction type
    const getDefaultAccountDetails = (transactionType, accounts, defaultSettings) => {
        if (!accounts || !defaultSettings) {
            console.error('Accounts or defaultSettings are not defined');
            return null;
        }

        console.log({ accounts, defaultSettings, transactionType })

        switch (transactionType) {
            case 'cash':
                return accounts.find(account => account._id === defaultSettings.defaultCashAccount);
            case 'bank':
                return accounts.find(account => account._id === defaultSettings.defaultBankAccount);
            case 'virtual':
                return accounts.find(account => account._id === defaultSettings.defaultVirtualAccount);
            default:
                return null;
        }
    };

    useEffect(() => {
        dispatch(getExpenseverseCategories());
        dispatch(getDefaultSettings());
        dispatch(getExpenseverseAccounts());
    }, []);

    useEffect(() => {
        adjustCategories();
    }, [categories]);

    useEffect(() => {
        if (selectedCategory != '') {
            setVisible(true);
            form.setFieldsValue({ category: [selectedCategory] });
        }
    }, [selectedCategory]);

    useEffect(() => {
        if (visible && selectedDay) {
            form.setFieldsValue({ transactionDate: selectedDay });
        }
    }, [selectedDay, visible]);

    useEffect(() => {
        if (visible) {
            const accountDetails = getDefaultAccountDetails(transactionType, accounts, defaultSettings);
            setSelectedAccountDetails(accountDetails);
            form.setFieldsValue({
                currency: accounts.find(account => account?._id === defaultSettings?.defaultCashAccount)?.defaultCurrency,
                tags: setDefaultTags,
            });
        }
    }, [visible]);

    useEffect(() => {
        if (expensesCollection) {
            expensesCollection.reviewed && setShortForm(false);
            setSelectedAccountDetails(accounts.find(account => account._id === expensesCollection.accountId))
            form.setFieldsValue({
                transactionDate: dayjs(expensesCollection?.transactionDate),
                bankProcessedDate: expensesCollection.bankProcessedDate && dayjs(expensesCollection?.bankProcessedDate),
                amount: expensesCollection.amount,
                accountId: expensesCollection.accountId,
                paymentMethod: expensesCollection.paymentMethod,
                currency: expensesCollection.currency,
                category: expensesCollection.category,
                note: expensesCollection.note,
                location: expensesCollection.location,
                status: expensesCollection.status,
                provider: expensesCollection.provider,
                tags: expensesCollection.tags,
                groups: expensesCollection.groups,
                parents: expensesCollection.parents,
                tabID: expensesCollection.tabID,
                // transactionType: expensesCollection.transactionType,
                toAccountId: expensesCollection.toAccountId,
                fromAccountId: expensesCollection.fromAccountId,
                originalAmount: expensesCollection.originalAmount,
                originalCurrency: expensesCollection.originalCurrency,
                tip: expensesCollection.tip,
                reviewed: expensesCollection.reviewed || false,
                investment: expensesCollection.investment,
                property: expensesCollection.property,
            });
            setTransactionType(accounts.find(account => account._id === expensesCollection.accountId)?.accountType)
        }
    }, [expensesCollection]);

    const handleSubmit = async (values) => {
        try {
            // Get current categories from the form values, ensuring it's an array
            const formCategories = values.category || [];

            // Separate existing category IDs from new category names
            const existingCategoryIds = [];
            const newCategoryNames = [];

            if (formCategories.length > 0) {
                formCategories.forEach(category => {
                    if (allCategories.some(existingCategory => existingCategory.value === category)) {
                        existingCategoryIds.push(category);
                    } else {
                        newCategoryNames.push(category);
                    }
                });
            }

            // If there are new categories, show a confirmation dialog
            if (newCategoryNames.length > 0) {
                Modal.confirm({
                    title: 'New Category Detected',
                    content: (
                        <div>
                            <p>The category <strong>{newCategoryNames.join(', ')}</strong> does not exist. Do you want to continue and create this new category?</p>
                            <p>You can add an icon, color, or group the category in the <strong>Settings</strong> page later.</p>
                        </div>
                    ),
                    okText: 'Yes, Continue',
                    cancelText: 'No, Cancel',
                    onOk: async () => {
                        await proceedWithSubmit(values, existingCategoryIds, newCategoryNames);
                        handleRemoveURLParamsRelatedToDrawer();
                    },
                    onCancel: () => {
                        message.info('Transaction submission canceled.');
                    },
                });
            } else {
                // If no new categories, proceed directly
                await proceedWithSubmit(values, existingCategoryIds, []);
                handleRemoveURLParamsRelatedToDrawer()
            }
        } catch (error) {
            console.error(error.message);
            message.error(error.message || 'An unexpected error occurred. Please try again.');
        }
    };

    const proceedWithSubmit = async (values, existingCategoryIds, newCategoryNames) => {

        try {
            // Create new categories and get their IDs if there are any new categories
            const newCategoryIds = await Promise.all(newCategoryNames.map(async categoryName => {
                const result = await dispatch(createExpenseverseCategory({ name: categoryName }));
                if (result.status === 'success') {
                    return result.data._id; // Assuming the new category ID is in result.data._id
                } else {
                    message.error(`Failed to create category: ${categoryName}`);
                    throw new Error(`Failed to create category: ${categoryName}`);
                }
            }));

            // Combine existing category IDs with new category IDs
            const allCategoryIds = [...existingCategoryIds, ...newCategoryIds];

            // Update the form values with the combined category IDs
            values.category = allCategoryIds;

            let result;
            if (currentExpenseID) {
                result = await dispatch(updateExpenseData(currentExpenseID, values));
                if (result.status === 'success') {
                    message.success('Transaction updated successfully!');
                    clear();
                    setVisible(false);
                } else {
                    message.error(`An error occurred while updating transaction: ${result.message}`);
                }
            } else {
                values.reviewed = shortForm ? false : true;

                result = await dispatch(createExpenseData(values));
                if (result.status === 'success') {
                    message.success('New transaction created successfully!');
                    clear();
                    setVisible(false);
                } else {
                    message.error('An error occurred while creating transaction. Please try again.');
                }
            }
        } catch (error) {
            console.error(error.message);
            message.error(error.message || 'An unexpected error occurred. Please try again.');
        }
    };


    const clear = () => {
        setCurrentExpenseID(null);
        form.resetFields();
        setTransactionType(null);
        setSelectedCategory('');
        setShortForm(true);
        setInOutType('expense')
    };

    const showModal = () => {
        setShortForm(true);
        setVisible(true);
        clear();
    };

    const handleCancel = () => {
        handleRemoveURLParamsRelatedToDrawer();
        setVisible(false);
        clear();
    };

    const formName = `expenseForm${currentExpenseID}`;

    const suffixSelector = (
        <Form.Item name="currency" noStyle rules={[{ required: true, message: 'Please choose currency!' }]}>
            <Select placeholder="Select a currency" style={{ width: 70 }}>
                {validCurrencies.map(({ code, name, symbol }) => (
                    <Option key={code} value={code}>
                        {symbol}
                    </Option>
                ))}
            </Select>
        </Form.Item>
    );

    const inputVariant = { variant: "filled" };




    // Define styles as objects
    // const containerStyle = {
    //     width: 'inherit',
    //     display: 'grid',
    //     gap: '10px',
    //     gridTemplateColumns: 'calc(50% - 5px) calc(50% - 5px)',  // Subtract half the gap from each column
    //     gridTemplateRows: 'auto auto',
    // };

    // const amountStyle = {
    //     gridColumn: isSmallScreen ? '1 / 3' : '1 / 2',  // Full width on small screens, 1st column on larger screens
    // };

    // const accountStyle = {
    //     gridColumn: '1 / 2',
    //     gridRow: '2',
    // };

    // const restStyle = {
    //     gridColumn: '2 / 3',
    //     gridRow: isSmallScreen ? '2' : '1 / 3',  // Full height on large screens
    // };

    // // Determine screen size
    // const isSmallScreen = window.innerWidth < 768;

    const content = <>
        <Form form={form} name={formName} onFinish={handleSubmit}
            initialValues={{
                transactionDate: dayjs(Date.now()),
                reviewed: false,
                currency: getDefaultAccountDetails(transactionType, accounts, defaultSettings)?.defaultCurrency,
                // accountId: getDefaultAccountDetails(transactionType, accounts, defaultSettings)?._id,
                // paymentMethod: getDefaultAccountDetails(transactionType, accounts, defaultSettings)?.defaultPaymentMethod,
            }}
        >
            <Row gutter={[16, 16]}>
                <Col span={24}>
                    <Row gutter={[16, 16]}>
                        <Col span={isSmallScreen ? 24 : 16}>
                            <Form.Item name="amount"
                                rules={[
                                    { required: true }, // Required rule
                                    () => ({
                                        validator(_, value) {
                                            if (value != 0) {
                                                return Promise.resolve(); // Valid amount, no error
                                            }
                                            return Promise.reject(new Error('Amount must be greater than zero')); // Error for zero or negative
                                        },
                                    }),
                                ]}>
                                <InputNumber placeholder="Amount" {...inputVariant} size="large"
                                    inputmode="decimal"
                                    decimalSeparator={','}
                                    onChange={(value) => { inOutType === 'expense' ? form.setFieldValue('amount', Math.abs(value) * -1) : form.setFieldValue('amount', Math.abs(value) * 1); }}
                                    addonAfter={suffixSelector}
                                    addonBefore={
                                        <Select
                                            defaultValue={expensesCollection?.amount == null ? 'expense' : expensesCollection?.amount < 0 ? 'expense' : 'income'}
                                            popupMatchSelectWidth={false}
                                            onChange={(value) => {
                                                var amount = form.getFieldValue('amount') || 0;
                                                var orgAmount = form.getFieldValue('originalAmount') || 0;
                                                setInOutType(value);
                                                value === 'expense' ? form.setFieldsValue({
                                                    amount: Math.abs(amount) * -1,
                                                    originalAmount: Math.abs(orgAmount) * -1,
                                                }) : form.setFieldsValue({
                                                    amount: Math.abs(amount) * 1,
                                                    originalAmount: Math.abs(orgAmount) * 1,
                                                });
                                            }}
                                            options={[
                                                { value: 'expense', label: <span className="material-icons" style={{ fontSize: 20, verticalAlign: 'middle', opacity: .7, color: "red" }}>trending_down</span> },
                                                { value: 'income', label: <span className="material-icons" style={{ fontSize: 20, verticalAlign: 'middle', opacity: .7, color: "green" }}>trending_up</span> }
                                            ]}
                                        />
                                    }
                                />
                            </Form.Item>
                            <Form.Item name="note" >
                                <Input placeholder="Note" {...inputVariant} />
                            </Form.Item>

                            <Row gutter={[16, 16]}>
                                <Col
                                    span={20}
                                >
                                    <Form.Item name="tags" >
                                        <Select {...inputVariant}
                                            name="tags"
                                            mode="tags"
                                            style={{ width: "100%" }}
                                            placeholder="Tags"
                                            value={expensesCollection?.tags[0] === "" ? [] : expensesCollection?.tags}
                                            onChange={(e) => ({ tags: e })}
                                            tokenSeparators={[","]}
                                            options={allTags}
                                        />
                                    </Form.Item>
                                </Col>
                                <Col
                                    span={4}
                                >
                                    {/* <DefaultSettingsModal showTags
                                        showIconInsteadOfTitle={'page_info'}
                                    /> */}
                                    <DefaultTagsModal showIconInsteadOfTitle={'page_info'} />
                                </Col>
                            </Row>
                        </Col>

                        <Col span={isSmallScreen ? 24 : 8}>
                            <Form.Item name="category"
                                rules={applyReviewRules ? [{ required: true, message: 'Please choose category!' }] : null}
                            >
                                <Select locale={locale}
                                    variant='text'
                                    style={{ minWidth: 85 }} // Add this line for content fit
                                    allowClear
                                    optionFilterProp="label"
                                    name="category"
                                    mode="tags"
                                    popupMatchSelectWidth={false}
                                    placeholder="Category"
                                    value={expensesCollection?.category[0] === "" ? [] : expensesCollection?.category}
                                    onChange={(value) => {
                                        // Replace the previous value with the new one
                                        const newValue = value.length > 0 ? [value[value.length - 1]] : [];
                                        form.setFieldsValue({ category: newValue });
                                    }} tokenSeparators={[","]}
                                    options={allCategories}
                                />
                            </Form.Item>

                            <Form.Item name="transactionDate" style={{ width: 'inherit' }} rules={[{ required: true, message: 'Please input the Transaction Date!' }]}>
                                <DatePicker
                                    variant="text"
                                    style={{ width: 'auto', minWidth: 'fit-content' }} // Add this line for content fit
                                    needConfirm={false}
                                    showNow={false} />
                            </Form.Item>
                        </Col>
                    </Row>
                    {/* <DevVarDisplay value={applyReviewRules} /> */}
                    <Divider plain>
                        <Space>
                            <Button type='ghost' size="small"
                                style={{ background: token.colorBgContainerDisabled, color: token.colorTextSecondary }}
                                onClick={handleShowMore}>{shortForm ? 'Show' : 'Hide'} Others</Button>
                            <Form.Item name="reviewed" valuePropName="checked"
                                // onClick={() => setShortForm(false)}
                                style={{ marginBottom: 0 }}>
                                <Switch onChange={(v) => {
                                    setApplyReviewRules(v);
                                    v && setShortForm(false);
                                    if (!v) {
                                        // Reset the field's validation when switching back to 'Draft'
                                        form.setFields([
                                            { name: 'category', errors: [] },
                                            { name: 'accountId', errors: [] },
                                            { name: 'bankProcessedDate', errors: [] },
                                        ]);
                                    }
                                }}
                                    checkedChildren={<><CheckOutlined /> Reviewed</>}
                                    unCheckedChildren="Draft"
                                    defaultChecked={false}
                                />
                            </Form.Item>
                        </Space>
                    </Divider>
                </Col>
            </Row>

            {!shortForm && <>
                <Row style={{ width: 'inherit' }} gutter={[16, 16]}>
                    <Col span={isSmallScreen ? 24 : 12}>

                        <Form.Item name="location"  >
                            <Select {...inputVariant}
                                popupMatchSelectWidth={false}
                                locale={locale}
                                mode="tags"
                                placeholder="Location"
                                onChange={(selectedItems) => {
                                    const locationString = selectedItems[selectedItems.length - 1] || null;
                                    form.setFieldValue('location', locationString);
                                }}
                                options={allLocations}
                            />
                        </Form.Item>

                        <Form.Item name="originalAmount" style={{ width: "100%" }}>
                            <InputNumber placeholder="Org. Amount" {...inputVariant}
                                style={{ width: "100%" }}
                                onChange={(value) => { inOutType === 'expense' ? form.setFieldValue('originalAmount', Math.abs(value) * -1) : form.setFieldValue('originalAmount', Math.abs(value) * 1); }}
                                addonAfter={
                                    <Form.Item name="originalCurrency" noStyle>
                                        <Select placeholder="Select a currency" style={{ width: 70 }}
                                            defaultValue={'EUR'}>
                                            {validCurrencies.map(({ code, name, symbol }) => (
                                                <Option key={code} value={code}>
                                                    {symbol}
                                                </Option>
                                            ))}
                                        </Select>
                                    </Form.Item>
                                }
                            />
                        </Form.Item>

                        <Form.Item name="tip"  >
                            <Input placeholder="Tips" {...inputVariant} />
                        </Form.Item>

                        <Form.Item name="investment" valuePropName="checked" >
                            <Checkbox>Investment</Checkbox>
                        </Form.Item>

                        <Form.Item name="property" valuePropName="checked" >
                            <Checkbox>Personal Property</Checkbox>
                        </Form.Item>
                    </Col>

                    <Col span={isSmallScreen ? 24 : 12}>
                        <Card styles={{
                            body: {
                                padding: 8,
                                background: 'rgba(150,150,150,.07)',
                                marginBottom: 16,
                            },
                        }}
                            bordered={false}  >
                            <Form.Item>
                                <Select popupMatchSelectWidth={false}
                                    placeholder={'Choose type'}
                                    value={transactionType}
                                    variant='text'
                                    onChange={(value) => {
                                        setTransactionType(value);
                                        var currentTransactionDate = form.getFieldValue('transactionDate');
                                        form.setFieldsValue({
                                            'accountId': getDefaultAccountDetails(value, accounts, defaultSettings)?._id,
                                            'paymentMethod': getDefaultAccountDetails(value, accounts, defaultSettings)?.defaultPaymentMethod,
                                            'bankProcessedDate': value === 'cash' ? currentTransactionDate : null,
                                            'currency': getDefaultAccountDetails(value, accounts, defaultSettings)?.defaultCurrency
                                        })
                                        const selectedAccount = getDefaultAccountDetails(value, accounts, defaultSettings);
                                        setSelectedAccountDetails(selectedAccount || null);
                                    }}
                                >
                                    <Option value="cash">Cash Transaction</Option>
                                    <Option value="bank">Bank Transaction</Option>
                                    <Option value="virtual">Virtual Transaction</Option>
                                </Select>
                            </Form.Item>

                            <Form.Item name="accountId"
                                rules={applyReviewRules ? [{ required: true, message: 'Please select account!' }] : null}
                            >
                                <Select variant="text"
                                    defaultValue={selectedAccountDetails?._id}
                                    placeholder="Account"
                                    onChange={(e) => {
                                        const selectedAccount = accounts.find(account => account._id === e);
                                        setSelectedAccountDetails(selectedAccount || null);
                                        setTransactionType(selectedAccount.accountType);
                                        var currentTransactionDate = form.getFieldValue('transactionDate');
                                        form.setFieldsValue({
                                            'paymentMethod': selectedAccount.defaultPaymentMethod?.length > 0 ? selectedAccount.defaultPaymentMethod : null,
                                            'currency': selectedAccount?.defaultCurrency,
                                            'bankProcessedDate': selectedAccount.accountType === 'cash' ? currentTransactionDate : null,
                                        })
                                    }}
                                    allowClear>
                                    {accounts.map(account => (
                                        <Option
                                            key={account._id}
                                            value={account._id}
                                            disabled={account.endDate && dayjs(account.endDate).isBefore(dayjs())}
                                        >
                                            {account.name} {account.endDate && dayjs(account.endDate).isBefore(dayjs()) ? '(Inactive)' : ''}
                                        </Option>
                                    ))}
                                </Select>
                            </Form.Item>

                            <Form.Item name="paymentMethod" wrapperCol={{ span: 24 }}>
                                <Select variant="text"
                                    placeholder="Amount"
                                    defaultValue={selectedAccountDetails?.defaultPaymentMethod}
                                >
                                    {selectedAccountDetails?.cards.map(card => (
                                        <Option
                                            key={card}
                                            value={card}
                                        >
                                            **** **** **** {card.slice(-4)}
                                        </Option>
                                    ))}
                                </Select>
                            </Form.Item>

                            <Form.Item name="bankProcessedDate" style={{ width: 'inherit' }}
                                rules={applyReviewRules ? [{ required: true, message: 'This field is required!' }] : null}
                            >
                                <DatePicker variant="text"
                                    placeholder="Accounted Date"
                                    style={{ width: '100%' }} />
                            </Form.Item>
                        </Card>



                    </Col>
                </Row>
            </>}

            <Form.Item style={{ paddingBottom: '24px', marginBottom: 0 }}>
                <Row style={{ width: 'inherit' }} gutter={[16, 16]}>
                    <Col xs={12} md={12}>
                        <Button className={"classes.buttonSubmit"} form={formName} variant="text" style={{ width: '100%' }} size="large" onClick={clear}>Clear Form</Button>
                    </Col>
                    <Col xs={12} md={12}>
                        <Button className={"classes.buttonSubmit"} form={formName} htmlType="submit" variant="contained" style={{ width: '100%' }} size="large" type="primary">Submit</Button>
                    </Col>
                </Row>
            </Form.Item>
        </Form>
    </>

    return (
        <>
            <Button onClick={showModal} tooltip='test' type="primary">
                <span className="material-icons" style={{ fontSize: 16, verticalAlign: 'middle', opacity: .7 }}>add_circle_outline</span>
            </Button>
            <ConfigProvider locale={enUS} theme={{
                components: {
                    Input: {
                        colorText: token.colorTextSecondary,
                    },
                    Select: {
                        colorText: token.colorTextSecondary,
                    },
                    DatePicker: {
                        colorText: token.colorTextSecondary,
                    },
                    Checkbox: {
                        colorText: token.colorTextSecondary,
                    },
                },
            }}>
                {isMobile ? (
                    // Show Drawer for XS screens
                    <Drawer placement="bottom" className={`handleNotchBottom ${!shortForm ? `handleNotchTop` : null}`}
                        styles={{ body: { height: '100%' } }}
                        height={shortForm ? '450px' : '100%'} //height adapts to content by setting height, without it it will become scrollable with fixed width instead
                        visible={visible}
                        onClose={handleCancel}
                        title={currentExpenseID ? 'Edit Transaction' : 'Create Transaction'}
                    >
                        {content}
                    </Drawer>
                ) : (
                    // Show Modal for SM and higher screens
                    <Modal
                        className="removeDefaultPadding addCustomBottomPadding"
                        title={currentExpenseID ? 'Edit Transaction' : 'Create Transaction'}
                        visible={visible}
                        onCancel={handleCancel}
                        footer={null}
                        destroyOnClose
                        style={{
                            marginBottom: 0,
                            borderRadius: token.borderRadius === 6 ? 13 : token.borderRadius,
                            // Additional styles
                        }}
                    >
                        {content}
                    </Modal>
                )}

                {/* </Modal > */}
                {/* </Drawer> */}
            </ConfigProvider >
        </>
    );
};

export default ExpenseForm;