import React from 'react';
import { theme } from 'antd';
import ProLayout, { PageContainer } from '@ant-design/pro-layout';

const PageContentWrapper = (props) => {
    const { useToken } = theme;
    const { token } = useToken(); // get the token
    return (
        <>
            <PageContainer theme={'light'}
                className='moveDown'
            >
                <div
                    style={{
                        maxWidth: '1200px',
                        margin: '0 auto',
                        paddingTop: 16,
                        color: token.colorPrimary,
                    }}
                >
                    {props.breadcrumbs}
                </div>

                <div
                    style={{
                        maxWidth: '1200px',
                        margin: '0 auto',
                        minHeight: 360,
                        color: token.colorPrimary,
                        justifyContent: 'center'
                    }}
                >
                    {props.children}
                </div>
            </PageContainer>
        </>
    );
};

export default PageContentWrapper;
