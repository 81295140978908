import { useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';

export function useDeepLinkHighlight(otherDependencyArray) {

    /* DEEP LINKS
   ** Scroll to the note element when it is rendered
   ** Get the note ID from the URL query and Create a ref to store the note element

   ** other dependency array can be used to trigger useeffect on other neccessary rerenders, 
   ** for example if returning new data after form submission or when returning 
   ** empty array from filters and rendering orriginal array
   ** in this cases it is ideal to provide extra dependency array, if it is 
   ** not present nothing happens, useEffect will trigger everytime noteRef rerenders on page
   */
    const noteId = new URLSearchParams(useLocation().search).get("noteId");
    const noteRef = useRef(null);
    const hasAnimated = useRef(false);
    // check if note exists  
    const doesNoteExist = noteRef.current ? true : false;

    useEffect(() => {
        if (noteRef.current && !hasAnimated.current) {
            noteRef.current.scrollIntoView({ behavior: "smooth", block: 'center' });
            console.log('noteId', noteId)

            // Add the animation class to the note element
            setTimeout(() => {
                noteRef.current.classList.add("highlightMe");
            }, 1000);

            // Remove the animation class
            setTimeout(() => {
                noteRef.current.classList.remove("highlightMe");
            }, 5000);

            hasAnimated.current = true;
        }

    }, [noteRef, otherDependencyArray]);

    return { noteId, noteRef, doesNoteExist };
}