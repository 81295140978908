export default (pageTabsCollections = [], action) => {
    switch (action.type) {
        case 'DELETE_PAGETABS':
            return pageTabsCollections.filter((pageTabsCollection) => pageTabsCollection._id !== action.payload);
        case 'UPDATE_PAGETABS':
            return pageTabsCollections.map((pageTabsCollection) => pageTabsCollection._id === action.payload._id ? action.payload : pageTabsCollection);
        case 'FETCH_ALL_PAGETABS':
            return action.payload;
        case 'CREATE_PAGETABS':
            return [...pageTabsCollections, action.payload];
        default:
            return pageTabsCollections;
    }
}