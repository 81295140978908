import React, { useEffect, useState } from 'react';

import { Layout, Menu, Button, ConfigProvider, Modal, notification, Space, theme, Typography, Affix, Flex, Grid, Col, Row, Card, Collapse } from 'antd';

import { useDispatch, useSelector } from 'react-redux';

import { getNoteCollections } from '../actions/noteCollections'
import NotesCollections from '../components/NotesView/NotesCollections/NotesCollections';
import NoteForm from '../components/NotesView/NoteForm/NoteForm';
import obrazok from '../images/folder.png';
// import useStyles from '../styles';
import "../styles.css";
import { getFolderCollections } from '../actions/folderCollections';
import FolderTreeMenu from '../components/Navigation/NavigationIgnoringTabs/FolderTreeMenu';
import metadata from '../metadata.json'; // only this article worked (https://richhewlett.com/2020/07/11/auto-increment-build-number-in-a-javascript-app/),
import PageContentWrapper from './PageContentWrapper/PageContentWrapper';
import NavigationTree from '../components/Navigation/NavigationIncludingTabs/NavigationTree';
import Home1 from '../components/Home';
import NoteFormInTab from '../components/NotesView/NoteForm/NoteFormInTab';
import FolderForm from '../components/FolderView/FolderForm/FolderForm';
import { createDataTree } from '../components/Navigation/TestEdge/createDataTreeForBreadcrumbsWithHome';
import DevVarDisplay from '../components/Other/DevVarDisplay';
//using environmental variables as in this article did not work, thats why i used solution above  (https://medium.com/@diptimaya.patra/auto-increment-react-app-version-when-a-build-is-created-343f2006c978) 

const Home = ({ darkMode, LightToken, DarkToken, setPageTitle }) => {
    const [currentId, setCurrentId] = useState(null);

    setPageTitle('Home');

    // const classes = useStyles();
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getNoteCollections());
    }, [currentId, dispatch]);

    useEffect(() => {
        dispatch(getFolderCollections());
    }, []);

    const [loadedCount, setLoadedCount] = useState(5); // Initial count of loaded notes
    const allNotes = useSelector((state) => state.noteCollections);
    const recentNotes = allNotes.slice(-loadedCount);

    const handleLoadMore = () => {
        // Increase the count of loaded notes by 5
        setLoadedCount(loadedCount + 5);
    };


    // Find Lost items Only
    const folders = useSelector((state) => state.folderCollections);
    const notes = useSelector((state) => state.noteCollections);

    let validParentIds = new Set(Object.values(folders).flatMap(folder => folder._id));
    let notesWithouParents = Object.values(notes).filter(note => note.parents.length === 0 || note.parents.some(parentId => !validParentIds.has(parentId)));

    const onChange = (key) => {
        console.log(key);
    };
    return (

        <PageContentWrapper >
            <Row gutter={[16, 16]} >
                <Col xs={12} sm={12} md={12} lg={12} xl={6}>
                    {notes.length >= 1 ? <Card bordered={false} >
                        <Typography.Title level={5}>New Note</Typography.Title>
                        < NoteFormInTab />
                    </Card> :
                        < NoteFormInTab addFirstNote={true} />
                    }
                </Col>
                <Col xs={12} sm={12} md={12} lg={12} xl={6}>
                    {folders.length >= 1 ? <Card bordered={false} >
                        <Typography.Title level={5}>New Folder</Typography.Title>
                        < FolderForm />
                    </Card> : < FolderForm addFirstFolder={true} />}
                </Col>
                {notes.length >= 1 ? <> <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                    <Collapse onChange={onChange}
                        items={
                            [{
                                key: '1',
                                label: `Recent Notes `,
                                children: [<Space direction="vertical" size='middle' style={{ width: '100%' }}>
                                    {loadedCount < allNotes.length && ( // Render button only if there are more notes to load
                                        <> <Button onClick={handleLoadMore}>Load 5 More </Button> </>
                                    )}
                                    <NotesCollections setCurrentId={setCurrentId} receivedCollection={recentNotes} showVisitFolderLink={true} distinguishBackground />
                                    {loadedCount < allNotes.length && ( // Render button only if there are more notes to load
                                        <> <Button onClick={handleLoadMore}>Load 5 More </Button> </>
                                    )}
                                </Space>]
                            }]
                        }
                    />
                </Col>
                    <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                        <Collapse onChange={onChange}
                            items={
                                [{
                                    key: '1',
                                    label: `Notes without no parent (${notesWithouParents.length})`,
                                    children:
                                        [<Space direction="vertical" size='middle' style={{ width: '100%' }}>
                                            <NotesCollections setCurrentId={setCurrentId} receivedCollection={notesWithouParents} distinguishBackground />
                                        </Space>]
                                }]} />
                    </Col></> : null}
            </Row>
            <br />
            <br />
            {/* </Grid> */}
            {/* <Affix offsetTop={0} margin={0}>
                <Layout.Header style={{
                    borderRadius: 5,
                    border: '1px solid rgba(123,123,123,.5)',

                    height: 40,
                    display: 'flex',
                    alignItems: 'center',
                    color: darkMode ? DarkToken.colorText : LightToken.colorText,
                    background: darkMode ? DarkToken.darkItemBg : LightToken.colorBgBase,
                    // position: 'sticky'
                }}>NoteVerse - {`${metadata.buildMajor}.${metadata.buildMinor}/ ${metadata.buildRevision} ${metadata.buildTag}`}</Layout.Header>
            </Affix> */}

        </PageContentWrapper>
    )
}

export default Home;