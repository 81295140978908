import { Link } from "react-router-dom";
import { useDispatch } from 'react-redux';
import { Avatar, Button, Card, Col, Descriptions, Popover } from 'antd';
import {
    EditOutlined,
    FolderFilled,
    DeleteOutlined,
    InfoCircleOutlined
} from '@ant-design/icons';
import { deleteFolderCollection } from "../../actions/folderCollections";
import moment from 'moment';

const ListCard = ({ folderCollection, setCurrentIdFolder, parentReadable }) => {
    const dispatch = useDispatch();

    const deleteFolder = (id) => {
        if (window.confirm('Delete the item?')) {
            dispatch(deleteFolderCollection(id));
        }
    };

    const items = [
        {
            key: '1',
            label: 'folderID',
            children: folderCollection._id,
        },
        {
            key: '2',
            label: 'created',
            children: folderCollection.createdAt,
        },
        {
            key: '3',
            label: 'readable',
            children: moment(folderCollection.createdAt).fromNow(),
        },
        {
            key: '4',
            label: 'parents',
            children: (parentReadable + ' (' + folderCollection.folderParents + ')'),
        }
    ];
    return (
        <div style={{ marginBottom: 10 }}>
            <Col>
                <Card
                    title={<Link to={`/folders/${folderCollection._id}`}><Avatar size={{ md: 20, lg: 25, xl: 30, xxl: 35 }} ><FolderFilled /></Avatar> {folderCollection.folderName}</Link>}
                    hoverable
                    bodyStyle={{ backgroundColor: 'red', display: 'none' }}
                    extra={<>
                        <Button
                            type="link"
                            onClick={() => setCurrentIdFolder(folderCollection._id)}
                            icon={<EditOutlined />}
                        />
                        <Popover content={<Descriptions
                            style={{ maxWidth: '340px' }}
                            title={folderCollection.folderName}
                            items={items}
                            size="small"
                            column={1}
                        />}
                        >
                            <Button
                                type="link"
                                icon={<InfoCircleOutlined />}
                            /></Popover>
                        <Button
                            type="link"
                            onClick={() => deleteFolder(folderCollection._id)}
                            icon={<DeleteOutlined />}
                        />
                    </>}
                />
            </Col>
        </div>
    );
}

export default ListCard;