import React, { useEffect, useState, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Select, Button, ConfigProvider, Card, Row, Col, Space, DatePicker, Tabs, Checkbox } from 'antd';
import { getExpenseDataByDateRange } from '../../../actions/expensesActions';
import dayjs from 'dayjs';
import { useLocation, useNavigate } from 'react-router-dom';
import { getDateRangeFromParams } from '../../../utils/dateUtils';
import quarterOfYear from 'dayjs/plugin/quarterOfYear';
import 'dayjs/locale/en'; // import locale
import locale from 'antd/es/date-picker/locale/en_US';
import updateLocale from 'dayjs/plugin/updateLocale';
import DateRangeHeader from './DateRangeHeader';
import DateRangePicker from './DateRangePicker';
import TotalSummaryPanel from './TotalSummaryPanel';
import CategorySummaryPanel from './CategorySummaryPanel';
import CategoryPieChartPanel from './CategoryPieChartPanel';
import TagsSummaryPanel from './TagsSummaryPanel';
import G2_divergingBarChart from '../../../components/ExpensesView/Charts/G2_divergingBarChart';
import { getAllDailyLimits, getDailyLimitsByRange } from '../../../actions/expenseverseDailyLimitActions';
import { processCategories } from '../../../utils/categoryUtils';
import { getExpenseverseCategories } from '../../../actions/expenseverseCategoryActions';
import PageContentWrapper from '../../PageContentWrapper/PageContentWrapper';
import Expenses from '../../../components/ExpensesView/Expenses/Expenses';
import CategoryBarChart from './CategoryBarChart';
import { getExpenseverseAccounts } from '../../../actions/expenseverseAccountActions';

dayjs.extend(quarterOfYear);
dayjs.locale('en');
dayjs.extend(updateLocale);
dayjs.updateLocale('en', {
    weekStart: 1,
});

const Insights = ({ setPageTitle, darkMode }) => {
    const dispatch = useDispatch();
    const expenses = useSelector(state => state.expensesCollection);
    const accounts = useSelector(state => state.expenseverseAccounts);

    const [triageExpenses, setTriageExpenses] = useState(expenses);  // For expenses filtered by currency
    const [selectedCurrency, setSelectedCurrency] = useState(null);  // State for selected currency
    const [availableCurrencies, setAvailableCurrencies] = useState([]);  // State to store available currencies
    const [selectedAccounts, setSelectedAccounts] = useState([]);  // State for selected accounts (multiple)
    const [availableAccounts, setAvailableAccounts] = useState([]);  // State to store available accounts

    const categories = useSelector((state) => state.expenseverseCategories);
    const dailyLimits = useSelector((state) => state.expenseverseDailyLimit.dailyLimits);
    const [dateRange, setDateRange] = useState([dayjs().startOf('month'), dayjs().endOf('month')]);
    const [dateRangeType, setDateRangeType] = useState('Month'); // default to Month
    const [filteredExpenses, setFilteredExpenses] = useState(expenses); // State to manage filtered expenses
    const [selectedTags, setSelectedTags] = useState([]); // State to manage selected tags
    const [matchAllTags, setMatchAllTags] = useState(false); // New state for tag matching logic
    const [typeCategoriesChart, setTypeCategoriesChart] = useState(true); // New state for tag matching logic
    const [separateCharts, setSeparateCharts] = useState(false); // State for separate charts
    const location = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        setPageTitle('Analytics');
        dispatch(getExpenseverseCategories());
        dispatch(getExpenseverseAccounts());
        const params = new URLSearchParams(location.search);
        const { type, range } = getDateRangeFromParams(params);

        // Ensure that the range values are dayjs objects
        const startDate = dayjs(range[0]);
        let endDate = dayjs(range[1]);

        // Adjust endDate to end of the day for 'Custom' type to match 'Week' type behavior
        if (type === 'Custom') {
            endDate = endDate.endOf('day');
        }

        setDateRangeType(type);
        setDateRange([startDate, endDate]);

        const startDateAsString = startDate.format('YYYY-MM-DD');  // Extract the date part

        dispatch(getExpenseDataByDateRange(startDate.toISOString(), endDate.toISOString()));
        dispatch(getDailyLimitsByRange(startDate.toISOString(), endDate.toISOString(), startDateAsString)); // Fetch daily limits with string format
    }, [location.search]);

    const filterExpensesByCurrencyAndAccounts = (currency, accounts) => {
        let filtered = expenses;

        // Apply currency filter
        if (currency) {
            filtered = filtered.filter(expense => expense.currency === currency);
        }

        // Apply accounts filter: Include only expenses with valid accountId as string
        if (accounts && accounts.length > 0) {
            filtered = filtered.filter(expense => accounts.includes(expense.accountId));
        }

        setTriageExpenses(filtered);
    };

    useEffect(() => {
        // Extract unique currencies and accounts from expenses, filter only valid accountId (strings)
        const currencies = [...new Set(expenses.map(expense => expense.currency))];
        const validAccountIds = expenses
            .filter(expense => typeof expense.accountId === 'string') // Only accounts with valid string accountId
            .map(expense => expense.accountId);

        setAvailableCurrencies(currencies);
        setAvailableAccounts([...new Set(validAccountIds)]); // Ensure unique account IDs

        // Filter expenses based on selected currency and accounts
        filterExpensesByCurrencyAndAccounts(selectedCurrency, selectedAccounts);

        // Reset currency if it's no longer valid
        if (!currencies.includes(selectedCurrency)) {
            setSelectedCurrency(null);
        }
    }, [expenses, selectedCurrency, selectedAccounts]);


    useEffect(() => {
        filterExpensesByTags(selectedTags, matchAllTags);
    }, [triageExpenses, selectedTags, matchAllTags]);

    const filterExpensesByTags = (tags, matchAllTags) => {
        if (tags.length === 0) {
            setFilteredExpenses(triageExpenses);
            return;
        }

        let filtered = triageExpenses;

        // Filter by Incoming/Outgoing transaction types
        if (tags.includes('Incoming')) {
            filtered = filtered.filter(expense => expense.amount > 0);
        }

        if (tags.includes('Outgoing')) {
            filtered = filtered.filter(expense => expense.amount < 0);
        }

        // Filter by non-special tags and categories
        const nonSpecialTags = tags.filter(tag => tag !== 'Incoming' && tag !== 'Outgoing');

        if (nonSpecialTags.length > 0) {
            filtered = filtered.filter(expense => {
                const hasRequiredTags = matchAllTags
                    ? nonSpecialTags.every(tag =>
                        expense.tags.includes(tag) ||
                        (categories.find(cat => cat.name === tag) && expense.category.includes(categories.find(cat => cat.name === tag)._id)) ||
                        (tag === 'Uncategorized' && expense.category.length === 0) ||
                        (tag === 'Drafts' && expense.reviewed === false) ||
                        (tag === 'Reviewed' && expense.reviewed === true)
                    )
                    : nonSpecialTags.some(tag =>
                        expense.tags.includes(tag) ||
                        (categories.find(cat => cat.name === tag) && expense.category.includes(categories.find(cat => cat.name === tag)._id)) ||
                        (tag === 'Uncategorized' && expense.category.length === 0) ||
                        (tag === 'Drafts' && expense.reviewed === false) ||
                        (tag === 'Reviewed' && expense.reviewed === true)
                    );

                return hasRequiredTags;
            });
        }

        setFilteredExpenses(filtered);
    };

    const onAccountSelect = (selectedAccounts) => {
        setSelectedAccounts(selectedAccounts);

        if (selectedAccounts.length > 0) {
            const firstAccountDetails = accounts.find(acc => acc._id === selectedAccounts[0]);
            if (firstAccountDetails?.defaultCurrency) {
                setSelectedCurrency(firstAccountDetails.defaultCurrency);
            }
        } else {
            setSelectedCurrency(null); // Reset the currency if no accounts are selected
        }
    };

    const onTagClick = (tag) => {
        setSelectedTags(prevTags => {
            let newTags;

            // If the clicked tag is 'Incoming' or 'Outgoing', deselect the other
            if (tag === 'Incoming') {
                newTags = prevTags.includes(tag)
                    ? prevTags.filter(t => t !== tag) // Deselect 'Incoming' if already selected
                    : [...prevTags.filter(t => t !== 'Outgoing'), tag]; // Select 'Incoming' and deselect 'Outgoing'
            } else if (tag === 'Outgoing') {
                newTags = prevTags.includes(tag)
                    ? prevTags.filter(t => t !== tag) // Deselect 'Outgoing' if already selected
                    : [...prevTags.filter(t => t !== 'Incoming'), tag]; // Select 'Outgoing' and deselect 'Incoming'
                // Handle Drafts and Reviewed deselection logic
            } else if (tag === 'Drafts') {
                newTags = prevTags.includes(tag)
                    ? prevTags.filter(t => t !== tag) // Deselect 'Drafts' if already selected
                    : [...prevTags.filter(t => t !== 'Reviewed'), tag]; // Select 'Drafts' and deselect 'Reviewed'
            } else if (tag === 'Reviewed') {
                newTags = prevTags.includes(tag)
                    ? prevTags.filter(t => t !== tag) // Deselect 'Reviewed' if already selected
                    : [...prevTags.filter(t => t !== 'Drafts'), tag]; // Select 'Reviewed' and deselect 'Drafts'

                // Handle other tags
            } else {
                newTags = prevTags.includes(tag)
                    ? prevTags.filter(t => t !== tag) // Deselect tag if already selected
                    : [...prevTags, tag]; // Select tag if not selected
            }

            return newTags;
        });
    };

    const handleClearTags = () => {
        setSelectedTags([]); // Clear all selected tags
    };

    const handleMatchAllChange = (e) => {
        setMatchAllTags(e.target.checked);
    };
    const handleCategoriesChartChange = (e) => {
        setTypeCategoriesChart(e.target.checked);
    };

    const onRangePickerChange = (dates) => {
        if (!dates || dates.length === 0) {
            // Handle case when date range is cleared
            setDateRange(null);
            navigate(`?startDate=&endDate=`);
            return;
        }

        setDateRange(dates);
        const startDate = dates[0].format('YYYY-MM-DD');
        const endDate = dates[1].format('YYYY-MM-DD');
        navigate(`?startDate=${startDate}&endDate=${endDate}`);
        setDateRangeType('Custom');
        dispatch(getExpenseDataByDateRange(dates[0].toISOString(), dates[1].toISOString()));
    };

    const onDayPickerChange = (date) => {
        setDateRange([date.startOf('day'), date.endOf('day')]);
        const dateString = date.format('YYYY-MM-DD');
        navigate(`?day=${dateString}`);
        setDateRangeType('Day');
        dispatch(getExpenseDataByDateRange(date.startOf('day').toISOString(), date.endOf('day').toISOString()));
    };

    const onWeekPickerChange = (date) => {
        const start = date.startOf('week');
        const end = date.endOf('week');
        setDateRange([start, end]);
        const year = start.format('YYYY');
        const week = start.week();
        navigate(`?year=${year}&week=${week}`);
        setDateRangeType('Week');
        dispatch(getExpenseDataByDateRange(start.toISOString(), end.toISOString()));
    };

    const onMonthPickerChange = (date) => {
        const start = date.startOf('month');
        const end = date.endOf('month');
        setDateRange([start, end]);
        const year = start.format('YYYY');
        const month = start.format('MM');
        navigate(`?year=${year}&month=${month}`);
        setDateRangeType('Month');
        dispatch(getExpenseDataByDateRange(start.toISOString(), end.toISOString()));
    };

    const onQuarterPickerChange = (date) => {
        const start = date.startOf('quarter');
        const end = date.endOf('quarter');
        setDateRange([start, end]);
        const year = start.format('YYYY');
        const quarter = start.quarter();
        navigate(`?year=${year}&quarter=${quarter}`);
        setDateRangeType('Quarter');
        dispatch(getExpenseDataByDateRange(start.toISOString(), end.toISOString()));
    };

    const onYearPickerChange = (date) => {
        const start = date.startOf('year');
        const end = date.endOf('year');
        setDateRange([start, end]);
        const year = start.format('YYYY');
        navigate(`?year=${year}`);
        setDateRangeType('Year');
        dispatch(getExpenseDataByDateRange(start.toISOString(), end.toISOString()));
    };

    const onButtonClick = (type) => {
        setDateRangeType(type);
        let start, end, urlParams;

        switch (type) {
            case 'Day':
                start = dayjs().startOf('day');
                end = dayjs().endOf('day');
                urlParams = `day=${start.format('YYYY-MM-DD')}`;
                break;
            case 'Week':
                start = dayjs().startOf('week');
                end = dayjs().endOf('week');
                urlParams = `year=${start.format('YYYY')}&week=${start.week()}`;
                break;
            case 'Month':
                start = dayjs().startOf('month');
                end = dayjs().endOf('month');
                urlParams = `year=${start.format('YYYY')}&month=${start.format('MM')}`;
                break;
            case 'Quarter':
                start = dayjs().startOf('quarter');
                end = dayjs().endOf('quarter');
                urlParams = `year=${start.format('YYYY')}&quarter=${start.quarter()}`;
                break;
            case 'Year':
                start = dayjs().startOf('year');
                end = dayjs().endOf('year');
                urlParams = `year=${start.format('YYYY')}`;
                break;
            case 'Custom':
                return; // Custom will use RangePicker's default behavior
            default:
                return;
        }

        setDateRange([start, end]);
        navigate(`?${urlParams}`);
        dispatch(getExpenseDataByDateRange(start.toISOString(), end.toISOString()));
    };

    // Process categories and prepare data for both CategorySummaryPanel and CategoryPieChartPanel
    const { groupedCategories, remainingCategories } = useMemo(
        () => processCategories(filteredExpenses, categories),
        [filteredExpenses, categories]
    );

    const chartDataForCategoriesChart = useMemo(() => {
        const data = [
            ...groupedCategories.map(group => ({
                name: group.name,
                total: Math.abs(group.total),
                income: group.income,
                outcome: Math.abs(group.outcome),
            })),
            ...remainingCategories.map(cat => ({
                name: cat.name,
                total: Math.abs(cat.total),
                income: cat.income,
                outcome: Math.abs(cat.outcome),
            })),
        ];
        return data;
    }, [groupedCategories, remainingCategories]);

    const navigationOptions = [
        {
            label: 'Day',
            value: 'Day',
            key: 'Day',
        },
        {
            label: 'Week',
            value: 'Week',
            key: 'Week',
        },
        {
            label: 'Month',
            value: 'Month',
            key: 'Month',
        },
        {
            label: 'Quarter',
            value: 'Quarter',
            key: 'Quarter',
        },
        {
            label: 'Year',
            value: 'Year',
            key: 'Year',
        },
        {
            label: 'Custom',
            value: 'Custom',
            key: 'Custom',
        },
    ]

    return (
        <ConfigProvider locale={locale}>
            <PageContentWrapper>
                <Space direction="vertical" size={'large'} style={{ width: '100%' }} >
                    <Tabs
                        activeKey={dateRangeType}
                        items={navigationOptions}
                        onChange={value => onButtonClick(value)}
                        tabBarExtraContent={<Expenses showAsDrawer={true} receivedCollection={filteredExpenses} />}
                    />
                    <br />
                    <Space direction="horizontal" style={{ marginBottom: 16 }}>
                        {availableCurrencies.map(currency => (
                            <Button
                                key={currency}
                                type={selectedCurrency === currency ? 'primary' : 'default'}
                                onClick={() => {
                                    setSelectedCurrency(currency);
                                    setSelectedAccounts([]); // Reset accounts if currency is manually selected
                                }}
                            >
                                {currency}
                            </Button>
                        ))}
                        <Button
                            key="all"
                            type={!selectedCurrency ? 'primary' : 'default'}
                            onClick={() => {
                                setSelectedCurrency(null);
                                setSelectedAccounts([]); // Reset accounts when all currencies are selected
                            }}
                        >
                            All Currencies
                        </Button>
                    </Space>

                    <Select
                        mode="multiple"
                        style={{ width: '100%', marginBottom: 16 }}
                        placeholder="Select accounts"
                        value={selectedAccounts}
                        onChange={onAccountSelect}
                    >
                        {availableAccounts.map(account => {
                            const accountDetails = accounts.find(acc => acc._id === account);
                            return (
                                <Select.Option
                                    key={account}
                                    value={account}
                                    disabled={accountDetails?.defaultCurrency !== selectedCurrency && selectedCurrency !== null}
                                >
                                    {accountDetails?.name || 'Unknown Account'}
                                </Select.Option>
                            );
                        })}
                    </Select>

                    <br />
                    <Row gutter={[16, 16]} >
                        <Col xs={24} md={8}>
                            <Space direction="vertical" size={'large'} style={{
                                height: '100%', display: 'flex', justifyContent: 'space-between',
                            }}>
                                <div>
                                    <DateRangeHeader
                                        dateRangeType={dateRangeType}
                                        dateRange={dateRange}
                                        setDateRange={setDateRange}
                                        setDateRangeType={setDateRangeType}
                                        navigate={navigate}
                                        locale={locale}
                                        hideArrows={false}
                                        hideDatePickersInputField={false}
                                        hideDatePickersPanel={true}
                                        dispatch={dispatch}
                                        getExpenseDataByDateRange={getExpenseDataByDateRange}
                                        onRangePickerChange={onRangePickerChange}
                                        onDayPickerChange={onDayPickerChange}
                                        onWeekPickerChange={onWeekPickerChange}
                                        onMonthPickerChange={onMonthPickerChange}
                                        onQuarterPickerChange={onQuarterPickerChange}
                                        onYearPickerChange={onYearPickerChange}
                                        noCardBackground={false}
                                        noCardTitle={true}
                                    />
                                    <TotalSummaryPanel expenses={filteredExpenses}
                                        noCardBackground={true}
                                        noCardTitle={true}
                                        selectedCurrency={selectedCurrency}
                                    />
                                </div>
                                <DateRangePicker
                                    hideDatePickersInputField={true}
                                    dateRangeType={dateRangeType}
                                    dateRange={dateRange}
                                    onRangePickerChange={onRangePickerChange}
                                    onDayPickerChange={onDayPickerChange}
                                    onWeekPickerChange={onWeekPickerChange}
                                    onMonthPickerChange={onMonthPickerChange}
                                    onQuarterPickerChange={onQuarterPickerChange}
                                    onYearPickerChange={onYearPickerChange}
                                    locale={locale}
                                    darkMode={darkMode}
                                    noCardBackground={true}
                                />
                            </Space>
                        </Col>

                        <Col xs={24} md={16} style={{ height: '530px' }}>
                            <Card style={{ height: '100%' }}>
                                <G2_divergingBarChart
                                    transactions={filteredExpenses}
                                    dateRange={dateRange}
                                    dailyLimits={dailyLimits}
                                    showLegend={true}
                                    showDayLabels={true}
                                    showTextMarkers={false}
                                    darkMode={darkMode}
                                    showDateLimitAsLine={dateRangeType === 'Day'}
                                />
                            </Card>
                        </Col>
                    </Row>
                    <Row gutter={[16, 16]}>
                        <Col xs={24} md={24}>
                            <Card title="Filters" extra={selectedTags.length > 0 && (
                                <>
                                    <Checkbox checked={matchAllTags} onChange={handleMatchAllChange}>
                                        Strictly Match
                                    </Checkbox>
                                    <span>    </span>
                                    <Button size='small' danger ghost onClick={handleClearTags} type="primary">
                                        Reset
                                    </Button>
                                </>
                            )}>
                                <TagsSummaryPanel
                                    expenses={triageExpenses}
                                    selectedTags={selectedTags}
                                    onTagClick={onTagClick}
                                    layout="inlineList"
                                    showTransactionTags={true}
                                    showTransactionTypes={true}
                                    showTransactionCategories={true}
                                    categories={categories}
                                />
                            </Card>
                        </Col>
                        <Col xs={24} md={16}>
                            <CategorySummaryPanel
                                expenses={filteredExpenses}
                                categories={categories}
                                groupedCategories={groupedCategories}
                                remainingCategories={remainingCategories}
                                showFooter={true}
                            />
                        </Col>
                        <Col xs={24} md={8}>
                            <Card title='Category Distribution' style={{ height: '100%' }} extra={[
                                <Checkbox checked={typeCategoriesChart} onChange={handleCategoriesChartChange}>
                                    Pie Chart
                                </Checkbox>,
                                <Checkbox checked={separateCharts} onChange={e => setSeparateCharts(e.target.checked)}>
                                    Separate Income/Outcome
                                </Checkbox>
                            ]}>
                                {typeCategoriesChart ? (
                                    <CategoryPieChartPanel
                                        chartData={chartDataForCategoriesChart}
                                        separateCharts={separateCharts}
                                    />
                                ) : (
                                    <CategoryBarChart
                                        categories={chartDataForCategoriesChart}
                                        darkMode={darkMode}
                                        separateCharts={separateCharts}
                                    />
                                )}
                            </Card>
                        </Col>
                    </Row>

                </Space>
            </PageContentWrapper>
        </ConfigProvider>
    );
};

export default Insights;
