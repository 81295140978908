
import React, { useState } from 'react'
import { Navigate, Link } from 'react-router-dom'
import { Button, Checkbox, ConfigProvider, Divider, Flex, Form, Input, Modal, Space, Typography } from 'antd'
import { useAuth } from '../../contexts/authContext'
// import './BackgroundAnimations/ripple.css'
// import { ReactComponent as CustomBackground } from './Images/large-triangles copy.svg';
// import logo from './Images/CartoonRocket_wide_13.jpg'

export default function AuthModal(props) {
    const { userLoggedIn } = useAuth()
    return (
        <>
            {userLoggedIn && (<Navigate to={'/'} replace={true} />)}
            <Modal open={true} mask={false} centered footer={null} closeIcon={null} className={props.removeDefaultPadding ? 'removeDefaultPadding authModal' : 'authModal'}  >
                <Flex align="start" vertical justify='center' >

                    {props.children}
                </Flex>
            </Modal>
            <img src={'https://source.unsplash.com/1280x720/?nature'} style={{
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
                objectFit: 'cover'
            }} />
            {/* <img src={logo} style={{
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
                objectFit: 'cover'
            }} /> */}
            {/* <CustomBackground style={{
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
                objectFit: 'cover'
            }} /> */}
        </>
    )
}
