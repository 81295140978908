import React, { useState } from 'react';
import FolderForm from '../components/FolderView/FolderForm/FolderForm';
import PageContentWrapper from './PageContentWrapper/PageContentWrapper';
import GridListView from '../components/Views/GridListView';
import ParentComponent from '../components/Playgrounds/TestSeparateMenuRootFolders';

const Folders = ({ setPageTitle }) => {
    const [currentIdFolder, setCurrentIdFolder] = useState(null);

    setPageTitle('Folders');

    return (
        <PageContentWrapper >
            <br />
            <FolderForm currentIdFolder={currentIdFolder} setCurrentIdFolder={setCurrentIdFolder} />
            <ParentComponent setCurrentIdFolder={setCurrentIdFolder} />
            <GridListView setCurrentIdFolder={setCurrentIdFolder} />
        </PageContentWrapper>
    )
}

export default Folders;