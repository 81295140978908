// api/index.js
import axios from 'axios';

// const url_expenses = 'http://localhost:4000/expenses';
// const url_notes = 'http://localhost:4000/noteCollections';
// const url_folders = 'http://localhost:4000/folders';
// const url_page_tabs = 'http://localhost:4000/pageTabs';
// const url_delete_user = 'http://localhost:4000/userData';
// const url_user_settings = 'http://localhost:4000/userSettings';
// const url_expenseverse_categories = 'http://localhost:4000/expenseverse/categories';
// const url_expenseverse_accounts = 'http://localhost:4000/expenseverse/accounts';
// const url_expenseverse_products = 'http://localhost:4000/expenseverse/products';
// const url_expenseverse_daily_limits = 'http://localhost:4000/expenseverse/dailyLimits';
// const url_expenseverse_defaultSettings = 'http://localhost:4000/expenseverse/defaultSettings';

const url_expenses = 'https://wiswig-tinymce-3a27a4e95e7e.herokuapp.com/expenses';
const url_notes = 'https://wiswig-tinymce-3a27a4e95e7e.herokuapp.com/noteCollections';
const url_folders = 'https://wiswig-tinymce-3a27a4e95e7e.herokuapp.com/folders';
const url_page_tabs = 'https://wiswig-tinymce-3a27a4e95e7e.herokuapp.com/pageTabs';
const url_delete_user = 'https://wiswig-tinymce-3a27a4e95e7e.herokuapp.com/userData';
const url_user_settings = 'https://wiswig-tinymce-3a27a4e95e7e.herokuapp.com/userSettings';
const url_expenseverse_categories = 'https://wiswig-tinymce-3a27a4e95e7e.herokuapp.com/expenseverse/categories';
const url_expenseverse_accounts = 'https://wiswig-tinymce-3a27a4e95e7e.herokuapp.com/expenseverse/accounts';
const url_expenseverse_products = 'https://wiswig-tinymce-3a27a4e95e7e.herokuapp.com/expenseverse/products';
const url_expenseverse_daily_limits = 'https://wiswig-tinymce-3a27a4e95e7e.herokuapp.com/expenseverse/dailyLimits';
const url_expenseverse_defaultSettings = 'https://wiswig-tinymce-3a27a4e95e7e.herokuapp.com/expenseverse/defaultSettings';


// Expenses API
export const fetchExpenseData = (token) => axios.get(url_expenses, { headers: { 'Authorization': `Bearer ${token}` } });
export const createExpenseData = (expenseData, token) => axios.post(url_expenses, expenseData, { headers: { 'Authorization': `Bearer ${token}` } });
export const updateExpenseData = (id, expenseData, token) => axios.patch(`${url_expenses}/${id}`, expenseData, { headers: { 'Authorization': `Bearer ${token}` } });
export const deleteExpenseData = (id, token) => axios.delete(`${url_expenses}/${id}`, { headers: { 'Authorization': `Bearer ${token}` } });
export const fetchExpenseDataByDateRange = (startDate, endDate, token) => axios.get(`${url_expenses}/range?startDate=${startDate}&endDate=${endDate}`, { headers: { 'Authorization': `Bearer ${token}` } });

// Expenseverse Categories API
export const fetchExpenseverseCategories = (token) => axios.get(url_expenseverse_categories, { headers: { 'Authorization': `Bearer ${token}` } });
export const createExpenseverseCategory = (category, token) => axios.post(url_expenseverse_categories, category, { headers: { 'Authorization': `Bearer ${token}` } });
export const updateExpenseverseCategory = (id, category, token) => axios.patch(`${url_expenseverse_categories}/${id}`, category, { headers: { 'Authorization': `Bearer ${token}` } });
export const deleteExpenseverseCategory = (id, token) => axios.delete(`${url_expenseverse_categories}/${id}`, { headers: { 'Authorization': `Bearer ${token}` } });

// Expenseverse Accounts API
export const fetchExpenseverseAccounts = (token) => axios.get(url_expenseverse_accounts, { headers: { 'Authorization': `Bearer ${token}` } });
export const createExpenseverseAccount = (accountData, token) => axios.post(url_expenseverse_accounts, accountData, { headers: { 'Authorization': `Bearer ${token}` } });
export const updateExpenseverseAccount = (id, accountData, token) => axios.patch(`${url_expenseverse_accounts}/${id}`, accountData, { headers: { 'Authorization': `Bearer ${token}` } });
export const deleteExpenseverseAccount = (id, token) => axios.delete(`${url_expenseverse_accounts}/${id}`, { headers: { 'Authorization': `Bearer ${token}` } });

// Expenseverse Products API
export const fetchExpenseverseProducts = (token) => axios.get(url_expenseverse_products, { headers: { 'Authorization': `Bearer ${token}` } });
export const createExpenseverseProduct = (productData, token) => axios.post(url_expenseverse_products, productData, { headers: { 'Authorization': `Bearer ${token}` } });
export const updateExpenseverseProduct = (id, productData, token) => axios.patch(`${url_expenseverse_products}/${id}`, productData, { headers: { 'Authorization': `Bearer ${token}` } });
export const deleteExpenseverseProduct = (id, token) => axios.delete(`${url_expenseverse_products}/${id}`, { headers: { 'Authorization': `Bearer ${token}` } });

// Expenseverse Daily Limits API
export const fetchDailyLimit = (date, token) => axios.get(`${url_expenseverse_daily_limits}/${date}`, { headers: { 'Authorization': `Bearer ${token}` } });
export const fetchDailyLimits = (page, pageSize, token) => axios.get(`${url_expenseverse_daily_limits}?page=${page}&pageSize=${pageSize}`, { headers: { 'Authorization': `Bearer ${token}` } });
export const fetchAllDailyLimits = (token) => axios.get(`${url_expenseverse_daily_limits}/all`, { headers: { 'Authorization': `Bearer ${token}` } });
export const fetchDailyLimitsByRange = (startDate, endDate, startDateAsString, token) => axios.get(`${url_expenseverse_daily_limits}/range?startDate=${startDate}&endDate=${endDate}&startDateAsString=${startDateAsString}`, { headers: { 'Authorization': `Bearer ${token}` } });
export const createOrUpdateDailyLimit = (dailyLimitData, token) => axios.post(url_expenseverse_daily_limits, dailyLimitData, { headers: { 'Authorization': `Bearer ${token}` } });
export const deleteDailyLimit = (date, token) => axios.delete(`${url_expenseverse_daily_limits}/${date}`, { headers: { 'Authorization': `Bearer ${token}` } });
export const fetchDailyLimitByDate = (date, token) => axios.get(`${url_expenseverse_daily_limits}/daily-limit/${date}`, { headers: { 'Authorization': `Bearer ${token}` } });

// Expenseverse Default Settings API
export const fetchDefaultSettings = (token) => axios.get(url_expenseverse_defaultSettings, { headers: { 'Authorization': `Bearer ${token}` } });
export const updateDefaultSettings = (defaultSettingsData, token) => axios.patch(url_expenseverse_defaultSettings, defaultSettingsData, { headers: { 'Authorization': `Bearer ${token}` } });
// User Settings API
export const fetchUserSettings = (token) => axios.get(url_user_settings, { headers: { 'Authorization': `Bearer ${token}` } });
export const updateUserSettings = (settingsData, token) => axios.patch(`${url_user_settings}`, settingsData, { headers: { 'Authorization': `Bearer ${token}` } });

// Notes API
export const fetchNoteCollections = (token) => axios.get(url_notes, { headers: { 'Authorization': `Bearer ${token}` } });
export const createNoteCollection = (noteCollection, token) => axios.post(url_notes, noteCollection, { headers: { 'Authorization': `Bearer ${token}` } });
export const updateNoteCollection = (id, noteCollection, token) => axios.patch(`${url_notes}/${id}`, noteCollection, { headers: { 'Authorization': `Bearer ${token}` } });
export const deleteNoteCollection = (id, token) => axios.delete(`${url_notes}/${id}`, { headers: { 'Authorization': `Bearer ${token}` } });

// Folders API
export const fetchFolderCollections = (token) => axios.get(url_folders, { headers: { 'Authorization': `Bearer ${token}` } });
export const createFolderCollection = (newFolderCollection, token) => axios.post(url_folders, newFolderCollection, { headers: { 'Authorization': `Bearer ${token}` } });
export const updateFolderCollection = (id, updatedFolderCollection, token) => axios.patch(`${url_folders}/${id}`, updatedFolderCollection, { headers: { 'Authorization': `Bearer ${token}` } });
export const deleteFolderCollection = (id, token) => axios.delete(`${url_folders}/${id}`, { headers: { 'Authorization': `Bearer ${token}` } });

// Page Tabs API
export const fetchPageTabsCollections = (token) => axios.get(url_page_tabs, { headers: { 'Authorization': `Bearer ${token}` } });
export const createPageTabsCollection = (newPageTabsCollection, token) => axios.post(url_page_tabs, newPageTabsCollection, { headers: { 'Authorization': `Bearer ${token}` } });
export const updatePageTabsCollection = (id, updatedPageTabsCollection, token) => axios.patch(`${url_page_tabs}/${id}`, updatedPageTabsCollection, { headers: { 'Authorization': `Bearer ${token}` } });
export const deletePageTabsCollection = (id, token) => axios.delete(`${url_page_tabs}/${id}`, { headers: { 'Authorization': `Bearer ${token}` } });

// Delete Users collections when user is being deleted
export const deleteUserCollections = (userId, token) => axios.delete(`${url_delete_user}/${userId}`, { headers: { 'Authorization': `Bearer ${token}` }, timeout: 5000 });

