import * as api from '../api/index';
import { getAuth, getIdToken } from "firebase/auth";

// action creators
export const getExpenseData = () => async (dispatch) => {
    try {
        const auth = getAuth();
        const token = await getIdToken(auth.currentUser);
        const { data } = await api.fetchExpenseData(token);

        dispatch({ type: 'FETCH_ALL_EXPENSES', payload: data });
        return data; // Explicitly return the data
    } catch (error) {
        console.log(error.message);
        throw error; // Throw error to handle it properly
    }
};

export const getExpenseDataByDateRange = (startDate, endDate) => async (dispatch) => {
    try {
        const auth = getAuth();
        const token = await getIdToken(auth.currentUser);
        const { data } = await api.fetchExpenseDataByDateRange(startDate, endDate, token);
        dispatch({ type: 'FETCH_EXPENSES_BY_DATE_RANGE', payload: data });
    } catch (error) {
        console.log(error.message);
    }
};

export const createExpenseData = (expenseData) => async (dispatch) => {
    try {
        const auth = getAuth();
        const token = await getIdToken(auth.currentUser);
        const { data } = await api.createExpenseData(expenseData, token);

        dispatch({ type: 'CREATE_EXPENSE', payload: data });
        return { status: 'success' };
    } catch (error) {
        console.log(error);
        return { status: 'error', message: error.message };
    }
}

export const updateExpenseData = (id, expenseData) => async (dispatch) => {
    try {
        const auth = getAuth();
        const token = await getIdToken(auth.currentUser);
        const { data } = await api.updateExpenseData(id, expenseData, token);

        dispatch({ type: 'UPDATE_EXPENSE', payload: data })
        return { status: 'success' };
    } catch (error) {
        console.log(error.message);
        return { status: 'error', message: error.message };
    }
}

export const deleteExpenseData = (id) => async (dispatch) => {
    try {
        const auth = getAuth();
        const token = await getIdToken(auth.currentUser);
        await api.deleteExpenseData(id, token);

        dispatch({ type: 'DELETE_EXPENSE', payload: id })
        return { status: 'success' };
    } catch (error) {
        console.log(error);
        return { status: 'error', message: error.message };
    }
}