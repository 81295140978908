// ChangeDailyLimitModal.js
import React, { useEffect } from 'react';
import { Modal, Form, InputNumber, Button, message } from 'antd';
import { useDispatch } from 'react-redux';
import { createOrUpdateDailyLimit, getDailyLimitByDate } from '../../../actions/expenseverseDailyLimitActions';

const ChangeDailyLimitModal = ({ visible, onClose, currentDate, dailyLimit }) => {
    const [form] = Form.useForm();
    const dispatch = useDispatch();
    currentDate = currentDate.format('YYYY-MM-DD');
    // Load the current daily limit for the selected date when the modal opens
    useEffect(() => {
        if (visible) {
            // Set the current daily limit in the form if needed
            form.setFieldsValue({ dailyLimit: dailyLimit });
            // You could use dispatch to get the current daily limit based on the date if necessary
        }
    }, [visible, currentDate]);

    const handleOk = async () => {
        try {
            const values = await form.validateFields();
            const dailyLimitData = {
                date: currentDate, // Format the date
                amount: values.dailyLimit,
            };

            const result = await dispatch(createOrUpdateDailyLimit(dailyLimitData));
            if (result.success) {
                message.success('Daily limit updated successfully');
                dispatch(getDailyLimitByDate(currentDate)); // Dispatch the action to get daily limit for the selected date
                onClose(); // Close the modal
            }
        } catch (error) {
            message.error('Failed to update daily limit. Please try again.');
        }
    };

    // const handleEditOk = async () => {
    //     try {
    //         const values = await editForm.validateFields();
    //         const dailyLimitUpdate = {
    //             date: currentEditDate,
    //             amount: values.dailyLimit,
    //         };

    //         const result = await dispatch(createOrUpdateDailyLimit(dailyLimitUpdate));
    //         if (result.success) {
    //             message.success('Daily limit updated successfully');
    //             setIsEditModalVisible(false);
    //             setCurrentEditDate(null);
    //             dispatch(getDailyLimits(currentPage, pageSize));
    //             refreshPrimaryModal();
    //         }
    //     } catch (error) {
    //         message.error('Failed to update daily limit. Please try again.');
    //     }
    // };

    // const handleEditCancel = () => {
    //     setIsEditModalVisible(false);
    //     setCurrentEditDate(null);
    // };

    return (
        <Modal
            title={`Change Daily Limit for ${currentDate}`}
            visible={visible}
            onCancel={onClose}
            footer={[
                <Button key="cancel" onClick={onClose}>
                    Cancel
                </Button>,
                <Button key="submit" type="primary" onClick={handleOk}>
                    Save
                </Button>
            ]}
        >
            <Form form={form} layout="vertical">
                <Form.Item
                    name="dailyLimit"
                    label="Daily Limit"
                    rules={[
                        { required: true, message: 'Please enter a daily limit!' },
                        {
                            validator: (_, value) => {
                                if (value > 0) {
                                    return Promise.reject(new Error('Daily limit must be negative!'));
                                }
                                return Promise.resolve();
                            }
                        }
                    ]}
                >
                    <InputNumber style={{ width: '100%' }} />
                </Form.Item>
            </Form>
        </Modal>
    );
};

export default ChangeDailyLimitModal;
