// Define the supported colors and their hex equivalents for AntD Palette
export const supportedAntDColors = {
    magenta: '#eb2f96',
    red: '#f5222d',
    volcano: '#fa541c',
    orange: '#fa8c16',
    gold: '#faad14',
    lime: '#a0d911',
    green: '#52c41a',
    cyan: '#13c2c2',
    blue: '#1890ff',
    geekblue: '#2f54eb',
    purple: '#722ed1'
};

// Define the recommended colors
const recommendedColors = [
    '#e6261f',
    '#eb7532',
    '#f7d038',
    '#a3e048',
    '#49da9a',
    '#34bbe6',
    '#4355db',
    '#d23be7'
    , '#bfbfbf', '#8c8c8c', '#595959'
];

export const presetColors = [
    {
        label: 'Recommended',
        colors: recommendedColors
    },
    {
        label: 'AntD Palette',
        colors: Object.values(supportedAntDColors)
    }
];