import React, { useState } from 'react';
import { Table, Button, Popconfirm, Typography, Space, Select, Tag, Avatar, Row, Col, Modal, message } from 'antd';
import { EditFilled, DeleteFilled, FilterFilled } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import { getExpenseData, updateExpenseData } from '../../../actions/expensesActions';
import { deleteExpenseverseCategory } from '../../../actions/expenseverseCategoryActions';
import CategoriesInfo from './CategoriesInfo';

const { Title, Paragraph } = Typography;
const { Option } = Select;

const CategoryList = ({ categories, onEdit, onAddCategory, onAddGroup, treeData }) => {
    const [filter, setFilter] = useState('all');
    const [expandedKeys, setExpandedKeys] = useState([]);
    const [pagination, setPagination] = useState({ current: 1, pageSize: 10 });
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [categoryToDelete, setCategoryToDelete] = useState(null);
    const [relatedTransactions, setRelatedTransactions] = useState([]);
    const dispatch = useDispatch();

    const handleFilterChange = (value) => {
        setFilter(value);
    };

    const handleTableChange = (pagination, filters, sorter) => {
        setPagination(pagination);
    };

    const flattenCategories = (data) => {
        let flatList = [];
        data.forEach(item => {
            flatList.push(item);
            if (item.children) {
                flatList = flatList.concat(flattenCategories(item.children));
            }
        });
        return flatList;
    };

    const filteredData = filter === 'categories' ? flattenCategories(treeData).filter(item => !item.isGroup) : treeData.filter(item => {
        if (filter === 'all') return true;
        if (filter === 'groups') return item.isGroup;
        return true;
    });

    const handleExpandCollapseAll = () => {
        if (expandedKeys.length === 0) {
            const allKeys = treeData
                .filter(item => item.children && item.children.length > 0)
                .map(item => item.key);
            setExpandedKeys(allKeys);
        } else {
            setExpandedKeys([]);
        }
    };

    const showDeleteConfirmation = async (category) => {
        try {
            const response = await dispatch(getExpenseData());
            const transactions = response.payload || response;
            console.log('Fetched transactions:', transactions);

            if (transactions) {
                const relatedTransactions = transactions.filter(transaction => transaction.category.includes(category.key));
                if (relatedTransactions.length > 0) {
                    setCategoryToDelete(category);
                    setRelatedTransactions(relatedTransactions);
                    setIsModalVisible(true);
                } else {
                    handleDeleteCategory(category.key);
                }
            } else {
                message.error('No transactions found.');
            }
        } catch (error) {
            console.error('Error fetching transactions:', error);
            message.error('Failed to check related transactions.');
        }
    };

    const handleDeleteCategory = async (categoryId) => {
        try {
            // Update related transactions to remove the category
            const updatePromises = relatedTransactions.map(transaction => {
                const updatedCategories = transaction.category.filter(catId => catId !== categoryId);
                return dispatch(updateExpenseData(transaction._id, { ...transaction, category: updatedCategories }));
            });

            await Promise.all(updatePromises);

            // Now delete the category
            await dispatch(deleteExpenseverseCategory(categoryId));
            message.success('Category deleted successfully!');
            setIsModalVisible(false);
            setCategoryToDelete(null);
            setRelatedTransactions([]);
        } catch (error) {
            message.error('Failed to delete category.');
            console.error('Error:', error);
        }
    };

    const columns = [
        {
            title: 'Name',
            dataIndex: 'title',
            key: 'title',
            width: 250,
            ellipsis: true,
            sorter: (a, b) => a.title.localeCompare(b.title),
            render: (text, record) => {
                const originalCategory = categories.find(category => category._id === record.key);
                const isGroup = originalCategory && originalCategory.isGroup;
                const icon = originalCategory?.icon || 'question_mark';
                return (
                    <Space>
                        {originalCategory && (
                            <Avatar shape="circle" style={{ background: 'none', border: '1px solid rgba(123,123,123,.7)', color: 'rgba(123,123,123,.7)' }} icon={
                                <span className="material-symbols-rounded" style={{
                                    fontVariationSettings: "'FILL' 1",
                                    fontSize: 20, verticalAlign: 'middle', opacity: .7,
                                }}>
                                    {icon}
                                </span>
                            } />
                        )}
                        {isGroup ? (
                            <Tag color='' icon={
                                <span className="material-symbols-rounded"
                                    style={{
                                        fontVariationSettings: "'FILL' 1",
                                        fontSize: 20, verticalAlign: 'middle',
                                        opacity: .7, paddingRight: 8,
                                    }}
                                >
                                    app_registration
                                </span>
                            }>{text}</Tag>
                        ) : (
                            text
                        )}
                    </Space>
                );
            }
        },
        {
            title: 'Color',
            dataIndex: 'color',
            key: 'color',
            align: 'right',
            render: (text, record) => {
                const originalCategory = categories.find(category => category._id === record?.value);
                return (
                    originalCategory?.color ? <Tag color={originalCategory.color}
                    >{originalCategory.color}</Tag> : null
                )
            }
        },
        {
            title: 'Actions',
            key: 'actions',
            align: 'right',
            fixed: 'right',
            width: 150,
            render: (text, record) => (
                <Space>
                    <Button type="link" onClick={() => onEdit(record)}><EditFilled /></Button>
                    <Popconfirm
                        title="Are you sure you want to delete this category?"
                        onConfirm={() => showDeleteConfirmation(record)}
                        okText="Yes"
                        cancelText="No"
                    >
                        <Button type="link" danger><DeleteFilled /></Button>
                    </Popconfirm>
                </Space>
            )
        }
    ];

    return (
        <>
            <Space >
                <Title level={3} style={{ marginBottom: 0 }}>Categories</Title>
                <CategoriesInfo />
            </Space>
            <Paragraph>Categories and Groups are essential tools for organizing your financial data. They help analyze your spending patterns, and maintain a clear overview of your financial health.</Paragraph>
            <Paragraph>By effectively using categories and groups, you can gain better control over your finances, making it easier to budget, save, and achieve your financial goals.</Paragraph>
            <br /><br />
            <Space >
                <Button type="primary" onClick={onAddCategory}>New Category</Button>
                <Button onClick={onAddGroup} >New Group</Button>
            </Space>

            <Space wrap style={{ marginBottom: 16 }} size={'default'} >
                <Select variant="borderless" defaultValue="all" style={{ marginLeft: 0 }}
                    suffixIcon={<FilterFilled />}
                    popupMatchSelectWidth={false}
                    onChange={handleFilterChange}>
                    <Option value="all">All</Option>
                    <Option value="groups">Groups</Option>
                    <Option value="categories">Categories</Option>
                </Select>
                <Button type='text' disabled={filter === 'categories'}
                    onClick={handleExpandCollapseAll}
                    style={{ marginLeft: 10 }}>
                    {expandedKeys.length === 0 ? <span className="material-symbols-rounded" style={{
                        fontVariationSettings: "'FILL' 1",
                        fontSize: 15, verticalAlign: 'middle', opacity: .7,
                    }}>
                        open_in_full
                    </span> : <span className="material-symbols-rounded" style={{
                        fontVariationSettings: "'FILL' 1",
                        fontSize: 15, verticalAlign: 'middle', opacity: .7,
                    }}>
                        close_fullscreen
                    </span>}
                </Button>
            </Space>
            <Table
                columns={columns}
                dataSource={filteredData}
                rowKey="key"
                expandable={{
                    expandedRowKeys: expandedKeys,
                    onExpand: (expanded, record) => {
                        setExpandedKeys(expanded ? [...expandedKeys, record.key] : expandedKeys.filter(key => key !== record.key));
                    },
                    rowExpandable: record => record.children && record.children.length > 0,
                    expandedRowClassName: record => 'expanded_row'
                }}
                pagination={{
                    ...pagination,
                    position: ['bottomCenter'],
                    showSizeChanger: true,
                    pageSizeOptions: ['5', '10', '20', '50'],
                    onChange: (page, pageSize) => setPagination({ current: page, pageSize }),
                }}
                scroll={{ x: true }}
                onChange={handleTableChange}
            />
            <Modal
                title="Confirm Category Deletion"
                visible={isModalVisible}
                onOk={() => handleDeleteCategory(categoryToDelete.key)}
                onCancel={() => setIsModalVisible(false)}
            >
                <p>The following transactions are related to this category:</p>
                <ul>
                    {relatedTransactions.map(transaction => (
                        <li key={transaction._id}>{transaction.note || transaction._id}</li>
                    ))}
                </ul>
                <p>Are you sure you want to delete this category?</p>
            </Modal>
        </>
    );
};

export default CategoryList;