import { Avatar, Typography } from "antd";
import { useState } from "react";

const DynamicIconInputToggle = ({ inputs, initialValues, darkMode, LightToken, DarkToken }) => {
    const [visibility, setVisibility] = useState(
        inputs.reduce((acc, input) => ({
            ...acc,
            [input.name]: !!initialValues[input.name] && (
                typeof initialValues[input.name] === 'string' ? initialValues[input.name].trim() !== '' :
                    Array.isArray(initialValues[input.name]) ? initialValues[input.name].length > 0 :
                        true // For other types that are truthy
            )
        }), {})
    );

    const toggleVisibility = (name) => {
        setVisibility(prev => ({ ...prev, [name]: !prev[name] }));
    };

    return (
        <>
            <div style={{ display: 'flex', justifyContent: 'space-around' }}>
                {inputs.map(input => (
                    <div key={input.name} onClick={() => toggleVisibility(input.name)}
                        style={{
                            display: "flex",
                            flexDirection: 'column',
                            alignItems: 'center',
                            width: 60,
                            overflow: 'hidden',
                        }}>
                        <Avatar size='large' shape="square" style={{
                            background: 'none',
                            border: visibility[input.name] ? `2px solid ${darkMode ? DarkToken.colorText : LightToken.colorText}` : 'none',
                        }}>
                            <span className="material-icons" style={{
                                fontSize: 20,
                                verticalAlign: 'middle',
                                opacity: .7,
                                color: visibility[input.name] ? (darkMode ? DarkToken.colorText : LightToken.colorText) : 'rgba(123,123,123,.4)'
                            }}>{input.icon}</span>
                        </Avatar>
                        <Typography style={{
                            color: visibility[input.name] ? 'rgba(123,123,123,1)' : 'rgba(123,123,123,.4)',
                            fontSize: 12,
                            width: 50,
                            textAlign: 'center',
                            whiteSpace: 'nowrap',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                        }}>{input.label}</Typography>
                    </div>
                ))}
            </div>
            <br />
            <div>
                {inputs.map(input => visibility[input.name] ? input.component : null)}
            </div>
            <br />
        </>
    )
}
export default DynamicIconInputToggle;