import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Form, Tree, TreeSelect, Typography } from 'antd';
import { Link } from "react-router-dom";

const FolderItem = ({ folder, folderMap, notesMap }) => {
    const nestedFolders = folderMap[folder._id] || [];
    const folderNotes = notesMap[folder._id] || [];
    const totalItems = nestedFolders.length + folderNotes.length;

    return {
        key: folder.folderName,
        value: folder._id,
        title: folder.folderName + ` (${totalItems}) heloooo`,
        // title: (
        //     <Link
        //         to={`/folders/${folder._id}`}
        //         onClick={() => console.log(`Folder ID: ${folder._id}`)} // Logs the folder ID when clicked
        //     >
        //         {folder.folderName} ({totalItems})
        //     </Link>
        // ),
        children: [
            ...nestedFolders.map((nestedFolder) => (
                FolderItem({ folder: nestedFolder, folderMap, notesMap })
            )),
            ...folderNotes.map((note) => ({
                key: note.message,
                value: note._id,
                title: (
                    //  show note title in a link element so we can define our targets
                    <a href={`#${note.message.replace(/ /g, "-")}`}>N: {note.message}</a>
                ),
            }))
        ]
    };
};

const CustomTreeSelect = ({ folderId, showFolderOnly, selectedFolderId, setSelectedFolderId, parentsIDList, setParentsIDList }) => {
    const folders = useSelector((state) => state.folderCollections);
    const notes = useSelector((state) => showFolderOnly ? [] : state.noteCollections);
    const folderMap = {};
    const notesMap = {};

    folders.forEach((folder) => {
        const hasParent = folder.folderParents && folder.folderParents.length > 0 && folder.folderParents[0] !== "";
        if (hasParent) {
            folder.folderParents.forEach((parentId) => {
                if (!folderMap[parentId]) {
                    folderMap[parentId] = [];
                }
                folderMap[parentId].push(folder);
            });
        } else {
            if (!folderMap[""]) {
                folderMap[""] = [];
            }
            folderMap[""].push(folder);
        }
    });

    notes.forEach((note) => {
        if (note.parents && note.parents.length > 0) {
            note.parents.forEach((parentId) => {
                if (!notesMap[parentId]) {
                    notesMap[parentId] = [];
                }
                notesMap[parentId].push(note);
            });
        }
    });

    // Get all root folders (folders without filled folderParents or with empty string in folderParents)
    let rootFolders = folders.filter(folder => !folder.folderParents || !folder.folderParents.length || (folder.folderParents.length === 1 && folder.folderParents[0] === ""));

    //  implement TreeSelect component - now this  is Basic example from their web

    const treeDataOrigin = rootFolders.map((folder) => (FolderItem({ folder, folderMap, notesMap })))
    console.log('tree data jek: ', treeDataOrigin)

    const [value, setValue] = useState();

    const onChange = (newValue) => {
        setParentsIDList(newValue);
        setValue(newValue);
    };



    console.log('value is now: ', value);
    return (
        <>
            <Form.Item
                name="folderParents"
                rules={[{ required: false, message: 'Please input the folder description!' }]}
            >
                <TreeSelect
                    showSearch
                    style={{
                        width: '100%',
                    }}
                    value={parentsIDList}
                    dropdownStyle={{
                        maxHeight: 400,
                        // overflow: 'auto',
                    }}
                    treeNodeFilterProp='key'
                    placeholder="Please select"
                    allowClear
                    treeDefaultExpandAll
                    onChange={onChange}
                    treeData={treeDataOrigin}
                />
            </Form.Item>
            <p>Received prop is: {parentsIDList}</p>
        </>
    );
};

export default CustomTreeSelect;
