const initialState = {
    defaultSettings: {
        defaultCashAccount: '',
        defaultBankAccount: '',
        defaultVirtualAccount: '',
    },
    // other state properties
};

export default (state = initialState, action) => {
    switch (action.type) {
        case 'FETCH_DEFAULT_SETTINGS':
            return {
                ...state,
                defaultSettings: {
                    ...state.defaultSettings,
                    ...action.payload, // Merge with existing settings
                },
            };
        case 'UPDATE_DEFAULT_SETTINGS':
            return {
                ...state,
                defaultSettings: {
                    ...state.defaultSettings,
                    ...action.payload, // Only update the fields provided in the payload
                },
            };
        // other cases
        default:
            return state;
    }
};
