import { Typography } from "antd";

function DevVarDisplay(props) {
    return (
        <Typography>
            {props.title || props.variableName}
            <pre style={{ whiteSpace: "pre-wrap" }}> {JSON.stringify(props.value)} </pre>
        </Typography>)
};

export default DevVarDisplay;
