import React, { useEffect, useState } from 'react';
import { MinusCircleOutlined, PlusOutlined, PlusCircleOutlined } from '@ant-design/icons';
import { Button, Col, Collapse, ConfigProvider, Divider, Form, Input, Modal, Radio, Row, Space, Spin, Switch, Tooltip, Typography } from 'antd';
import { createPageTabsCollection, updatePageTabsCollection, deletePageTabsCollection } from '../../../actions/pageTabsCollections';
import { useDispatch, useSelector } from 'react-redux';
import ShowExtraField from '../../Other/ShowExtraField';
const { Panel } = Collapse;




const NewPageTabsAnt = ({ parentID, visible, setVisible, hideDefaultButton }) => {
    const dispatch = useDispatch();
    const pageTabsCollectionsFromServer = useSelector((state) => parentID ? state.pageTabsCollections.find((r) => r.parentID === parentID) : null);
    const [form] = Form.useForm();
    const tabs = "";
    const tabsType = "";
    const [isLoading, setIsLoading] = useState(true);

    const onFinish = (values) => {
        let formData = form.getFieldsValue();
        if (pageTabsCollectionsFromServer?._id) {
            dispatch(updatePageTabsCollection(pageTabsCollectionsFromServer._id, { parentID: parentID, tabsType: formData.tabsType, tabs: formData.tabs }))
            setVisible(false); // close the modal when the user clicks the cancel button
        } else {
            dispatch(createPageTabsCollection({ parentID: parentID, tabsType: formData.tabsType, tabs: formData.tabs }));
            setVisible(false); // close the modal when the user clicks the cancel button
        }
    };

    const removePageTabsObject = () => {
        if (window.confirm('Delete Page Tabs?')) {
            dispatch(deletePageTabsCollection(pageTabsCollectionsFromServer._id))
            setVisible(false); // close the modal when the user clicks the cancel button
        }
    };

    // Reset the form fields
    form.resetFields();

    // Set new initial values conditionally
    const initialValues = pageTabsCollectionsFromServer ? { tabsType: pageTabsCollectionsFromServer.tabsType, tabs: pageTabsCollectionsFromServer.tabs } : { tabs: [{ tabName: "All", tabOrder: "100" }] };
    form.setFieldsValue(initialValues);

    // this useEffect is No  LOnger Needed since the time I started to set variable "initialValues" there this object is defined
    // useEffect(() => {
    //     if (pageTabsCollectionsFromServer) {
    //         setTabs(pageTabsCollectionsFromServer.tabs);
    //         setTabsType(pageTabsCollectionsFromServer.tabsType)
    //         setIsLoading(false);
    //     }
    // }, [pageTabsCollectionsFromServer]);

    const showModal = () => {
        setVisible(true); // show the modal when the user clicks the button
    };

    const handleCancel = () => {
        setVisible(false); // close the modal when the user clicks the cancel button
    };


    // const sizes =  xs={6} ;

    const sizes = {
        xs: 24,
        sm: 12,
        md: 12,
        lg: 8,
        xl: 8
    }

    // get Form Item Value to check the condition
    const isFieldVisible = (fieldValue) => fieldValue !== undefined && fieldValue !== '';


    const formular = <div><Form
        layout='vertical'
        form={form}
        name="dynamic_form_nest_item"
        initialValues={{
            tabsType,
            tabs
        }}
        onFinish={onFinish}
        style={{
            maxWidth: 600,
        }}
    // autoComplete="off"
    >
        <Form.Item name="tabsType" label="Render Type" >
            {/* <Input allowClear placeholder="Tab Bar Type" /> */}
            <Radio.Group>
                <Radio value="card"> Card </Radio>
                <Radio value="line"> Line </Radio>
            </Radio.Group>
        </Form.Item>

        <Form.List name="tabs">
            {(fields, { add, remove }) => (
                <>
                    {fields.map((restField) => (
                        <>
                            <Row wrap={false} style={{ marginBottom: 10 }}>
                                <Col flex='40px'>
                                    <MinusCircleOutlined style={{ marginTop: 16 }} onClick={() => remove(restField.name)} />
                                </Col>
                                <Collapse style={{ width: '100%' }}>
                                    <Panel header={
                                        <Form.Item
                                            style={{ marginBottom: 0 }}
                                            {...restField}
                                            name={[restField.name, 'tabName']}
                                            rules={[
                                                {
                                                    required: true,
                                                    message: 'Missing tab name',
                                                },
                                            ]}
                                        >
                                            <Input allowClear placeholder="Tab Name" />
                                        </Form.Item>
                                    }>
                                        <Col flex='auto' >
                                            <Row gutter={10} >
                                                <Col {...sizes} >
                                                    <ShowExtraField label='Color'
                                                        show={isFieldVisible(form.getFieldValue(['tabs', restField.name, 'tabColor']))}>
                                                        <Form.Item label="Color"
                                                            {...restField}
                                                            name={[restField.name, 'tabColor']}
                                                        >
                                                            <Input allowClear placeholder="Color Hex" />
                                                        </Form.Item>
                                                    </ShowExtraField>
                                                </Col>
                                                <Col {...sizes}>
                                                    <ShowExtraField label='Order'
                                                        show={isFieldVisible(form.getFieldValue(['tabs', restField.name, 'tabOrder']))}>
                                                        <Form.Item label='Order'
                                                            {...restField}
                                                            name={[restField.name, 'tabOrder']}
                                                        >
                                                            <Input allowClear placeholder="Order No." />
                                                        </Form.Item>
                                                    </ShowExtraField>
                                                </Col>
                                                <Col {...sizes}>
                                                    <ShowExtraField label='Layout'
                                                        show={isFieldVisible(form.getFieldValue(['tabs', restField.name, 'tabLayout']))}
                                                    >
                                                        <Form.Item label='Layout'
                                                            {...restField}
                                                            name={[restField.name, 'tabLayout']}
                                                        >
                                                            <Input allowClear placeholder="tab Layout" />
                                                        </Form.Item>
                                                    </ShowExtraField>
                                                </Col>
                                                <Col {...sizes}>
                                                    <ShowExtraField label='Image'
                                                        show={isFieldVisible(form.getFieldValue(['tabs', restField.name, 'tabImage']))}
                                                    >
                                                        <Form.Item label='Image'
                                                            {...restField}
                                                            name={[restField.name, 'tabImage']}
                                                        >
                                                            <Input allowClear placeholder="tab Image" />
                                                        </Form.Item>
                                                    </ShowExtraField>
                                                </Col>
                                                <Col {...sizes}>
                                                    <ShowExtraField label='Icon'
                                                        show={isFieldVisible(form.getFieldValue(['tabs', restField.name, 'tabIcon']))}
                                                    >
                                                        <Form.Item label='Icon'
                                                            {...restField}
                                                            name={[restField.name, 'tabIcon']}
                                                        >
                                                            <Input allowClear placeholder="tab Icon" />
                                                        </Form.Item>
                                                    </ShowExtraField>
                                                </Col>
                                                <Col {...sizes}>
                                                    <ShowExtraField label='Title'
                                                        show={isFieldVisible(form.getFieldValue(['tabs', restField.name, 'tabTitle']))}
                                                    >
                                                        <Form.Item label='Title'
                                                            {...restField}
                                                            name={[restField.name, 'tabTitle']}
                                                        >
                                                            <Input allowClear placeholder="tab Title" />
                                                        </Form.Item>
                                                    </ShowExtraField>
                                                </Col>
                                                <Col {...sizes}>
                                                    <ShowExtraField label='Description'
                                                        show={isFieldVisible(form.getFieldValue(['tabs', restField.name, 'tabDescription']))}
                                                    >
                                                        <Form.Item label='Description'
                                                            {...restField}
                                                            name={[restField.name, 'tabDescription']}
                                                        >
                                                            <Input allowClear placeholder="tab Description" />
                                                        </Form.Item>
                                                    </ShowExtraField>
                                                </Col>
                                                <Col {...sizes}>
                                                    <ShowExtraField label='Show Header'
                                                        show={isFieldVisible(form.getFieldValue(['tabs', restField.name, 'tabHeader']))}
                                                    >
                                                        <Form.Item label='Show Header'
                                                            {...restField}
                                                            name={[restField.name, 'tabHeader']}
                                                        >
                                                            <Radio.Group>
                                                                <Radio value="show"> Show </Radio>
                                                                <Radio value="hide"> Hide </Radio>
                                                            </Radio.Group>
                                                        </Form.Item>
                                                    </ShowExtraField>
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Panel>
                                </Collapse>
                            </Row >
                        </>
                    ))}
                    <Form.Item>
                        <Button type="dashed" style={{ marginTop: 12 }} onClick={() => add()} block icon={<PlusOutlined />}>
                            Add field
                        </Button>
                    </Form.Item>
                </>
            )}
        </Form.List>
        <Form.Item>
            <Button type="primary" htmlType="submit">
                Submit
            </Button>
            <Button type="default" onClick={handleCancel}>
                Cancel
            </Button>
            <Button type="text" danger onClick={removePageTabsObject}>
                Remove Tabs Object
            </Button>
        </Form.Item>
    </Form>
    </div >;
    return (
        <>

            {hideDefaultButton ?
                <ConfigProvider
                    theme={{
                        components: {
                            Button: { cardBg: "none", fontSizeSM: 8, },
                        },
                    }}
                >
                    <Button size='small' onClick={showModal} icon={<PlusOutlined />} />
                </ConfigProvider>
                : (pageTabsCollectionsFromServer?._id ? <Tooltip title="Edit Tabs"><Button onClick={showModal} tooltip='test'>
                    {<span class="material-icons" style={{ fontSize: 16, verticalAlign: 'middle', opacity: .7 }}>tab</span>}
                </Button></Tooltip> :
                    <Tooltip title="Create Tabs"><Button onClick={showModal} tooltip='test'>
                        {<span class="material-icons" style={{ fontSize: 16, verticalAlign: 'middle', opacity: .7 }}>tab_unselected</span>}
                    </Button></Tooltip>)}


            <Modal
                title={pageTabsCollectionsFromServer?._id ? 'Editting Page Tabs' : 'Creating Page Tabs'}
                visible={visible}
                onCancel={handleCancel}
                footer={null}
            >
                {!isLoading ? <div>
                    {formular}
                </div> :
                    formular
                }
            </Modal>
        </>)
};
export default NewPageTabsAnt;