import { useSelector } from "react-redux";

const useOptions = (collectionName, propertyName, id, tabID) => {
  const sourceDataCollection = useSelector((state) => {
    let filteredData = state[collectionName] || [];

    if (id) {
      filteredData = filteredData.filter((data) => data.parents.includes(id));
    }

    if (tabID) {
      filteredData = filteredData.filter((data) => data.tabID === tabID);
    }

    return filteredData;
  });

  let allOptions = new Set();

  sourceDataCollection.forEach((data) => {
    if (Array.isArray(data[propertyName])) {
      data[propertyName].forEach((item) => {
        if (item) {
          allOptions.add(item.trim());
        }
      });
    } else if (typeof data[propertyName] === "string" && data[propertyName]) {
      allOptions.add(data[propertyName].trim());
    }
  });

  allOptions = [...allOptions].sort();

  const options = allOptions.map((option) => ({
    label: option,
    value: option,
  }));

  return options;
};

export default useOptions;
