import { useState } from 'react';
import { Link } from "react-router-dom";
import { useDispatch } from 'react-redux';
import { Button, Card, Col, Descriptions, Popover } from 'antd';
import {
    ArrowRightOutlined,
    EditOutlined,
    FolderFilled,
    DeleteOutlined,
    InfoCircleOutlined,
    DownOutlined,
    UpOutlined
} from '@ant-design/icons';
import { deleteFolderCollection } from "../../actions/folderCollections";
import moment from 'moment';

const GridCard = ({ folderCollection, setCurrentIdFolder, parentReadable }) => {
    const dispatch = useDispatch();
    const [collapsed, setCollapsed] = useState(true); // state to control the collapse

    const deleteFolder = (id) => {
        if (window.confirm('Delete the item?')) {
            dispatch(deleteFolderCollection(id));
        }
    };

    const handleCollapse = () => {
        setCollapsed(!collapsed); // toggle the collapse state
    };

    const items = [
        {
            key: '1',
            label: 'folderID',
            children: folderCollection._id,
        },
        {
            key: '2',
            label: 'created',
            children: folderCollection.createdAt,
        },
        {
            key: '3',
            label: 'readable',
            children: moment(folderCollection.createdAt).fromNow(),
        },
        {
            key: '4',
            label: 'parents',
            children: (parentReadable + ' (' + folderCollection.folderParents + ')'),
        }
    ];
    return (
        <Col xs={12} sm={8} md={6} lg={6} xl={4}>
            <Card
                title={<><Link to={`/folders/${folderCollection._id}`}><FolderFilled style={{ marginRight: 10 }} on /></Link>{folderCollection.folderName}</>}
                hoverable
                style={{ width: '100%' }}
                bodyStyle={{ backgroundColor: 'red', display: 'none' }}
                extra={collapsed ? <DownOutlined onClick={() => handleCollapse(false)} /> : <UpOutlined onClick={() => handleCollapse(true)} />} // add a button to collapse or expand the card
                actions={collapsed ? null : [
                    <Link to={`/folders/${folderCollection._id}`}>
                        <Button
                            type='link'
                            icon={<ArrowRightOutlined />}
                        />
                    </Link>
                    ,
                    <Button
                        type="link"
                        onClick={() => setCurrentIdFolder(folderCollection._id)}
                        icon={<EditOutlined />}
                    />,
                    <Popover content={<Descriptions
                        style={{ maxWidth: '340px' }}
                        title={folderCollection.folderName}
                        items={items}
                        size="small"
                        column={1}
                    />}
                    >
                        <Button
                            type="link"
                            icon={<InfoCircleOutlined />}
                        /></Popover>,
                    <Button
                        type="link"
                        onClick={() => deleteFolder(folderCollection._id)}
                        icon={<DeleteOutlined />}
                    />
                ]}
                collapsible // enable the collapsible feature
                collapsed={collapsed} // set the collapse state
                onCollapse={handleCollapse} // handle the collapse event
            />
        </Col>
    );
}

export default GridCard;
