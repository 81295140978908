import React, { useEffect, useState } from 'react';
import Chart from 'react-apexcharts';
import dayjs from 'dayjs';
import 'dayjs/locale/en';

// Set dayjs locale to English
dayjs.locale('en');

const TransactionChart = ({ transactions, defaultBudget }) => {
    const [chartData, setChartData] = useState({ categories: [], series: [] });
    const [maxValue, setMaxValue] = useState(0);

    useEffect(() => {
        // Ensure locale is set to English
        dayjs.locale('en');

        // Process transactions to get sums per day
        const processTransactions = (transactions) => {
            const result = {};

            // Aggregate transactions by date
            transactions.forEach(transaction => {
                const date = dayjs(transaction.transactionDate).format('YYYY-MM-DD');
                if (!result[date]) {
                    result[date] = 0;
                }
                result[date] += transaction.amount;
            });

            return result;
        };

        // Get the last 7 days
        const getLast7Days = () => {
            const days = [];
            for (let i = 6; i >= 0; i--) {
                days.push(dayjs().subtract(i, 'day').format('YYYY-MM-DD'));
            }
            return days;
        };

        // Prepare the chart data
        const prepareChartData = (aggregatedData, last7Days) => {
            const categories = last7Days.map(day => `${dayjs(day).format('dd')}\n ${dayjs(day).format('D.M.')}`);
            const seriesData = last7Days.map(day => aggregatedData[day] || 0);

            return {
                categories,
                series: [{
                    name: 'Transactions',
                    data: seriesData
                }]
            };
        };

        const aggregatedData = processTransactions(transactions);
        const last7Days = getLast7Days();
        const chartData = prepareChartData(aggregatedData, last7Days);

        // set the maximum value from defaultBudget
        const maxSeriesValue = defaultBudget === "Nevyplneny" ? 0 : defaultBudget;
        setMaxValue(maxSeriesValue);

        setChartData(chartData);
    }, [transactions, defaultBudget]);

    const options = {
        chart: {
            type: 'bar'
        },
        xaxis: {
            categories: chartData.categories
        },
        title: {
            text: 'Transactions in the Last 7 Days'
        },
        annotations: {
            yaxis: [
                {
                    y: maxValue,
                    borderColor: '#FF4560',
                    label: {
                        borderColor: '#FF4560',
                        style: {
                            color: '#fff',
                            background: '#FF4560'
                        },
                        text: `Budget: ${maxValue}`
                    }
                }
            ]
        },
        plotOptions: {
            bar: {
                borderRadius: 5, // Adjust this value for more or less rounding
                borderRadiusApplication: 'end', // Apply border radius to the end of bars
                dataLabels: {
                    position: 'top', // top, center, bottom
                },
            }
        },
        dataLabels: {
            enabled: true,
            offsetY: -20,
            style: {
                fontSize: '12px',
                colors: ['rgba(123,123,123,1)']
            }
        }
    };

    return (
        <div>
            <Chart options={options} series={chartData.series} type="bar" height={350} />
        </div>
    );
};

export default TransactionChart;