import React, { useState, useEffect } from 'react';
import { Modal, Form, Typography, Row, Col, Button, Select, message } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { getDefaultSettings, updateDefaultSettings } from '../../../actions/expenseverseDefaultSettingsActions';

const { Title, Paragraph } = Typography;
const { Option } = Select;

const DefaultTagsModal = ({ showIconInsteadOfTitle }) => {
    const dispatch = useDispatch();
    const [isModalVisible, setIsModalVisible] = useState(false);
    const defaultSettings = useSelector(state => state.expenseverseDefaultSettings?.defaultSettings || {});
    const [defaultTagsForm] = Form.useForm();
    const [areTagsChanged, setAreTagsChanged] = useState(false);
    const [tags, setTags] = useState([]);

    // Load default settings for tags when the modal is opened
    useEffect(() => {
        if (isModalVisible) {
            dispatch(getDefaultSettings());
        }
    }, [isModalVisible, dispatch]);

    // Set default tags from the state when the modal is visible
    useEffect(() => {
        if (defaultSettings && Object.keys(defaultSettings).length > 0) {
            defaultTagsForm.setFieldsValue({
                defaultTags: defaultSettings.defaultTags || '',
            });
            setTags(defaultSettings.defaultTags || []);
        }
    }, [defaultSettings, defaultTagsForm]);

    // Handle changes in tags
    const handleTagsChange = (value) => {
        setTags(value);
        setAreTagsChanged(true);
    };

    // Handle form submission to save the default tags
    const handleOkDefaultSettings = async () => {
        try {
            const values = await defaultTagsForm.validateFields();
            await dispatch(updateDefaultSettings(values)); // Dispatch update action with form values
            // message.success('Default tags updated successfully');
            setAreTagsChanged(false);
        } catch (error) {
            message.error('Failed to update default tags. Please try again.');
        }
    };

    const handleCancelDefaultSettings = () => {
        setIsModalVisible(false);
        setAreTagsChanged(false);
    };

    return (
        <>
            {showIconInsteadOfTitle ? (
                <Button
                    icon={<span className="material-symbols-rounded" style={{
                        fontVariationSettings: "'FILL' 1",
                        fontSize: 20, verticalAlign: 'middle', opacity: .7
                    }}>
                        {showIconInsteadOfTitle}
                    </span>}
                    onClick={() => setIsModalVisible(true)}
                />
            ) : (
                <>
                    <Title level={3}>Default Tags</Title>
                    <Button type="primary" onClick={() => setIsModalVisible(true)}>
                        Set Default Tags
                    </Button>
                </>
            )}

            <Modal
                title="Set Default Tags"
                visible={isModalVisible}
                onCancel={handleCancelDefaultSettings}
                footer={null}
            >
                {/* <Typography.Title level={3}>Default Tags</Typography.Title> */}
                <Paragraph>
                    Use this field to prefill Tags for each future transaction.
                    This is useful if, for example, you are going on a trip and want to make sure
                    that each transaction is marked with tags like 'Norway trip' or 'Solo trip'.
                </Paragraph>

                <Form
                    form={defaultTagsForm}
                    name="defaultTags"
                    layout="vertical"
                    onValuesChange={() => setAreTagsChanged(true)}
                >
                    <Row gutter={[16]}>
                        <Col span={16}>
                            <Form.Item name="defaultTags">
                                <Select
                                    mode="tags"
                                    placeholder="Select default tags"
                                    onChange={handleTagsChange}
                                    value={tags}
                                    tokenSeparators={[',']}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item>
                                <Button
                                    block
                                    type="primary"
                                    onClick={handleOkDefaultSettings}
                                    disabled={!areTagsChanged}
                                >
                                    Save
                                </Button>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Modal>
        </>
    );
};

export default DefaultTagsModal;
