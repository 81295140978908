// ImportTransactions.js

import React, { useState } from 'react';
import { Button, Input, Alert, Space, Collapse, ConfigProvider, Divider, theme, Popover, Typography } from 'antd';
import { ExclamationCircleOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';
const { Panel } = Collapse;
const { TextArea } = Input;

const validCurrencies = [
    'USD', 'EUR', 'CZK', 'GBP', 'JPY', 'AUD', 'CAD', 'CHF', 'CNY', 'SEK', 'NZD', 'BRL', 'RUB', 'INR', 'MXN',
    'SGD', 'HKD', 'NOK', 'KRW', 'TRY', 'ZAR', 'DKK', 'PLN', 'THB', 'IDR', 'HUF', 'MYR', 'PHP', 'AED', 'ARS',
    'CLP', 'COP', 'EGP', 'ILS', 'PKR', 'SAR', 'VND'
];

const dateFormats = [
    'D.M.YYYY',
    'D.M.YY',
    'D-M-YYYY',
    'D-M-YY',
    'DD.MM.YYYY',
    'DD.MM.YY',
    'DD-MM-YYYY',
    'DD-MM-YY'
];

const parseTextInput = (input, setErrorMessage) => {
    const transactions = [];
    const lines = input.split('\n');
    let currentDate = dayjs(); // Default to current date
    const errors = [];

    lines.forEach(line => {
        if (line.trim() === '') return;

        const dateMatch = line.match(/(\d{1,2}[.-]\d{1,2}[.-]\d{2,4})/);
        if (dateMatch) {
            let validDate = false;
            for (const format of dateFormats) {
                const parsedDate = dayjs(dateMatch[0], format, true);
                if (parsedDate.isValid()) {
                    currentDate = parsedDate;
                    validDate = true;
                    break;
                }
            }
            if (!validDate) {
                errors.push(`Invalid date format: ${dateMatch[0]}`);
                currentDate = dayjs(); // Set to current date if invalid
            }
        } else {
            const transactionMatch = line.match(/([+-])\s*(\d+)\s*([A-Z]{3})\s*,?\s*(.*)/i);
            if (transactionMatch) {
                const [, sign, amount, currency, note] = transactionMatch.map(item => item.trim());

                // Validate amount
                if (!amount) {
                    errors.push(`Missing amount: ${line.trim()}`);
                }

                // Validate currency
                if (!validCurrencies.includes(currency.toUpperCase())) {
                    errors.push(`Invalid or missing currency: ${line.trim()}`);
                } else {
                    const transaction = {
                        transactionDate: currentDate,
                        amount: sign === '-' ? -amount : +amount,
                        currency: currency.toUpperCase(),
                        isExpense: sign === '-' ? 'expense' : 'income',
                        accountId: 'cash',
                        paymentMethod: 'cash'
                    };

                    // Conditionally add note if it exists
                    if (note) {
                        transaction.note = note;
                    }

                    transactions.push(transaction);
                }
            } else {
                errors.push(`Invalid transaction format: ${line.trim()}`);
            }
        }
    });

    setErrorMessage(errors.join('\n'));
    return transactions;
};

const ImportTransactions = ({ form, setPaidByCash }) => {
    const [textInput, setTextInput] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [successMessage, setSuccessMessage] = useState('');
    const { useToken } = theme;
    const { token } = useToken(); // get the token

    const handleLoadTransactions = () => {
        setErrorMessage('');
        setSuccessMessage('');
        const parsedTransactions = parseTextInput(textInput, setErrorMessage);
        if (parsedTransactions.length === 0) {
            setErrorMessage('No valid transactions found');
            return;
        }
        form.setFieldsValue({
            transactions: parsedTransactions.map((transaction, index) => ({
                ...transaction,
                key: index
            }))
        });
        const initialPaiedByCash = {};
        parsedTransactions.forEach((transaction, index) => {
            initialPaiedByCash[index] = 'cash';
        });
        setPaidByCash(initialPaiedByCash);

        // Set success message with the total number of valid transactions
        setSuccessMessage(`${parsedTransactions.length} transactions successfully loaded into the form.`);
    };

    const handleShowTransactions = () => {
        const parsedTransactions = parseTextInput(textInput, setErrorMessage);
        console.log(parsedTransactions);
    };

    const instructions = (
        <div style={{
            maxWidth: 500,
            overflow: 'auto',
            // maxHeight: 300,
        }}>
            <p>Use this feature to convert text to transactions. Submitting text will check validity of each transaction and if valid it will be prefilled in form which can be later submitted.
                <br />
                <br />
                Enter transactions in the following format:</p>
            <ul>
                <li>Date (optional, defaults to current date) in formats:<br />
                    D.M.YYYY, D.M.YY, D-M-YYYY, D-M-YY,<br /> DD.MM.YYYY, DD.MM.YY, DD-MM-YYYY, DD-MM-YY</li>
                <li>Amount and currency (mandatory) in the format: +100 USD, -50 EUR</li>
                <li>Note (optional) after a comma: -50 USD, groceries</li>
            </ul>
            <p>Example:</p>
            <Typography>
                <pre>
                    22.5.2024 <br />
                    -240 CZK, supermarket<br />
                    +1200 CZK, transfer between accounts<br />
                    -140 CZK, new plant
                </pre>
            </Typography>
        </div>
    );

    return (
        <>
            <div style={{ position: 'sticky', top: 0, backgroundColor: token.colorBgElevated, zIndex: 1001 }}>
                <Collapse ghost style={{ padding: '8px 0 0px 0' }} >
                    <Panel header={<>Import transactions from text <Popover placement="top" title={'Instructions'} content={instructions}>
                        <QuestionCircleOutlined />
                    </Popover></>} key="1" >
                        <Space size={16} direction='vertical' style={{ width: '100%' }}>

                            <TextArea variant='filled' rows={4} value={textInput} onChange={(e) => setTextInput(e.target.value)} placeholder="Enter transactions here" />
                            <Button type="primary" onClick={handleLoadTransactions} style={{ width: '100%' }}>Load transactions from text</Button>
                            {/* <Button type="default" onClick={handleShowTransactions} style={{ width: '100%' }}>Show transactions from text</Button> */}
                            {errorMessage && (
                                <Alert
                                    type="error"
                                    showIcon
                                    message={
                                        <ConfigProvider
                                            theme={{
                                                components: {
                                                    Collapse: {
                                                        headerPadding: 0
                                                    },
                                                },
                                            }}
                                        >
                                            <Collapse ghost>
                                                <Panel header={`Error (${errorMessage.split('\n').length})`} key="1">
                                                    <div>
                                                        <ol>
                                                            {errorMessage.split('\n').map((err, index) => (
                                                                <li key={index}>{err}</li>
                                                            ))}
                                                        </ol>
                                                    </div>
                                                </Panel>
                                            </Collapse>
                                        </ConfigProvider>
                                    }
                                />
                            )}
                            {successMessage && (
                                <Alert
                                    type="success"
                                    message={successMessage}
                                    showIcon
                                />
                            )}
                            {/* <br /> */}
                        </Space>
                    </Panel>
                </Collapse>
                <Divider style={{ margin: '8px -16px 16px -16px', width: 'inherit' }} />
            </div>
        </>
    );
};

export default ImportTransactions;