import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Card, ConfigProvider, message } from 'antd';
import enUS from 'antd/lib/locale/en_US';
import PageContentWrapper from '../../PageContentWrapper/PageContentWrapper';
import CategoryList from '../../../components/ExpensesView/PageSettings/CategoryList';
import CategoryModal from '../../../components/ExpensesView/PageSettings/CategoryModal';
import { getExpenseverseCategories, createExpenseverseCategory, updateExpenseverseCategory, deleteExpenseverseCategory } from '../../../actions/expenseverseCategoryActions';
import AccountList from '../../../components/ExpensesView/PageSettings/AccountList';
import ProductList from '../../../components/ExpensesView/PageSettings/ProductList';
import DefaultSettingsModal from '../../../components/ExpensesView/PageSettings/DefaultSettingsModal';

const Settings = ({ setPageTitle }) => {
    const [isModalCategoryVisible, setIsModalCategoryVisible] = useState(false);
    const [isGroup, setIsGroup] = useState(false);
    const [currentCategoryId, setCurrentCategoryId] = useState(null);
    const [formValues, setFormValues] = useState({});
    const dispatch = useDispatch();
    const categories = useSelector((state) => state.expenseverseCategories);

    useEffect(() => {
        setPageTitle('Products & Settings');
        dispatch(getExpenseverseCategories());
    }, [dispatch, setPageTitle]);

    const buildTreeData = (categories) => {
        const groupCategories = [];
        const remainingCategories = [];
        const categoryMap = {};

        categories.forEach(category => {
            categoryMap[category._id] = category;
        });

        const childIds = new Set();
        const groupIds = new Set();

        categories.forEach(category => {
            if (category.isGroup) {
                groupIds.add(category._id);
                groupCategories.push({
                    title: `${category.name} (${category.children.length > 0 ? category.children.length : 'empty group'})`,
                    isGroup: true,
                    value: category._id,
                    key: category._id,
                    selectable: false,
                    children: category.children.length > 0 ? category.children.map(childId => {
                        const childCategory = categoryMap[childId];
                        if (childCategory) {
                            childIds.add(childId);
                            return {
                                title: childCategory.name,
                                value: childCategory._id,
                                key: childCategory._id
                            };
                        }
                        return null;
                    }).filter(child => child !== null) : null
                });
            }
        });

        categories.forEach(category => {
            if (!childIds.has(category._id) && !groupIds.has(category._id)) {
                remainingCategories.push({
                    title: category.name,
                    value: category._id,
                    key: category._id
                });
            }
        });

        return [...groupCategories, ...remainingCategories];
    };

    const treeData = buildTreeData(categories);

    const showModal = (category, group = false) => {
        if (category) {
            console.log('handleModal received: ', category);
            const originalCategory = categories.find(cat => cat._id === category.key);
            console.log('originalCategory: ', originalCategory);
            setCurrentCategoryId(originalCategory ? originalCategory._id : null);
            setIsGroup(group || originalCategory?.isGroup || false);
            setFormValues(originalCategory || {});
        } else {
            setCurrentCategoryId(null);
            setIsGroup(group);
            setFormValues({});
        }
        setIsModalCategoryVisible(true);
    };

    const handleCancel = () => {
        setIsModalCategoryVisible(false);
        setFormValues({});
    };

    const handleOk = async (values) => {
        try {
            let result;
            if (currentCategoryId) {
                result = await dispatch(updateExpenseverseCategory(currentCategoryId, values));
            } else {
                result = await dispatch(createExpenseverseCategory(values));
            }
            if (result.status === 'success') {
                handleCancel();
                return { status: 'success' };
            } else {
                message.error(`Failed to save category (${result.message})`);
                return { status: 'error', message: result.message };
            }
        } catch (errorInfo) {
            console.log('Failed:', errorInfo);
            return { status: 'error', message: errorInfo.message };
        }
    };


    const handleDelete = async (id) => {
        for (const group of categories) {
            if (group.children && group.children.includes(id)) {
                group.children = group.children.filter(childId => childId !== id);
                await dispatch(updateExpenseverseCategory(group._id, group));
            }
        }

        const result = await dispatch(deleteExpenseverseCategory(id));
        if (result.status === 'success') {
            message.success('Category deleted successfully');
        } else {
            message.error(`Failed to delete category (${result.message})`);
        }
    };

    return (
        <ConfigProvider locale={enUS}>
            <PageContentWrapper>
                <Card>
                    <DefaultSettingsModal showTags showAccounts showDailyLimits />
                </Card>
                <br /> <br /><br />

                <ProductList />
                <br /> <br /><br />

                <Card>
                    <AccountList />
                </Card>
                <br /> <br /><br />

                <CategoryList
                    categories={categories}
                    onEdit={showModal}
                    onDelete={handleDelete}
                    onAddCategory={() => showModal(null, false)}
                    onAddGroup={() => showModal(null, true)}
                    treeData={treeData}
                />
                <CategoryModal
                    visible={isModalCategoryVisible}
                    onCancel={handleCancel}
                    onOk={handleOk}
                    category={formValues}
                    isGroup={isGroup}
                    treeData={treeData}
                    categories={categories}
                />
            </PageContentWrapper>
        </ConfigProvider>
    );
};

export default Settings;