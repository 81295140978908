import * as api from '../api/index';
import { getAuth, getIdToken } from "firebase/auth";

// action creators
export const getNoteCollections = () => async (dispatch) => {
    try {
        const auth = getAuth();
        const token = await getIdToken(auth.currentUser);
        const { data } = await api.fetchNoteCollections(token);

        dispatch({ type: 'FETCH_ALL', payload: data });
    } catch (error) {
        console.log(error.message)
    }
}

export const createNoteCollection = (noteCollection) => async (dispatch) => {
    try {
        const auth = getAuth();
        const token = await getIdToken(auth.currentUser);
        const { data } = await api.createNoteCollection(noteCollection, token);

        dispatch({ type: 'CREATE', payload: data });
    } catch (error) {
        console.log(error)
    }
}

export const updateNoteCollection = (id, noteCollection) => async (dispatch) => {
    try {
        const auth = getAuth();
        const token = await getIdToken(auth.currentUser);
        const { data } = await api.updateNoteCollection(id, noteCollection, token);

        dispatch({ type: 'UPDATE', payload: data })
    } catch (error) {
        console.log(error.message);
    }
}

export const deleteNoteCollection = (id) => async (dispatch) => {
    try {
        const auth = getAuth();
        const token = await getIdToken(auth.currentUser);
        await api.deleteNoteCollection(id, token);

        dispatch({ type: 'DELETE', payload: id })
    } catch (error) {
        console.log(error);
    }
}