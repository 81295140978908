export default (noteCollections = [], action) => {
    switch (action.type) {
        case 'DELETE':
            return noteCollections.filter((noteCollection) => noteCollection._id !== action.payload);
        case 'UPDATE':
            return noteCollections.map((noteCollection) => noteCollection._id === action.payload._id ? action.payload : noteCollection);
        case 'FETCH_ALL':
            return action.payload;
        case 'CREATE':
            return [...noteCollections, action.payload];
        default:
            return noteCollections;
    }
}