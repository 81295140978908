import React, { useEffect, useState } from 'react';
import { Typography, Col, Row, Button, Space, } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import "../../styles.css";
import PageContentWrapper from '../PageContentWrapper/PageContentWrapper';
import ExpenseForm from '../../components/ExpensesView/ExpenseForm/DraftExpenseFormSeptember24';
// import ExpenseForm from '../../components/ExpensesView/ExpenseForm/ExpenseForm';
import { getExpenseDataByDateRange } from '../../actions/expensesActions';
import Expenses from '../../components/ExpensesView/Expenses/Expenses';
import MultiExpenseForm from '../../components/ExpensesView/ExpenseForm/MultiExpenseForm';
import SpentTracker from '../../components/ExpensesView/ExpenseSubcomponents/SpentTracker';
import DefaultSettingDrawer from '../../components/ExpensesView/ExpenseSubcomponents/DefaultSettingDrawer';
import useOptions from '../../utils/getOptions';
import ReportComponent from '../../components/ExpensesView/ExpenseSubcomponents/ReportComponent';
import { PageContainer } from '@ant-design/pro-layout';
import TransactionChart from '../../components/ExpensesView/Charts/TransactionChart';
import TransactionChart_G2Plot from '../../components/ExpensesView/Charts/TransactionChart_G2Plot';
import TransactionChart_V2 from '../../components/ExpensesView/Charts/TransactionChart_V2';
import TransactionChartWCategories from '../../components/ExpensesView/Charts/TransactionChartWCategories';
import StackedDivergingBarChart from '../../components/ExpensesView/Charts/StackedDivergingBarChart_G2';
import G2_divergingBarChart from '../../components/ExpensesView/Charts/G2_divergingBarChart';
import { getDailyLimitByDate, getDailyLimitsByRange } from '../../actions/expenseverseDailyLimitActions';
import dayjs from 'dayjs';
import DevVarDisplay from '../../components/Other/DevVarDisplay';
import { useLocation, useNavigate } from 'react-router-dom';
import { LeftOutlined, RightOutlined, EllipsisOutlined } from '@ant-design/icons';
import NewSpentTracker from '../../components/ExpensesView/ExpenseSubcomponents/NewSpentTracker';
import DailyTransactionChart from '../../components/ExpensesView/Charts/DailyTransactionChart';
import BasicColumnChart from '../../components/ExpensesView/Charts/DailyBasicColumnChartG2Plot';
import ChangeDailyLimitModal from '../../components/ExpensesView/PageSettings/ChangeDailyLimitModal';

//using environmental variables as in this article did not work, thats why i used solution above  (https://medium.com/@diptimaya.patra/auto-increment-react-app-version-when-a-build-is-created-343f2006c978) 
const { Text } = Typography

const ExpensesHome = ({ darkMode, LightToken, DarkToken, setPageTitle, pageHeaderMode }) => {
    const [currentId, setCurrentId] = useState(null);
    const [currentExpenseID, setCurrentExpenseID] = useState(null);
    const [visible, setVisible] = useState(false); // state for modal visibility
    const [visibleMultiExpense, setVisibleMultiExpense] = useState(false); // state for modal visibility
    const dailyLimit = useSelector((state) => state.expenseverseDailyLimit.currentDateLimit);
    const [selectedDay, setSelectedDay] = useState(dayjs().startOf('day'));
    const [selectedCategory, setSelectedCategory] = useState('');
    const [dateRange, setDateRange] = useState([dayjs().startOf('day'), dayjs().endOf('day')]);
    const categories = useSelector((state) => state.expenseverseCategories);
    const categoriesQuickOptions = categories.filter(r => r?.isQuickSelect === true);
    const defaultSettings = useSelector(state => state.expenseverseDefaultSettings?.defaultSettings || {});
    const accounts = useSelector(state => state.expenseverseAccounts);
    const defaultCurrency = accounts.find(account => account._id === defaultSettings?.defaultBankAccount || defaultSettings?.defaultCashAccount || defaultSettings?.defaultVirtualAccount)?.defaultCurrency;
    // const getExpenseData = useSelector((state) => state.expensesCollection).sort((a, b) => ascending ? moment(a[sortBy]).diff(b[sortBy]) : moment(b[sortBy]).diff(a[sortBy]));;
    const expenses = useSelector(state => state.expensesCollection);
    const [isDailyLimitModalVisible, setIsDailyLimitModalVisible] = useState(false); // Updated state name

    // const classes = useStyles();
    const dispatch = useDispatch();
    const location = useLocation(); // Hook to get the current location object
    const navigate = useNavigate();

    const expensesFilteredBeforeSumming = expenses.filter(expense => expense.currency === defaultCurrency && expense.amount < 0);
    const totalAmount = expensesFilteredBeforeSumming.reduce((sum, expense) => sum + expense.amount, 0);

    const dailyLimitsStore = useSelector((state) => state.expenseverseDailyLimit);

    useEffect(() => {
        console.log('dailyLimitsStore has changed', dailyLimitsStore);
    }, [dailyLimitsStore])

    useEffect(() => {
        const queryParams = new URLSearchParams(location.search);
        const drawerParam = queryParams.get('drawer');

        // Set the drawer state based on the URL parameter
        if (drawerParam === 'true' || drawerParam === '1') {
            setVisible(true);
        } else {
            setVisible(false);
        }

        const startDateAsString = selectedDay.format('YYYY-MM-DD'); // Extract the date part
        dispatch(getExpenseDataByDateRange(selectedDay.toISOString(), selectedDay.toISOString()));
        dispatch(getDailyLimitByDate(startDateAsString)); // Dispatch the action to get daily limit for the selected date
        setDateRange([selectedDay.startOf('day'), selectedDay.endOf('day')]);
        setPageTitle('Expenses');
    }, [selectedDay]);

    const handleRemoveURLParamsRelatedToDrawer = () => {
        setOpenDrawer(false);
        // Update URL to remove the 'drawer' parameter
        const queryParams = new URLSearchParams(location.search);
        queryParams.delete('drawer');
        navigate(`${location.pathname}?${queryParams.toString()}`, { replace: true });
    };

    const handlePreviousDay = () => {
        setSelectedDay(selectedDay.subtract(1, 'day'));
    };

    const handleNextDay = () => {
        const today = dayjs().startOf('day');
        if (!selectedDay.isSame(today, 'day')) {
            setSelectedDay(selectedDay.add(1, 'day'));
        }
    };


    // Find Lost items Only
    const transactions = useSelector((state) => state.expensesCollection);



    // DRAWER RELATED CODE
    const [openDrawer, setOpenDrawer] = useState(false); // state for DRAWER visibility
    const [defaultBudget, setDefaultBudget] = useState(localStorage.getItem('defaultBudget') ? localStorage.getItem('defaultBudget') : 'Nevyplneny');
    const [defaultAccount, setDefaultAccount] = useState(localStorage.getItem('defaultAccount') ? localStorage.getItem('defaultAccount') : 'Nevyplneny');
    // const [defaultCurrency, setDefaultCurrency] = useState(localStorage.getItem('defaultCurrency') ? localStorage.getItem('defaultCurrency') : 'Nevyplneny');
    const [defaultPaymentMethod, setDefaultPaymentMethod] = useState(localStorage.getItem('defaultPaymentMethod') ? localStorage.getItem('defaultPaymentMethod') : 'Nevyplneny');
    const allAccounts = useOptions('expensesCollection', 'account');
    allAccounts.push(
        {
            value: 'CSOBPremium',
            label: 'Premium ČSOB',
        }, {
        value: 'CSOBSporici',
        label: 'Sporici ČSOB',
    },
        {
            value: 'sodexo',
            label: 'Sodexo',
        },
        {
            value: 'cash',
            label: 'cash',
        },
    );

    return (

        <PageContainer className='moveDown'
            title={< >Expenses</>}
            // breadcrumbRender={() => <BreadcrumbsTest />}
            fixedHeader={pageHeaderMode}
            // content={
            //     <ReportComponent />
            // }
            extra={[
                <Space wrap>
                    <ExpenseForm
                        darkMode={darkMode}
                        handleRemoveURLParamsRelatedToDrawer={handleRemoveURLParamsRelatedToDrawer} // Pass function to handle drawer close and URL update
                        // LightToken={LightToken} DarkToken={DarkToken}
                        // defaultCurrency={defaultCurrency}
                        // defaultAccount={defaultAccount}
                        // defaultPaymentMethod={defaultPaymentMethod}
                        allAccounts={allAccounts}
                        setCurrentExpenseID={setCurrentExpenseID} currentExpenseID={currentExpenseID} setVisible={setVisible} visible={visible}

                        selectedDay={selectedDay}
                        selectedCategory={selectedCategory}
                        setSelectedCategory={setSelectedCategory}
                        setDefaultTags={defaultSettings.defaultTags}
                    />
                    {/* <dev> */}
                    {categoriesQuickOptions && categoriesQuickOptions.map(category =>
                        <Button icon={<span className="material-symbols-rounded" style={{ fontSize: 20, verticalAlign: 'middle', opacity: .7, }}>{category?.icon}</span>}
                            // style={{ color: category?.color }}
                            onClick={() => {
                                setSelectedCategory(category._id);
                            }}>
                            {/* {category?.name} */}
                        </Button>
                    )}
                    {/* </dev> */}
                    <MultiExpenseForm setCurrentExpenseID={setCurrentExpenseID} currentExpenseID={currentExpenseID} setVisibleMultiExpense={setVisibleMultiExpense} visibleMultiExpense={visibleMultiExpense}
                        darkMode={darkMode} LightToken={LightToken} DarkToken={DarkToken}
                        defaultCurrency={defaultCurrency}
                        defaultAccount={defaultAccount}
                        defaultPaymentMethod={defaultPaymentMethod}
                        allAccounts={allAccounts} />
                </Space>
            ]}
        >
            <div
                style={{
                    maxWidth: '900px',
                    margin: '0 auto',
                    paddingTop: 16,
                    // color: token.colorPrimary,
                }}
            >

                {/* <Typography.Title level={5}>All Transactions
                </Typography.Title> */}

                <Row gutter={16}>
                    <Col sm={8} xs={24}>
                        <Row gutter={16}>
                            <Col sm={24} xs={24}>

                                <div style={{
                                    marginBottom: 16, display: 'flex',
                                    justifyContent: 'space-between',
                                    alignItems: 'center'
                                }}>

                                    <Button icon={<LeftOutlined />}
                                        onClick={handlePreviousDay}
                                        style={{ marginRight: 8 }}
                                    />
                                    <Text>{selectedDay.format('YYYY-MM-DD')}</Text>
                                    <Button icon={<RightOutlined />}
                                        onClick={handleNextDay}
                                        style={{ marginLeft: 8 }}
                                        disabled={selectedDay.isSame(dayjs().startOf('day'), 'day')}
                                    />
                                </div>
                                {/* <DevVarDisplay value={defaultSettings.defaultTags} /> */}
                                {/* <DevVarDisplay value={totalAmount} /> */}
                                <NewSpentTracker
                                    dailyLimit={dailyLimit}
                                    defaultCurrency={defaultCurrency}
                                    totalAmount={totalAmount}
                                    // defaultSettings={defaultSettings}
                                    accounts={accounts}
                                    extraChart={
                                        <BasicColumnChart
                                            transactions={transactions}
                                            dailyLimit={Math.abs(dailyLimit)}
                                            defaultCurrency={defaultCurrency}
                                        // darkMode={darkMode}
                                        />
                                    }

                                    actionsButton={
                                        <>
                                            {/* <Button
                                                icon={<EllipsisOutlined />}
                                                onClick={() => setIsDailyLimitModalVisible(true)} // Updated to use new state name
                                                type="text"
                                            // style={{ position: 'absolute', top: 10, right: 10 }} // Adjust position as needed
                                            /> */}
                                            <Button
                                                type="text"
                                                icon={<span className="material-symbols-rounded" style={{
                                                    fontVariationSettings: "'FILL' 1",
                                                    fontSize: 20, verticalAlign: 'middle', opacity: .7,
                                                    // color: token.colorTextDescription,
                                                }}>currency_exchange
                                                </span>}
                                                onClick={() => setIsDailyLimitModalVisible(true)}>
                                            </Button>
                                            <ChangeDailyLimitModal
                                                visible={isDailyLimitModalVisible} // Updated to use new state name
                                                onClose={() => setIsDailyLimitModalVisible(false)} // Updated to use new state name
                                                currentDate={selectedDay}
                                                dailyLimit={dailyLimit}
                                            />
                                        </>
                                    }
                                />
                                <br />
                                {/* <SpentTracker defaultBudget={defaultBudget} allAccounts={allAccounts} /> */}
                            </Col>
                            <Col sm={24} xs={24}>
                                {/* <DevVarDisplay value={dailyLimits[0]?.amount} /> */}
                                <DevVarDisplay value={darkMode} />

                                {/* <DailyTransactionChart
                                    transactions={transactions}
                                    defaultCurrency={defaultCurrency}
                                    dailyLimit={dailyLimit}
                                    darkMode={darkMode}
                                // showLegend={false}
                                /> */}

                                {/* <G2_divergingBarChart transactions={transactions}
                                    dateRange={dateRange}
                                    dailyLimits={[dailyLimit]}
                                    showDateLimitAsLine={true}
                                    selectedDay={selectedDay}
                                    height={'200px'}
                                    showLegend={false}
                                /> */}
                            </Col>
                        </Row>
                    </Col>
                    <Col sm={16} xs={24}>
                        <Expenses
                            setCurrentExpenseID={setCurrentExpenseID} currentExpenseID={currentExpenseID}
                            setVisible={setVisible} receivedCollection={expenses} />
                    </Col>
                </Row>

                <br />



                {/* SETTINGS DRAWER COMPONENTS */}
                {/* <DefaultSettingDrawer openDrawer={openDrawer} setOpenDrawer={setOpenDrawer}
                    defaultBudget={defaultBudget} setDefaultBudget={setDefaultBudget}
                    defaultAccount={defaultAccount} setDefaultAccount={setDefaultAccount}
                    defaultCurrency={defaultCurrency} setDefaultCurrency={setDefaultCurrency}
                    defaultPaymentMethod={defaultPaymentMethod} setDefaultPaymentMethod={setDefaultPaymentMethod}
                    allAccounts={allAccounts}
                /> */}
            </div>
        </PageContainer>
    )
}

export default ExpensesHome;