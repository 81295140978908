import React, { useEffect, useState } from 'react';
import { Breadcrumb, Tree } from 'antd';
import DevVarDisplay from '../../Other/DevVarDisplay';
// import { createDataTree } from './createDataTreeForBreadcrumbs';
import { createDataTree } from './createDataTreeForBreadcrumbsWithHome';
import { useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import { HomeOutlined } from '@ant-design/icons';


const BreadcrumbsTest = ({ folderId }) => {
    // const location = useLocation();
    const folders = useSelector((state) => state.folderCollections);
    const notes = useSelector((state) => state.noteCollections);
    const tabs = useSelector((state) => state.pageTabsCollections);

    const labelPropertyName = "label";
    const linkPropertyName = "href";
    let tree = createDataTree(
        { folders, noteCollections: notes, pageTabs: tabs },
        { labelProperty: labelPropertyName, linkProperty: linkPropertyName },
        { parentId: "", useIconComponents: true, showHomeAsRoot: true, showBreadcrumbHome: true, showFoldersOnly: false, showStats: true }
    );



    let breadcrumbData = tree; // change to "tree" variable if you do not want to include "Home" 


    function findBreadcrumbNode(node, pathname) {
        if (node[linkPropertyName] === pathname) {
            return [node];
        }

        for (let child of node.children || []) {
            let result = findBreadcrumbNode(child, pathname);
            if (result) {
                return [node, ...result];
            }
        }

        return null;
    }

    function getCurrentBreadcrumbs(breadcrumbData, pathname) {
        for (let node of breadcrumbData) {
            let result = findBreadcrumbNode(node, pathname);
            if (result) {
                return result;
            }
        }

        return [];
    }

    // Usage:
    const location = useLocation();
    const currentBreadcrumbs = getCurrentBreadcrumbs(breadcrumbData, location.pathname);



    return (
        <div>
            <Breadcrumb routes={currentBreadcrumbs} />
            {/* <DevVarDisplay title="currentBreadcrumbs" value={currentBreadcrumbs} /> */}
        </div>
    );
};

export default BreadcrumbsTest;
