import * as api from '../api/index';
import { message } from 'antd';
import { getAuth, getIdToken } from 'firebase/auth';

export const getUserSettings = () => async (dispatch) => {
    try {
        const auth = getAuth();
        const token = await getIdToken(auth.currentUser);
        const { data } = await api.fetchUserSettings(token);

        dispatch({ type: 'FETCH_USER_SETTINGS', payload: data });
    } catch (error) {
        console.error(error.message);
        message.error('Failed to fetch user settings. Please try again.');
    }
};

export const updateUserSettings = (settingsData) => async (dispatch) => {
    try {
        const auth = getAuth();
        const token = await getIdToken(auth.currentUser);
        const { data } = await api.updateUserSettings(settingsData, token);

        dispatch({ type: 'UPDATE_USER_SETTINGS', payload: data });
        message.success('User settings updated successfully');
        return { success: true }; // Return success status
    } catch (error) {
        console.error(error.message);
        message.error('Failed to update user settings. Please try again.');
        return { success: false }; // Return failure status
    }
};
