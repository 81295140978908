import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
    createNoteCollection,
    updateNoteCollection,
} from "../../../actions/noteCollections";
import MyEditor from "../../MyEditor/MyEditor";
import { Button, Input, Form, Space, TreeSelect, Select } from "antd";
import "./NoteForm.css";
import moment from "moment";
import TestTagsFromAntD from "../../Playgrounds/TestTagsFromAntD";
import useOptions from "../../../utils/getOptions";

const NoteForm = ({ currentId, setCurrentId, showNoEditor, setShowNoEditor, createdFromParent, darkMode, tabID, treeTabs, setShowForm, formKey
}) => {
    const d = moment(new Date()).format("ddd, DD MMM YY HH:mm");
    const [tags, setTags] = useState([]);
    const allTags = useOptions('noteCollections', 'tags');

    const [submitClicked, setSubmitClicked] = useState(false);

    const [noteCollectionData, setNoteCollectionData] = useState({
        message: "",
        messageNote: "",
        tags: [],
        parents: "" || createdFromParent,
        tabID: "" || tabID,
        createdAt: d,
    });
    const noteCollection = useSelector((state) =>
        currentId ? state.noteCollections.find((r) => r._id === currentId) : null
    );
    const dispatch = useDispatch();
    const [form] = Form.useForm();
    const [showEditor, setShowEditor] = useState(false);

    useEffect(() => {
        if (noteCollection) setNoteCollectionData(noteCollection);
        console.log("showNoEditor is now in child component: " + showNoEditor);
    }, [noteCollection]);

    const handleSubmit = (e) => {
        if (currentId) {
            dispatch(updateNoteCollection(currentId, noteCollectionData));
            clear();
        } else {
            dispatch(createNoteCollection(noteCollectionData));
            clear();
        }
    };
    // var myContent = tinymce.get("myTextarea").getContent();
    const editorRef = useRef(null);
    const clear = () => {
        setCurrentId(null);
        // window.tinymce.get("myTextarea").setContent('');
        setNoteCollectionData({
            message: "",
            messageNote: "",
            tags: [],
            parents: "" || createdFromParent,
            tabID: "" || tabID,
        });
        // console.log('showNoEditor is now in child component: ' + showNoEditor)
        if (showNoEditor) {
            setShowNoEditor(false);
        }
        setShowForm(false)
        setSubmitClicked((v) => !v)
    };


    const getData = () => {

        window.tinymce.get("myTextarea").setContent('tralala');
    };
    const [content, setContent] = useState("");

    const handleEditorChange = (newContent) => {
        setContent(newContent);
        setNoteCollectionData({ ...noteCollectionData, messageNote: newContent });
    };

    const [focus, setFocus] = useState(false);
    const handleChange = (value) => {
        console.log(`selected ${value}`);
    };
    // const key = 'mnau'
    const formName = `myForm${currentId}${tabID}${formKey}`
    return (
        <>
            {/* <pre>{JSON.stringify(tagOptions)}</pre> */}
            {/* <pre>{JSON.stringify(test)}</pre> */}
            {/* <pre>vsetky tagy {JSON.stringify(allTags)}</pre> */}
            <Form
                form={form}
                name={formName}
                onFinish={handleSubmit}
                style={{
                    display: "flex",
                    flexWrap: "wrap",
                    // justifyContent: 'center',
                }}
            >
                <Space direction="vertical" style={{ width: "100%" }}>
                    <p>formName je: {formName}</p>
                    <Input
                        size="large"
                        name="message"
                        variant="outlined"
                        label="Note Title"
                        value={noteCollectionData.message}
                        onChange={(e) =>
                            setNoteCollectionData({
                                ...noteCollectionData,
                                message: e.target.value,
                            })
                        }
                    />
                    <div
                        className={"classes.cardActions"}
                        style={{
                            padding: "0px",
                            width: "100%",
                        }}
                    >
                        <MyEditor
                            darkMode={darkMode}
                            focus={focus}
                            setFocus={setFocus}
                            name="messageNote"
                            onInit={(evt, editor) => (editorRef.current = editor)}
                            textareaId="showEditor"
                            value={noteCollectionData.messageNote}
                            initialValue={noteCollection?.messageNote}
                            handleEditorChange={handleEditorChange}
                            showEditor={showEditor}
                            submitClicked={submitClicked}
                        />
                    </div>
                    {/* <Input
            size="large"
            name="tags"
            variant="outlined"
            label="Tags"
            value={noteCollectionData.tags}
            onChange={(e) =>
              setNoteCollectionData({
                ...noteCollectionData,
                tags: e.target.value.split(","),
              })
            }
          /> */}

                    <Select
                        name="tags"
                        mode="tags"
                        style={{
                            width: "100%",
                        }}
                        placeholder="Tags Mode"
                        value={
                            noteCollectionData.tags[0] == "" ? [] : noteCollectionData.tags
                        }
                        // value={noteCollectionData.tags}
                        onChange={(e) =>
                            setNoteCollectionData({
                                ...noteCollectionData,
                                tags: e,
                            })
                        }
                        tokenSeparators={[","]}
                        options={allTags}
                    />
                    {/* <TestTagsFromAntD tags={tags} setTags={setTags} /> */}
                    <Input
                        size="large"
                        name="parents"
                        variant="outlined"
                        label="Parents"
                        value={noteCollectionData.parents}
                        onChange={(e) =>
                            setNoteCollectionData({
                                ...noteCollectionData,
                                parents: e.target.value.split(","),
                            })
                        }
                    />
                    {/* <Input size="large" name="tabID" variant="outlined" label="tabID" value={noteCollectionData.tabID} placeholder="tabID"
                    onChange={(e) => setNoteCollectionData({ ...noteCollectionData, tabID: e.target.value })} /> */}
                    {/* <pre>{tabID} mnau {noteCollectionData.tabID}</pre> */}
                    <TreeSelect
                        size="large"
                        name="tabID"
                        variant="outlined"
                        label="tabID"
                        placeholder="tabID"
                        // showSearch
                        style={{
                            width: "100%",
                        }}
                        defaultValue={noteCollectionData.tabID}
                        treeNodeFilterProp="value"
                        treeData={treeTabs}
                        onChange={(e) =>
                            setNoteCollectionData({ ...noteCollectionData, tabID: e })
                        }
                    />
                    <Form.Item>
                        <Button
                            className={"classes.buttonSubmit"}
                            form={formName}
                            variant="text"
                            style={{
                                marginBottom: 10,
                            }}
                            // danger
                            size="large"
                            onClick={() => {
                                if (typeof setShowForm === 'function') {
                                    setShowForm(false);
                                }
                                if (typeof setShowNoEditor === 'function') {
                                    setShowNoEditor(false);
                                }
                            }}
                        >
                            Cancel
                        </Button>
                        <Button
                            className={"classes.buttonSubmit"}
                            form={formName}
                            htmlType="submit"
                            variant="contained"
                            style={{
                                marginBottom: 10,
                            }}
                            size="large"
                            type="primary"
                        >
                            Submit
                        </Button>
                    </Form.Item>
                </Space>
            </Form>
        </>
    );
};

export default NoteForm;
