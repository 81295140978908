// reducers/expenseverseDailyLimitReducer.js
const initialState = {
    currentDateLimit: 0, // New field for tracking the currently selected date daily limit
    dailyLimit: {},
    dailyLimits: [],
    total: 0,
};

const expenseverseDailyLimitReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'FETCH_DAILY_LIMIT':
            return { ...state, dailyLimit: action.payload };
        case 'FETCH_DAILY_LIMIT_BY_DATE':
            return { ...state, dailyLimit: action.payload, currentDateLimit: action.payload.amount }; // Set the current date limit
        case 'FETCH_DAILY_LIMITS':
            return { ...state, dailyLimits: action.payload.dailyLimits, total: action.payload.total };
        case 'FETCH_ALL_DAILY_LIMITS':
            return { ...state, dailyLimits: action.payload.dailyLimits, total: action.payload.total };
        case 'FETCH_DAILY_LIMITS_BY_RANGE': // Handling for range-based fetching
            return { ...state, dailyLimits: action.payload.dailyLimits, total: action.payload.total };
        case 'CREATE_OR_UPDATE_DAILY_LIMIT':
            return { ...state, dailyLimit: action.payload };
        case 'DELETE_DAILY_LIMIT':
            return { ...state, dailyLimits: state.dailyLimits.filter(limit => limit.date !== action.payload) };
        default:
            return state;
    }
};

export default expenseverseDailyLimitReducer;