import * as api from '../api/index';
import { getAuth, getIdToken } from "firebase/auth";

// action creators
export const getPageTabsCollections = () => async (dispatch) => {
    try {
        const auth = getAuth();
        const token = await getIdToken(auth.currentUser);
        const { data } = await api.fetchPageTabsCollections(token);

        dispatch({ type: 'FETCH_ALL_PAGETABS', payload: data });
    } catch (error) {
        console.log(error.message)
    }
}

export const createPageTabsCollection = (pageTabsCollection) => async (dispatch) => {
    try {
        const auth = getAuth();
        const token = await getIdToken(auth.currentUser);
        const { data } = await api.createPageTabsCollection(pageTabsCollection, token);
        dispatch({ type: 'CREATE_PAGETABS', payload: data });
        return { status: 'success' };
    } catch (error) {
        console.log(error);
        return { status: 'error', message: error.message };
    }
}

export const updatePageTabsCollection = (id, pageTabsCollection) => async (dispatch) => {
    try {
        const auth = getAuth();
        const token = await getIdToken(auth.currentUser);
        const { data } = await api.updatePageTabsCollection(id, pageTabsCollection, token);
        dispatch({ type: 'UPDATE_PAGETABS', payload: data });
        return { status: 'success' };
    } catch (error) {
        console.log(error.message);
        return { status: 'error', message: error.message };
    }
}

export const deletePageTabsCollection = (id) => async (dispatch) => {
    try {
        const auth = getAuth();
        const token = await getIdToken(auth.currentUser);
        await api.deletePageTabsCollection(id, token);

        dispatch({ type: 'DELETE_PAGETABS', payload: id })
    } catch (error) {
        console.log(error);
    }
}