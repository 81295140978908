const initialState = {
    userSettings: {
        enabledApps: [],
        preferredTheme: 'Ant Default',
        customTheme: {},
        layout: {
            compactMode: false,
            layoutMode: 'mix',
            pageHeaderMode: true,
            prefferedColorScheme: 'system',
        },
    },
};

export default (state = initialState, action) => {
    switch (action.type) {
        case 'FETCH_USER_SETTINGS':
            return {
                ...state,
                userSettings: {
                    ...state.userSettings,
                    ...action.payload,  // Merge with existing settings
                },
            };
        case 'UPDATE_USER_SETTINGS':
            return {
                ...state,
                userSettings: {
                    ...state.userSettings,
                    ...action.payload,  // Update only the fields provided
                },
            };
        default:
            return state;
    }
};
