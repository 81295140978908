import React, { useState } from "react";
import { Button, ConfigProvider, Modal, Tooltip } from "antd";
import NoteForm from "./NoteForm";

const NoteFormInTab = ({ currentId, setCurrentId, createdFromParent, darkMode, tabID, treeTabs, formKey, addFirstNote
}) => {
    const [showForm, setShowForm] = useState(false);

    const handleShowForm = (v) => {
        setShowForm((v) => !v);
        console.log("v is now: " + showForm);
    };

    return (
        <>
            {addFirstNote ? <ConfigProvider
                theme={{
                    components: {
                        Button: {
                            /* here is your component tokens */
                            defaultBg: 'none'
                        },
                    },
                }}
            >
                <Button type='dashed' style={{ width: '100%', height: '100%', minHeight: '100px' }} onClick={handleShowForm} >Add First Note</Button>
            </ConfigProvider> : <Tooltip title="New Note"><Button type="primary" onClick={handleShowForm} tooltip='test'>
                {<span class="material-icons" style={{ fontSize: 16, verticalAlign: 'middle', opacity: .7 }}>add</span>}
                {<span class="material-icons" style={{ fontSize: 16, verticalAlign: 'middle', opacity: .7 }}>edit</span>}
            </Button></Tooltip>}


            <Modal open={showForm} footer={null}
                onCancel={() => setShowForm(false)}
            >
                <NoteForm
                    currentId={currentId}
                    setCurrentId={setCurrentId}
                    createdFromParent={createdFromParent}
                    darkMode={darkMode}
                    tabID={tabID}
                    treeTabs={treeTabs}
                    setShowForm={setShowForm}
                    formKey={formKey}
                />
            </Modal>
        </>
    );
};

export default NoteFormInTab;
