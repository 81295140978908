import React, { useEffect, useRef, useState } from "react";
import { Editor } from "@tinymce/tinymce-react"; //1. Import TinyMCE Editor
import parse from 'html-react-parser';
import Prism from "prismjs";
// import Prism from 'prismjs';
import 'prismjs/plugins/line-numbers/prism-line-numbers';
import 'prismjs/plugins/line-numbers/prism-line-numbers.css';
import 'prismjs/plugins/line-highlight/prism-line-highlight';
import 'prismjs/plugins/line-highlight/prism-line-highlight.css';
import 'prismjs/plugins/toolbar/prism-toolbar.js';
import 'prismjs/plugins/toolbar/prism-toolbar.css';

import 'prismjs/plugins/copy-to-clipboard/prism-copy-to-clipboard';
// import 'prismjs/plugins/copy-to-clipboard/prism-copy-to-clipboard.css';
// import '.prism/copy-to-clipboard/prism-copy-to-clipboard';
// import '.prism/copy-to-clipboard/prism-copy-to-clipboard.css';
// import "./prism/prism.css";
import "./prism/synthwave84.css";
// import "./prism/prism-theme-k88hudson.css";
import './TinyMCE_default_styles_noneditor_zaloha_len_custom _styly.css'



function MyEditor(props) {
  const [editorExists, setEditorExists] = useState(null);
  const editorRef = useRef(null);

  // const [noEditor, setNoEditor] = useState(false);

  const handleInit = () => {
    Prism.highlightAll();
  };
  const handleEditorInit = () => {
    handleInit();
    props.onInit();
  };
  const handleButtonClick = () => {
    // Use execCommand API to insert a horizontal rule
    // tinymce.activeEditor.execCommand('InsertHorizontalRule');
    window.tinymce.activeEditor.execCommand('InsertHorizontalRule');
  }

  useEffect(() => {
    Prism.highlightAll();
  }, [props.noEditor])

  const styles = ``;

  useEffect(() => {
    if (editorRef.current) {
      editorRef.current.setContent('');
    }
  }, [props.submitClicked]);

  return (
    <div style={{ float: 'left', width: 'inherit' }}>
      {props.noEditor === true ? <div style={{ styles }}>{parse(props.initialValue)}</div> : <Editor
        onFocus={() => {
          props.setFocus(false);
          console.log('focus changed to: ' + props.focus);
        }}
        onBlur={() => {
          props.setFocus(true);
          console.log('focus changed to: ' + props.focus);
        }}
        // codesample_global_prismjs={true}
        // codesample_content_css={}
        tinymceScriptSrc={process.env.PUBLIC_URL + '/tinymce/tinymce.min.js'}
        initialValue={props.initialValue}
        onInit={(evt, editor) => (editorRef.current = editor)}
        init={{
          // codesample_global_prismjs: true,
          // codesample_content_css: './prism/synthwave84.css',
          // content_css: './prism/synthwave84.css',
          body_id: props.body_id,
          // skin_url: '/tinymce/skins/ui/OradzovaGenerator/',
          // skin: 'oxide-dark',
          skin: (props.darkMode ? "oxide-dark" : "oxide"),
          content_css: (props.darkMode ? "dark, ./prism/synthwave84.css" : "default, ./prism/synthwave84.css"),
          // skin: 'tinymce-5-dark',
          plugins: 'customAlert autolink toc alignment autoresize template accordion mychecklist lists advlist media code codesample fullscreen help insertdatetime link searchreplace preview table wordcount visualchars image',
          // toolbar: props.toolbarShow === false || '' ? false : 'myplugin  styles alignment align undo redo numlist bullist media poznamka accordion mychecklist codesample code fullscreen help insertdatetime link hr copy paste searchreplace preview table wordcount visualchars image template',
          // toolbar: 'myplugin  styles alignment align undo redo numlist bullist media poznamka accordion mychecklist codesample code fullscreen help insertdatetime link hr copy paste searchreplace preview table wordcount visualchars image template',
          // toolbar: ' indent outdent | myplugin | styles alignment align | undo redo | numlist bullist | media poznamka accordion mychecklist | codesample | code fullscreen help | insertdatetime link | hr copy paste | searchreplace preview table | wordcount visualchars | image template',
          // toolbar: '  myplugin |  alignment | | insertdatetime hr copy paste | searchreplace  | wordcount visualchars | template',
          toolbar: 'tipBlock cautionBlock flashcardBlock| toc styles formatting| alignment undo redo  align | bold underline italic strikethrough | codesample media poznamka table image link| forecolor backcolor | indent outdent | numlist bullist mychecklist accordion | preview code fullscreen help searchreplace',
          templates: [
            { title: 'Some title 1', description: 'Some desc 1', content: 'My content' },
            { title: 'Some title 2', description: 'Some desc 2', url: 'development.html' }
          ],
          menubar: false,
          // inline: true,
          branding: false,
          toolbar_sticky: true,
          toolbar_mode: 'floating', // or 'floating', or 'wrap'
          // height: 500,
          mobile: {
            // menubar: true,
            toolbar: 'tipBlock cautionBlock flashcardBlock| toc styles formatting| alignment undo redo  align | bold underline italic strikethrough | codesample media poznamka table image link| forecolor backcolor | indent outdent | numlist bullist mychecklist accordion | preview code fullscreen help searchreplace',
            resize: true,
            toolbar_sticky: true,
            toolbar_mode: 'floating',
          },
          resize: true,
          _setup: function (editor) {
            /* example, adding a group toolbar button */
            editor.ui.registry.addGroupToolbarButton('alignment', {
              icon: 'align-left',
              tooltip: 'Alignment',
              items: 'alignleft aligncenter alignright | alignjustify'
            });
            editor.ui.registry.addGroupToolbarButton('formatting', {
              icon: 'change-case',
              tooltip: 'formatting',
              items: 'bold underline italic | strikethrough'
            });
            /* example, adding a block div with class "pooznamka" toolbar button */
            editor.ui.registry.addButton('poznamka', {
              text: 'Note',
              // icon: 'bold',
              tooltip: 'poznamka',
              onAction: function (_) {
                editor.insertContent('<div class="poznamka">New code block</div>');
              }
            });
            // runs function after editor is initialized
            editor.on('init', function (e) {
              console.log('The Editor has initialized: ' + props.body_id + ' editor state is: ' + editorExists);
            });
          },
          get setup() {
            return this._setup;
          },
          set setup(value) {
            this._setup = value;
          },
          // block_formats: "Paragraph=p; Header 1=h1; Header 2=h2; Header 3=h3",
          content_style: `
          body {
            font-size: 14px;
          }

          .code-toolbar1{
            box-shadow: 0px 0.8px 2px rgba(0,0,0,.032),0px 2.7px 6.7px rgba(0,0,0,.048),0px 12px 30px rgba(0,0,0,.08);
        }
        .code-toolbar{
            box-shadow:0px 0.8px 2px var(--tw-shadow-color),0px 2.7px 6.7px var(--tw-shadow-color),0px 12px 30px var(--tw-shadow-color);
        }
        
        
        /* custom styles */
        code {
            background-color: rgba(123, 123, 123, .2);
            border-radius: 5px;
            padding: 0.1rem 0.2rem;
            white-space: pre-wrap;
        }
        
        pre {
            border-radius: 15px;
        }
        
        .poznamka {
            background-color: rgb(178, 178, 178);
        }
        
        blockquote {
            font-size: 1.4em;
            margin: 50px auto;
            font-family: Open Sans;
            font-style: italic;
            color: #555555;
            padding: .5em 10px .5em 75px;
            border-left: 8px solid #78C0A8;
            line-height: 1.6;
            position: relative;
            background: rgba(207, 207, 207, 0.4);
        }
        
        blockquote::before {
            font-family: Arial;
            color: #78C0A8;
            font-size: 4em;
            position: absolute;
            left: 10px;
            top: -10px;
        }
        
        .truncated-heading {
            display: -webkit-box;
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;
            overflow: hidden;
        }
        
        .tinymce-toc {
            background-color: rgba(123, 123, 123, .1);
            border-radius: 15px;
            padding: 10px 0px;
            margin: 10px 10px;
        }
        
        
        .tinymce-toc li:before {
            margin-right: 10px;
            border-radius: 100%;
            color: white;
            width: 1.2em;
            text-align: center;
        }
        
        .tinymce-toc a {
            color: inherit;
        }
        
        .tinymce-toc-title {
            margin: 10px 20px;
        }

      .tip-block {
      border-left: 5px solid #1890ff; /* Ant Design blue for info alerts */
      background-color: #e6f7ff; /* Light blue background */
      border-radius: 10px; /* Updated to 10px */
      padding: 10px;
    }

    .caution-block {
      border-left: 5px solid #faad14; /* Ant Design orange for caution alerts */
      background-color: #fff7e6; /* Light orange background */
      border-radius: 10px; /* Updated to 10px */
      padding: 10px;
    }

    .flashcard-block {
      border-left: 5px solid #52c41a; /* Ant Design green for flashcards */
      background-color: #f6ffed; /* Light green background */
      border-radius: 10px; /* Updated to 10px */
      padding: 10px;
    }

        
        /* Example media query for small screens (mobile) */
@media (max-width: 576px) {
    pre {
        border-radius: 0px;
    }

    .code-toolbar {
        /* box-shadow: 0px 0.8px 2px var(--tw-shadow-color), 0px 2.7px 6.7px var(--tw-shadow-color), 0px 12px 30px var(--tw-shadow-color);
        background-color: red; */
        margin-left: -24px;
        margin-right: -24px;
    }
}
     `,
        }}
        onEditorChange={props.handleEditorChange}
      />}
    </div>)


  //     props.noEditor ?
  //     <div style={{ float: 'left', maxWidth: '100vw' }}>
  //       {/* <button onClick={handleButtonClick}>Insert Horizontal Line</button> */}
  //       {/* <button onClick={handleInit}>run local Prism highlight</button> */}
  //       <div >{parse(props.initialValue)}</div>
  // :
  //       <Editor
  //         onFocus={() => {
  //           props.setFocus(false);
  //           console.log('focus changed to: ' + props.focus);
  //         }}
  //         onBlur={() => {
  //           props.setFocus(true);
  //           console.log('focus changed to: ' + props.focus);
  //         }}
  //         // codesample_global_prismjs={true}
  //         // codesample_content_css={}
  //         tinymceScriptSrc={process.env.PUBLIC_URL + '/tinymce/tinymce.min.js'}
  //         initialValue={props.initialValue}
  //         onInit={handleEditorInit}
  //         init={{
  //           // codesample_global_prismjs: true,
  //           // codesample_content_css: './prism/synthwave84.css',
  //           content_css: './prism/synthwave84.css',
  //           body_id: props.body_id,
  //           skin_url: '/tinymce/skins/ui/OradzovaGenerator/',
  //           plugins: ' myplugin autoresize template accordion mychecklist lists advlist media code codesample fullscreen help insertdatetime link searchreplace preview table wordcount visualchars image',
  //           // toolbar: props.toolbarShow === false || '' ? false : 'myplugin  styles alignment align undo redo numlist bullist media poznamka accordion mychecklist codesample code fullscreen help insertdatetime link hr copy paste searchreplace preview table wordcount visualchars image template',
  //           toolbar: 'myplugin  styles alignment align undo redo numlist bullist media poznamka accordion mychecklist codesample code fullscreen help insertdatetime link hr copy paste searchreplace preview table wordcount visualchars image template',
  //           templates: [
  //             { title: 'Some title 1', description: 'Some desc 1', content: 'My content' },
  //             { title: 'Some title 2', description: 'Some desc 2', url: 'development.html' }
  //           ],
  //           menubar: false,
  //           // inline: true,
  //           branding: false,
  //           // toolbar_mode: 'wrap',
  //           _setup: function (editor) {
  //             /* example, adding a group toolbar button */
  //             editor.ui.registry.addGroupToolbarButton('alignment', {
  //               icon: 'align-left',
  //               tooltip: 'Alignment',
  //               items: 'alignleft aligncenter alignright | alignjustify'
  //             });
  //             /* example, adding a block div with class "pooznamka" toolbar button */
  //             editor.ui.registry.addButton('poznamka', {
  //               text: 'Add new code block',
  //               icon: 'bold',
  //               tooltip: 'poznamka',
  //               onAction: function (_) {
  //                 editor.insertContent('<div class="poznamka">New code block</div>');
  //               }
  //             });
  //             // runs function after editor is initialized
  //             editor.on('init', function (e) {
  //               console.log('The Editor has initialized: ' + props.body_id + ' editor state is: ' + editorExists);
  //             });
  //           },
  //           get setup() {
  //             return this._setup;
  //           },
  //           set setup(value) {
  //             this._setup = value;
  //           },
  //           // block_formats: "Paragraph=p; Header 1=h1; Header 2=h2; Header 3=h3",
  //           content_style: `
  //         body {
  //           font-family: Arial, sans-serif;
  //           margin: 12px;
  //         }
  //         h1, h2, h3, p {
  //           color: #4D66CB;
  //           margin: 10px;
  //         }
  //         pre {
  //           border-radius: 10px;
  //         }
  //         .poznamka {
  //           background-color: red;
  //           width: 100%;
  //         }
  //         `,
  //         }}
  //         onEditorChange={props.handleEditorChange}
  //       />
  //     </div>

}

export default MyEditor;