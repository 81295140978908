const expenseverseAccountReducer = (accounts = [], action) => {
    switch (action.type) {
        case 'FETCH_ALL_EXPENSEVERSE_ACCOUNTS':
            return action.payload;
        case 'CREATE_EXPENSEVERSE_ACCOUNT':
            return [...accounts, action.payload];
        case 'UPDATE_EXPENSEVERSE_ACCOUNT':
            return accounts.map((account) => (account._id === action.payload._id ? action.payload : account));
        case 'DELETE_EXPENSEVERSE_ACCOUNT':
            return accounts.filter((account) => account._id !== action.payload);
        default:
            return accounts;
    }
};

export default expenseverseAccountReducer;