function roundToTwo(num) {
    return +(Math.round(num + "e+2") + "e-2");
}
export const processCategories = (expenses, categories) => {
    if (!expenses || !categories) return { groupedCategories: [], remainingCategories: [] };

    const categoryTotals = expenses.reduce((acc, expense) => {
        if (expense.category.length === 0) {
            // Handle uncategorized expenses
            if (!acc['uncategorized']) {
                acc['uncategorized'] = { total: 0, income: 0, outcome: 0 };
            }
            acc['uncategorized'].total += expense.amount;
            if (expense.amount > 0) {
                acc['uncategorized'].income += expense.amount;
            } else {
                acc['uncategorized'].outcome += expense.amount;
            }
        } else {
            // Handle categorized expenses
            expense.category.forEach(catId => {
                if (!acc[catId]) {
                    acc[catId] = { total: 0, income: 0, outcome: 0 };
                }
                acc[catId].total += expense.amount;
                if (expense.amount > 0) {
                    acc[catId].income += expense.amount;
                } else {
                    acc[catId].outcome += expense.amount;
                }
            });
        }
        return acc;
    }, {});

    const categoryMap = categories.reduce((acc, cat) => {
        acc[cat._id] = cat;
        return acc;
    }, {});

    const groupMap = {};
    const remainingCategories = [];

    // Process categorized expenses
    for (const catId in categoryTotals) {
        if (catId === 'uncategorized') continue; // Skip uncategorized for now, handle it later

        const category = categoryMap[catId];
        if (category) {
            const { total, income, outcome } = categoryTotals[catId];

            if (category.isGroup) {
                groupMap[catId] = {
                    ...category,
                    total: roundToTwo(total),
                    income: roundToTwo(income),
                    outcome: roundToTwo(outcome),
                    children: [],
                };
            } else {
                const parentGroup = Object.values(categoryMap).find(
                    group => group.isGroup && group.children.includes(catId)
                );
                if (parentGroup) {
                    if (!groupMap[parentGroup._id]) {
                        groupMap[parentGroup._id] = {
                            ...parentGroup,
                            total: 0,
                            income: 0,
                            outcome: 0,
                            children: [],
                        };
                    }
                    groupMap[parentGroup._id].children.push({
                        ...category,
                        total: roundToTwo(total),
                        income: roundToTwo(income),
                        outcome: roundToTwo(outcome),
                    });
                    groupMap[parentGroup._id].total += total;
                    groupMap[parentGroup._id].income += income;
                    groupMap[parentGroup._id].outcome += outcome;
                } else {
                    remainingCategories.push({
                        ...category,
                        total: roundToTwo(total),
                        income: roundToTwo(income),
                        outcome: roundToTwo(outcome),
                    });
                }
            }
        }
    }

    // Handle uncategorized transactions
    if (categoryTotals['uncategorized']) {
        const { total, income, outcome } = categoryTotals['uncategorized'];
        remainingCategories.push({
            _id: 'uncategorized',
            name: 'Uncategorized',
            total: roundToTwo(total),
            income: roundToTwo(income),
            outcome: roundToTwo(outcome),
        });
    }

    return {
        groupedCategories: Object.values(groupMap),
        remainingCategories,
    };
};
