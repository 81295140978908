import React, { useEffect, useRef } from 'react';
import { Chart } from '@antv/g2';
import dayjs from 'dayjs';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import 'dayjs/locale/en'; // Import English locale
import { Card } from 'antd';
import './styles.css'
// import { tooltip } from '@antv/g2plot/lib/adaptor/common';

// dayjs.extend(localizedFormat);
// dayjs.locale('en'); // Set the locale to English globally
interface Transaction {
    amount: number;
    transactionDate: Date;
}

interface DailyLimit {
    date: string;
    amount: number;
}

interface Props {
    transactions: Transaction[];
    dateRange?: [Date, Date];
    dailyLimits: DailyLimit[];
    showLegend?: boolean;
    showDayLabels?: boolean;
    showTextMarkers?: boolean;
    showDailyLimits?: boolean; // New prop to control the visibility of the daily limits line
    darkMode?: boolean;
    showDateLimitAsLine?: boolean;
    height?: number
}

const G2_divergingBarChart = React.memo(({
    transactions,
    dateRange = [
        new Date(dayjs().startOf('day').toISOString()),
        new Date(dayjs().endOf('day').toISOString())
    ],
    dailyLimits = [],
    showLegend = true,
    showDayLabels = true,
    showTextMarkers = true,
    showDailyLimits = true, // Default to true
    darkMode,
    showDateLimitAsLine = false,
    height
}: Props) => {
    const chartRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        // Set dayjs locale to English on component mount
        dayjs.locale('en');
        const processData = (transactions: Transaction[], dateRange?: [Date, Date], dailyLimits: DailyLimit[] = []) => {
            // Ensure dateRange is an array and has two valid dates
            const [startDate, endDate] = dateRange && dateRange.length === 2 ? dateRange : [new Date(), new Date()];

            const dates: string[] = [];
            let currentDate = dayjs(startDate);

            // Sort the dailyLimits array by date to apply limits correctly
            const sortedDailyLimits = dailyLimits.sort((a, b) => dayjs(a.date).diff(dayjs(b.date)));

            // Initialize the last known daily limit, starting with 0
            let lastKnownLimit = 0;

            const data = [];

            while (currentDate.isBefore(dayjs(endDate))) {
                const formattedDate = currentDate.format('YYYY-MM-DD');

                // Check if there's a new limit defined for this date
                const limit = sortedDailyLimits.find(limit => limit.date === formattedDate);
                if (limit) {
                    lastKnownLimit = limit.amount; // Update the last known limit
                }

                // Push the date and the last known limit
                data.push({
                    date: formattedDate,
                    incoming: 0,
                    outgoing: 0,
                    dailyLimit: lastKnownLimit,
                });

                currentDate = currentDate.add(1, 'day');
            }

            transactions.forEach((transaction) => {
                const transactionDate = dayjs(transaction.transactionDate).format('YYYY-MM-DD');
                const amount = transaction.amount;

                const dayData = data.find((d) => d.date === transactionDate);
                if (dayData) {
                    if (amount > 0) {
                        dayData.incoming += amount;
                    } else {
                        dayData.outgoing += amount;
                    }
                }
            });

            const chartData = data.map((d) => [
                { user: dayjs(d.date).format('dd\nD.M.'), key: 'Incoming', value: d.incoming },
                { user: dayjs(d.date).format('dd\nD.M.'), key: 'Outgoing', value: d.outgoing },
            ]).flat();

            return { chartData, data };
        };

        const { chartData, data } = processData(transactions, dateRange, dailyLimits);

        const chart = new Chart({
            container: chartRef.current ?? undefined,
            autoFit: true,
            theme: darkMode && 'classicDark',
        });
        console.log('chartData', chartData);
        console.log('chartData', data);
        console.log('LineY Data:', data.map(d => ({
            user: dayjs(d.date).format('dd\nD.M.'),
            dayLimit: d.dailyLimit,
            key: 'Day Limit',
        })));

        const testConfig = !showDateLimitAsLine ? {
            scale: {
                x: { padding: 0.2 },
                color: {
                    domain: ['Incoming', 'Outgoing', 'Daily Limit'],
                    range: ['#61cdbb', '#f47560', 'red'],
                },
            }
        } : {
            scale: {
                x: { padding: 0.2 },
                color: {
                    domain: ['Incoming', 'Outgoing', 'Daily Limit'],
                    range: ['#61cdbb', '#f47560', 'blue'],
                },
            }
        };

        chart.options({
            type: 'view',
            paddingLeft: 25,
            children: [
                {
                    type: 'interval',
                    data: {
                        type: 'inline',
                        value: chartData
                    },
                    encode: { x: 'user', y: 'value', color: 'key' },
                    transform: [{ type: 'stackY' }],
                    scale: {
                        x: { padding: 0.2 },
                        color: {
                            domain: ['Incoming', 'Outgoing'],
                            range: ['#61cdbb', '#f47560'],
                        },
                    },
                    style: { radius: 10 },
                    axis: {
                        x: showDayLabels ? { title: false } : false,  // Control the day labels
                        y: { position: 'left', title: false, labelFormatter: (v: unknown) => `${v}` },
                    },
                    legend: showLegend ? { color: { title: false } } : false,
                    labels: [
                        {
                            text: 'value',
                            position: 'inside',
                            formatter: (v: unknown) => (v ? `${v}` : ''),
                            transform: [{ type: 'overlapDodgeY' }],
                            // fill: 'rgb(123,123,123)',
                            fontSize: 8,
                        },
                    ],
                },
                {
                    type: 'lineY',
                    data: [0],
                    style: { lineWidth: 2, stroke: 'grey' }, // Use smooth: true for a bezier curve
                },
                // Conditionally render the daily limits line based on the showDailyLimits prop
                showDailyLimits && {
                    type: 'line',
                    data: {
                        type: 'inline',
                        value: data.map(d => ({
                            user: dayjs(d.date).format('dd\nD.M.'),
                            dailyLimit: d.dailyLimit,
                            key: 'Daily Limit',
                        }))
                    },
                    encode: { x: 'user', y: 'dailyLimit', color: 'key' },
                    ...testConfig,
                    style: { lineWidth: 2, stroke: 'red', shape: 'smooth' }, // Use smooth: true for a bezier curve
                },
                // conditionally show dailyLimit as line if we are showing only one day, this setting is received via component prop
                showDateLimitAsLine && {
                    type: 'lineY',
                    data: {
                        type: 'inline',
                        value: data.map(d => ({
                            user: dayjs(d.date).format('dd\nD.M.'),
                            dailyLimit: d.dailyLimit,
                            key: 'Daily Limit',
                        }))
                    },
                    encode: { x: 'user', y: 'dailyLimit', color: 'key' },
                    style: { lineWidth: 2, stroke: 'blue' }, // Use smooth: true for a bezier curve
                },
            ].filter(Boolean), // Filter out the false value if showDailyLimits is false
        });

        const timeoutId = setTimeout(() => {
            chart.render();
        }, 100); // Delay the rendering by 100ms

        // Cleanup function to destroy the chart and clear the timeout
        return () => {
            clearTimeout(timeoutId);
            chart.destroy();
        };
    }, [transactions,
        // dateRange,
        dailyLimits,
        // showLegend,
        // showDayLabels,
        // showTextMarkers,
        // showDailyLimits,
        darkMode]);

    return transactions.length > 0 && <div ref={chartRef} style={{ width: '100%', height: `${height}` }} />;
});

export default G2_divergingBarChart;
