import React from 'react';
import { useSelector } from 'react-redux';
import { FloatButton, Drawer, Select, InputNumber, Typography } from 'antd';
import { ArrowUpOutlined, ArrowDownOutlined, LikeOutlined, DislikeOutlined } from '@ant-design/icons';
const { Text } = Typography

const DefaultSettingDrawer = ({ defaultBudget, setDefaultBudget,
    defaultAccount, setDefaultAccount,
    defaultCurrency, setDefaultCurrency,
    openDrawer, setOpenDrawer,
    defaultPaymentMethod, setDefaultPaymentMethod }) => {

    function closeDrawer() {
        setOpenDrawer(false)
    }

    return (<>
        <FloatButton // icons set if outlined or two-toned or default is defined via class, fonts are imported via Google Font link in index.html
            // tooltip=' Themes'
            style={{ bottom: 24 + 60 + 60 }}
            icon={<span class="material-icons " style={{ fontSize: 18 }}>savings</span>}
            onClick={() => setOpenDrawer(true)}
        />
        <Drawer
            title="Expense settings"
            open={openDrawer} maskClosable
            onOk={closeDrawer}
            onClose={closeDrawer}
            closeIcon={false}
            placement='bottom'
        >

            <Text key={'defaultAccount'} style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '8px 0' }}>
                <span>{'Default Account'}</span>
                <Select size='default'
                    defaultValue={defaultAccount}
                    style={{
                        width: '150px',
                    }}
                    // disabled
                    onChange={(value) => (setDefaultAccount(value),
                        localStorage.setItem('defaultAccount', value))}
                    options={[
                        {
                            value: 'CSOBPremium',
                            label: 'Premium ČSOB',
                        }, {
                            value: 'CSOBSporici',
                            label: 'Sporici ČSOB',
                        },
                        {
                            value: 'sodexo',
                            label: 'Sodexo',
                        },
                        {
                            value: 'cash',
                            label: 'cash',
                        },
                    ]}
                />
            </Text>

            <Text key={'defaultCurrency'} style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '8px 0' }}>
                <span>{'Default Currency'}</span>
                <Select size='default'
                    defaultValue={defaultCurrency}
                    style={{
                        minWidth: '70px',
                    }}
                    // disabled
                    onChange={(value) => (setDefaultCurrency(value),
                        localStorage.setItem('defaultCurrency', value))}
                    options={[
                        {
                            value: 'EUR',
                            label: 'EUR',
                        },
                        {
                            value: 'CZK',
                            label: 'CZK',
                        },
                        {
                            value: 'GBP',
                            label: 'GBP',
                        },
                        {
                            value: 'Other',
                            label: 'Other',
                        },
                    ]}
                />
            </Text>

            <Text key={'defaultBudget'} style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '8px 0' }}>
                <span>{'Default Budget'}</span>
                <InputNumber name='defaultBudget' defaultValue={defaultBudget}
                    style={{
                        minWidth: '70px',
                    }}
                    // disabled
                    onChange={(value) => (setDefaultBudget(JSON.stringify(value)),
                        localStorage.setItem('defaultBudget', value))} />
            </Text>

            <Text key={'defaultPaymentMethod'} style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '8px 0' }}>
                <span>{'Default Payment Method'}</span>
                <Select size='default'
                    defaultValue={defaultPaymentMethod}
                    style={{
                        width: '150px',
                    }}
                    onChange={(value) => (setDefaultPaymentMethod(value),
                        localStorage.setItem('defaultPaymentMethod', value))}
                    options={[
                        {
                            value: 'Visa8316',
                            label: 'Visa 8316',
                        },
                        {
                            value: 'FlexiCard',
                            label: 'Flexi Card',
                        },
                        {
                            value: 'cash',
                            label: 'cash',
                        },
                    ]}
                />
            </Text>

        </Drawer>
    </>
    )
}

export default DefaultSettingDrawer