// actions/expenseverseDailyLimitActions.js
import * as api from '../api/index';
import { message } from 'antd';
import { getAuth, getIdToken } from "firebase/auth";

export const getDailyLimit = (date) => async (dispatch) => {
    try {
        const auth = getAuth();
        const token = await getIdToken(auth.currentUser);
        const { data } = await api.fetchDailyLimit(date, token);

        if (data.amount === 0 && data.message) {
            message.info(data.message);
        } else {
            dispatch({ type: 'FETCH_DAILY_LIMIT', payload: data });
        }
    } catch (error) {
        console.log(error.message);
        message.error('Failed to fetch today\'s daily limit. Please try again.');
    }
};

export const getDailyLimits = (page, pageSize) => async (dispatch) => {
    try {
        const auth = getAuth();
        const token = await getIdToken(auth.currentUser);
        const { data } = await api.fetchDailyLimits(page, pageSize, token);

        dispatch({ type: 'FETCH_DAILY_LIMITS', payload: data });
    } catch (error) {
        console.log(error.message);
        message.error('Failed to fetch the list of daily limits. Please try again.');
    }
};

export const getAllDailyLimits = () => async (dispatch) => {
    try {
        const auth = getAuth();
        const token = await getIdToken(auth.currentUser);
        const { data } = await api.fetchAllDailyLimits(token);

        dispatch({ type: 'FETCH_ALL_DAILY_LIMITS', payload: data });
    } catch (error) {
        console.log(error.message);
        message.error('Failed to fetch the list of daily limits. Please try again.');
    }
};

export const getDailyLimitsByRange = (startDate, endDate, startDateAsString) => async (dispatch) => {
    try {
        const auth = getAuth();
        const token = await getIdToken(auth.currentUser);
        const { data } = await api.fetchDailyLimitsByRange(startDate, endDate, startDateAsString, token);

        dispatch({ type: 'FETCH_DAILY_LIMITS_BY_RANGE', payload: { dailyLimits: data } });
    } catch (error) {
        console.log(error.message);
        message.error('Failed to fetch daily limits for the given date range. Please try again.');
    }
};

export const createOrUpdateDailyLimit = (dailyLimitData) => async (dispatch) => {
    try {
        const auth = getAuth();
        const token = await getIdToken(auth.currentUser);
        const { data } = await api.createOrUpdateDailyLimit(dailyLimitData, token);

        dispatch({ type: 'CREATE_OR_UPDATE_DAILY_LIMIT', payload: data });
        return { success: true };
    } catch (error) {
        console.log(error.message);
        message.error('Failed to update daily limit. Please try again.');
        return { success: false };
    }
};

export const deleteDailyLimit = (date) => async (dispatch) => {
    try {
        const auth = getAuth();
        const token = await getIdToken(auth.currentUser);
        await api.deleteDailyLimit(date, token);

        dispatch({ type: 'DELETE_DAILY_LIMIT', payload: date });
        message.success('Daily limit deleted successfully');
    } catch (error) {
        console.log(error.message);
        message.error('Failed to delete daily limit. Please try again.');
    }
};

export const getDailyLimitByDate = (date) => async (dispatch) => {
    try {
        const auth = getAuth();
        const token = await getIdToken(auth.currentUser);
        const { data } = await api.fetchDailyLimitByDate(date, token); // Make sure you implement this API call

        dispatch({ type: 'FETCH_DAILY_LIMIT_BY_DATE', payload: data }); // Update the dailyLimit in the state
        return data; // Return the data if needed
    } catch (error) {
        console.error('Error fetching daily limit by date:', error);
        message.error('Failed to fetch daily limit for the selected date. Please try again.');
        return null; // Return null or handle error as necessary
    }
};