import React, { useState, useEffect, useCallback } from 'react';
import { Table, Button, Modal, Form, Input, Select, DatePicker, Typography, Space, Popconfirm, Dropdown, Menu, Checkbox, Card, message } from 'antd';
import { EditOutlined, DeleteOutlined, PlusOutlined, SettingOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import { getExpenseverseAccounts, createExpenseverseAccount, updateExpenseverseAccount, deleteExpenseverseAccount } from '../../../actions/expenseverseAccountActions';
import { getExpenseData, updateExpenseData } from '../../../actions/expensesActions'; // Importing transaction-related actions
import dayjs from 'dayjs';
import formLayout from '../../Shared UI/formLayout';
import { debounce } from 'lodash';
import { validCurrencies } from '../../../utils/currencyList';

const { Option } = Select;
const { Title, Paragraph } = Typography;

const AccountList = () => {
    const dispatch = useDispatch();
    const accounts = useSelector(state => state.expenseverseAccounts);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);
    const [currentAccount, setCurrentAccount] = useState(null);
    const [relatedTransactions, setRelatedTransactions] = useState([]); // New state for related transactions
    const [form] = Form.useForm();
    const [visibleColumns, setVisibleColumns] = useState(['name', 'accountNumber', 'status', 'actions']);
    const [dropdownVisible, setDropdownVisible] = useState(false);
    const [defaultPaymentMethodOptions, setDefaultPaymentMethodOptions] = useState([]);
    const [previousDefaultPaymentMethod, setPreviousDefaultPaymentMethod] = useState(null);

    const [cashData, setCashData] = useState({ accountNumber: '', defaultPaymentMethod: 'cash' });
    const [bankData, setBankData] = useState({ accountNumber: '', cards: [], defaultPaymentMethod: '' });
    const [virtualData, setVirtualData] = useState({ accountNumber: '', defaultPaymentMethod: 'points' });

    useEffect(() => {
        dispatch(getExpenseverseAccounts());
    }, [dispatch]);

    const generateRandomFourDigitNumber = () => Math.floor(1000 + Math.random() * 9000);

    const handleAccountTypeChange = (value) => {
        if (value === 'cash') {
            const randomFourDigitNumber = generateRandomFourDigitNumber();
            const newAccountNumber = `cash_account_${randomFourDigitNumber}`;
            setCashData(prev => ({ ...prev, accountNumber: newAccountNumber }));
            form.setFieldsValue({ accountNumber: newAccountNumber, cards: [], defaultPaymentMethod: 'cash' });
            setDefaultPaymentMethodOptions(['cash']);
        } else if (value === 'virtual') {
            form.setFieldsValue({
                accountNumber: virtualData.accountNumber,
                cards: [],
                defaultPaymentMethod: 'points'
            });
            setDefaultPaymentMethodOptions(['points']);
        } else if (value === 'bank') {
            form.setFieldsValue({
                accountNumber: bankData.accountNumber,
                cards: bankData.cards,
                defaultPaymentMethod: bankData.defaultPaymentMethod
            });
            setDefaultPaymentMethodOptions(bankData.cards);
        }
    };

    const formatCardNumberForDisplay = (cardNumber) => {
        if (cardNumber) {
            return `**** **** **** ${cardNumber.slice(-4)}`;
        }
        return '';
    };

    const debouncedHandleCardsChange = useCallback(
        debounce((cards) => {
            const previousMethod = form.getFieldValue('defaultPaymentMethod');
            setDefaultPaymentMethodOptions(cards);

            if (cards.includes(previousMethod)) {
                form.setFieldsValue({ defaultPaymentMethod: previousMethod });
            } else {
                form.setFieldsValue({ defaultPaymentMethod: undefined });
                setBankData(prev => ({ ...prev, defaultPaymentMethod: '' }));
            }

            setBankData(prev => ({ ...prev, cards }));
        }, 300),
        []
    );

    const debouncedHandleAccountNumberChange = useCallback(
        debounce((value, accountType) => {
            if (accountType === 'cash') {
                setCashData(prev => ({ ...prev, accountNumber: value }));
            } else if (accountType === 'virtual') {
                setVirtualData(prev => ({ ...prev, accountNumber: value }));
            } else if (accountType === 'bank') {
                setBankData(prev => ({ ...prev, accountNumber: value }));
            }
        }, 300),
        []
    );

    const debouncedHandleDefaultPaymentMethodChange = useCallback(
        debounce((value, accountType) => {
            if (accountType === 'cash') {
                setCashData(prev => ({ ...prev, defaultPaymentMethod: value }));
            } else if (accountType === 'virtual') {
                setVirtualData(prev => ({ ...prev, defaultPaymentMethod: value }));
            } else if (accountType === 'bank') {
                setBankData(prev => ({ ...prev, defaultPaymentMethod: value }));
            }
        }, 300),
        []
    );

    // Show Modal for Adding/Editing an Account
    const showModal = (account = null) => {
        setCurrentAccount(account);
        form.resetFields();
        setDefaultPaymentMethodOptions([]);

        setCashData({ accountNumber: '', defaultPaymentMethod: 'cash' });
        setBankData({ accountNumber: '', cards: [], defaultPaymentMethod: '' });
        setVirtualData({ accountNumber: '', defaultPaymentMethod: 'points' });

        if (account) {
            setDefaultPaymentMethodOptions(account.cards);
            if (account.accountType === 'virtual') {
                setVirtualData({ accountNumber: account.accountNumber, defaultPaymentMethod: 'points' });
            } else if (account.accountType === 'cash') {
                setCashData({ accountNumber: account.accountNumber, defaultPaymentMethod: 'cash' });
            } else if (account.accountType === 'bank') {
                setBankData({ accountNumber: account.accountNumber, cards: account.cards, defaultPaymentMethod: account.defaultPaymentMethod });
            }

            form.setFieldsValue({
                ...account,
                startDate: account.startDate ? dayjs(account.startDate) : null,
                endDate: account.endDate ? dayjs(account.endDate) : null,
                defaultPaymentMethod: account.defaultPaymentMethod,
            });
        }
        setIsModalVisible(true);
    };

    const handleOk = async () => {
        try {
            const values = await form.validateFields();
            values.name = values.name.trim();
            let result;
            if (currentAccount) {
                result = await dispatch(updateExpenseverseAccount(currentAccount._id, values));
            } else {
                result = await dispatch(createExpenseverseAccount(values));
            }
            if (result.success) {
                setIsModalVisible(false);
                form.resetFields();
                setCurrentAccount(null);
            }
        } catch (info) {
            console.log('Validate Failed:', info);
        }
    };

    const handleCancel = () => {
        setIsModalVisible(false);
        form.resetFields();
        setCurrentAccount(null);
    };

    // Show confirmation modal for deleting an account
    const showDeleteConfirmation = async (accountId) => {
        try {
            // Always reset relatedTransactions to ensure the check happens on each deletion
            setRelatedTransactions([]);
            const response = await dispatch(getExpenseData());
            const transactions = response.payload || response;
            const relatedTransactions = transactions.filter(transaction => transaction.accountId === accountId);

            if (relatedTransactions.length > 0) {
                setCurrentAccount(accountId);
                setRelatedTransactions(relatedTransactions);
                setIsDeleteModalVisible(true); // Show confirmation modal
            } else {
                handleDelete(accountId); // No related transactions, proceed with deletion
            }
        } catch (error) {
            console.error('Error fetching transactions:', error);
            message.error('Failed to check related transactions.');
        }
    };

    const handleDelete = async (accountId) => {
        try {
            // Remove account from related transactions
            const updatePromises = relatedTransactions.map(transaction => {
                return dispatch(updateExpenseData(transaction._id, {
                    ...transaction,
                    accountId: null,
                    bankProcessedDate: null,
                    paymentMethod: null,
                    reviewed: false
                }));
            });

            await Promise.all(updatePromises);

            // Now delete the account
            await dispatch(deleteExpenseverseAccount(accountId));
            setRelatedTransactions([]);
            setIsDeleteModalVisible(false); // Close delete confirmation modal after success
        } catch (error) {
            message.error('Failed to delete account.');
            console.error('Error:', error);
        }
    };

    const getStatus = (endDate) => {
        if (endDate && dayjs(endDate).isBefore(dayjs())) {
            return 'Ended';
        }
        return 'Active';
    };

    const columns = [
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            fixed: 'left',
            width: 150,
            sorter: (a, b) => a.name.localeCompare(b.name),
            ellipsis: true,
            visible: true
        },
        {
            title: 'Owner',
            dataIndex: 'owner',
            key: 'owner',
            sorter: (a, b) => a.owner.localeCompare(b.owner),
            ellipsis: true,
            visible: false
        },
        {
            title: 'Account Type',
            dataIndex: 'accountType',
            key: 'accountType',
            sorter: (a, b) => a.accountType.localeCompare(b.accountType),
            ellipsis: true,
            visible: false
        },
        {
            title: 'Note',
            dataIndex: 'note',
            key: 'note',
            ellipsis: true,
            visible: false
        },
        {
            title: 'Account Number',
            dataIndex: 'accountNumber',
            key: 'accountNumber',
            ellipsis: true,
            visible: true
        },
        {
            title: 'Cards',
            dataIndex: 'cards',
            key: 'cards',
            render: cards => cards.join(', '),
            ellipsis: true,
            visible: false
        },
        {
            title: 'Default Payment Method',
            dataIndex: 'defaultPaymentMethod',
            key: 'defaultPaymentMethod',
            ellipsis: true,
            visible: false
        },
        {
            title: 'Default Currency',
            dataIndex: 'defaultCurrency',
            key: 'defaultCurrency',
            ellipsis: true,
            visible: false
        },
        {
            title: 'Start Date',
            dataIndex: 'startDate',
            key: 'startDate',
            render: date => (date ? dayjs(date).format('YYYY-MM-DD') : ''),
            ellipsis: true,
            visible: false
        },
        {
            title: 'End Date',
            dataIndex: 'endDate',
            key: 'endDate',
            render: date => (date ? dayjs(date).format('YYYY-MM-DD') : ''),
            ellipsis: true,
            visible: false
        },
        {
            title: 'Created At',
            dataIndex: 'createdAt',
            key: 'createdAt',
            render: date => (date ? dayjs(date).format('YYYY-MM-DD') : ''),
            ellipsis: true,
            visible: false
        },
        {
            title: 'Status',
            key: 'status',
            render: (text, record) => getStatus(record.endDate),
            ellipsis: true,
            visible: true
        },
        {
            title: 'Actions',
            key: 'actions',
            fixed: 'right',
            align: 'right',
            width: 100,
            render: (text, record) => (
                <Space>
                    <Button icon={<EditOutlined />} onClick={() => showModal(record)} />
                    <Popconfirm
                        title="Are you sure you want to delete this account?"
                        onConfirm={() => showDeleteConfirmation(record._id)} // Updated deletion logic
                        okText="Yes"
                        cancelText="No"
                    >
                        <Button icon={<DeleteOutlined />} />
                    </Popconfirm>
                </Space>
            ),
            ellipsis: true,
            visible: true
        },
    ];

    const handleMenuClick = (e) => {
        const columnKey = e.key;
        setVisibleColumns((prevVisibleColumns) =>
            prevVisibleColumns.includes(columnKey)
                ? prevVisibleColumns.filter((key) => key !== columnKey)
                : [...prevVisibleColumns, columnKey]
        );
    };

    const menu = (
        <Menu onClick={handleMenuClick}>
            {columns.map((column) => (
                <Menu.Item key={column.key}>
                    <Checkbox checked={visibleColumns.includes(column.key)}>
                        {column.title}
                    </Checkbox>
                </Menu.Item>
            ))}
        </Menu>
    );

    const handleDropdownVisibleChange = (flag) => {
        setDropdownVisible(flag);
    };

    const filteredColumns = columns.filter(column => visibleColumns.includes(column.key));

    return (
        <>
            <Title level={3}>Accounts /  Contacts</Title>
            <Paragraph>
                <p>Manage your financial accounts including bank accounts, cash accounts, and virtual accounts (For example for credits/ points in some mobile apps which represents real money).
                </p>
                <p> You can also add accounts of your friends just to easily distinguish transactions to known accounts.</p>
                Add new accounts, edit existing ones, and set inactive those that are no longer needed.
            </Paragraph>
            <br />
            <br />
            <Space style={{ marginBottom: 16 }}>
                <Button type="primary" icon={<PlusOutlined />} onClick={() => showModal()}>
                    Add Account
                </Button>
                <Dropdown overlay={menu} trigger={['click']} onVisibleChange={handleDropdownVisibleChange} visible={dropdownVisible}>
                    <Button icon={<SettingOutlined />}>Columns</Button>
                </Dropdown>
            </Space>
            <Table
                columns={filteredColumns}
                dataSource={accounts}
                rowKey="_id"
                scroll={{ x: 500 }}
            />
            <Modal title={currentAccount ? 'Edit Account' : 'Add Account'} visible={isModalVisible} onOk={handleOk} onCancel={handleCancel}>
                <Form form={form}  {...formLayout}>
                    <Card
                        bordered={false}
                        style={{
                            background: 'rgba(150,150,150,.07)',
                            marginBottom: 16,
                        }}>
                        <Form.Item name="name" label="Name" rules={[{ required: true, message: 'Please input the account name!' }]}>
                            <Input />
                        </Form.Item>
                        <Form.Item name="owner" label="Owner" rules={[{ required: true, message: 'Please input the owner!' }]}>
                            <Input />
                        </Form.Item>
                        <Form.Item name="accountType" label="Account Type" rules={[{ required: true, message: 'Please select the account type!' }]}>
                            <Select onChange={handleAccountTypeChange}>
                                <Option value="cash">Cash</Option>
                                <Option value="bank">Bank</Option>
                                <Option value="virtual">Virtual</Option>
                            </Select>
                        </Form.Item>
                        <Form.Item name="accountNumber" label="Account Number" rules={[{ required: true, message: 'Please input the account number!' }]}>
                            <Input placeholder={form.getFieldValue('accountType') === 'virtual' ? 'User number or Account number' : ''} onChange={(e) => debouncedHandleAccountNumberChange(e.target.value, form.getFieldValue('accountType'))} />
                        </Form.Item>
                        <Form.Item name="cards" label="Cards">
                            <Select mode="tags" style={{ width: '100%' }} tokenSeparators={[',']} onChange={debouncedHandleCardsChange} disabled={form.getFieldValue('accountType') !== 'bank'} />
                        </Form.Item>
                        <Form.Item name="defaultPaymentMethod" label="Default Payment Method" rules={[{ required: true, message: 'Please select the default payment method!' }]}>
                            <Select disabled={['cash', 'virtual'].includes(form.getFieldValue('accountType'))} onChange={(value) => debouncedHandleDefaultPaymentMethodChange(value, form.getFieldValue('accountType'))}>
                                {defaultPaymentMethodOptions.map(option => (
                                    <Option key={option} value={option}>{formatCardNumberForDisplay(option)}</Option>
                                ))}
                            </Select>
                        </Form.Item>
                        <Form.Item name="defaultCurrency" label="Default Currency" rules={[{ required: true, message: 'Please input the default currency!' }]}>
                            <Select placeholder="Select a currency"
                                style={{ width: 'auto', maxWidth: '100%' }}
                                popupMatchSelectWidth={false}>
                                {validCurrencies.map(({ code, name, symbol }) => (
                                    <Option key={code} value={code}>
                                        {name} ({symbol})
                                    </Option>
                                ))}
                            </Select>
                        </Form.Item>
                        <Form.Item name="startDate" label="Start Date" rules={[{ required: true, message: 'Please select the start date!' }]}>
                            <DatePicker style={{ width: '100%' }} />
                        </Form.Item>
                    </Card>
                    <Form.Item name="note" label="Note">
                        <Input />
                    </Form.Item>
                    <Form.Item name='endDate' label='End Date'>
                        <DatePicker style={{ width: '100%' }} />
                    </Form.Item>
                </Form>

                {/* Display current state values */}
                <div style={{ marginTop: 20 }}>
                    <Typography.Text>Cash Data: {JSON.stringify(cashData)}</Typography.Text>
                    <br />
                    <Typography.Text>Bank Data: {JSON.stringify(bankData)}</Typography.Text>
                    <br />
                    <Typography.Text>Virtual Data: {JSON.stringify(virtualData)}</Typography.Text>
                </div>
            </Modal>

            {/* Modal to confirm account deletion and list related transactions */}
            <Modal
                title="Confirm Account Deletion"
                visible={isDeleteModalVisible}
                onOk={() => handleDelete(currentAccount)}
                onCancel={() => {
                    setIsDeleteModalVisible(false);
                    setRelatedTransactions([]); // Reset related transactions on cancel
                }}
            >
                <p>The following transactions are related to this account:</p>
                <ul>
                    {relatedTransactions.map(transaction => (
                        <li key={transaction._id}>{transaction.note || transaction._id}</li>
                    ))}
                </ul>
                <p>Are you sure you want to delete this account?</p>
            </Modal>
        </>
    );
};

export default AccountList;