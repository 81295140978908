import { Space, TreeSelect } from "antd";
import { useEffect, useState } from "react";
import NotesCollections from "../../components/NotesView/NotesCollections/NotesCollections";
import useOptions from "../../utils/getOptions";
import FormInTab from "../../components/NotesView/NoteForm/NoteFormInTab";
import { useDeepLinkHighlight } from "../../components/Other/useDeepLinkHighlight";


const PageWithoutTabs = ({ allNotesForCurrentFolder, currentId, setCurrentId, darkMode, id }) => {
    const tagsSpecificToFolder = useOptions('noteCollections', 'tags', id);
    const [notesFromFilter, setNotesFromFilter] = useState([]);
    const [filterValue, setFilterValue] = useState([])

    const onFilterChange = (newValue) => {
        setFilterValue(newValue);
        setNotesFromFilter(
            allNotesForCurrentFolder?.filter((note) =>
                newValue?.some((tag) => note.tags.includes(tag))
            )
        );
    };
    useEffect(() => {
        setNotesFromFilter(
            allNotesForCurrentFolder?.filter((note) =>
                filterValue?.some((tag) => note.tags.includes(tag))
            )
        );
    }, [allNotesForCurrentFolder])

    /* DEEP LINKS
    ** Scroll to the note element when it is rendered
    ** Get the note ID from the URL query and Create a ref to store the note element
    */
    const { noteId, noteRef, doesNoteExist } = useDeepLinkHighlight(allNotesForCurrentFolder);




    return (

        < Space direction='vertical' style={{ width: '100%' }} size={'large'}>
            Folder does not contain Tabs
            < br />
            <TreeSelect
                style={{ width: "100%", }}
                placeholder="Filters"
                treeCheckable="true"
                treeData={tagsSpecificToFolder}
                onChange={(value) => onFilterChange(value)}
            />
            <NotesCollections
                receivedCollection={notesFromFilter.length === 0 ? allNotesForCurrentFolder : notesFromFilter}
                setCurrentId={setCurrentId}
                darkMode={darkMode}
                requestedNoteId={noteId} noteRef={noteRef}
            />
            <FormInTab
                currentId={currentId}
                setCurrentId={setCurrentId}
                createdFromParent={id}
                darkMode={darkMode}

                formKey={'CalledFromContent'}
            />
        </Space>
    )
}


export default PageWithoutTabs;