// video tutorial https://www.youtube.com/watch?v=WpIDez53SK4

// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth } from "firebase/auth";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyAd9PJP2hdPCE9kc7L1WhlGbnU4mWWvDQE",
    authDomain: "noteverse-f8862.firebaseapp.com",
    projectId: "noteverse-f8862",
    storageBucket: "noteverse-f8862.appspot.com",
    messagingSenderId: "1035632421835",
    appId: "1:1035632421835:web:d8d3dbafecfbb357bae44e",
    measurementId: "G-8X4CE037GL"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

// Initialize Firebase Authentication and get a reference to the service
const auth = getAuth(app);
export { app, auth };