import { combineReducers } from 'redux';

import expensesCollection from './expensesReducer';
import noteCollections from './noteCollections';
import folderCollections from './folderCollections';
import pageTabsCollections from './pageTabsCollections';
import expenseverseCategories from './expenseverseCategoryReducer';
import expenseverseAccounts from './expenseverseAccountReducer';
import expenseverseProducts from './expenseverseProductReducer';
import expenseverseDailyLimit from './expenseverseDailyLimitReducer';
import expenseverseDefaultSettings from './expenseverseDefaultSettingsReducer';
import userSettings from './userSettingsReducer';

export default combineReducers({
    noteCollections,
    folderCollections,
    pageTabsCollections,
    expensesCollection,
    expenseverseCategories,
    expenseverseAccounts,
    expenseverseProducts,
    expenseverseDailyLimit,
    expenseverseDefaultSettings,
    userSettings,  // New user settings reducer
});