import React, { useEffect, useRef } from 'react';
import { Column } from '@antv/g2plot';

function roundToTwo(num) {
    return +(Math.round(num + "e+2") + "e-2");
}

const DailyTransactionChart = ({ transactions, dailyLimit, defaultCurrency, darkMode }) => {
    const chartRef = useRef(null);

    // Function to calculate the sums of incoming and outgoing transactions
    const calculateSums = (transactions, dailyLimit) => {
        let incomingSum = 0;
        let outgoingSum = 0;
        let withinLimit = 0;
        let exceedingLimit = 0;

        // Calculate total incoming and outgoing sums
        transactions
            .filter(transaction => defaultCurrency ? transaction.currency === defaultCurrency : transaction)
            .forEach((transaction) => {
                if (transaction.amount > 0) {
                    incomingSum += transaction.amount;
                } else {
                    outgoingSum += Math.abs(transaction.amount); // Outgoing as positive
                }
            });

        // Split outgoing into two parts: within limit and exceeding limit
        if (outgoingSum > dailyLimit) {
            withinLimit = roundToTwo(dailyLimit);
            exceedingLimit = roundToTwo(outgoingSum - dailyLimit);
        } else {
            withinLimit = roundToTwo(outgoingSum);
        }

        return { incomingSum, withinLimit, exceedingLimit };
    };

    useEffect(() => {
        const { incomingSum, withinLimit, exceedingLimit } = calculateSums(transactions, dailyLimit);

        // Ensure both columns (Incoming and Outgoing) are always present
        const data = [
            { type: 'Incoming', category: 'Incoming', value: incomingSum || 0 },
            { type: 'Outgoing', category: 'Exceeding Limit', value: exceedingLimit || 0 },
            { type: 'Outgoing', category: 'Within Limit', value: withinLimit || 0 },
        ];

        // check if Incoming or outcomming is lower than daily limit, if so set minimum scale to dailyLimit
        const areColumnsBelowDailyLimit = incomingSum < dailyLimit && (withinLimit + exceedingLimit) < dailyLimit;
        const customHeight = areColumnsBelowDailyLimit ? dailyLimit : null;

        // Initialize the chart if it's not already created
        if (!chartRef.current) {
            chartRef.current = new Column('chart-container', {
                data,
                // theme: darkMode ? 'dark' : 'default',
                xField: 'type',
                yField: 'value',
                seriesField: 'category', // This stacks the columns by category
                isStack: true, // Enable stacking
                tooltip: {
                    shared: true, // Show both values in the tooltip
                },
                color: ['#A0D911', '#FA8D15', '#FAAD14'], // Custom colors for Incoming, Within Limit, Exceeding Limit
                // Apply rounded corners conditionally based on the category (only for the top of the stack)
                columnStyle: (record) => {
                    // If there's no Exceeding Limit, apply radius to Within Limit
                    if (record.category === 'Exceeding Limit') {
                        return { radius: [10, 10, 0, 0] }; // Rounded for Exceeding Limit stack (top)
                    } else if (record.category === 'Within Limit' && exceedingLimit === 0) {
                        return { radius: [10, 10, 0, 0] }; // If no Exceeding Limit, round Within Limit
                    } else if (record.category === 'Incoming') {
                        return { radius: [10, 10, 0, 0] }; // Rounded corners for Incoming
                    } else {
                        return { radius: [0, 0, 0, 0] }; // No rounded corners for bottom stacks
                    }
                },
                legend: false,
                label: {
                    animate: true,
                    layout: 'limitInShape',
                    position: 'middle', // You can use 'top', 'bottom', or 'middle' depending on where you want the label
                    style: {
                        // fill: darkMode ? '#fff' : '#000', // Text color based on darkMode
                        // fontSize: 12,
                        fontWeight: 'bold',
                    },
                    // Hide labels for columns with value 0
                    formatter: (category) => {
                        return category.value === 0 ? '' : category.value;
                    },
                },
                // Make Y-axis grid lines dashed
                yAxis: {
                    maxLimit: customHeight, // Set dynamic max limit, minimum of 500 if maxValue is below 500
                    grid: {
                        line: {
                            style: {
                                lineDash: [4, 4], // Dashed pattern
                                lineWidth: 0.2,
                                opacity: .7,
                            },
                        },
                    },
                },
            });

            chartRef.current.render();
        } else {
            // Update the data in the existing chart
            chartRef.current.changeData(data);
        }

        // Cleanup on component unmount
        return () => {
            if (chartRef.current) {
                chartRef.current.destroy();
                chartRef.current = null;
            }
        };
    }, [transactions, dailyLimit]); // Recalculate when transactions or dailyLimit change

    return <div id="chart-container" style={{ height: 200 }}></div>;
};

export default DailyTransactionChart;
