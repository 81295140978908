import React, { useEffect, useState } from 'react';
import { Breadcrumb, Tree } from 'antd';
import { useSelector } from 'react-redux';
import { Link } from "react-router-dom";
import 'antd/dist/reset.css';
const { TreeNode } = Tree;

const BreadcrumbNav = ({ folderId }) => {
  const [breadcrumbs, setBreadcrumbs] = useState([]);
  const [expandedKeys, setExpandedKeys] = useState([]);
  const [selectedKeys, setSelectedKeys] = useState([]);
  const folderData = useSelector((state) => state.folderCollections);

  useEffect(() => {
    const generateBreadcrumbs = (folderId) => {
      const result = [];
      let currentFolder = folderData.find((folder) => folder._id === folderId);
      while (currentFolder) {
        result.unshift(currentFolder);
        const parentFolderId = currentFolder.folderParents[0];
        currentFolder = folderData.find((folder) => folder._id === parentFolderId);
      }
      return result;
    };
    const newBreadcrumbs = generateBreadcrumbs(folderId);
    setBreadcrumbs(newBreadcrumbs);
    setExpandedKeys(newBreadcrumbs.map((folder) => folder._id));
    setSelectedKeys([folderId]);
  }, [folderData, folderId]);

  const generateTreeNodes = (folder) => {
    const childFolders = folderData.filter(
      (f) => f.folderParents[0] === folder._id
    );
    if (childFolders.length === 0) return null;
    let treeNodes = childFolders.map((f) => (
      <TreeNode
        title={<Link to={`/folders/${f._id}`}>{f.folderName}</Link>}
        key={f._id}
      >
        {generateTreeNodes(f)}
      </TreeNode>
    ));
    return treeNodes;
  };

  const handleSelect = (selectedKeys, info) => {
    setSelectedKeys(selectedKeys);
  };

  const handleExpand = (expandedKeys) => {
    setExpandedKeys(expandedKeys);
  };

  const homeLink = <Link to={"/"}>Home</Link>;
  if (breadcrumbs.length === 0) {
    return (
      <Breadcrumb items={[{ title: homeLink }, { title: 'No breadcrumbs available' }]} />
    );
  }
  return (
    <div>
      <Breadcrumb
        style={{
          margin: '16px 0',
        }}
        items={[
          { title: homeLink },
          ...breadcrumbs.map((folder) => ({
            title: <Link to={`/folders/${folder._id}`}>{folder.folderName}</Link>,
            key: folder._id,
          })),
        ]}
      />
      <Tree showLine
        expandedKeys={expandedKeys}
        onExpand={handleExpand}
        onSelect={handleSelect}
        selectedKeys={selectedKeys}
      >
        {generateTreeNodes(breadcrumbs[0])}
      </Tree>
      <br />
    </div>
  );
};

export default BreadcrumbNav;
