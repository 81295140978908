import * as api from '../api/index';
import { getAuth, getIdToken } from "firebase/auth";

export const deleteUserCollections = () => async (dispatch) => {
    try {
        const auth = getAuth();
        const token = await getIdToken(auth.currentUser);
        await api.deleteUserCollections(auth.currentUser.uid, token);

        dispatch({ type: 'DELETE_USER_COLLECTIONS' });
        return { status: 'success', message: 'User data was deleted successfully from database!' };
    } catch (error) {
        console.log(error);
        let message = error.message;
        if (message.includes('timeout')) {
            message = 'There was timeout error, check your internet connection!';
        }
        return { status: 'error', message: message };
    }
}