import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
    createExpenseData,
    updateExpenseData,
} from "../../../actions/expensesActions";
import { createExpenseverseCategory, getExpenseverseCategories } from "../../../actions/expenseverseCategoryActions";
import { Button, ConfigProvider, DatePicker, Input, Form, message, Modal, Space, Row, Typography, Select, InputNumber, Avatar, Col, Divider, Radio, Tooltip, Alert, theme } from "antd";
import dayjs from 'dayjs';
import useOptions from "../../../utils/getOptions";
import locale from 'antd/es/date-picker/locale/en_US';
import enUS from 'antd/locale/en_US';
import { PlusOutlined, DeleteOutlined, WalletFilled, BankFilled } from '@ant-design/icons';
import ImportTransactions from "./ImportTransactions";
const { TextArea } = Input;

const DynamicIconInputToggle = ({ inputs, initialValues, darkMode, LightToken, DarkToken }) => {
    const [visibility, setVisibility] = useState(
        inputs.reduce((acc, input) => ({
            ...acc,
            [input.name]: !!initialValues[input.name] && (
                typeof initialValues[input.name] === 'string' ? initialValues[input.name].trim() !== '' :
                    Array.isArray(initialValues[input.name]) ? initialValues[input.name].length > 0 :
                        true
            )
        }), {})
    );

    const toggleVisibility = (name) => {
        setVisibility(prev => ({ ...prev, [name]: !prev[name] }));
    };

    return (
        <>
            <div style={{ display: 'flex', justifyContent: 'space-around' }}>
                {inputs.map(input => (
                    <div key={input.name} onClick={() => toggleVisibility(input.name)}
                        style={{
                            display: "flex",
                            flexDirection: 'column',
                            alignItems: 'center',
                            width: 60,
                            overflow: 'hidden',
                        }}>
                        <Avatar size='large' shape="square" style={{
                            background: 'none',
                            border: visibility[input.name] ? `2px solid ${darkMode ? DarkToken.colorText : LightToken.colorText}` : 'none',
                        }}>
                            <span className="material-icons" style={{
                                fontSize: 20,
                                verticalAlign: 'middle',
                                opacity: .7,
                                color: visibility[input.name] ? (darkMode ? DarkToken.colorText : LightToken.colorText) : 'rgba(123,123,123,.4)'
                            }}>{input.icon}</span>
                        </Avatar>
                        <Typography style={{
                            color: visibility[input.name] ? 'rgba(123,123,123,1)' : 'rgba(123,123,123,.4)',
                            fontSize: 12,
                            width: 50,
                            textAlign: 'center',
                            whiteSpace: 'nowrap',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                        }}>{input.label}</Typography>
                    </div>
                ))}
            </div>
            <br />
            <div>
                {inputs.map(input => visibility[input.name] ? input.component : null)}
            </div>
            <br />
        </>
    );
};

const MultiExpenseForm = ({
    currentExpenseID, setCurrentExpenseID, setVisibleMultiExpense, visibleMultiExpense,
    createdFromParent, darkMode, tabID, treeTabs, setShowForm, formKey,
    defaultCurrency, allAccounts, defaultAccount, defaultPaymentMethod,
    LightToken, DarkToken,
}) => {
    const allTags = useOptions('expensesCollection', 'tags');
    const allLocations = useOptions('expensesCollection', 'location');
    const { useToken } = theme;
    const { token } = useToken();
    const expensesCollection = useSelector((state) =>
        currentExpenseID ? state.expensesCollection.find((r) => r._id === currentExpenseID) : null
    );
    const dispatch = useDispatch();
    const [form] = Form.useForm();
    const [textInput, setTextInput] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const categories = useSelector((state) => state.expenseverseCategories);
    const [allCategories, setAllCategories] = useState([]);

    const adjustCategories = () => {
        setAllCategories(categories.map(category => ({ label: category.name, value: category._id })));
    }

    useEffect(() => {
        dispatch(getExpenseverseCategories());
    }, [dispatch]);

    useEffect(() => {
        adjustCategories();
    }, [categories]);

    const initialTransactions = [
        {
            currency: defaultCurrency,
            transactionDate: dayjs(Date.now()), currency: defaultCurrency,
            isExpense: 'expense',
            accountId: defaultAccount,
            paymentMethod: defaultPaymentMethod
        },
    ];

    useEffect(() => {
        const initialPaiedByCash = {};
        initialTransactions.forEach((_, index) => {
            initialPaiedByCash[index] = 'cash';
        });
        setPaidByCash(initialPaiedByCash);
    }, []);

    const [paiedByCash, setPaidByCash] = useState({});
    const handlePaiedByCashChange = (fieldKey, value) => {
        setPaidByCash((prev) => ({
            ...prev,
            [fieldKey]: value,
        }));

        if (value === 'cash') {
            form.setFieldsValue({
                transactions: form.getFieldValue('transactions').map((transaction, index) => {
                    if (index === fieldKey) {
                        return {
                            ...transaction,
                            accountId: 'cash',
                            paymentMethod: 'cash',
                        };
                    }
                    return transaction;
                }),
            });
        } else {
            form.setFieldsValue({
                transactions: form.getFieldValue('transactions').map((transaction, index) => {
                    if (index === fieldKey) {
                        return {
                            ...transaction,
                            accountId: defaultAccount,
                            paymentMethod: defaultPaymentMethod,
                        };
                    }
                    return transaction;
                }),
            });
        }
    };

    const handleAddTransaction = (add, initialTransactions) => {
        add(...initialTransactions);
        setTimeout(() => {
            const fields = form.getFieldValue('transactions');
            const newFieldKey = fields.length - 1;
            setPaidByCash((prev) => ({
                ...prev,
                [newFieldKey]: 'cash',
            }));
            form.setFieldsValue({
                transactions: form.getFieldValue('transactions').map((transaction, index) => {
                    if (index === newFieldKey) {
                        return {
                            ...transaction,
                            accountId: 'cash',
                            paymentMethod: 'cash',
                        };
                    }
                    return transaction;
                }),
            });
        }, 0);
    };

    const inputVariant = { variant: "filled" }
    const formItemLayout = {
        labelCol: { xs: { span: 8 } },
        wrapperCol: { xs: { span: 16 } },
    };

    useEffect(() => {
        if (expensesCollection) {
            let date = dayjs(expensesCollection?.transactionDate)
            form.setFieldsValue({
                transactionDate: date,
                amount: expensesCollection.amount,
                type: expensesCollection.type,
                category: expensesCollection.category,
                note: expensesCollection.note,
                currency: expensesCollection.currency,
            });
        }
    }, [expensesCollection]);

    const handleSubmit = async (e) => {
        const transactions = e.transactions;
        const categoryIdMap = {};

        // Extract all categories from transactions
        transactions.forEach(transaction => {
            if (transaction.category && Array.isArray(transaction.category)) {
                transaction.category.forEach(category => {
                    if (!categories.some(cat => cat._id === category)) {
                        categoryIdMap[category] = null; // Mark as needing an ID
                    }
                });
            }
        });

        // Get the list of new categories to be created
        const newCategories = Object.keys(categoryIdMap).filter(categoryName => !categories.some(cat => cat.name === categoryName));

        if (newCategories.length > 0) {
            // Show confirmation modal
            Modal.confirm({
                title: 'Create New Categories',
                content: (
                    <div>
                        <p>The following new categories will be created:</p>
                        <ul>
                            {newCategories.map(category => (
                                <li key={category}>{category}</li>
                            ))}
                        </ul>
                    </div>
                ),
                okText: 'Create',
                cancelText: 'Cancel',
                onOk: async () => {
                    try {
                        // Create new categories and get their IDs
                        for (const categoryName of newCategories) {
                            const result = await dispatch(createExpenseverseCategory({ name: categoryName }));
                            if (result.status === 'success') {
                                categoryIdMap[categoryName] = result.data._id;
                            } else {
                                throw new Error(`Failed to create category: ${categoryName}`);
                            }
                        }

                        // Replace category names with their new IDs
                        const updatedTransactions = transactions.map(transaction => ({
                            ...transaction,
                            category: transaction.category ? transaction.category.map(cat => categoryIdMap[cat] || cat) : []
                        }));

                        // Submit updated transactions
                        const result = await dispatch(createExpenseData({ transactions: updatedTransactions }));
                        if (result.status === 'success') {
                            message.success(`( ${updatedTransactions.length} ) new transactions created successfully!`);
                            clear();
                            setVisibleMultiExpense(false);
                        } else {
                            throw new Error('An error occurred while creating transactions. Please try again.');
                        }
                    } catch (error) {
                        message.error(error.message);
                        console.error(error.message);
                    }
                },
                onCancel() {
                    message.info('Transaction creation cancelled.');
                },
            });
        } else {
            // No new categories to create, proceed with submitting transactions
            try {
                const result = await dispatch(createExpenseData({ transactions }));
                if (result.status === 'success') {
                    message.success(`( ${transactions.length} ) new transactions created successfully!`);
                    clear();
                    setVisibleMultiExpense(false);
                } else {
                    throw new Error('An error occurred while creating transactions. Please try again.');
                }
            } catch (error) {
                message.error(error.message);
                console.error(error.message);
            }
        }
    };


    const clear = () => {
        setCurrentExpenseID(null);
        form.resetFields();
    };

    const formName = `expenseForm`

    const showModal = () => {
        setVisibleMultiExpense(true);
    };
    const handleCancel = () => {
        setVisibleMultiExpense(false);
    };

    const [inOutType, setInOutType] = useState('expense');

    const labelFakeInpuWidth = 90
    const componentsSize = 'default'

    const generateOptionalInputs = (field) => [
        {
            name: 'location',
            label: 'Location',
            icon: 'place',
            component:
                <Space.Compact block size={componentsSize}>
                    <Form.Item  {...formItemLayout}
                        {...field}
                        name={[field.name, 'location']}
                        style={{ width: "100%" }}
                        label="Location"
                    >
                        <Select  {...inputVariant}
                            popupMatchSelectWidth={false}
                            locale={locale}
                            mode="tags"
                            placeholder="Location"
                            onChange={(selectedItems) => {
                                const locationString = selectedItems[selectedItems.length - 1] || null;
                                form.setFieldsValue({
                                    transactions: form.getFieldValue('transactions').map((transaction, index) => {
                                        if (index === field.name) {
                                            return { ...transaction, location: locationString };
                                        }
                                        return transaction;
                                    }),
                                });
                            }}
                            options={allLocations}
                        />
                    </Form.Item>
                </Space.Compact>
        },
        {
            name: 'tags',
            label: 'Tags',
            icon: 'style',
            component:
                <Space.Compact block size={componentsSize}>
                    <Form.Item  {...formItemLayout}
                        {...field}
                        name={[field.name, 'tags']}
                        style={{ width: "100%" }}
                        label="Tags"
                    >
                        <Select {...inputVariant}
                            name="tags"
                            mode="tags"
                            style={{
                                width: "100%",
                            }}
                            placeholder="Tags Mode"
                            value={
                                expensesCollection?.tags[0] === "" ? [] : expensesCollection?.tags
                            }
                            onChange={(e) => ({
                                tags: e,
                            })
                            }
                            tokenSeparators={[","]}
                            options={allTags}
                        />
                    </Form.Item>
                </Space.Compact>
        },
        {
            name: 'note',
            label: 'Note',
            icon: 'notes',
            component:
                <Space.Compact block size={componentsSize}>
                    <Form.Item  {...formItemLayout}
                        {...field}
                        name={[field.name, 'note']}
                        style={{ width: "100%" }}
                        label="Note"
                    >
                        <Input placeholder="Note" {...inputVariant} />
                    </Form.Item>
                </Space.Compact>
        },
        {
            name: 'status',
            label: 'Status',
            icon: 'price_check',
            component:
                <Space.Compact block size={componentsSize}>
                    <Form.Item  {...formItemLayout}
                        {...field}
                        name={[field.name, 'status']}
                        style={{ width: "100%" }}
                        label='Status'
                    >
                        <Input placeholder="Transaction Status" {...inputVariant} />
                    </Form.Item>
                </Space.Compact>
        },
        {
            name: 'originalAmount',
            label: 'Currency',
            icon: 'currency_exchange',
            component:
                <Space.Compact block size={componentsSize} >
                    <Form.Item {...formItemLayout}
                        {...field}
                        name={[field.name, 'originalAmount']}
                        label="Org. Amount"
                        style={{ width: "100%" }}
                    >
                        <InputNumber placeholder="Org. Amount" {...inputVariant}
                            style={{ width: "100%" }}
                            onChange={(value) => {
                                const isExpense = form.getFieldValue(['transactions', field.name, 'isExpense'])
                                const modifiedValue = isExpense === 'expense' ? -Math.abs(value) : Math.abs(value);

                                form.setFieldsValue({
                                    transactions: form.getFieldValue('transactions').map((transaction, index) => {
                                        if (index === field.name) {
                                            return { ...transaction, originalAmount: modifiedValue };
                                        }
                                        return transaction;
                                    }),
                                });
                                console.log(form.getFieldValue('originalAmount'))
                            }}
                            addonAfter={
                                <Form.Item
                                    {...field}
                                    name={[field.name, 'originalCurrency']}
                                    noStyle>
                                    <Select
                                        style={{
                                            width: 70,
                                        }}
                                        options={[
                                            { value: 'EUR', label: '€' },
                                            { value: 'CZK', label: 'Kč' },
                                            { value: 'GBP', label: '£' },
                                            { value: 'Other', label: '?' },
                                        ]}
                                    />
                                </Form.Item>
                            }
                        />
                    </Form.Item>
                </Space.Compact>
        },
        {
            name: 'accountsFromAndTo',
            label: 'From/To',
            icon: 'sync_alt',
            component:
                <Space.Compact block size={componentsSize}
                    style={{ width: "100%" }}
                >
                    <Row style={{ width: '110%' }}
                        gutter={[8, 8]}
                    >
                        <Col span={8} style={{
                            textAlign: 'end',
                            verticalAlign: 'middle',
                            whiteSpace: 'nowrap',
                        }}>
                            <label style={{
                                height: '32px',
                                display: "inline-flex",
                                alignItems: 'center'
                            }}>
                                <Typography>From / To :</Typography>
                            </label>
                        </Col>
                        <Col span={8}>
                            <Form.Item
                                {...field}
                                name={[field.name, 'fromAccountId']}
                                style={{ minWidth: '35%' }}
                            >
                                <Select {...inputVariant}
                                    popupMatchSelectWidth={false}
                                    locale={locale}
                                    name="fromAccountId"
                                    mode="tags"
                                    placeholder="From Acc."
                                    onChange={(selectedItems) => {
                                        const accountString = selectedItems[selectedItems.length - 1] || null;
                                        form.setFieldsValue({
                                            transactions: form.getFieldValue('transactions').map((transaction, index) => {
                                                if (index === field.name) {
                                                    return { ...transaction, fromAccountId: accountString };
                                                }
                                                return transaction;
                                            }),
                                        });
                                    }}
                                    options={allAccounts}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item
                                {...field}
                                name={[field.name, 'toAccountId']}
                                style={{ minWidth: '35%' }}
                            >
                                <Select {...inputVariant}
                                    popupMatchSelectWidth={false}
                                    locale={locale}
                                    name="toAccountId"
                                    mode="tags"
                                    placeholder="To Acc."
                                    onChange={(selectedItems) => {
                                        const accountString = selectedItems[selectedItems.length - 1] || null;
                                        form.setFieldsValue({
                                            transactions: form.getFieldValue('transactions').map((transaction, index) => {
                                                if (index === field.name) {
                                                    return { ...transaction, toAccountId: accountString };
                                                }
                                                return transaction;
                                            }),
                                        });
                                    }}
                                    options={allAccounts}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                </Space.Compact>
        }
    ];

    const initialValuesOfOptionalInputs = {
        location: expensesCollection?.location || '',
        category: expensesCollection?.category?.length > 0 ? expensesCollection?.category : [],
        tags: expensesCollection?.tags?.length > 0 ? expensesCollection?.tags : [],
        note: expensesCollection?.note || '',
        status: expensesCollection?.status || '',
    };

    const handleRemoveTransaction = (remove, fieldKey, fieldName) => {
        remove(fieldName);
        setPaidByCash((prev) => {
            const newPaidByCash = { ...prev };
            delete newPaidByCash[fieldKey];
            return newPaidByCash;
        });
    };

    return (
        <>
            <Button onClick={showModal} tooltip='test'>
                {<span class="material-icons" style={{ fontSize: 18, verticalAlign: 'middle', opacity: .7 }}>control_point_duplicate</span>}
            </Button>
            <ConfigProvider
                locale={enUS}
                theme={{
                    hashed: false,
                    components: {
                        DatePicker: { activeBg: 'red', fontSize: 16 },
                        Input: { activeBg: 'red', inputFontSize: 16 }
                    },
                }}
            >
                <Modal className="removeDefaultPadding addCustomBottomPadding"
                    title={currentExpenseID ? 'Edit Transaction' : 'Create Transaction'}
                    visible={visibleMultiExpense}
                    onCancel={handleCancel}
                    footer={null}
                >
                    <ImportTransactions form={form} setPaidByCash={setPaidByCash} />
                    {errorMessage && (
                        <Alert
                            type="error"
                            message={`Total Errors: ${errorMessage.split('\n').length}`}
                            description={
                                <div>
                                    <ol>
                                        {errorMessage.split('\n').map((err, index) => (
                                            <li key={index}>{err}</li>
                                        ))}
                                    </ol>
                                </div>
                            }
                        />
                    )}
                    <Form form={form} name={formName} onFinish={handleSubmit} >
                        <Form.List name="transactions">
                            {(fields, { add, remove }) => (
                                <>
                                    {fields.map((field) => (
                                        <Row key={field.key} style={{ display: 'flex', marginBottom: 8 }} align="baseline">
                                            <Col span={21}>
                                                <Row gutter={[16, 16]} style={{ width: 'inherit' }}>
                                                    <Col span={12}>
                                                        <Form.Item
                                                            {...field}
                                                            name={[field.name, 'transactionDate']}
                                                            style={{ width: 'inherit' }}
                                                            rules={[{ required: true, message: 'Please input the Transaction Date!' }]}
                                                        >
                                                            <DatePicker showTime {...inputVariant} size={componentsSize}
                                                                defaultValue={expensesCollection?.transactionDate ? expensesCollection.transactionDate : form.setFieldValue('transactionDate', dayjs(Date.now()))}
                                                                style={{ width: '100%' }}
                                                            />
                                                        </Form.Item>
                                                    </Col>
                                                    <Col span={12}>
                                                        <Form.Item {...inputVariant}
                                                            {...field}
                                                            name={[field.name, 'category']}
                                                            style={{ width: "100%" }}
                                                        >
                                                            <Select locale={locale} {...inputVariant} size={componentsSize}
                                                                optionFilterProp="label"
                                                                name="category"
                                                                mode="tags"
                                                                popupMatchSelectWidth={false}
                                                                placeholder="Category"
                                                                value={expensesCollection?.category[0] === "" ? [] : expensesCollection?.category}
                                                                onChange={(e) => ({ category: e })}
                                                                tokenSeparators={[","]}
                                                                options={allCategories}
                                                            />
                                                        </Form.Item>
                                                    </Col>
                                                </Row>
                                                <div style={{ display: "flex", width: '100%', gap: 10 }} >
                                                    <div style={{}}>
                                                        <Radio.Group
                                                            size={componentsSize}
                                                            defaultValue={paiedByCash[field.key] || 'cash'}
                                                            onChange={(e) => handlePaiedByCashChange(field.name, e.target.value)}
                                                            style={{ width: '100px' }}
                                                        >
                                                            <Tooltip title='Cash transaction'><Radio.Button value="cash" style={{ width: "50%", textAlign: 'center', border: 'none' }}><WalletFilled /></Radio.Button></Tooltip>
                                                            <Tooltip title='Bank transaction'><Radio.Button value="bank" style={{ width: "50%", textAlign: 'center', border: 'none', borderLeft: '1px solid red' }}><BankFilled /></Radio.Button></Tooltip>
                                                        </Radio.Group>
                                                    </div>
                                                    <div style={{ width: '100%' }}>
                                                        <Form.Item
                                                            {...field}
                                                            name={[field.name, 'amount']}
                                                            style={{ width: "100%" }}
                                                            rules={[{ required: true, message: 'Please input the Amount' }]}
                                                        >
                                                            <InputNumber placeholder="Amount" {...inputVariant}
                                                                style={{ width: "100%" }}
                                                                onChange={(value) => {
                                                                    const isExpense = form.getFieldValue(['transactions', field.name, 'isExpense']);
                                                                    const newAmount = isExpense === 'expense' ? -Math.abs(value) : Math.abs(value);
                                                                    form.setFieldsValue({
                                                                        transactions: form.getFieldValue('transactions').map((transaction, index) => {
                                                                            if (index === field.name) {
                                                                                return { ...transaction, amount: newAmount };
                                                                            }
                                                                            return transaction;
                                                                        }),
                                                                    });
                                                                }}
                                                                addonAfter={
                                                                    <Form.Item
                                                                        {...field}
                                                                        name={[field.name, 'currency']}
                                                                        noStyle>
                                                                        <Select
                                                                            style={{ width: 70 }}
                                                                            options={[
                                                                                { value: 'EUR', label: '€' },
                                                                                { value: 'CZK', label: 'Kč' },
                                                                                { value: 'GBP', label: '£' },
                                                                                { value: 'Other', label: '?' },
                                                                            ]}
                                                                        />
                                                                    </Form.Item>
                                                                }
                                                                addonBefore={
                                                                    <Form.Item
                                                                        {...field}
                                                                        name={[field.name, 'isExpense']}
                                                                        noStyle>
                                                                        <Select
                                                                            popupMatchSelectWidth={false}
                                                                            style={{ width: labelFakeInpuWidth }}
                                                                            onChange={(value, option) => {
                                                                                const amount = form.getFieldValue(['transactions', field.name, 'amount']) || 0;
                                                                                const newAmount = value === 'expense' ? Math.abs(amount) * -1 : Math.abs(amount);
                                                                                var orgAmount = form.getFieldValue(['transactions', field.name, 'originalAmount']) || 0;
                                                                                const newOrgAmount = value === 'expense' ? Math.abs(orgAmount) * -1 : Math.abs(orgAmount);
                                                                                form.setFieldsValue({
                                                                                    transactions: form.getFieldValue('transactions').map((transaction, index) => {
                                                                                        if (index === field.name) {
                                                                                            return { ...transaction, amount: newAmount, originalAmount: newOrgAmount };
                                                                                        }
                                                                                        return transaction;
                                                                                    }),
                                                                                });
                                                                            }} options={[
                                                                                {
                                                                                    value: 'expense',
                                                                                    label: <><span class="material-icons" style={{ fontSize: 30, verticalAlign: 'middle', opacity: .7, color: "red" }}>trending_down</span> -</>,
                                                                                },
                                                                                {
                                                                                    value: 'income',
                                                                                    label: <><span class="material-icons" style={{ fontSize: 30, verticalAlign: 'middle', opacity: .7, color: "green" }}>trending_up</span> + </>,
                                                                                }
                                                                            ]}
                                                                        />
                                                                    </Form.Item >
                                                                }
                                                            />
                                                        </Form.Item>
                                                    </div>
                                                </div>
                                                {paiedByCash[field.name] === 'cash' ? (
                                                    <>
                                                        <Form.Item {...field} name={[field.name, 'accountId']} style={{ display: 'none' }}>
                                                            <Input />
                                                        </Form.Item>
                                                        <Form.Item {...field} name={[field.name, 'paymentMethod']} style={{ display: 'none' }}>
                                                            <Input />
                                                        </Form.Item>
                                                    </>
                                                ) : (
                                                    <>
                                                        <Space.Compact block size={componentsSize}>
                                                            <Row style={{ width: '110%' }}
                                                                gutter={[8, 8]}
                                                            >
                                                                <Col span={8} style={{
                                                                    textAlign: 'end',
                                                                    verticalAlign: 'middle',
                                                                    whiteSpace: 'nowrap',
                                                                }}>
                                                                    <label style={{
                                                                        height: '32px',
                                                                        display: "inline-flex",
                                                                        alignItems: 'center'
                                                                    }}>
                                                                        <Typography>Bank Date :</Typography>
                                                                    </label>
                                                                </Col>
                                                                <Col span={16}>
                                                                    <Form.Item
                                                                        {...field}
                                                                        name={[field.name, 'bankProcessedDate']}
                                                                        style={{ width: 'inherit' }}
                                                                    >
                                                                        <DatePicker showTime {...inputVariant}
                                                                            style={{ width: '100%' }}
                                                                        />
                                                                    </Form.Item>
                                                                </Col>
                                                            </Row>
                                                        </Space.Compact>

                                                        <Space.Compact block size={componentsSize} style={{ width: "100%" }}>
                                                            <Row style={{ width: '110%' }} gutter={[8, 8]} >
                                                                <Col span={8} style={{
                                                                    textAlign: 'end',
                                                                    verticalAlign: 'middle',
                                                                    whiteSpace: 'nowrap',
                                                                }}>
                                                                    <label style={{
                                                                        height: '32px',
                                                                        display: "inline-flex",
                                                                        alignItems: 'center'
                                                                    }}>
                                                                        <Typography>Account ID :</Typography>
                                                                    </label>
                                                                </Col>
                                                                <Col span={9}>
                                                                    <Form.Item
                                                                        {...field}
                                                                        name={[field.name, 'accountId']}
                                                                        style={{ minWidth: '35%' }}
                                                                        rules={[{ required: true, message: 'Please input the Account' }]}
                                                                    >
                                                                        <Select {...inputVariant}
                                                                            popupMatchSelectWidth={false}
                                                                            locale={locale}
                                                                            name="accountId"
                                                                            mode="tags"
                                                                            placeholder="Account"
                                                                            onChange={(selectedItems) => {
                                                                                const accountString = selectedItems[selectedItems.length - 1] || null;
                                                                                form.setFieldsValue({
                                                                                    transactions: form.getFieldValue('transactions').map((transaction, index) => {
                                                                                        if (index === field.name) {
                                                                                            return { ...transaction, accountId: accountString };
                                                                                        }
                                                                                        return transaction;
                                                                                    }),
                                                                                });
                                                                            }}
                                                                            options={allAccounts}
                                                                        />

                                                                    </Form.Item>
                                                                </Col>
                                                                <Col span={7}>
                                                                    <Form.Item
                                                                        {...field}
                                                                        name={[field.name, 'paymentMethod']}
                                                                        style={{ minWidth: '35%' }}
                                                                        rules={[{ required: true, message: 'Please input the Payment Method' }]}
                                                                    >
                                                                        <Select {...inputVariant}
                                                                            popupMatchSelectWidth={false}
                                                                            style={{ width: '100%' }}
                                                                            options={[
                                                                                { value: 'Visa8316', label: 'Visa 8316' },
                                                                                { value: 'FlexiCard', label: 'Flexi Card' },
                                                                                { value: 'cash', label: 'cash' },
                                                                            ]}
                                                                        />
                                                                    </Form.Item>
                                                                </Col>
                                                            </Row>
                                                        </Space.Compact>
                                                    </>
                                                )}
                                                <DynamicIconInputToggle
                                                    inputs={generateOptionalInputs(field)}
                                                    initialValues={initialValuesOfOptionalInputs}
                                                    darkMode={darkMode} LightToken={LightToken} DarkToken={DarkToken}
                                                />
                                            </Col>
                                            <Col span={3} style={{
                                                display: 'flex',
                                                alignItems: 'flex-start',
                                                justifyContent: 'end',
                                            }}>
                                                <Avatar style={{ backgroundColor: '#fde3cf', color: '#f56a00' }}
                                                    size={componentsSize}
                                                    shape='square'
                                                    onClick={() => handleRemoveTransaction(remove, field.key, field.name)}
                                                >
                                                    <DeleteOutlined />
                                                </Avatar>
                                            </Col>
                                            <Divider />
                                        </Row>
                                    ))}
                                    <Form.Item>
                                        <Button type="dashed" onClick={() => handleAddTransaction(add, initialTransactions)} block icon={<PlusOutlined />}>
                                            Add Transaction
                                        </Button>
                                    </Form.Item>
                                </>
                            )}
                        </Form.List>
                        <div style={{
                            position: 'sticky', bottom: 0,
                            backgroundColor: token.colorBgElevated,
                            zIndex: 1001,
                            paddingBottom: '1px', marginBottom: 0,
                        }}>
                            <Divider style={{ margin: '16px -16px 16px -16px', width: 'inherit' }} />
                            <Form.Item >
                                <Row style={{ width: 'inherit' }} gutter={[16, 16]}>
                                    <Col xs={12} md={12}>
                                        <Button type="primary" htmlType="submit" style={{ width: '100%' }}>
                                            Submit
                                        </Button>
                                    </Col>
                                    <Col xs={12} md={12}>
                                        <Button type="default" onClick={clear} style={{ width: '100%' }}>
                                            Reset Form
                                        </Button>
                                    </Col>
                                </Row>
                            </Form.Item>
                        </div>
                    </Form >
                </Modal>
            </ConfigProvider>
        </>
    );
};

export default MultiExpenseForm;