import React, { useState, useEffect } from 'react';
import { Modal, Form, Typography, Space, Button, Select, Divider, Row, Col, InputNumber, Collapse, Table, Popconfirm, Pagination, DatePicker, message, Menu } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { getDailyLimit, createOrUpdateDailyLimit, deleteDailyLimit, getDailyLimits } from '../../../actions/expenseverseDailyLimitActions';
import dayjs from 'dayjs';
import { SettingOutlined, EditOutlined, PlusOutlined, DeleteOutlined } from '@ant-design/icons';

import { getDefaultSettings, updateDefaultSettings } from '../../../actions/expenseverseDefaultSettingsActions';
import { getExpenseverseAccounts } from '../../../actions/expenseverseAccountActions';
import DevVarDisplay from '../../Other/DevVarDisplay';

const { Title, Paragraph } = Typography;
const { Panel } = Collapse;
const { Option } = Select;

const DefaultSettingsModal = ({ showTags, showAccounts, showDailyLimits, showIconInsteadOfTitle, buttonText }) => {
    const dispatch = useDispatch();
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [isEditModalVisible, setIsEditModalVisible] = useState(false);
    const [currentEditDate, setCurrentEditDate] = useState(null);
    const [isCreateModalVisible, setIsCreateModalVisible] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState(5);
    const dailyLimit = useSelector(state => state.expenseverseDailyLimit.dailyLimit) || {};
    const dailyLimits = useSelector(state => state.expenseverseDailyLimit.dailyLimits) || [];
    const total = useSelector(state => state.expenseverseDailyLimit.total) || 0;

    const [selectedAccountDetails, setSelectedAccountDetails] = useState({
        cash: null,
        bank: null,
        virtual: null,
    });
    const defaultSettings = useSelector(state => state.expenseverseDefaultSettings?.defaultSettings || {});
    const accounts = useSelector(state => state.expenseverseAccounts) || [];

    const [defaultTagsForm] = Form.useForm();
    const [cashAccountForm] = Form.useForm();
    const [bankAccountForm] = Form.useForm();
    const [virtualAccountForm] = Form.useForm();
    const [dailyLimitForm] = Form.useForm();
    const [editForm] = Form.useForm();
    const [createForm] = Form.useForm();

    const [isCashAccountChanged, setIsCashAccountChanged] = useState(false);
    const [isBankAccountChanged, setIsBankAccountChanged] = useState(false);
    const [isVirtualAccountChanged, setIsVirtualAccountChanged] = useState(false);
    const [isDailyLimitChanged, setIsDailyLimitChanged] = useState(false);
    const [areTagsChanged, setAreTagsChanged] = useState(false);
    const [tags, setTags] = useState([]);  // New state for defaultTags

    useEffect(() => {
        if (isModalVisible) {
            dispatch(getDefaultSettings());
            dispatch(getExpenseverseAccounts());
        }
    }, [isModalVisible, dispatch]);

    useEffect(() => {
        if (defaultSettings && Object.keys(defaultSettings).length > 0) {
            defaultTagsForm.setFieldsValue({
                defaultTags: defaultSettings.defaultTags || ''
            });
            cashAccountForm.setFieldsValue({
                defaultCashAccount: defaultSettings.defaultCashAccount || ''
            });
            bankAccountForm.setFieldsValue({
                defaultBankAccount: defaultSettings.defaultBankAccount || ''
            });
            virtualAccountForm.setFieldsValue({
                defaultVirtualAccount: defaultSettings.defaultVirtualAccount || ''
            });
            setSelectedAccountDetails({
                cash: accounts.find(account => account._id === defaultSettings.defaultCashAccount) || null,
                bank: accounts.find(account => account._id === defaultSettings.defaultBankAccount) || null,
                virtual: accounts.find(account => account._id === defaultSettings.defaultVirtualAccount) || null,
            });
        }
    }, [defaultSettings, accounts, cashAccountForm, bankAccountForm, virtualAccountForm]);

    // Handle changes in tags
    const handleTagsChange = (value) => {
        setTags(value);
        setAreTagsChanged(true);
    };

    const handleAccountChange = (accountId, type) => {
        const selectedAccount = accounts.find(account => account._id === accountId);
        setSelectedAccountDetails(prevDetails => ({
            ...prevDetails,
            [type]: selectedAccount || null,
        }));
        if (type === 'cash') {
            cashAccountForm.setFieldsValue({ defaultCashAccount: accountId });
            setIsCashAccountChanged(true);
        } else if (type === 'bank') {
            bankAccountForm.setFieldsValue({ defaultBankAccount: accountId });
            setIsBankAccountChanged(true);
        } else if (type === 'virtual') {
            virtualAccountForm.setFieldsValue({ defaultVirtualAccount: accountId });
            setIsVirtualAccountChanged(true);
        }
    };

    const handleOkDefaultSettings = async (formInstance) => {
        try {
            // Validate the form fields and get the values from the form that was submitted
            const values = await formInstance.validateFields();

            // Dispatch the update action with only the submitted values (no undefined or empty fields)
            await dispatch(updateDefaultSettings(values));

            // message.success('Settings updated successfully');
        } catch (error) {
            message.error('Failed to update settings. Please try again.');
        }

        setIsCashAccountChanged(false);
        setIsBankAccountChanged(false);
        setIsVirtualAccountChanged(false);
        setAreTagsChanged(false);
    };



    const capitalizeFirstLetter = (string) => string.charAt(0).toUpperCase() + string.slice(1);

    const handleCancelDefaultSettings = () => {
        setIsModalVisible(false);
    };

    const renderInstructions = () => {
        if (accounts.length === 0) {
            return (
                <Paragraph type="warning">
                    No accounts available. Please create an account first.
                </Paragraph>
            );
        }

        return (
            <Paragraph>
                Choose your default accounts. This will help prefill the account field when recording new transactions.
            </Paragraph>
        );
    };

    useEffect(() => {
        if (isModalVisible) {
            dispatch(getDailyLimits(currentPage, pageSize));
        }
    }, [currentPage, pageSize, dispatch, isModalVisible]);

    useEffect(() => {
        if (dailyLimit && dailyLimitForm && !isEditModalVisible) { // Ensure the form is only set when not editing
            dailyLimitForm.setFieldsValue({
                dailyLimit: dailyLimit.amount || ''
            });
        }
    }, [dailyLimit, dailyLimitForm, isEditModalVisible]);

    const showModal = () => {
        setIsModalVisible(true);
        dailyLimitForm.resetFields();
        const today = dayjs().format('YYYY-MM-DD');
        dispatch(getDailyLimit(today)).catch(() => message.error('Failed to fetch today\'s daily limit. Please try again.'));
        dispatch(getDailyLimits(currentPage, pageSize)).catch(() => message.error('Failed to fetch the list of daily limits. Please try again.'));

        setIsCashAccountChanged(false);
        setIsBankAccountChanged(false);
        setIsVirtualAccountChanged(false);
        setAreTagsChanged(false);
    };

    const handleOkDailyLimit = async () => {
        try {
            const values = await dailyLimitForm.validateFields();
            const dailyLimitUpdate = {
                date: dayjs().format('YYYY-MM-DD'),
                amount: values.dailyLimit,
            };

            const result = await dispatch(createOrUpdateDailyLimit(dailyLimitUpdate));
            if (result.success) {
                setIsDailyLimitChanged(false);
                message.success('Daily limit set successfully');
                dispatch(getDailyLimits(currentPage, pageSize));
            }
        } catch (error) {
            message.error('Failed to update daily limit. Please try again.');
        }
    };

    const handleCancel = () => {
        setIsModalVisible(false);
    };

    const handleEditOk = async () => {
        try {
            const values = await editForm.validateFields();
            const dailyLimitUpdate = {
                date: currentEditDate,
                amount: values.dailyLimit,
            };

            const result = await dispatch(createOrUpdateDailyLimit(dailyLimitUpdate));
            if (result.success) {
                message.success('Daily limit updated successfully');
                setIsEditModalVisible(false);
                setCurrentEditDate(null);
                dispatch(getDailyLimits(currentPage, pageSize));
                refreshPrimaryModal();
            }
        } catch (error) {
            message.error('Failed to update daily limit. Please try again.');
        }
    };

    const handleEditCancel = () => {
        setIsEditModalVisible(false);
        setCurrentEditDate(null);
    };

    const handleCreateOk = async () => {
        try {
            const values = await createForm.validateFields();
            const dailyLimitUpdate = {
                date: values.date.format('YYYY-MM-DD'),
                amount: values.dailyLimit,
            };

            const result = await dispatch(createOrUpdateDailyLimit(dailyLimitUpdate));
            if (result.success) {
                message.success('Daily limit created successfully');
                setIsCreateModalVisible(false);
                dispatch(getDailyLimits(currentPage, pageSize));
            }
        } catch (error) {
            message.error('Failed to create daily limit. Please try again.');
        }
    };

    const handleCreateCancel = () => {
        setIsCreateModalVisible(false);
    };

    const handleEdit = (record) => {
        setCurrentEditDate(record.date);
        setIsEditModalVisible(true);
        editForm.setFieldsValue({ dailyLimit: record.amount });
    };

    const handleDelete = async (date) => {
        await dispatch(deleteDailyLimit(date));
        dispatch(getDailyLimits(currentPage, pageSize));
        refreshPrimaryModal();
    };

    const refreshPrimaryModal = () => {
        const today = dayjs().format('YYYY-MM-DD');
        dispatch(getDailyLimit(today));
    };


    const renderAccountInstructions = (accountType, defaultAccountField, accountTypeLabel) => {
        const filteredAccounts = accounts.filter(account => account.accountType === accountType);

        if (filteredAccounts.length === 0) {
            return (
                <Paragraph type="warning">
                    No {accountTypeLabel} accounts available. Please create a {accountTypeLabel.toLowerCase()} account first.
                </Paragraph>
            );
        }

        const defaultAccountId = defaultSettings[defaultAccountField];

        if (!defaultAccountId) {
            return (
                <Paragraph type="warning">
                    No default {accountTypeLabel.toLowerCase()} account set. Please choose a default {accountTypeLabel.toLowerCase()} account to help prefill the account field when recording new transactions.
                </Paragraph>
            );
        }

        const defaultAccount = accounts.find(account => account._id === defaultAccountId);

        if (!defaultAccount) {
            return (
                <Paragraph type="warning">
                    The default {accountTypeLabel.toLowerCase()} account set previously is no longer available. Please choose a new default {accountTypeLabel.toLowerCase()} account.
                </Paragraph>
            );
        }

        if (defaultAccount.endDate && dayjs(defaultAccount.endDate).isBefore(dayjs())) {
            return (
                <Paragraph type="warning">
                    The default {accountTypeLabel.toLowerCase()} account set previously is no longer active due to its end date. Please choose a new default {accountTypeLabel.toLowerCase()} account.
                </Paragraph>
            );
        }

        return (
            <Paragraph>
                Choose your default {accountTypeLabel.toLowerCase()} account. This will help prefill the account field when recording new transactions.
            </Paragraph>
        );
    };



    const columns = [
        {
            title: 'Date',
            dataIndex: 'date',
            key: 'date',
            sorter: (a, b) => new Date(a.date) - new Date(b.date),
            defaultSortOrder: 'descend',
            ellipsis: true,
        },
        {
            title: 'Amount',
            dataIndex: 'amount',
            key: 'amount',
        },
        {
            title: 'Actions',
            key: 'actions',
            align: 'right',
            render: (text, record) => (
                <Space>
                    <Button icon={<EditOutlined />} type='text' onClick={() => handleEdit(record)} />
                    <Popconfirm
                        title="Are you sure you want to delete this daily limit?"
                        onConfirm={() => handleDelete(record.date)}
                        okText="Yes"
                        cancelText="No"
                    >
                        <Button danger icon={<DeleteOutlined />} type='text' />
                    </Popconfirm>
                </Space>
            ),
        },
    ];

    return (
        <> {buttonText ? <Menu.Item key="1"
            onClick={showModal}>
            {buttonText}
        </Menu.Item>
            : showIconInsteadOfTitle
                ? <Button
                    // type="primary"
                    icon={<span className="material-symbols-rounded" style={{
                        fontVariationSettings: "'FILL' 1",
                        fontSize: 20, verticalAlign: 'middle', opacity: .7,
                        // color: token.colorTextDescription,
                    }}>{showIconInsteadOfTitle}
                    </span>}
                    onClick={showModal}>
                </Button>
                : <>
                    <Title level={3}>Default Account & Daily Limits</Title>
                    <Space style={{ marginBottom: 16 }}>
                        <Button type="primary" icon={<SettingOutlined />} onClick={showModal}>
                            Settings
                        </Button>
                    </Space>
                </>}
            <Modal
                title="Settings"
                visible={isModalVisible}
                onCancel={handleCancel}
                footer={null}
                destroyOnClose
            >
                {/* <DevVarDisplay title='defaultSettings' value={defaultSettings} />
                <DevVarDisplay title='selectedAccountDetails' value={selectedAccountDetails} />
                <DevVarDisplay title='accounts' value={accounts} /> */}
                {showTags && <>
                    <Typography.Title level={3}>Default Tags</Typography.Title>
                    <Paragraph> Use this field to prefill Tags for each future transaction,
                        this is useful if for example you are going on a trip and you want to makesure
                        that each transaction is marked with tags. For example: 'Norway trip', 'Solo trip'.
                    </Paragraph>
                    <Form form={defaultTagsForm} name='defaultTags' layout="vertical"
                        onValuesChange={() => setAreTagsChanged(true)}
                    >
                        <Row gutter={[16]}>
                            <Col span={16}>
                                <Form.Item name="defaultTags"
                                // label="Default Tags"
                                >
                                    <Select
                                        mode="tags"
                                        placeholder="Select default tags"
                                        onChange={handleTagsChange}
                                        value={tags}
                                        tokenSeparators={[',']}
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item>
                                    <Button block key="submit" type="primary" onClick={() => handleOkDefaultSettings(defaultTagsForm)} disabled={!areTagsChanged}>
                                        Save
                                    </Button>
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form>
                </>}
                {showTags && showAccounts && <Divider />}
                {showAccounts && <>
                    <Typography.Title level={3}>Default Accounts</Typography.Title>
                    {renderInstructions()}

                    {accounts.length > 0 && (
                        <>
                            <Typography.Title level={4}>Cash Account</Typography.Title>
                            {renderAccountInstructions('cash', 'defaultCashAccount', 'Cash')}
                            <Form form={cashAccountForm} name='cashAccountForm' layout="vertical" onValuesChange={() => setIsCashAccountChanged(true)}>
                                <Row gutter={[16]}>
                                    <Col span={16}>
                                        <Form.Item name="defaultCashAccount" rules={[{ required: true, message: 'Please select a default cash account!' }]}>
                                            <Select onChange={(accountId) => handleAccountChange(accountId, 'cash')} allowClear>
                                                {accounts.filter(account => account.accountType === 'cash').map(account => (
                                                    <Option
                                                        key={account._id}
                                                        value={account._id}
                                                        disabled={account.endDate && dayjs(account.endDate).isBefore(dayjs())}
                                                    >
                                                        {account.name} {account.endDate && dayjs(account.endDate).isBefore(dayjs()) ? '(Inactive)' : ''}
                                                    </Option>
                                                ))}
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                    <Col span={8}>
                                        <Form.Item >
                                            <Button block key="submit" type="primary" onClick={() => handleOkDefaultSettings(cashAccountForm)} disabled={!isCashAccountChanged}>
                                                Save
                                            </Button>
                                        </Form.Item>
                                    </Col>
                                </Row>
                                {selectedAccountDetails.cash && (
                                    <div style={{ paddingLeft: 8 }}>
                                        <Typography.Text type='secondary'>Default Payment Method: {selectedAccountDetails.cash.defaultPaymentMethod || 'N/A'}</Typography.Text>
                                        <br />
                                        <Typography.Text type='secondary'>Default Currency: {selectedAccountDetails.cash.defaultCurrency || 'N/A'}</Typography.Text>
                                    </div>
                                )}
                            </Form>
                        </>
                    )}
                    <br />
                    <br />
                    {accounts.length > 0 && (
                        <>
                            <Typography.Title level={4}>Bank Account</Typography.Title>
                            {renderAccountInstructions('bank', 'defaultBankAccount', 'Bank')}
                            <Form form={bankAccountForm} name='bankAccountForm' layout="vertical" onValuesChange={() => setIsBankAccountChanged(true)}>
                                <Row gutter={[16]}>
                                    <Col span={16}>
                                        <Form.Item name="defaultBankAccount" rules={[{ required: true, message: 'Please select a default bank account!' }]}>
                                            <Select onChange={(accountId) => handleAccountChange(accountId, 'bank')} allowClear>
                                                {accounts.filter(account => account.accountType === 'bank').map(account => (
                                                    <Option
                                                        key={account._id}
                                                        value={account._id}
                                                        disabled={account.endDate && dayjs(account.endDate).isBefore(dayjs())}
                                                    >
                                                        {account.name} {account.endDate && dayjs(account.endDate).isBefore(dayjs()) ? '(Inactive)' : ''}
                                                    </Option>
                                                ))}
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                    <Col span={8}>
                                        <Form.Item >
                                            <Button block key="submit" type="primary" onClick={() => handleOkDefaultSettings(bankAccountForm)} disabled={!isBankAccountChanged}>
                                                Save
                                            </Button>
                                        </Form.Item>
                                    </Col>
                                </Row>
                                {selectedAccountDetails.bank && (
                                    <div style={{ paddingLeft: 8 }}>
                                        <Typography.Text type='secondary'>Default Payment Method: {selectedAccountDetails.bank.defaultPaymentMethod || 'N/A'}</Typography.Text>
                                        <br />
                                        <Typography.Text type='secondary'>Default Currency: {selectedAccountDetails.bank.defaultCurrency || 'N/A'}</Typography.Text>
                                    </div>
                                )}
                            </Form>
                        </>
                    )}
                    <br />
                    <br />
                    {accounts.length > 0 && (
                        <>
                            <Typography.Title level={4}>Virtual Account</Typography.Title>
                            {renderAccountInstructions('virtual', 'defaultVirtualAccount', 'Virtual')}
                            <Form form={virtualAccountForm} name='virtualAccountForm' layout="vertical" onValuesChange={() => setIsVirtualAccountChanged(true)}>
                                <Row gutter={[16]}>
                                    <Col span={16}>
                                        <Form.Item name="defaultVirtualAccount" rules={[{ required: true, message: 'Please select a default virtual account!' }]}>
                                            <Select onChange={(accountId) => handleAccountChange(accountId, 'virtual')} allowClear>
                                                {accounts.filter(account => account.accountType === 'virtual').map(account => (
                                                    <Option
                                                        key={account._id}
                                                        value={account._id}
                                                        disabled={account.endDate && dayjs(account.endDate).isBefore(dayjs())}
                                                    >
                                                        {account.name} {account.endDate && dayjs(account.endDate).isBefore(dayjs()) ? '(Inactive)' : ''}
                                                    </Option>
                                                ))}
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                    <Col span={8}>
                                        <Form.Item >
                                            <Button block key="submit" type="primary" onClick={() => handleOkDefaultSettings(virtualAccountForm)} disabled={!isVirtualAccountChanged}>
                                                Save
                                            </Button>
                                        </Form.Item>
                                    </Col>
                                </Row>
                                {selectedAccountDetails.virtual && (
                                    <div style={{ paddingLeft: 8 }}>
                                        <Typography.Text type='secondary'>Default Payment Method: {selectedAccountDetails.virtual.defaultPaymentMethod || 'N/A'}</Typography.Text>
                                        <br />
                                        <Typography.Text type='secondary'>Default Currency: {selectedAccountDetails.virtual.defaultCurrency || 'N/A'}</Typography.Text>
                                    </div>
                                )}
                            </Form>
                        </>
                    )}

                    <br />
                </>}
                {showTags && showDailyLimits || showAccounts && showDailyLimits ? <Divider orientation="left" /> : null}
                {showDailyLimits && <>
                    <Typography.Title level={3}>Daily Limits</Typography.Title>

                    <Form form={dailyLimitForm} name="dailyLimit" layout="vertical" variant='filled' onValuesChange={() => setIsDailyLimitChanged(true)}>
                        <Row gutter={[16]}>
                            <Col>
                                <Form.Item name="dailyLimit" label="Today's Daily Limit"
                                    rules={[
                                        { required: true, message: 'Please enter a daily limit!' },
                                        {
                                            validator: (_, value) => {
                                                if (value > 0) {
                                                    return Promise.reject(new Error('Daily limit must be negative!'));
                                                }
                                                return Promise.resolve();
                                            }
                                        }
                                    ]}                                >
                                    <InputNumber type="number" step="0.01" style={{ width: '100%' }} inputMode='decimal' />
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item label=" ">
                                    <Button block key="submit" type="primary" onClick={handleOkDailyLimit} disabled={!isDailyLimitChanged}>
                                        Save
                                    </Button>
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form>
                    <Collapse bordered={false}>
                        <Panel header="Previous Daily Limits" key="1">
                            <Table
                                columns={columns}
                                dataSource={dailyLimits}
                                pagination={false}
                                rowKey="_id"
                            />
                            <Space style={{ marginTop: '16px', justifyContent: 'space-between', display: 'flex' }}>
                                <Button
                                    type="dashed"
                                    icon={<PlusOutlined />}
                                    onClick={() => setIsCreateModalVisible(true)}
                                >
                                    Add Daily Limit
                                </Button>
                                <Space>
                                    <Select
                                        defaultValue={pageSize}
                                        onChange={(value) => setPageSize(value)}
                                    >
                                        <Option value={5}>5</Option>
                                        <Option value={10}>10</Option>
                                        <Option value={20}>20</Option>
                                    </Select>
                                    <Pagination
                                        current={currentPage}
                                        pageSize={pageSize}
                                        total={total}
                                        onChange={(page) => setCurrentPage(page)}
                                        style={{ textAlign: 'right' }}
                                    />
                                </Space>
                            </Space>
                        </Panel>
                    </Collapse>
                </>}
            </Modal>
            <Modal
                title={`Edit Daily Limit for ${currentEditDate}`}
                visible={isEditModalVisible}
                onOk={handleEditOk}
                onCancel={handleEditCancel}
                footer={[
                    <Button key="back" onClick={handleEditCancel}>Cancel</Button>,
                    <Button key="submit" type="primary" onClick={handleEditOk}>
                        Save
                    </Button>
                ]}
            >
                <Form form={editForm} layout="vertical">
                    <Form.Item name="dailyLimit" label="Daily Limit"
                        // rules={[{ required: true, message: 'Please enter a daily limit!' }]}
                        rules={[
                            { required: true, message: 'Please enter a daily limit!' },
                            {
                                validator: (_, value) => {
                                    if (value > 0) {
                                        return Promise.reject(new Error('Daily limit must be negative!'));
                                    }
                                    return Promise.resolve();
                                }
                            }
                        ]}
                    >
                        <InputNumber type="number" step="0.01" style={{ width: '100%' }} inputMode='decimal' />
                    </Form.Item>
                </Form>
            </Modal>
            <Modal
                title="Create Daily Limit"
                visible={isCreateModalVisible}
                onOk={handleCreateOk}
                onCancel={handleCreateCancel}
                footer={[
                    <Button key="back" onClick={handleCreateCancel}>Cancel</Button>,
                    <Button key="submit" type="primary" onClick={handleCreateOk}>
                        Save
                    </Button>
                ]}
            >
                <Form form={createForm} layout="vertical">
                    <Form.Item name="date" label="Date" rules={[{ required: true, message: 'Please select a date!' }]}>
                        <DatePicker style={{ width: '100%' }} />
                    </Form.Item>
                    <Form.Item name="dailyLimit" label="Daily Limit"
                        // rules={[{ required: true, message: 'Please enter a daily limit!' }]}
                        rules={[
                            { required: true, message: 'Please enter a daily limit!' },
                            {
                                validator: (_, value) => {
                                    if (value > 0) {
                                        return Promise.reject(new Error('Daily limit must be negative!'));
                                    }
                                    return Promise.resolve();
                                }
                            }
                        ]}
                    >
                        <InputNumber type="number" step="0.01" style={{ width: '100%' }} inputMode='decimal' />
                    </Form.Item>
                </Form>
            </Modal>
        </>
    );
};

export default DefaultSettingsModal;