export default (folderCollections = [], action) => {
    switch (action.type) {
        case 'DELETE_FOLDER':
            return folderCollections.filter((folderCollection) => folderCollection._id !== action.payload);
        case 'UPDATE_FOLDER':
            return folderCollections.map((folderCollection) => folderCollection._id === action.payload._id ? action.payload : folderCollection);
        case 'FETCH_ALL_FOLDERS':
            return action.payload;
        case 'CREATE_FOLDER':
            return [...folderCollections, action.payload];
        default:
            return folderCollections;
    }
}