import React, { useState, useEffect } from 'react';
import { Modal, Form, Input, Typography, Tooltip, TreeSelect, ColorPicker, Button, Popover, Space, message, Switch } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';
import IconPicker from './IconPicker'; // Import the custom IconPicker
import { useDispatch } from 'react-redux';
import { updateExpenseverseCategory } from '../../../actions/expenseverseCategoryActions';
import formLayout from '../../Shared UI/formLayout';
import { presetColors, supportedAntDColors } from '../../Shared UI/presetColors';

const { Text } = Typography;

const CategoryModal = ({ visible, onCancel, onOk, category, isGroup, treeData, categories }) => {
    const [form] = Form.useForm();
    const [iconPreview, setIconPreview] = useState('');
    const [iconPickerVisible, setIconPickerVisible] = useState(false);
    const dispatch = useDispatch();

    useEffect(() => {
        if (category) {
            const formValues = {
                name: category.name || '',
                icon: category.icon || '',
                children: category.children || [],
                color: category.color || '',
                isQuickSelect: category.isQuickSelect,
            };
            form.setFieldsValue(formValues);
            setIconPreview(category.icon || '');
        } else {
            form.resetFields();
            setIconPreview('');
        }
    }, [category, form]);

    const handleIconChange = (iconName) => {
        setIconPreview(iconName);
        form.setFieldsValue({ icon: iconName });
        setIconPickerVisible(false);
    };

    const handleIconPickerVisibleChange = (visible) => {
        setIconPickerVisible(visible);
    };

    const handleClearIcon = () => {
        setIconPreview('');
        form.setFieldsValue({ icon: '' });
    };

    const handleClearColor = () => {
        form.setFieldsValue({ color: '' });
    };

    const handleMoveChildrenBetweenGroups = async (inputChildren, currentGroupId) => {
        const matchingGroups = categories.filter(cat => cat.isGroup && cat.children.some(childId => inputChildren.includes(childId) && cat._id !== currentGroupId));
        const groupsToUpdate = {};

        matchingGroups.forEach(group => {
            groupsToUpdate[group._id] = {
                ...group,
                children: group.children.filter(childId => !inputChildren.includes(childId))
            };
        });

        // Update the groups to remove the moved children
        for (const groupId of Object.keys(groupsToUpdate)) {
            const updateResult = await dispatch(updateExpenseverseCategory(groupId, groupsToUpdate[groupId]));
            if (updateResult.status !== 'success') {
                throw new Error(`Failed to update group ${groupId}`);
            }
        }
    };

    const handleSubmit = async (values) => {
        try {
            const inputChildren = values.children || [];
            const currentGroupId = category._id || 'New';
            const matchingGroups = categories.filter(cat => cat.isGroup && cat._id !== currentGroupId && cat.children.some(childId => inputChildren.includes(childId)));

            // Move children between groups if needed
            if (matchingGroups.length > 0) {
                await handleMoveChildrenBetweenGroups(inputChildren, currentGroupId);
            }

            // Convert hex color back to color name if it matches one of the supported AntD colors
            if (values.color) {
                const colorName = Object.keys(supportedAntDColors).find(key => supportedAntDColors[key] === values.color);
                if (colorName) {
                    values.color = colorName;
                }
            }

            // Perform the submit action
            const result = await onOk({ ...values, isGroup });
            if (result.status === 'success') {
                message.success('Saved successfully');
            } else {
                throw new Error(result.message);
            }
        } catch (error) {
            message.error(error.message || 'An unexpected error occurred. Please try again.');
        }
    };

    return (
        <Modal
            title={category && category._id ? (isGroup ? "Edit Group" : "Edit Category") : (isGroup ? "Add Group" : "Add Category")}
            visible={visible}
            onOk={form.submit}
            onCancel={onCancel}
            destroyOnClose
        >
            {isGroup ? (
                <>
                    <Text>Group related categories for a clearer picture in reports or in dropdown selections.</Text>
                    <br />
                    <br />
                </>
            ) : (
                <>
                    <Text>Categorize your transactions by creating categories. They can have a name, icon, and later you can group them if necessary.</Text>
                    <br />
                    <br />
                </>
            )}
            <Form form={form} {...formLayout} onFinish={handleSubmit}>
                <Form.Item
                    name="name"
                    label={isGroup ? "Group Name" : "Category Name"}
                    rules={[{ required: true, message: `Please input the ${isGroup ? 'group' : 'category'} name!` }]}
                >
                    <Input />
                </Form.Item>

                <Form.Item
                    name="icon"
                    label={
                        <span>
                            {isGroup ? "Group Icon" : "Category Icon"}&nbsp;
                            <Tooltip title="Pick the icon from the icon picker below">
                                <InfoCircleOutlined />
                            </Tooltip>
                        </span>
                    }
                    rules={isGroup === false ? [{ required: true, message: 'Please input the category icon!' }] : null}
                >
                    <Input
                        readOnly
                        suffix={
                            <>
                                <Popover
                                    content={<IconPicker value={form.getFieldValue('icon')} onChange={handleIconChange} />}
                                    title="Select Icon"
                                    trigger="click"
                                    visible={iconPickerVisible}
                                    onVisibleChange={handleIconPickerVisibleChange}
                                >
                                    <Button icon={<span className="material-symbols-outlined">apps</span>} />
                                </Popover>
                                <Button type='text' onClick={handleClearIcon}>Clear</Button>
                            </>
                        }
                        prefix={iconPreview && (
                            <span className="material-symbols-rounded" style={{ fontSize: 24, verticalAlign: 'middle', fontVariationSettings: "'FILL' 1" }}
                                onClick={handleIconPickerVisibleChange}>
                                {iconPreview}
                            </span>
                        )}
                        onClick={() => setIconPickerVisible(true)}
                    />
                </Form.Item>

                {isGroup && (
                    <Form.Item
                        name="children"
                        label="Children Categories"
                    >
                        <TreeSelect
                            showSearch
                            style={{ width: '100%' }}
                            dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                            placeholder="Please select"
                            allowClear
                            multiple
                            treeNodeFilterProp="title"
                            value={form.getFieldValue('children')}
                            treeDefaultExpandAll
                            onChange={(value) => form.setFieldsValue({ children: value })}
                            treeData={treeData}
                        />
                    </Form.Item>
                )}
                <Form.Item
                    name="color"
                    label={isGroup ? "Group Color" : "Category Color"}
                    getValueFromEvent={(color) => {
                        return color.toHexString();
                    }}
                >
                    <ColorPicker
                        allowClear
                        format={'hex'}
                        presets={presetColors}
                        onClear={handleClearColor}
                    />
                </Form.Item>
                <Form.Item name="isQuickSelect" valuePropName="checked"
                    label={
                        <span>
                            Quick Select&nbsp;
                            <Tooltip title={<>Handy for categories which are meant to be used frequently. <br />This setting enables category to be listed as quick options. Quick options than allow user to call from them New transaction dialog with prefilled category.</>}>
                                <InfoCircleOutlined />
                            </Tooltip>
                        </span>
                    }>
                    <Switch
                        checkedChildren='enabled'
                        unCheckedChildren="disabled"
                    // defaultChecked={false}
                    />
                </Form.Item>
            </Form >
        </Modal >
    );
};

export default CategoryModal;
