import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Menu, Tree } from 'antd';
import { createDataTree } from '../TestEdge/createDataTreeForBreadcrumbsWithHome';
import { useLocation } from 'react-router-dom';




const NavigationTreeV2 = ({ folderId, navigationType, showHomeAsRoot }) => {
    const folders = useSelector((state) => state.folderCollections);
    const notes = useSelector((state) => state.noteCollections);
    const tabs = useSelector((state) => state.pageTabsCollections);

    let tree = createDataTree(
        { folders, noteCollections: notes, pageTabs: tabs },
        { labelProperty: navigationType === 'tree' ? 'title' : 'label', linkProperty: "path" }, // change labelProperty to "label" for Menu component and to "title" for Tree component
        { parentId: folderId, useIconComponents: true, showHomeAsRoot: showHomeAsRoot ?? true, showFoldersOnly: false, showStats: true, showLostItems: true }
    );



    // *************************************
    // START OF CODE RELATED TO GENERATING OPEN KEYS ARRAY
    // in fact this is duplicate code from BreadcrumbsTest.js component, 
    // in future when you have time extract it to own file and use it in both components 
    // to prevent code duplicate
    // *************************************
    let breadcrumbData = tree; // change to "tree" variable if you do not want to include "Home" 


    function findBreadcrumbNode(node, pathname) {
        if (node["path"] === pathname) {
            return [node];
        }

        for (let child of node.children || []) {
            let result = findBreadcrumbNode(child, pathname);
            if (result) {
                return [node, ...result];
            }
        }

        return null;
    }

    function getCurrentBreadcrumbs(breadcrumbData, pathname) {
        for (let node of breadcrumbData) {
            let result = findBreadcrumbNode(node, pathname);
            if (result) {
                return result;
            }
        }

        return [];
    }

    const location = useLocation();
    var currentBreadcrumbs = getCurrentBreadcrumbs(tree, location.pathname).map((item) => item.key);

    var customKeys = currentBreadcrumbs
    const [openKeys, setOpenKeys] = useState(customKeys);
    const [selectedKeys, setSelectedKeys] = useState([]);

    useEffect(() => {
        setOpenKeys(currentBreadcrumbs);
        setSelectedKeys(currentBreadcrumbs);
    }, [currentBreadcrumbs.length > 0, location]);

    const onOpenChange = keys => {
        setOpenKeys(keys);
    };
    // const onSelect = ({ selectedKeys }) => {
    //     setSelectedKeys(selectedKeys);
    // };

    if (navigationType === 'tree') {
        return <Tree showLine showIcon treeData={tree} mode='inline' />
    } else {
        return <Menu
            items={tree}
            mode='inline'
            openKeys={[...openKeys]}
            defaultOpenKeys={[...openKeys]}
            selectedKeys={[...selectedKeys]}
            onOpenChange={onOpenChange}
        // onSelect={onSelect}
        />
    }

};

export default NavigationTreeV2;