// video tutorial https://www.youtube.com/watch?v=WpIDez53SK4

import React from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { useAuth } from '../../contexts/authContext'
import { doDeleteUser, doSignOut } from '../../firebase/auth'
import { useDispatch, useSelector } from 'react-redux' // import useSelector
import { deleteUserCollections } from '../../actions/deleteUserCollections'
import { Button, Dropdown, Menu, Modal, notification } from 'antd'
import { FolderOutlined, FolderAddOutlined, AppstoreOutlined, MailOutlined, SettingOutlined, HomeOutlined, LeftOutlined, LogoutOutlined, UserOutlined, UserDeleteOutlined } from '@ant-design/icons';
const { SubMenu } = Menu;


const HeaderFirebase = () => {
    const navigate = useNavigate();
    const { userLoggedIn, currentUser } = useAuth();
    const dispatch = useDispatch();
    const noteCollections = useSelector(state => state.noteCollections);
    const folderCollections = useSelector(state => state.folderCollections);
    const pageTabsCollections = useSelector(state => state.pageTabsCollections);
    const [loading, setLoading] = React.useState(false);
    const [logoutLoading, setLogoutLoading] = React.useState(false);

    const firebaseErrorCodes = {
        'auth/requires-recent-login': 'User deletion has failed. You need to log out and log in and then try again.',
    };

    const handleDelete = async () => {
        const result = await dispatch(deleteUserCollections());
        if (result.status === 'success') {
            notification.success({ message: result.message });
            doDeleteUser()
                .then(() => {
                    notification.success({ message: 'User deleted also from authentication system' });
                    navigate('/login');
                    setLoading(false);
                })
                .catch((error) => {
                    const userFriendlyMessage = firebaseErrorCodes[error.code] || 'An unknown error occurred when deleting user from authentication system';
                    notification.error({ message: userFriendlyMessage });
                    setLoading(false);
                });
        } else {
            notification.error({ message: result.message });
            setLoading(false);
        }
    };

    const showDeleteConfirm = async () => {
        const totalItems = noteCollections.length + folderCollections.length + pageTabsCollections.length;
        if (totalItems > 0) {
            Modal.confirm({
                title: `Test Are you sure you want to continue? You will lose ${totalItems} items.`,
                onOk: () => {
                    Modal.confirm({
                        title: 'Delete the user? This action cannot be undone.',
                        onOk: handleDelete,
                        onCancel: () => {
                            notification.warning({ message: 'Operation cancelled by user.' });
                            setLoading(false);
                        },
                    });
                },
                onCancel: () => {
                    notification.warning({ message: 'Operation cancelled by user.' });
                    setLoading(false)
                },
            });
        } else {
            Modal.confirm({
                title: 'Test Delete the user? This action cannot be undone.',
                onOk: handleDelete,
                onCancel: () => {
                    notification.warning({ message: 'Operation cancelled by user.' });
                    setLoading(false);
                },
            });
        }
    };

    const logOutUser = async () => {
        setLogoutLoading(true);
        try {
            await doSignOut();
            notification.success({ message: `User ${currentUser.displayName || currentUser.email} logged out successfully!` });
            navigate('/login');
        } catch (error) {
            notification.error({ message: 'An unknown error has occurred' });
            console.error(error);
        }
        setLogoutLoading(false);
    }

    const deleteUser = async () => {
        setLoading(true);
        await showDeleteConfirm();
        // setLoading(false);
        console.log('loading now:', loading)
    }

    const itemsForMenuComponent = [
        {
            label: `User ${currentUser?.displayName || currentUser?.email} `,
            key: 'user',
            icon: <UserOutlined />,
            children: [
                {
                    label: 'Logout',
                    key: 'logout',
                    icon: <LogoutOutlined />,
                    onClick: logOutUser,
                    danger: true
                },
                {
                    label: 'Delete Account',
                    key: 'delete_account',
                    icon: <UserDeleteOutlined />,
                    onClick: deleteUser
                },
            ]
        },
    ]

    const items = [{
        label: 'Logout',
        key: 'logout',
        icon: <LogoutOutlined />,
        // onClick: logOutUser,
        danger: true
    },
    {
        label: 'Delete Account',
        key: 'deleteaccount',
        icon: <UserDeleteOutlined />,
        // onClick: deleteUser
    }]
    return (
        <nav className='flex flex-row gap-x-2 w-full z-20 fixed top-0 left-0 h-12 border-b place-content-center items-center bg-gray-200'>
            {userLoggedIn ? (
                <>
                    <Menu items={itemsForMenuComponent} />
                    {/* <Dropdown
                        menu={{
                            items,
                        }}
                    >
                        <a>
                            <UserOutlined />  {currentUser?.displayName || currentUser?.email}
                        </a>
                    </Dropdown> */}
                    {/* <Button onClick={async () => {
                        setLogoutLoading(true);
                        try {
                            await doSignOut();
                            notification.success({ message: `User ${currentUser.displayName || currentUser.email} logged out successfully!` });
                            navigate('/login');
                        } catch (error) {
                            notification.error({ message: 'An unknown error has occurred' });
                            console.error(error);
                        }
                        setLogoutLoading(false);
                    }}>
                        Logout
                    </Button> */}
                    {/* {JSON.stringify(currentUser.displayName || currentUser.email)} */}
                    {/* <Button
                        loading={loading}
                        onClick={async () => {
                            setLoading(true);
                            await showDeleteConfirm();
                            // setLoading(false);
                            console.log('loading now:', loading)
                        }}
                    >
                        Delete Account
                    </Button> */}
                </>
            ) : (
                <>
                    <Link className='text-sm text-blue-600 underline' to={'/login'}>
                        Login
                    </Link>
                    <Link className='text-sm text-blue-600 underline' to={'/register'}>
                        Register New Account
                    </Link>
                </>
            )}
        </nav>
    );
};

export default HeaderFirebase