import { Button, Input, Space, message } from "antd";
import { useState } from "react";
import DevVarDisplay from "./DevVarDisplay";
import { CopyOutlined } from "@ant-design/icons";

const CopyInput = ({ copyValue }) => {
  const [finalValue, setFinalValue] = useState(copyValue);

  return (
    <>
      <Space.Compact
        style={{
          width: "100%",
        }}
      >
        <Input
          onChange={(v) => setFinalValue(v.target.value)}
          defaultValue={copyValue}
        />
        <Button
          type="primary"
          icon={<CopyOutlined />}
          onClick={() => {
            message.success("URL Copied!");
            navigator.clipboard.writeText(finalValue);
          }}
        />
      </Space.Compact>
      {/* <DevVarDisplay title={"finalValue"} value={finalValue} /> */}
    </>
  );
};

export default CopyInput;
