import React, { useState } from 'react';
import { List, Card, ConfigProvider, DatePicker, TreeSelect, Col, Row, Collapse, Divider } from 'antd';
import { useSelector } from 'react-redux';
import dayjs from 'dayjs'; // Import Day.js library for date handling
import useOptions from '../../../utils/getOptions';
import DevVarDisplay from '../../Other/DevVarDisplay';
import enUS from 'antd/locale/en_US';
import './ReportComponent_styles.css';
import { Pie } from '@ant-design/plots';
import ReactApexCharts from 'react-apexcharts'
import ReactApexChart from 'react-apexcharts';

const { Panel } = Collapse

const DemoPie = ({ chartData }) => {
    const config = {
        data: [...chartData],
        autoFit: true,
        angleField: 'value',
        colorField: 'type',
        paddingRight: 80,
        innerRadius: 0.6,
        label: {
            text: 'label',
            style: {
                fontWeight: 'bold',
            },
        },
        legend: {
            color: {
                title: false,
                position: 'right',
                rowPadding: 5,
            },
        },
        annotations: [
            {
                type: 'text',
                style: {
                    // text: 'AntV\nCharts',
                    x: '50%',
                    y: '50%',
                    textAlign: 'center',
                    fontSize: 40,
                    fontStyle: 'bold',
                },
            },
        ],
    };

    const configSpecialStyles2 = {
        data: {
            // type: 'fetch',
            value: [...chartData],
        },
        autoFit: true,
        angleField: 'value',
        colorField: 'type',
        legend: false,
        innerRadius: 0.6,
        labels: [
            { text: 'label', style: { fontSize: 10, fontWeight: 'bold' } },
            {
                text: (d, i, data) => (i < data.length - 3 ? d.value : ''),
                style: {
                    fontSize: 9,
                    you: 12,
                },
            },
        ],
        style: {
            stroke: '#fff',
            inset: 1,
            radius: 10,
        },
        scale: {
            color: {
                palette: 'spectral',
                offset: (t) => t * 0.8 + 0.1,
            },
        },
    }


    return <Pie {...config} />;
};

const ReportComponent = () => {
    const allTags = useOptions('expensesCollection', 'tags');
    const allCategories = useOptions('expensesCollection', 'category');
    const transactions = useSelector((state) => state.expensesCollection);
    const [selectedDateRange, setSelectedDateRange] = useState(null);
    const [selectedCategories, setSelectedCategories] = useState([]);

    // Create a dictionary to store category totals
    const categoryTotals = {};
    // var selectedCategories = [
    //     // 'SexToys',
    //     // 'Groceries',
    //     // 'Apples',
    //     // 'Gifts',
    //     // 'Food & Drink',
    // ]
    // Populate selectedCategories with allCategories if it's empty
    const handleEmptySelectedCategory = selectedCategories.length === 0 ? allCategories.map((category) => category.value) : selectedCategories;

    // Filter transactions based on date range
    const filteredTransactions = transactions.filter((transaction) => {
        const transactionDate = dayjs(transaction.transactionDate);
        const isWithinDateRange =
            !selectedDateRange ||
            (transactionDate.isAfter(selectedDateRange[0]) &&
                transactionDate.isBefore(selectedDateRange[1].add(1, 'day'), 'day'));
        return isWithinDateRange;
    });

    // Update category totals for selected categories and "Uncategorized"
    for (const transaction of filteredTransactions) {
        const categories = transaction.category || [];
        const amount = transaction.amount || 0;

        // Update category totals only for selected categories
        for (const category of categories) {
            if (handleEmptySelectedCategory.includes(category)) {
                categoryTotals[category] = (categoryTotals[category] || 0) + amount;
            }
        }
        if (!categories.length && selectedCategories.length === 0) {
            categoryTotals['Uncategorized'] = (categoryTotals['Uncategorized'] || 0) + amount;
        }
    }

    // Print the total amount for "Uncategorized" and the array of uncategorized transactions
    console.log(`Total amount for 'Uncategorized': ${categoryTotals['Uncategorized'] || 0}`);
    console.log(`categoryTotals':}`, categoryTotals);

    // Transform categoryTotals to the data for Pie Chart <- Ant Design Pie chart
    const pieData = Object.keys(categoryTotals).map(key => ({
        type: key,
        value: Math.abs(categoryTotals[key]),  // Ensure values are positive for the pie chart
        label: `${key} \n ${Math.abs(categoryTotals[key])}`,
    }));
    console.log(`pieData':}`, pieData);


    // Transform categoryTotals to the data for React-ApexChart
    // Extract labels and series
    const labels = Object.keys(categoryTotals);
    const series = labels.map(key => Math.abs(categoryTotals[key]));
    // Calculate the total value
    const totalValue = series.reduce((acc, curr) => acc + curr, 0);
    console.log('labels', labels);
    console.log('series', series);
    const chartConfig = {

        series: series,
        options: {
            chart: {
                type: 'donut',
            },
            labels: labels,
            responsive: [{
                breakpoint: 560,
                options: {
                    chart: {
                        width: "100%"
                    },
                    legend: {
                        position: 'bottom'
                    }
                }
            }],
            plotOptions: {
                pie: {
                    donut: {
                        labels: {
                            show: true,
                            total: {
                                show: true,
                                label: 'Total',
                                formatter: () => totalValue.toString(),
                            }
                        }
                    }
                }
            },
            dataLabels: {
                enabled: true,
                formatter: (val, opts) => {
                    return opts.w.config.series[opts.seriesIndex];
                },
                dropShadow: {
                    enabled: false,
                }
            }
        }


    };

    return (
        <div
        // className='test'
        >
            <ConfigProvider
                locale={enUS}
            >
                <Collapse ghost>
                    <Panel header="Monthly Report" key="1" >
                        <Card  >
                            <Row
                                style={{ width: '100%' }}
                            >
                                {/* <DemoPie chartData={pieData} /> */}
                                <ReactApexChart
                                    style={{ width: '100%' }}
                                    className="bar-chart"
                                    options={chartConfig.options}
                                    series={chartConfig.series}
                                    type="donut"
                                // height={220}
                                />
                            </Row>
                            <Row gutter={8}>
                                <Col md={12} xs={12}>
                                    <DatePicker.RangePicker
                                        onChange={(dates) => setSelectedDateRange(dates)}
                                        style={{ marginBottom: 16 }}
                                    />
                                </Col>
                                <Col md={12} xs={12} style={{ marginBottom: 16 }}>
                                    <TreeSelect
                                        style={{
                                            minWidth: 150,
                                        }}
                                        value={selectedCategories}
                                        mode={"multiple"}
                                        onChange={(value) => setSelectedCategories(value)}
                                        maxTagCount={0}
                                        maxTagPlaceholder={(value) => `Categories (${value.length})`}
                                        placeholder="Categories"
                                        treeCheckable="true"
                                        treeData={allCategories}
                                    />
                                </Col>
                            </Row>
                            <Divider style={{ marginTop: 0 }} />
                            <List
                                dataSource={Object.keys(categoryTotals)}
                                renderItem={(category) => (
                                    <List.Item key={category}>
                                        <Col span={12}> Category: {category}</Col>
                                        <Col span={12} style={{ textAlign: 'right' }}>  Total Amount: {categoryTotals[category]}</Col>
                                    </List.Item>
                                )}
                            />
                        </Card>
                    </Panel>
                </Collapse>

            </ConfigProvider>
            <br />
        </div>
    );
};

export default ReportComponent;