import React, { useState } from 'react';
import { Card, Table, Typography, Row, Col, Checkbox } from 'antd';
import DevVarDisplay from '../../../components/Other/DevVarDisplay';

const { Text } = Typography;

const CategorySummaryPanel = ({ groupedCategories, remainingCategories, showFooter = true }) => {
    const [showTotal, setShowTotal] = useState('true');
    const showFooterConfig = showFooter && { footer: () => '' }
    const [separateIncomesOutcomes, setSeparateIncomesOutcomes] = useState(false);  // New state for showing income and outcome separately

    // Calculate total sum
    const totalSum = [
        ...groupedCategories,
        ...remainingCategories
    ].reduce((acc, category) => acc + category.total, 0);

    const columns = [
        {
            title: 'Category',
            dataIndex: 'name',
            key: 'name',
            sorter: (a, b) => a.name.localeCompare(b.name),
        },
        {
            title: 'Total',
            dataIndex: 'total',
            key: 'total',
            render: (total) => (
                <Text type={total >= 0 ? 'success' : 'danger'}>
                    {total >= 0 ? `+${total}` : total}
                </Text>
            ),
            sorter: (a, b) => a.total - b.total,
            defaultSortOrder: 'ascend',
        },
    ];

    // Add Income and Outcome columns dynamically if the user wants to separate them
    if (separateIncomesOutcomes) {
        columns.splice(1, 0,  // Insert after Category column
            {
                title: 'Income',
                dataIndex: 'income',
                key: 'income',
                render: (income) => (
                    <Text type="success">
                        {income > 0 ? `+${income}` : '—'}
                    </Text>
                ),
                sorter: (a, b) => a.income - b.income,
            },
            {
                title: 'Outcome',
                dataIndex: 'outcome',
                key: 'outcome',
                render: (outcome) => (
                    <Text type="danger">
                        {outcome < 0 ? outcome : '—'}
                    </Text>
                ),
                sorter: (a, b) => a.outcome - b.outcome,
            }
        );
    }

    const dataSource = [
        ...groupedCategories.map((group) => ({
            ...group,
            key: group._id,
            children: group.children.map((child) => ({
                ...child,
                key: child._id,
            })),
        })),
        ...remainingCategories.map((cat) => ({
            ...cat,
            key: cat._id,
        })),
    ];

    function removeEmptyChildren(obj) {
        let newObj = { ...obj };

        if (Array.isArray(newObj.children)) {
            newObj.children = newObj.children.map(removeEmptyChildren);

            if (newObj.children.length === 0) {
                delete newObj.children;
            }
        }

        return newObj;
    }

    function processArray(arr) {
        return arr.map(removeEmptyChildren);
    }

    const dataSourceStrippedFromEmptyChildren = processArray(dataSource);

    function roundToTwo(num) {
        return +(Math.round(num + "e+2") + "e-2");
    }
    return (
        <Card title="Categories Report" extra={<>
            <Checkbox
                checked={separateIncomesOutcomes}
                onChange={() => setSeparateIncomesOutcomes(!separateIncomesOutcomes)}
            >
                Separate Incomes and Outcomes
            </Checkbox>
            <Checkbox checked={showTotal} onChange={() => setShowTotal(!showTotal)}>
                Show Total
            </Checkbox>
        </>}>
            {/* <DevVarDisplay value={groupedCategories} /> */}
            {/* <DevVarDisplay value={remainingCategories} /> */}
            {dataSourceStrippedFromEmptyChildren.length > 0 ? (
                <Table
                    scroll={{ y: 300 - 48 - 48 }}
                    columns={columns}
                    dataSource={dataSourceStrippedFromEmptyChildren}
                    expandable={{
                        expandedRowClassName: (record) => 'expanded_row',
                        defaultExpandAllRows: false,
                        expandRowByClick: true,
                    }}
                    rowKey={(record) => record.key}
                    pagination={false}
                    locale={{
                        emptyText: 'No transactions found',
                    }}
                    defaultSortOrder="ascend"
                    {...showFooterConfig}
                    summary={() =>
                        showTotal && (
                            <Table.Summary fixed>
                                <Table.Summary.Row >
                                    <Table.Summary.Cell index={0} colSpan={1}>
                                        <strong>Total:</strong>
                                    </Table.Summary.Cell>
                                    {separateIncomesOutcomes && (
                                        <>
                                            <Table.Summary.Cell index={1}>
                                                <Text type="success">
                                                    {dataSource.reduce((acc, category) => acc + category.income, 0)}
                                                </Text>
                                            </Table.Summary.Cell>
                                            <Table.Summary.Cell index={2}>
                                                <Text type="danger">
                                                    {dataSource.reduce((acc, category) => acc + category.outcome, 0)}
                                                </Text>
                                            </Table.Summary.Cell>
                                        </>
                                    )}
                                    <Table.Summary.Cell index={1} colSpan={1} >
                                        <Text type={totalSum >= 0 ? 'success' : 'danger'}>
                                            {roundToTwo(totalSum >= 0 ? `+${totalSum}` : totalSum)}
                                        </Text>
                                    </Table.Summary.Cell>
                                </Table.Summary.Row>
                            </Table.Summary>
                        )
                    }
                />
            ) : (
                <Typography span={24} style={{ textAlign: 'center' }}>
                    No transactions found nor any categories.
                </Typography>
            )}
        </Card>
    );
};

export default CategorySummaryPanel;
