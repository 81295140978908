import React from 'react';
import { Typography, Popover } from 'antd';
import { QuestionCircleOutlined } from '@ant-design/icons';

const { Paragraph, Title } = Typography;

const CategoriesInfo = () => {

    return (
        <Popover content={
            <div style={{
                maxWidth: 500,
                overflow: 'auto',
                // maxHeight: 300,
            }}>
                <Title level={4}>Categories</Title>
                <Paragraph>Categories allow you to label and organize your transactions based on their nature or purpose. For instance, you can create categories like "Food", "Transport", "Utilities", "Entertainment", etc. Each transaction can be assigned one category, helping you see where your money is going. </Paragraph>
                <Paragraph>If there is need for more labels to single transaction please consider using Tags, each transaction can be assigned with multiple tags and they can be later used for filtrations or deeper analyzing.</Paragraph>
                <Paragraph>Set your favourite color or icon to it.</Paragraph>


                <Title level={4}>Groups</Title>
                <Paragraph>Groups are used to organize related categories under a single umbrella.</Paragraph>
                <Paragraph>For example, you might have a group called "Living Expenses" that includes categories like "Rent", "Utilities", and "Groceries". This hierarchical structure helps in maintaining a clean and organized view of your categories.</Paragraph>
            </div>
        }>
            <QuestionCircleOutlined />
        </Popover>
    );
};

export default CategoriesInfo;