import { message } from 'antd';
import * as api from '../api/index';
import { getAuth, getIdToken } from "firebase/auth";

// Action creators
export const getExpenseverseProducts = () => async (dispatch) => {
    try {
        const auth = getAuth();
        const token = await getIdToken(auth.currentUser);
        const { data } = await api.fetchExpenseverseProducts(token);

        dispatch({ type: 'FETCH_ALL_EXPENSEVERSE_PRODUCTS', payload: data });
    } catch (error) {
        console.log(error.message);
    }
};

export const createExpenseverseProduct = (productData) => async (dispatch) => {
    try {
        const auth = getAuth();
        const token = await getIdToken(auth.currentUser);
        const { data } = await api.createExpenseverseProduct(productData, token);

        dispatch({ type: 'CREATE_EXPENSEVERSE_PRODUCT', payload: data });
        message.success('Product created successfully');
        return { success: true };
    } catch (error) {
        if (error.response && error.response.status === 409) {
            message.error(error.response.data.message);
        } else {
            message.error('An error occurred while creating the product. Please try again.');
        }
        return { success: false };
    }
};

export const updateExpenseverseProduct = (id, productData) => async (dispatch) => {
    try {
        const auth = getAuth();
        const token = await getIdToken(auth.currentUser);
        const { data } = await api.updateExpenseverseProduct(id, productData, token);

        dispatch({ type: 'UPDATE_EXPENSEVERSE_PRODUCT', payload: data });
        message.success('Product updated successfully');
        return { success: true };
    } catch (error) {
        if (error.response && error.response.status === 409) {
            message.error(error.response.data.message);
        } else {
            message.error('An error occurred while updating the product. Please try again.');
        }
        return { success: false };
    }
};

export const deleteExpenseverseProduct = (id) => async (dispatch) => {
    try {
        const auth = getAuth();
        const token = await getIdToken(auth.currentUser);
        await api.deleteExpenseverseProduct(id, token);

        dispatch({ type: 'DELETE_EXPENSEVERSE_PRODUCT', payload: id });
        message.success('Product deleted successfully');
    } catch (error) {
        message.error('An error occurred while deleting the product. Please try again.');
    }
};