import React, { useEffect, useState } from 'react';
import Chart from 'react-apexcharts';
import dayjs from 'dayjs';
import 'dayjs/locale/en';

// Set dayjs locale to English
dayjs.locale('en');

const TransactionChartWCategories = ({ transactions, defaultBudget, chartType = 'column' }) => {
    const [chartData, setChartData] = useState({ categories: [], series: [] });
    const [maxValue, setMaxValue] = useState(0);
    const [minValue, setMinValue] = useState(0);
    const [daysToShow, setDaysToShow] = useState(7);
    const [startDateOffset, setStartDateOffset] = useState(0);

    useEffect(() => {
        // Ensure locale is set to English
        dayjs.locale('en');

        // Get the last N days starting from a specific offset
        const getLastNDays = (n, offset) => {
            const days = [];
            for (let i = n - 1 + offset; i >= offset; i--) {
                days.push(dayjs().subtract(i, 'day').format('YYYY-MM-DD'));
            }
            return days;
        };

        const lastNDays = getLastNDays(daysToShow, startDateOffset);

        // Filter transactions to include only those within the last N days
        const filteredTransactions = transactions.filter(transaction => {
            const transactionDate = dayjs(transaction.transactionDate).format('YYYY-MM-DD');
            return lastNDays.includes(transactionDate);
        });

        // Process transactions for regular column chart (totals per day)
        const processTransactionsForColumn = (transactions) => {
            const result = {};

            // Aggregate transactions by date
            transactions.forEach(transaction => {
                const date = dayjs(transaction.transactionDate).format('YYYY-MM-DD');
                if (!result[date]) {
                    result[date] = 0;
                }
                result[date] += transaction.amount;
            });

            return result;
        };

        // Process transactions for stacked column chart (totals per day and category)
        const processTransactionsForColumnStack = (transactions) => {
            const result = {};
            const categoriesSet = new Set();

            transactions.forEach(transaction => {
                const date = dayjs(transaction.transactionDate).format('YYYY-MM-DD');
                const category = transaction.category.length > 0 ? transaction.category[0] : 'Uncategorized';
                categoriesSet.add(category);

                if (!result[date]) {
                    result[date] = {};
                }
                if (!result[date][category]) {
                    result[date][category] = 0;
                }
                result[date][category] += transaction.amount;
            });

            return { result, categories: Array.from(categoriesSet) };
        };

        // Prepare the chart data for regular column chart
        const prepareChartDataForColumn = (aggregatedData, lastNDays) => {
            const categories = lastNDays.map(day => `${dayjs(day).format('dd')}\n ${dayjs(day).format('D.M.')}`);
            const seriesData = lastNDays.map(day => aggregatedData[day] || 0);

            return {
                categories,
                series: [{
                    name: 'Transactions',
                    data: seriesData
                }]
            };
        };

        // Prepare the chart data for stacked column chart
        const prepareChartDataForColumnStack = (aggregatedData, categoriesList, lastNDays) => {
            const series = categoriesList.map(category => {
                return {
                    name: category,
                    data: lastNDays.map(day => aggregatedData[day] && aggregatedData[day][category] ? aggregatedData[day][category] : 0)
                };
            });

            const categories = lastNDays.map(day => `${dayjs(day).format('dd')}\n ${dayjs(day).format('D.M.')}`);

            return {
                categories,
                series
            };
        };

        let chartData;
        if (chartType === 'columnStack') {
            const { result: aggregatedData, categories: categoriesList } = processTransactionsForColumnStack(filteredTransactions);
            chartData = prepareChartDataForColumnStack(aggregatedData, categoriesList, lastNDays);
        } else {
            const aggregatedData = processTransactionsForColumn(filteredTransactions);
            chartData = prepareChartDataForColumn(aggregatedData, lastNDays);
        }

        // Manually calculate the maximum and minimum values for the y-axis
        const seriesValues = chartData.series.flatMap(s => s.data);
        const totalMaxValues = lastNDays.map(day => chartData.series.reduce((sum, s) => sum + (s.data[lastNDays.indexOf(day)] || 0), 0));
        const totalMinValues = lastNDays.map(day => chartData.series.reduce((sum, s) => sum + (s.data[lastNDays.indexOf(day)] || 0), 0));

        const maxSeriesValue = Math.max(...totalMaxValues, defaultBudget === "Nevyplneny" ? 0 : defaultBudget);
        const minSeriesValue = Math.min(...totalMinValues);

        setMaxValue(maxSeriesValue);
        setMinValue(minSeriesValue);

        setChartData(chartData);
    }, [transactions, defaultBudget, daysToShow, startDateOffset, chartType]);

    const options = {
        chart: {
            type: 'bar',
            stacked: chartType === 'columnStack' // Enable stacked columns if chartType is 'columnStack'
        },
        xaxis: {
            categories: chartData.categories
        },
        title: {
            text: 'Transactions in the Last 7 Days'
        },
        annotations: {
            yaxis: [
                {
                    y: maxValue,
                    borderColor: '#FF4560',
                    label: {
                        borderColor: '#FF4560',
                        style: {
                            color: '#fff',
                            background: '#FF4560'
                        },
                        text: `Budget: ${maxValue}`
                    }
                }
            ]
        },
        yaxis: {
            min: minValue, // Set the minimum value for the y-axis
            max: maxValue // Set the maximum value for the y-axis
        },
        plotOptions: {
            bar: {
                horizontal: false,
                endingShape: "rounded",
                borderRadius: 20,
                borderRadiusApplication: "end", // "around" / "end" 
                borderRadiusWhenStacked: "last", // "all"/"last"  
                columnWidth: '70%', // Adjust this value as needed to prevent squeezing
                distributed: false
            },
        },
        dataLabels: {
            enabled: true,
            offsetY: -20,
            style: {
                fontSize: '12px',
                colors: ['rgba(123,123,123,1)']
            }
        },
        legend: {
            position: 'top'
        },
        tooltip: {
            shared: true,
            intersect: false
        }
    };

    // Determine if there are newer dates available
    const isNewerDatesAvailable = startDateOffset > 0;

    return (
        <div>
            <div style={{ marginBottom: '10px' }}>
                <button onClick={() => setStartDateOffset(startDateOffset + 7)}>Show Older Dates</button>
                <button onClick={() => isNewerDatesAvailable && setStartDateOffset(startDateOffset - 7)} disabled={!isNewerDatesAvailable}>Show Newer Dates</button>
            </div>
            <Chart options={options} series={chartData.series} type="bar" height={350} />
        </div>
    );
};

export default TransactionChartWCategories;
