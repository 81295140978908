import React, { useMemo } from 'react';
import { Card, List, Tag, Space } from 'antd';
import { CloseOutlined } from '@ant-design/icons';

const TagsSummaryPanel = ({
    expenses,
    selectedTags,
    onTagClick,
    layout = 'list',
    showTransactionTags = true,
    showTransactionTypes = true,
    showTransactionCategories = true, // New prop to show categories
    categories // Categories from the store
}) => {

    // Memoize tags count for better performance
    const tagsCount = useMemo(() => {
        const tagsMap = {};

        expenses.forEach(expense => {
            const tags = expense.tags || [];
            tags.forEach(tag => {
                if (tagsMap[tag]) {
                    tagsMap[tag] += 1;
                } else {
                    tagsMap[tag] = 1;
                }
            });
        });

        return Object.entries(tagsMap)
            .map(([tag, count]) => ({ tag, count }))
            .sort((a, b) => b.count - a.count); // Sort by count in descending order
    }, [expenses]);

    // Memoize transaction types (Incoming, Outgoing)
    const transactionTypes = useMemo(() => {
        const hasIncoming = expenses.some(expense => expense.amount > 0);
        const hasOutgoing = expenses.some(expense => expense.amount < 0);

        return [
            hasIncoming && { tag: 'Incoming', color: selectedTags.includes('Incoming') ? '#52c41a' : 'green' },
            hasOutgoing && { tag: 'Outgoing', color: selectedTags.includes('Outgoing') ? '#ff4d4f' : 'red' }
        ].filter(Boolean); // Filter out null values
    }, [expenses, selectedTags]);

    // Memoize categories
    const categoryTags = useMemo(() => {
        if (!showTransactionCategories || !categories) return [];

        const usedCategoryIds = new Set(expenses.flatMap(expense => expense.category));

        return categories
            .filter(category => usedCategoryIds.has(category._id))
            .map(category => ({
                tag: category.name,
                color: selectedTags.includes(category.name) ? 'grey' : undefined // Default color unless selected
            }));
    }, [categories, expenses, showTransactionCategories, selectedTags]);

    // Add Draft and Reviewed Tags based on `reviewed` field
    const reviewStatusTags = useMemo(() => {
        const hasDrafts = expenses.some(expense => expense.reviewed === false);
        const hasReviewed = expenses.some(expense => expense.reviewed === true);

        return [
            hasDrafts && { tag: 'Drafts', color: selectedTags.includes('Drafts') ? '#FFD700' : 'yellow' }, // Yellow for Drafts
            hasReviewed && { tag: 'Reviewed', color: selectedTags.includes('Reviewed') ? '#FFD700' : 'yellow' }, // Yellow for Reviewed
        ].filter(Boolean); // Filter out null values
    }, [expenses, selectedTags]);

    // Combine all tags together
    const allTags = [
        ... (showTransactionTypes ? transactionTypes : []),
        ... (showTransactionCategories ? categoryTags : []),
        ... (showTransactionTags ? tagsCount : []),
        ...reviewStatusTags // Add Drafts and Reviewed tags
    ];

    return (
        layout === 'inline' ? (
            <Space wrap>
                {allTags.map(item => (
                    <Tag
                        key={item.tag}
                        color={item.color || (categoryTags.some(cat => cat.tag === item.tag)
                            ? (selectedTags.includes(item.tag) ? 'grey' : undefined) // Grey for selected categories, default for unselected
                            : (selectedTags.includes(item.tag) ? '#0958d9' : 'blue') // Blue for tags
                        )}
                        onClick={() => onTagClick(item.tag)}
                        style={{ cursor: 'pointer' }}
                    >
                        {item.tag} {item.count ? `(${item.count})` : ''}
                        {selectedTags.includes(item.tag) && <CloseOutlined style={{ marginLeft: 5 }} />}
                    </Tag>
                ))}
            </Space>
        ) : layout === 'inlineList' ? (
            <Space direction="vertical">
                <Space wrap>
                    {transactionTypes.map(item => (
                        <Tag
                            key={item.tag}
                            color={item.color}
                            onClick={() => onTagClick(item.tag)}
                            style={{ cursor: 'pointer' }}
                        >
                            {item.tag}
                            {selectedTags.includes(item.tag) && <CloseOutlined style={{ marginLeft: 5 }} />}
                        </Tag>
                    ))}
                </Space>
                <Space wrap>
                    {tagsCount.map(item => (
                        <Tag
                            key={item.tag}
                            color={selectedTags.includes(item.tag) ? '#0958d9' : 'blue'}
                            onClick={() => onTagClick(item.tag)}
                            style={{ cursor: 'pointer' }}
                        >
                            {item.tag} ({item.count})
                            {selectedTags.includes(item.tag) && <CloseOutlined style={{ marginLeft: 5 }} />}
                        </Tag>
                    ))}
                </Space>
                <Space wrap>
                    {categoryTags.map(item => (
                        <Tag
                            key={item.tag}
                            color={selectedTags.includes(item.tag) ? 'grey' : undefined}
                            onClick={() => onTagClick(item.tag)}
                            style={{ cursor: 'pointer' }}
                        >
                            {item.tag}
                            {selectedTags.includes(item.tag) && <CloseOutlined style={{ marginLeft: 5 }} />}
                        </Tag>
                    ))}
                </Space>
                <Space wrap>
                    {reviewStatusTags.map(item => (
                        <Tag
                            key={item.tag}
                            color={item.color}
                            onClick={() => onTagClick(item.tag)}
                            style={{ cursor: 'pointer' }}
                        >
                            {item.tag}
                            {selectedTags.includes(item.tag) && <CloseOutlined style={{ marginLeft: 5 }} />}
                        </Tag>
                    ))}
                </Space>
            </Space>
        ) : (
            <List
                itemLayout="horizontal"
                dataSource={allTags}
                renderItem={item => (
                    <List.Item>
                        <List.Item.Meta
                            title={
                                <Tag
                                    color={item.color || (categoryTags.some(cat => cat.tag === item.tag)
                                        ? (selectedTags.includes(item.tag) ? 'grey' : undefined) // Grey for selected categories, default for unselected
                                        : (selectedTags.includes(item.tag) ? '#0958d9' : 'blue') // Blue for tags
                                    )}
                                    onClick={() => onTagClick(item.tag)}
                                    style={{ cursor: 'pointer' }}
                                >
                                    {item.tag}
                                </Tag>
                            }
                            description={item.count ? `Used in ${item.count} transactions` : ''}
                        />
                    </List.Item>
                )}
            />
        )
    );
};

export default TagsSummaryPanel;
