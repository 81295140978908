import * as api from '../api/index';
import { getAuth, getIdToken } from 'firebase/auth';

// action creators
export const getExpenseverseCategories = () => async (dispatch) => {
    try {
        const auth = getAuth();
        const token = await getIdToken(auth.currentUser);
        const { data } = await api.fetchExpenseverseCategories(token);

        dispatch({ type: 'FETCH_ALL_EXPENSEVERSE_CATEGORIES', payload: data });
    } catch (error) {
        console.log(error.message);
    }
};

export const createExpenseverseCategory = (category) => async (dispatch) => {
    try {
        const auth = getAuth();
        const token = await getIdToken(auth.currentUser);
        const { data } = await api.createExpenseverseCategory(category, token);

        dispatch({ type: 'CREATE_EXPENSEVERSE_CATEGORY', payload: data });
        return { status: 'success', data }; // Ensure the data is returned
    } catch (error) {
        const message = error.response?.data?.message || 'An error occurred';
        throw new Error(message);
    }
};

export const updateExpenseverseCategory = (id, category) => async (dispatch) => {
    try {
        const auth = getAuth();
        const token = await getIdToken(auth.currentUser);
        const { data } = await api.updateExpenseverseCategory(id, category, token);

        dispatch({ type: 'UPDATE_EXPENSEVERSE_CATEGORY', payload: data });
        return { status: 'success' };
    } catch (error) {
        const message = error.response?.data?.message || 'An error occurred';
        throw new Error(message);
    }
};

export const deleteExpenseverseCategory = (id) => async (dispatch) => {
    try {
        const auth = getAuth();
        const token = await getIdToken(auth.currentUser);
        await api.deleteExpenseverseCategory(id, token);

        dispatch({ type: 'DELETE_EXPENSEVERSE_CATEGORY', payload: id });
        return { status: 'success' };
    } catch (error) {
        console.log(error);
        return { status: 'error', message: error.message };
    }
};
