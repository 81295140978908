import React, { useEffect, useRef, useState } from "react";
import moment from "moment";
import { useDispatch } from "react-redux";
import { deleteNoteCollection } from "../../../../actions/noteCollections";
import MyEditor from "../../../MyEditor/MyEditor";
import parse from "html-react-parser";
import Form from "../../NoteForm/NoteForm";
import {
    Card, Button, Typography, Divider, Row, Col, theme, Dropdown, Input, Space,
} from "antd";
import {
    DownOutlined, DeleteOutlined, FormOutlined, MoreOutlined, CopyOutlined, ArrowRightOutlined, EditFilled
} from "@ant-design/icons";
import "./styles.css";
import CopyInput from "../../../Other/CopyInput";
import Prism from "prismjs";
const { Text } = Typography;

const Note = ({ noteCollection, setCurrentId, currentId, darkMode, tabID, treeTabs, distinguishBackground, requestedNoteId, noteRef, showVisitFolderLink }) => {
    const dispatch = useDispatch();
    const [showNoEditor, setShowNoEditor] = useState(false);
    const editorRef = useRef(null);
    const { useToken } = theme;
    const { token } = useToken(); // get the token
    const tabId = tabID ? `/${tabID}/` : ''; // unfortunatelly bad workaround ...check line bellow
    // to store tabID as tabId because if I was overritting variable tabID than in note form the tab was not preffilled correctly

    const handleshowNoEditor = (v) => {
        setShowNoEditor((v) => !v);
        console.log("v is now: " + showNoEditor);
    };

    const deleteNote = (id) => {
        if (window.confirm("Delete the item?")) {
            dispatch(deleteNoteCollection(noteCollection._id));
        }
    };

    /* dropdown related code */
    const [menuVisible, setMenuVisible] = useState(false);
    const handleMenuClick = (e) => {
        // Handle menu item clicks here
        if (e.key != 7) {
            setMenuVisible(false); // Close the menu when Button is clicked
        }
        // You can add more conditions for other menu items if needed
    };

    // Get the domain of the current URL
    const domain = window.location.origin;
    const items = [
        showVisitFolderLink ? {
            key: "0",
            label: (
                <a danger type="text" href={`/folders/${noteCollection.parents[0]}${tabId}?noteId=${noteCollection._id}`}>
                    {" "}
                    Open in Folder
                </a>
            ),
            icon: <ArrowRightOutlined />,
            primary: true,
        } : null,
        {
            key: "1",
            label: (
                <a danger type="text" onClick={handleshowNoEditor}>
                    {" "}
                    Edit
                </a>
            ),
            icon: <FormOutlined />,
            primary: true,
        },
        {
            key: "2",
            label: (
                <a danger type="text" onClick={() => deleteNote(noteCollection._id)}>
                    {" "}
                    Delete
                </a>
            ),
            icon: <DeleteOutlined />,
            danger: true,
        },
        {
            type: "divider",
        },
        {
            key: "6",
            label: (
                <>
                    <a
                        danger
                        type="text"
                        onClick={() => {
                            navigator.clipboard.writeText(
                                `${domain}/folders/${noteCollection.parents[0]}${tabId}?noteId=${noteCollection._id}`
                            );
                        }}
                    >
                        {" "}
                        Copy Note URL to Clipboard
                    </a>
                </>
            ),
            icon: <CopyOutlined />,
            primary: true,
        },
        {
            key: "7",
            label: (
                <>
                    <Text type="secondary">You can edit this URL</Text>
                    <CopyInput
                        copyValue={`${domain}/folders/${noteCollection.parents[0]}${tabId}?noteId=${noteCollection._id}`}
                    />
                </>
            ),
        },
        {
            type: "divider",
        },
        noteCollection.message
            ? {
                key: "3",
                label: (
                    <Typography.Text
                        id={`${noteCollection.message.replace(/ /g, "-")}`}
                        type="secondary"
                        className={"classes.colorGrey"}
                    >
                        note title: {noteCollection.message}
                    </Typography.Text>
                ),
                // icon: <SmileOutlined />,
                disabled: true,
            }
            : null,
        noteCollection.tags[0]?.length > 0
            ? {
                key: "4",
                label: (
                    <Typography.Text variant="body2" type="secondary">
                        tags: {noteCollection.tags.map((tag) => `#${tag} `)}
                    </Typography.Text>
                ),
                disabled: true,
            }
            : null,
        {
            key: "5",
            label: (
                <Typography.Text type="secondary" variant="body2">
                    Created: {moment(noteCollection.createdAt).fromNow()}
                </Typography.Text>
            ),
            disabled: true,
        },
    ];

    // apply Prism Highlights only to Notes visible in Viewport using observer
    //
    // Trigger Prism Highlighting with observer to avoid performance issues, 
    // because when you were simply applying Prism to all elements on page 
    // than Home.js with cca 80 x notes were loading for 5-15 seconds
    const noteContentRef = useRef(null);

    useEffect(() => {
        const observer = new IntersectionObserver((entries) => {
            entries.forEach((entry) => {
                if (entry.isIntersecting) {
                    Prism.highlightAll();
                }
            });
        });

        if (noteContentRef.current) {
            observer.observe(noteContentRef.current);
        }

        return () => {
            if (noteContentRef.current) {
                observer.unobserve(noteContentRef.current);
            }
        };
    }, []);


    return (
        <>
            <div
                style={{
                    // textAlign: 'right',
                    top: 60,
                    position: "sticky",
                    zIndex: 10,
                }}
            >
                <div style={{
                    position: 'absolute',
                    right: 0,
                    top: 8,
                }}>
                    <Dropdown
                        menu={{
                            items,
                            onClick: handleMenuClick,
                        }}
                        onOpenChange={(flag) => setMenuVisible(flag)}
                        open={menuVisible}
                    >
                        <Button style={{ borderRight: 'none', borderRadius: '8px 0px 0px 8px', marginRight: 1 }} onClick={(e) => e.preventDefault()}>
                            <EditFilled />
                            <DownOutlined />
                        </Button>
                    </Dropdown>
                </div>
            </div>
            <Card
                ref={noteCollection._id === requestedNoteId ? noteRef : null}
                style={{
                    background: distinguishBackground
                        ? token.colorBgLayout
                        : token.colorBg,
                }}
            >
                <div className="noteStyles" style={{ float: "left", width: "100%" }}>
                    {showNoEditor ? (
                        <Form
                            darkMode={darkMode}
                            currentId={noteCollection._id}
                            setCurrentId={setCurrentId}
                            showNoEditor={showNoEditor}
                            setShowNoEditor={setShowNoEditor}
                            tabID={tabID}
                            treeTabs={treeTabs}
                        />
                    ) : (
                        <>

                            {/* this div bellow should get Highlighted */}
                            {/* {parse(noteCollection.messageNote)} */}
                            <div ref={noteContentRef}>{parse(noteCollection.messageNote)}</div>
                        </>
                    )}
                </div>
            </Card>
        </>
    );
};

export default Note;
