import * as api from '../api/index';
import { message } from 'antd';
import { getAuth, getIdToken } from 'firebase/auth';

export const getDefaultSettings = () => async (dispatch) => {
    try {
        const auth = getAuth();
        const token = await getIdToken(auth.currentUser);
        const { data } = await api.fetchDefaultSettings(token);

        dispatch({ type: 'FETCH_DEFAULT_SETTINGS', payload: data });
    } catch (error) {
        console.error(error.message);
        message.error('Failed to fetch default settings. Please try again.');
    }
};

export const updateDefaultSettings = (defaultSettingsData) => async (dispatch) => {
    try {
        const auth = getAuth();
        const token = await getIdToken(auth.currentUser);
        const { data } = await api.updateDefaultSettings(defaultSettingsData, token);

        dispatch({ type: 'UPDATE_DEFAULT_SETTINGS', payload: data });
        message.success('Default settings updated successfully');
    } catch (error) {
        console.error(error.message);
        message.error('Failed to update default settings. Please try again.');
    }
};