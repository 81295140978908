import { message } from 'antd';
import * as api from '../api/index';
import { getAuth, getIdToken } from "firebase/auth";

// Action creators
export const getExpenseverseAccounts = () => async (dispatch) => {
    try {
        const auth = getAuth();
        const token = await getIdToken(auth.currentUser);
        const { data } = await api.fetchExpenseverseAccounts(token);

        dispatch({ type: 'FETCH_ALL_EXPENSEVERSE_ACCOUNTS', payload: data });
    } catch (error) {
        console.log(error.message);
    }
};

export const createExpenseverseAccount = (accountData) => async (dispatch) => {
    try {
        const auth = getAuth();
        const token = await getIdToken(auth.currentUser);
        const { data } = await api.createExpenseverseAccount(accountData, token);

        dispatch({ type: 'CREATE_EXPENSEVERSE_ACCOUNT', payload: data });
        message.success('Account created successfully');
        return { success: true };
    } catch (error) {
        if (error.response && error.response.status === 409) {
            message.error(error.response.data.message);
        } else {
            message.error('An error occurred while creating the account. Please try again.');
        }
        return { success: false };
    }
};

export const updateExpenseverseAccount = (id, accountData) => async (dispatch) => {
    try {
        const auth = getAuth();
        const token = await getIdToken(auth.currentUser);
        const { data } = await api.updateExpenseverseAccount(id, accountData, token);

        dispatch({ type: 'UPDATE_EXPENSEVERSE_ACCOUNT', payload: data });
        message.success('Account updated successfully');
        return { success: true };
    } catch (error) {
        if (error.response && error.response.status === 409) {
            message.error(error.response.data.message);
        } else {
            message.error('An error occurred while updating the account. Please try again.');
        }
        return { success: false };
    }
};

export const deleteExpenseverseAccount = (id) => async (dispatch) => {
    try {
        const auth = getAuth();
        const token = await getIdToken(auth.currentUser);
        await api.deleteExpenseverseAccount(id, token);

        dispatch({ type: 'DELETE_EXPENSEVERSE_ACCOUNT', payload: id });
        message.success('Account deleted successfully');
    } catch (error) {
        message.error('An error occurred while deleting the account. Please try again.');
    }
};