import React from 'react';
import { Card, Row, Col, Button } from 'antd';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import DateRangePicker from './DateRangePicker';
import dayjs from 'dayjs';
dayjs.locale('en');

const DateRangeHeader = ({
    dateRangeType,
    dateRange,
    setDateRange,
    setDateRangeType,
    navigate,
    locale,
    hideDatePicker,
    hideArrows,
    dispatch,
    getExpenseDataByDateRange,
    hideDatePickersPanel,
    hideDatePickersInputField,
    onRangePickerChange,
    onDayPickerChange,
    onWeekPickerChange,
    onMonthPickerChange,
    onQuarterPickerChange,
    onYearPickerChange,

    noCardBackground,
    noCardTitle,
}) => {
    const propNoBackgroundStyles = noCardBackground && { style: { background: 'none', border: 'none' } };
    const propNoTitle = !noCardTitle && { title: "Selection" }

    const navigateToPrevious = () => {
        let start, end, urlParams;

        switch (dateRangeType) {
            case 'Day':
                start = dateRange[0].subtract(1, 'day').startOf('day');
                end = start.endOf('day');
                urlParams = `day=${start.format('YYYY-MM-DD')}`;
                break;
            case 'Week':
                start = dateRange[0].subtract(1, 'week').startOf('week');
                end = start.endOf('week');
                urlParams = `year=${start.format('YYYY')}&week=${start.week()}`;
                break;
            case 'Month':
                start = dateRange[0].subtract(1, 'month').startOf('month');
                end = start.endOf('month');
                urlParams = `year=${start.format('YYYY')}&month=${start.format('MM')}`;
                break;
            case 'Quarter':
                start = dateRange[0].subtract(1, 'quarter').startOf('quarter');
                end = start.endOf('quarter');
                urlParams = `year=${start.format('YYYY')}&quarter=${start.quarter()}`;
                break;
            case 'Year':
                start = dateRange[0].subtract(1, 'year').startOf('year');
                end = start.endOf('year');
                urlParams = `year=${start.format('YYYY')}`;
                break;
            default:
                return;
        }

        setDateRange([start, end]);
        navigate(`?${urlParams}`);
        dispatch(getExpenseDataByDateRange(start.toISOString(), end.toISOString()));
    };

    const navigateToNext = () => {
        let start, end, urlParams;

        switch (dateRangeType) {
            case 'Day':
                start = dateRange[0].add(1, 'day').startOf('day');
                end = start.endOf('day');
                urlParams = `day=${start.format('YYYY-MM-DD')}`;
                break;
            case 'Week':
                start = dateRange[0].add(1, 'week').startOf('week');
                end = start.endOf('week');
                urlParams = `year=${start.format('YYYY')}&week=${start.week()}`;
                break;
            case 'Month':
                start = dateRange[0].add(1, 'month').startOf('month');
                end = start.endOf('month');
                urlParams = `year=${start.format('YYYY')}&month=${start.format('MM')}`;
                break;
            case 'Quarter':
                start = dateRange[0].add(1, 'quarter').startOf('quarter');
                end = start.endOf('quarter');
                urlParams = `year=${start.format('YYYY')}&quarter=${start.quarter()}`;
                break;
            case 'Year':
                start = dateRange[0].add(1, 'year').startOf('year');
                end = start.endOf('year');
                urlParams = `year=${start.format('YYYY')}`;
                break;
            default:
                return;
        }

        setDateRange([start, end]);
        navigate(`?${urlParams}`);
        dispatch(getExpenseDataByDateRange(start.toISOString(), end.toISOString()));
    };

    const renderDatePicker = <DateRangePicker
        dateRangeType={dateRangeType}
        dateRange={dateRange}
        onRangePickerChange={onRangePickerChange}
        onDayPickerChange={onDayPickerChange}
        onWeekPickerChange={onWeekPickerChange}
        onMonthPickerChange={onMonthPickerChange}
        onQuarterPickerChange={onQuarterPickerChange}
        onYearPickerChange={onYearPickerChange}
        locale={locale}
        hideDatePickersPanel={hideDatePickersPanel}
        hideDatePickersInputField={hideDatePickersInputField}
    />

    const getTitle = () => {
        if (!hideDatePickersInputField) {
            switch (dateRangeType) {
                case 'Day':
                    return `Showing results for `;
                case 'Week':
                    return `Showing results for Week `;
                case 'Month':
                    return `Showing results for `;
                case 'Quarter':
                    return `Showing results for `;
                case 'Year':
                    return `Showing results for `;
                case 'Custom':
                    return `Showing results from ${dateRange[0].format('MMMM D, YYYY')} to ${dateRange[1].format('MMMM D, YYYY')}`;
                default:
                    return '';
            }
        } else {
            switch (dateRangeType) {
                case 'Day':
                    return `Showing results for ${dateRange[0].format('dddd, MMMM D, YYYY')}`;
                case 'Week':
                    return `Showing results for Week ${dateRange[0].week()} of ${dateRange[0].format('YYYY')}`;
                case 'Month':
                    return `Showing results for ${dateRange[0].format('MMMM YYYY')}`;
                case 'Quarter':
                    return `Showing results for Q${dateRange[0].quarter()} of ${dateRange[0].format('YYYY')}`;
                case 'Year':
                    return `Showing results for ${dateRange[0].format('YYYY')}`;
                case 'Custom':
                    return `Showing results from ${dateRange[0].format('MMMM D, YYYY')} to ${dateRange[1].format('MMMM D, YYYY')}`;
                default:
                    return '';
            }
        }
    };

    return (
        <Card {...propNoTitle} {...propNoBackgroundStyles}>
            <div style={{ display: 'flex', alignItems: 'stretch', justifyContent: 'space-between', height: '100%' }}>

                {!hideArrows && dateRangeType !== 'Custom' && (
                    <div style={{ flexShrink: 0 }}>
                        <Button
                            icon={<LeftOutlined />}
                            onClick={navigateToPrevious}
                            style={{ height: '100%', margin: 0 }}
                        />
                    </div>
                )}
                <div style={{ flexGrow: 1, textAlign: !hideArrows && 'center', padding: '0 16px' }}>
                    <div style={{ display: 'inline-block', whiteSpace: 'normal', wordWrap: 'break-word' }}>
                        {getTitle()} {!hideDatePickersInputField && dateRangeType !== 'Custom' && renderDatePicker}
                    </div>
                </div>
                {!hideArrows && dateRangeType !== 'Custom' && (
                    <div style={{ flexShrink: 0 }}>
                        <Button
                            icon={<RightOutlined />}
                            onClick={navigateToNext}
                            style={{ height: '100%', margin: 0 }}
                        />
                    </div>
                )}
            </div>
        </Card>
    );
};

export default DateRangeHeader;
