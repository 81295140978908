import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getNoteCollections } from './actions/noteCollections'
import { getFolderCollections } from './actions/folderCollections';
import { Link, Route, Routes } from 'react-router-dom';
import Home from './Pages/Home.js';
import Folders from './Pages/Folders';
import FolderPage from './Pages/FolderPage/FolderPage.js';
import './styles.css';
import { ConfigProvider, Divider, Menu, theme, Typography } from 'antd';
import { themes } from "./components/Shared UI/Themes";
import { getPageTabsCollections } from './actions/pageTabsCollections.js';
import ProLayout from '@ant-design/pro-layout';
import NavigationTreeV2 from './components/Navigation/NavigationIncludingTabs/NavigationTreeV2.js';
import HeaderFirebase from './components/Header/index.jsx';
import { FolderAddFilled, EuroCircleFilled } from '@ant-design/icons';
import ExpensesHome from './Pages/Expenses/ExpensesHome.js';
import Daily from './Pages/Expenses/Subpages/Daily.js';
import Insights from './Pages/Expenses/Subpages/Insights.js';
import History from './Pages/Expenses/Subpages/History.js';
import Settings from './Pages/Expenses/Subpages/Settings.js';
import metadata from './metadata.json'; // only this article worked (https://richhewlett.com/2020/07/11/auto-increment-build-number-in-a-javascript-app/),
// import { updateUserSettings } from './actions/userSettingsActions';
import UserSettingsModal from './components/Shared UI/UserSettingsModal';
import { getUserSettings } from './actions/userSettingsActions';
import DevVarDisplay from './components/Other/DevVarDisplay';
import UserSettingsListDrawer from './components/Shared UI/UserSettingsListDrawer';

const { getDesignToken } = theme;



const App = () => {
    const [currentId, setCurrentId] = useState(null);
    const dispatch = useDispatch();
    const [pageTitle, setPageTitle] = useState('NoteVerse');

    useEffect(() => {
        dispatch(getNoteCollections());
    }, [currentId, dispatch]);

    useEffect(() => {
        dispatch(getFolderCollections());
        dispatch(getPageTabsCollections());
        dispatch(getUserSettings());
    }, [])

    const [collapsed, setCollapsed] = useState(false);
    const [isBroken, setIsBroken] = useState(false);
    // *************************************
    // New User Settings Oct 2024
    // *************************************

    // Get user settings from Redux state
    const userSettings = useSelector(state => state.userSettings?.userSettings);
    const userPrefferedColorScheme = userSettings.layout?.prefferedColorScheme;
    const [darkMode, setDarkMode] = useState(true); // ked sa nacitava stranka tak preblikne defaultna tema, tak radsej som zvolil dark nech uzivatel v noci nedostane flash, sice sa to ukaze len na stotinu ale pri dark mode si to nevsimne a cez den ho prechod z dark na light nezabije
    const themeIndex = userSettings?.preferredTheme;



    useEffect(() => {
        // setDarkMode(userPrefferedColorScheme === 'system' ? matchMedia('(prefers-color-scheme: dark)').matches : userPrefferedColorScheme === 'light' ? false : true)

        if (userPrefferedColorScheme === "system") {
            // Do something when the condition is true
            console.log("System mode is selected");

            // Create a media query object that matches the system color mode
            const mq = window.matchMedia("(prefers-color-scheme: dark)");

            // Define a function that updates the darkMode state based on the media query
            const updateDarkMode = (evt) => {
                setDarkMode(evt.matches);
            };

            // Set the initial value of darkMode based on the media query
            setDarkMode(mq.matches);

            // Add an event listener to the media query object
            mq.addEventListener("change", updateDarkMode);

            // Return a cleanup function that removes the event listener
            return () => {
                mq.removeEventListener("change", updateDarkMode);
            };
        } else if (userPrefferedColorScheme === "light") {
            // Do something when the condition is false and selectedMode is "light"
            setDarkMode(false);
        } else if (userPrefferedColorScheme === "dark") {
            // Do something when the condition is false and selectedMode is "dark"
            setDarkMode(true);
        }
    }, [userPrefferedColorScheme])
    // *************************************
    // END of User Settings Oct 2024
    // *************************************

    // *************************************
    // START OF CODE RELATED TO THEME CONFIG
    // *************************************
    const { defaultAlgorithm, darkAlgorithm, compactAlgorithm } = theme;

    // pre load localStorage data to be used in setters to prevent flickering


    // Modes (Color, Compact, Theme) 
    const [selectedMode, setSelectedMode] = useState(userPrefferedColorScheme);  // <-- can be deleted

    // Algorithms array for Config Provider
    const algorithmsArray = userSettings.layout.compactMode ?
        [darkMode ? darkAlgorithm : defaultAlgorithm, compactAlgorithm] :
        [darkMode ? darkAlgorithm : defaultAlgorithm];



    // *************************************
    // handle opening Theme Config
    // *************************************
    const [isModalOpen, setIsModalOpen] = useState(false);
    const showModal = () => {
        setIsModalOpen(true);
    };
    const handleOk = () => {
        setIsModalOpen(false);
    };
    const handleCancel = () => {
        setIsModalOpen(false);
    };

    // *************************************
    // handle Color Tokens for Layout
    // *************************************
    // Global Tokens
    const globalToken = getDesignToken({ colorPrimary: '#FBCEB1', borderRadius: 30 }
        // themeIndex === 'custom' ? { token: { colorPrimary: 'purple', borderRadius: tokenBorderRadius } } : themes[themeIndex]
    );
    const DarkToken = getDesignToken({
        algorithm: darkAlgorithm
    });
    const LightToken = getDesignToken({
        algorithm: defaultAlgorithm
    });
    // const CompactToken = getDesignToken(
    //     userSettings.layout.compactMode ? { algorithm: compactAlgorithm } : null
    // )
    // custom Theme Tokens for Theme Previews
    const getThemeDetails = (index) => getDesignToken(
        // themeIndex === 'custom' ? { token: { colorPrimary: 'purple', borderRadius: 30 } } : themes[themeIndex]
        { colorPrimary: '#FBCEB1', borderRadius: 30 }
    )

    // const themeDetails = themeIndex === 'custom' ? { token: { colorPrimary: 'purple', borderRadius: 15 } } : { token: { colorPrimary: themes[themeIndex]?.token.colorPrimary, borderRadius: themes[themeIndex]?.token.borderRadius } };

    return (
        <ConfigProvider theme={{
            token: {
                ...(userSettings?.preferredTheme === 'custom' ? { colorPrimary: userSettings?.customTheme?.colorPrimary } : (userSettings?.preferredTheme === '0' ? {} : { colorPrimary: themes[userSettings?.preferredTheme]?.token?.colorPrimary || {} })),
                borderRadius: themeIndex === 'custom' ? userSettings.customTheme.borderRadius : themes[themeIndex]?.token?.borderRadius || 5
            },
            ...{ algorithm: algorithmsArray },
            // ...themes[themeIndex],
            // ...themeDetails,
            hashed: false
        }}
        >
            <ProLayout className='custom-layout'
                // logo={<Logo_NoteVerse fill='grey' />}
                title={pageTitle}
                token={{
                    header: {
                        // colorBgHeader: '#001529',         // Header background color
                        colorHeaderTitle: 'rgba(255,255,255,.9)',      // Header title color

                        // backgroundColor: '#4158D0',
                        // backgroundImage: 'linear-gradient(43deg, #4158D0 0%, #C850C0 46%, #FFCC70 100%)',
                    },
                    // colorBgAppListIconHover: 'rgba(0,0,0,0.06)',
                    // colorTextAppListIconHover: 'rgba(255,255,255,0.95)',
                    // colorTextAppListIcon: 'rgba(255,255,255,0.85)',
                    // sider: {
                    //     colorBgCollapsedButton: '#fff',
                    //     colorTextCollapsedButtonHover: 'rgba(0,0,0,0.65)',
                    //     colorTextCollapsedButton: 'rgba(0,0,0,0.45)',
                    //     colorMenuBackground: '#004FD9',
                    //     colorBgMenuItemCollapsedHover: 'rgba(0,0,0,0.06)',
                    //     colorBgMenuItemCollapsedSelected: 'rgba(0,0,0,0.15)',
                    //     colorBgMenuItemCollapsedElevated: 'rgba(0,0,0,0.85)',
                    //     colorMenuItemDivider: 'rgba(255,255,255,0.15)',
                    //     colorBgMenuItemHover: 'rgba(0,0,0,0.06)',
                    //     colorBgMenuItemSelected: 'rgba(0,0,0,0.15)',
                    //     colorTextMenuSelected: '#fff',
                    //     colorTextMenuItemHover: 'rgba(255,255,255,0.75)',
                    //     colorTextMenu: 'rgba(255,255,255,0.75)',
                    //     colorTextMenuSecondary: 'rgba(255,255,255,0.65)',
                    //     colorTextMenuTitle: 'rgba(255,255,255,0.95)',
                    //     colorTextMenuActive: 'rgba(255,255,255,0.95)',
                    //     colorTextSubMenuSelected: '#fff',
                    // },
                }}
                // headerRender={true}

                layout={userSettings.layout.layoutMode}
                fixedHeader={userSettings.layout.headerMode}

                bgLayoutImgList={
                    [
                        {
                            src: 'https://img.alicdn.com/imgextra/i3/O1CN018NxReL1shX85Yz6Cx_!!6000000005798-2-tps-884-496.png',
                            bottom: 0,
                            left: 0,
                            width: '331px',
                        },
                    ]}

                menuContentRender={() => {
                    return (
                        <div style={{
                            height: '100%',
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'space-between',
                            alignItems: 'stretch'
                        }}>
                            <div style={{
                                flex: 1,
                                overflowY: 'auto',
                            }}>
                                <Menu mode='inline'
                                    items={[{
                                        label: <Link to='/folders'>Folders</Link>,
                                        key: 'folders',
                                        icon: <FolderAddFilled />
                                    },
                                    {
                                        label: <Link to='/expenses'>Expenses</Link>,
                                        key: 'expenses',
                                        icon: <EuroCircleFilled />,
                                        children: [
                                            {
                                                label: <Link to='/expenses/daily'>Daily</Link>,
                                                key: 'daily',
                                                // icon: <EuroCircleFilled />,
                                            },
                                            {
                                                label: <Link to='/expenses/insights'>Insights</Link>,
                                                key: 'insights',
                                                // icon: <EuroCircleFilled />,
                                            },
                                            {
                                                label: <Link to='/expenses/history'>History</Link>,
                                                key: 'history',
                                                // icon: <EuroCircleFilled />,
                                            },
                                            {
                                                label: <Link to='/expenses/settings'>Products & Settings</Link>,
                                                key: 'settings',
                                                // icon: <EuroCircleFilled />,
                                            },
                                        ]
                                    }]}>
                                </Menu>
                                <Divider style={{ margin: 0 }} />
                                <NavigationTreeV2 />
                            </div>
                            <div style={{
                                flex: ' 0 0 auto',
                                borderTop: `1px solid rgba(123,123,123,0.35)`,
                            }}>
                                <HeaderFirebase />
                                <Divider style={{ margin: 0 }} />
                                <Typography.Text type='secondary' style={{ fontSize: 9, textAlign: 'center', display: 'block' }}>
                                    v{metadata.buildMajor}.{metadata.buildMinor}.{metadata.buildRevision}
                                    {/* {metadata.buildTag} */}
                                </Typography.Text>
                            </div>
                        </div>
                    );
                }}

            >

                {/* <DevVarDisplay value={themes[themeIndex]?.token?.colorPrimary || null} />
                <DevVarDisplay value={themeIndex} />
                <DevVarDisplay value={userPrefferedColorScheme} />
                <DevVarDisplay value={userSettings} /> */}
                <div>
                    {/* <h2>User Settings</h2> */}
                    <UserSettingsListDrawer
                        darkMode={darkMode}
                        setSelectedMode={setSelectedMode}
                    // userSettings={userSettings}
                    // onSave={handleSave}
                    />
                </div>
                <Routes>
                    <Route path='/' element={
                        <Home darkMode={darkMode}
                            LightToken={LightToken}
                            DarkToken={DarkToken}
                            setPageTitle={setPageTitle}
                            pageHeaderMode={userSettings.layout.pageHeaderMode}
                        />}
                    />
                    <Route path='/expenses' element={<ExpensesHome setPageTitle={setPageTitle} pageHeaderMode={userSettings.layout.pageHeaderMode}
                        darkMode={darkMode}
                        LightToken={LightToken}
                        DarkToken={DarkToken} />} />
                    <Route
                        path='/expenses/daily'
                        element={<Daily
                            setPageTitle={setPageTitle}
                            pageHeaderMode={userSettings.layout.pageHeaderMode}
                            darkMode={darkMode}
                            LightToken={LightToken}
                            DarkToken={DarkToken} />}
                    />
                    <Route
                        path='/expenses/insights'
                        element={<Insights
                            setPageTitle={setPageTitle}
                            pageHeaderMode={userSettings.layout.pageHeaderMode}
                            darkMode={darkMode}
                            LightToken={LightToken}
                            DarkToken={DarkToken} />}
                    />
                    <Route
                        path='/expenses/history'
                        element={<History
                            setPageTitle={setPageTitle}
                            pageHeaderMode={userSettings.layout.pageHeaderMode}
                            darkMode={darkMode}
                            LightToken={LightToken}
                            DarkToken={DarkToken} />}
                    />
                    <Route
                        path='/expenses/settings'
                        element={<Settings
                            setPageTitle={setPageTitle}
                            pageHeaderMode={userSettings.layout.pageHeaderMode}
                            darkMode={darkMode}
                            LightToken={LightToken}
                            DarkToken={DarkToken} />}
                    />
                    <Route path='/folders' element={<Folders setPageTitle={setPageTitle} pageHeaderMode={userSettings.layout.pageHeaderMode} />} />
                    <Route path='/folders/:id' element={<FolderPage darkMode={darkMode} setPageTitle={setPageTitle} pageHeaderMode={userSettings.layout.pageHeaderMode} />} />
                    <Route path='/folders/:id/:tabId' element={<FolderPage darkMode={darkMode} setPageTitle={setPageTitle} pageHeaderMode={userSettings.layout.pageHeaderMode} />} />
                </Routes>
            </ProLayout >
        </ConfigProvider >
    )
}

export default App;
