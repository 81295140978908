// video tutorial https://www.youtube.com/watch?v=WpIDez53SK4

import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { doSignInWithEmailAndPassword, doSignInWithGitHub, doSignInWithGoogle } from '../../../firebase/auth'
import { Button, Checkbox, Col, Divider, Flex, Form, Input, Row, Typography } from 'antd'
import { LockOutlined, MailOutlined, UserOutlined, GoogleOutlined, GithubOutlined } from "@ant-design/icons";
import '../styles.css';
import { ReactComponent as YourSvg } from '../logo_noteverse.svg';
import Logo_NoteVerse from '../logo_noteverse'
import AuthModal from '../AuthModal'
const { Text, Title } = Typography;


const Login = () => {

    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [isSigningIn, setIsSigningIn] = useState(false)
    const [errorMessage, setErrorMessage] = useState('')

    const firebaseErrorCodes = {
        'auth/network-request-failed': 'No internet connection. Please check your network and try again.',
        'auth/user-not-found': 'No user found with this email.',
        'auth/wrong-password': 'Incorrect password. Please try again.',
        'auth/invalid-credential': 'Incorrect credentials. Please try again.',
        // other error codes from github
        'auth/user-cancelled': 'User cancelled flow in GitHub popup.',
        'auth/account-exists-with-different-credential': 'Email is already in use, try to sign in with password or other Sign in option'
    }

    const onSubmit = async (e) => {
        // e.preventDefault()
        console.log(e);

        if (!isSigningIn) {
            setIsSigningIn(true)
            try {
                await doSignInWithEmailAndPassword(e.email, e.password)
            } catch (err) {
                const userFriendlyMessage = firebaseErrorCodes[err.code] || 'An unknown error occurred. Please try again.'
                setErrorMessage(userFriendlyMessage)
                console.log(err);
                setIsSigningIn(false)
            }
        }
    }

    const onGoogleSignIn = (e) => {
        e.preventDefault()
        if (!isSigningIn) {
            setIsSigningIn(true)
            doSignInWithGoogle().then(() => {
                setIsSigningIn(false)
            }).catch(err => {
                const userFriendlyMessage = firebaseErrorCodes[err.code] || err.code
                setErrorMessage(userFriendlyMessage)
                setIsSigningIn(false)
            })
        }
    }

    const onGitHubSignIn = (e) => {
        e.preventDefault()
        if (!isSigningIn) {
            setIsSigningIn(true)
            doSignInWithGitHub().then(() => {
                setIsSigningIn(false)
            }).catch(err => {
                const userFriendlyMessage = firebaseErrorCodes[err.code] || err.code
                setErrorMessage(userFriendlyMessage)
                setIsSigningIn(false)
            })
        }
    }

    // Page Title visible in browser tabs
    useEffect(() => {
        document.title = 'Login';
    }, []);

    return (
        <div>


            <AuthModal removeDefaultPadding>

                <div style={{
                    padding: '24px 24px 0px 24px', width: '100%',
                    background: 'rgba(255, 255, 255, 0.2)', borderRadius: '10px 10px 0 0'
                }} >
                    <Title >Sign in</Title>
                    <Text type='secondary'  >
                        Welcome back to NoteVerse! <br />Please enter your details below to
                        sign in. <br /><br />
                    </Text>
                    {/* <Logo_NoteVerse fill={'#273343'} transparentFill={'rgba(0,0,0,0)'} /> */}
                    <Form
                        name="normal_login"
                        className="login-form"
                        onFinish={onSubmit}
                        layout="vertical"
                        requiredMark="optional"
                    >
                        <Form.Item
                            name={'email'}
                            rules={[
                                {
                                    type: 'email',
                                    required: true,
                                    message: "Please input your Email!",
                                },
                            ]}
                        >
                            <Input autoComplete='email'
                                prefix={<UserOutlined className="site-form-item-icon" />}
                                placeholder="Email"
                            />
                        </Form.Item>

                        <Form.Item
                            name="password"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please input your password!',
                                    min: 0,
                                    max: 99,
                                },
                            ]}
                        >
                            <Input.Password
                                autoComplete='current-password'
                                prefix={<LockOutlined className="site-form-item-icon" />}
                                type="password"
                                placeholder="Password"
                            />
                        </Form.Item>

                        {errorMessage && (
                            <Text type="danger">{errorMessage}</Text>
                        )}

                        <Form.Item>
                            <Button type="primary" htmlType="submit"
                                disabled={isSigningIn} className="login-form-button">
                                {isSigningIn ? 'Signing In...' : 'Sign In'}
                            </Button>
                        </Form.Item>

                        <Form.Item>
                            <Form.Item name="remember" valuePropName="checked" noStyle>
                                {/* <Checkbox>Remember me</Checkbox> */}
                                Or  <Link to={'/register'} className="hover:underline font-bold">register now!</Link>
                            </Form.Item>

                            <Link to={'/reset'} className="login-form-forgot" href="/reset">
                                Forgot password
                            </Link>
                        </Form.Item>
                    </Form>
                </div>
                {/* <Divider style={{ marginBottom: 0, marginTop: 0 }} plain >other options</Divider> */}

                <div style={{ padding: '24px  24px 0 24px', width: '100%' }} >
                    <Text type='secondary' >
                        Other sign in options<br /><br />
                    </Text>
                    <Row gutter={20}>
                        <Col flex={3}><Form.Item>
                            <Button style={{ padding: '10px  0px 10px 0', color: 'grey', borderColor: 'grey' }} ghost
                                onClick={(e) => { onGoogleSignIn(e) }}
                                disabled={isSigningIn} className="login-form-button"
                            // icon={<GoogleOutlined />}
                            >
                                {isSigningIn ? 'Signing In...' : <><GoogleOutlined style={{ fontSize: '20px' }} />< br />Google Login</>}
                            </Button>
                        </Form.Item>
                        </Col>
                        <Col flex={3}><Form.Item>
                            <Link to={'/login'} className="hover:underline font-bold">
                                <Button style={{ padding: '10px  0px 10px 0', color: 'grey', borderColor: 'grey' }} ghost
                                    onClick={(e) => { onGitHubSignIn(e) }}
                                    disabled={isSigningIn} className="login-form-button"
                                // icon={<GithubOutlined />}
                                >
                                    {isSigningIn ? 'Signing In...' : <><GithubOutlined style={{ fontSize: '20px' }} /><br />GitHub Login</>}
                                </Button>
                            </Link>
                        </Form.Item></Col>
                    </Row>
                </div>
            </AuthModal>
        </div >
    )
}

export default Login