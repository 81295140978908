export const themes = [
    {
        name: 'Ant Default',
        token: {
            // colorPrimary: "#f00",
            // fontSize: 20,
            // customTokenName: 10,
            // borderRadius: 20,
            // colorLink: "#f00"
        }
    },
    {
        name: 'red',
        token: {
            colorPrimary: "#f00000",
            // fontSize: 20,
            customTokenName: 10,
            borderRadius: 20,
            colorLink: "#f00"
        }
    },
    {
        name: 'green',
        token: {
            colorPrimary: "#008000",
            // fontSize: 40,
            customTokenName: 30,
            colorLink: "#0f0"
        }
    },
    {
        name: 'purple',
        token: {
            colorPrimary: "#00f",
            customTokenName: 40,
            colorLink: "#00f"
        }
    }
];