import { Card, ConfigProvider, Space, Tabs, TreeSelect, Typography, message } from "antd";
import NewPageTabsAnt from "../../components/Tabs/NewPageTabs/NewPageTabsAnt";
import { useEffect, useState } from "react";
import { getTagsOptions } from "../../utils/tagsUtils";
import NotesCollections from "../../components/NotesView/NotesCollections/NotesCollections";
import FormInTab from "../../components/NotesView/NoteForm/NoteFormInTab";
import { useParams, useNavigate } from "react-router-dom";
import { useDeepLinkHighlight } from "../../components/Other/useDeepLinkHighlight";



const PageWithTabs = ({ darkMode, pageTabsCollectionsFromServer, visible, setVisible,
    setCurrentId, currentId, allNotesForCurrentFolder }) => {
    const [currentHeader, setCurrentHeader] = useState();
    const { id, tabId } = useParams(); // Use useParams to access parameters
    const [activeTab, setActiveTab] = useState(tabId);
    const navigate = useNavigate();

    useEffect(() => {
        setCurrentHeader(pageTabsCollectionsFromServer?.tabs[0]);

    }, [pageTabsCollectionsFromServer]);

    // handling TAB SWITCHING
    const onChange = (targetKey) => {
        setCurrentHeader(pageTabsCollectionsFromServer?.tabs.find(tab => tab._id === targetKey));
        navigate(`/folders/${id}/${targetKey}`); // Update URL
    };

    /* 
    ** DEEPLINKS FOR TABS 
    ** (deeplink handling for NOtes is part of TabContent component bellow)
    ** reading them and opening them properly by setting related variables
    */

    // This function checks if the tabId exists within the pageTabsCollectionsFromServer.tabs array
    const tabExists = (tabId) => {
        if (pageTabsCollectionsFromServer?.tabs.find(tab => tab._id === tabId)) {
            return true
        } else { return false }
    };

    useEffect(() => {
        // check wheter tab is defined
        if (tabId) {
            // check wheter tab exists in array of tabs
            if (tabExists(tabId)) {
                // Set the selected tab from URL params upon initial load
                if (tabId) {
                    setCurrentHeader(pageTabsCollectionsFromServer?.tabs.find(tab => tab._id === tabId));
                    setActiveTab(tabId);
                } else {
                    setCurrentHeader(pageTabsCollectionsFromServer?.tabs[0]);
                    setActiveTab(pageTabsCollectionsFromServer?.tabs[0]._id);
                }
            } else {
                // Set the selected tab from URL params upon initial load
                message.success("Tab mentioned in URL is either made up or was removed. It cannot be found.");
                if (tabId) {
                    setCurrentHeader(pageTabsCollectionsFromServer?.tabs[0]);
                    setActiveTab(pageTabsCollectionsFromServer?.tabs[0]._id);
                }
            }
        } else {
            if (tabId) {
                setCurrentHeader(pageTabsCollectionsFromServer?.tabs[0]);
                setActiveTab(pageTabsCollectionsFromServer?.tabs[0]._id);
            }
        }
    }, [tabId, pageTabsCollectionsFromServer]);

    const onEdit = () => {
        setVisible(true);
    };

    /* 
   ** code related to Tabs  
   ** reading them and setting array for Tabs component
   ** also useEffect which handles rerendering content
   */
    const [tabsArray, setTabsArray] = useState();

    const treeTabs = pageTabsCollectionsFromServer?.tabs.map((tab) => ({
        title: tab.tabName,
        value: tab._id,
        disabled: tab.tabName.toLowerCase() === "all" ? true : false
    }));

    useEffect(() => {
        if (pageTabsCollectionsFromServer) {
            setTabsArray(
                pageTabsCollectionsFromServer?.tabs
                    .sort(function (a, b) {
                        return a.tabOrder - b.tabOrder;
                    })
                    .map((elem, index) => {
                        const allNotesForCurrentTab = elem.tabName.toLowerCase() === "all"
                            ? allNotesForCurrentFolder :
                            allNotesForCurrentFolder?.filter((note) =>
                                note.tabID?.includes(elem._id)
                            );
                        return {
                            key: elem._id,
                            label: (<div>{elem.tabName}</div>),
                            children: (
                                <TabContent
                                    elem={elem} // all
                                    getTagsOptions={getTagsOptions} allNotesForCurrentTab={allNotesForCurrentTab}// TreeSelect
                                    setCurrentId={setCurrentId} allNotesForCurrentFolder={allNotesForCurrentFolder} treeTabs={treeTabs} darkMode={darkMode} // NotesCollections + FormInTab
                                    currentId={currentId} id={id} // FormInTab
                                />
                            ),
                        };
                    })
            );
        }
    }, [
        pageTabsCollectionsFromServer,
        allNotesForCurrentFolder,
    ]);
    return (
        <ConfigProvider
            theme={{
                components: {
                    Tabs: { cardBg: "none", fontSizeSM: 8, },
                },
            }}
        >

            <HeaderForTabs currentHeader={currentHeader} darkMode={darkMode} />
            <Tabs
                activeKey={activeTab}
                hideAdd
                renderTabBar={(props, TabNavList) => (
                    <TabNavList {...props} mobile={false} />
                )} // https://github.com/ant-design/ant-design/issues/27341
                tabBarExtraContent={<NewPageTabsAnt hideDefaultButton parentID={id} visible={visible} setVisible={setVisible} />}
                type={pageTabsCollectionsFromServer.tabsType == "line" ? "line" : "editable-card"}
                onChange={onChange}
                onEdit={onEdit}
                items={tabsArray}
            />
        </ConfigProvider>
    )
}



const HeaderForTabs = ({ currentHeader, darkMode }) => {
    const [ellipsis, setEllipsis] = useState(true);
    return (
        currentHeader ? (
            <div style={{ position: "relative", top: 55 }}>
                {currentHeader.tabImage ? (
                    <img
                        src={currentHeader.tabImage}
                        alt=""
                        style={{
                            objectFit: "cover",
                            width: "100%",
                            height: "200px",
                            background: currentHeader.tabColor,
                            borderRadius: "10px 10px 0px 0px",
                        }}
                    />
                ) : (
                    <div
                        style={{
                            width: "100%",
                            height: "200px",
                            background: currentHeader.tabColor,
                            borderRadius: "10px 10px 0px 0px",
                        }}
                    />
                )}
                <div
                    className="header_text_panel"
                    style={{
                        position: "absolute",
                        bottom: 0,
                        width: "100%",
                        padding: 24,
                        background: darkMode ? "rgba(0,0,0,.2)" : "rgba(255,255,255,.2)",
                        borderRadius: "0px 0px 0px 0px",
                    }}
                >
                    <div style={{ marginBottom: 30 }}>
                        <Typography.Title level={2} style={{}}>
                            {currentHeader.tabTitle || currentHeader.tabName}
                        </Typography.Title>
                        <Typography.Paragraph
                            ellipsis={ellipsis ? { rows: 2, expandable: true, symbol: "more", } : false}
                            level={2}              >
                            {currentHeader.tabDescription}
                        </Typography.Paragraph>
                    </div>
                </div>
            </div>
        ) : null
    )
}

const TabContent = ({
    elem, //all
    getTagsOptions, allNotesForCurrentTab, // TreeSelect
    setCurrentId, allNotesForCurrentFolder, treeTabs, darkMode,// NotesCollections + FormInTab
    currentId, id, // FormInTab
}) => {

    const [filterValue, setFilterValue] = useState([]);
    const [notesFromFilter, setNotesFromFilter] = useState([]);

    const onFilterChange = (newValue) => {
        setFilterValue(newValue);
        setNotesFromFilter(
            allNotesForCurrentTab.filter((note) =>
                newValue.some((tag) => note.tags.includes(tag))
            )
        );
    };

    useEffect(() => {
        setNotesFromFilter(
            allNotesForCurrentTab?.filter((note) =>
                filterValue?.some((tag) => note.tags.includes(tag))
            )
        );
    }, [allNotesForCurrentFolder])

    /* DEEP LINKS for NOTES
    ** Scroll to the note element when it is rendered
    ** Get the note ID from the URL query and Create a ref to store the note element
    */
    const { noteId, noteRef } = useDeepLinkHighlight(allNotesForCurrentFolder);

    return (
        <Card className="shrinkPaddingonMobile"
            bordered={false}
            style={{
                borderTop: "0px red solid",
                borderRadius: '0px 0px 20px 20px',
                // minWidth: "inherit",
            }}
        >
            < Space direction='vertical' style={{ width: '100%' }} size={'large'}>
                <TreeSelect
                    style={{
                        width: "100%",
                    }}
                    value={filterValue}
                    mode={"multiple"}
                    onChange={(value) => onFilterChange(value)}
                    maxTagCount={0}
                    maxTagPlaceholder={(value) => `Tags (${value.length})`}
                    placeholder="Tags"
                    treeCheckable="true"
                    treeData={elem.tabName.toLowerCase() === "all"
                        ? getTagsOptions(allNotesForCurrentFolder)
                        : getTagsOptions(allNotesForCurrentTab)}
                />
                <NotesCollections
                    receivedCollection={notesFromFilter.length == 0 ? allNotesForCurrentTab : notesFromFilter}
                    setCurrentId={setCurrentId}
                    darkMode={darkMode}
                    tabID={elem._id}
                    treeTabs={treeTabs}
                    distinguishBackground
                    requestedNoteId={noteId} noteRef={noteRef}
                />
                <FormInTab
                    currentId={currentId}
                    setCurrentId={setCurrentId}
                    createdFromParent={id}
                    darkMode={darkMode}
                    tabID={elem._id}
                    treeTabs={treeTabs}

                    formKey={'CalledFromContent'}
                />
            </Space>
        </Card>
    )
}


export default PageWithTabs;

