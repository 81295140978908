// TransactionChart_G2Plot.js
import React, { useEffect, useRef, useState } from 'react';
import { Column } from '@antv/g2plot';
import dayjs from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';
import weekday from 'dayjs/plugin/weekday';
import localizedFormat from 'dayjs/plugin/localizedFormat';

dayjs.extend(isBetween);
dayjs.extend(weekday);
dayjs.extend(localizedFormat);

const TransactionChart = ({ transactions, reversed = false, daysToShow = 7 }) => {
    const [startDateOffset, setStartDateOffset] = useState(0);
    const chartRef = useRef(null);
    const containerRef = useRef(null);

    const getLastNDays = (n, offset) => {
        const days = [];
        for (let i = n - 1 + offset; i >= offset; i--) {
            days.push(dayjs().subtract(i, 'day').format('YYYY-MM-DD'));
        }
        return days;
    };

    const updateChart = () => {
        if (chartRef.current) {
            chartRef.current.destroy();
        }

        const lastNDays = getLastNDays(daysToShow, startDateOffset);

        const filterTransactionsByDateRange = (transactions, lastNDays) => {
            return transactions.filter(transaction => {
                const transactionDate = dayjs(transaction.transactionDate).format('YYYY-MM-DD');
                return lastNDays.includes(transactionDate);
            });
        };

        const aggregateTransactionsByCategoryAndDay = (transactions) => {
            const aggregatedData = {};

            transactions.forEach((transaction) => {
                const date = dayjs(transaction.transactionDate).format('YYYY-MM-DD');
                const category = transaction.category.length > 0 ? transaction.category[0] : 'Uncategorized';

                if (!aggregatedData[date]) {
                    aggregatedData[date] = {};
                }
                if (!aggregatedData[date][category]) {
                    aggregatedData[date][category] = 0;
                }
                aggregatedData[date][category] += transaction.amount;
            });

            return aggregatedData;
        };

        const filteredTransactions = filterTransactionsByDateRange(transactions, lastNDays);
        const aggregatedData = aggregateTransactionsByCategoryAndDay(filteredTransactions);

        const chartData = [];
        lastNDays.forEach((date) => {
            const categories = aggregatedData[date] || { Uncategorized: 0 };
            for (const [category, amount] of Object.entries(categories)) {
                chartData.push({
                    date,
                    type: category,
                    value: reversed ? -amount : amount, // Reverse values if reversed is true
                });
            }
        });

        const stackedColumnPlot = new Column(containerRef.current, {
            data: chartData,
            isStack: true,
            xField: 'date',
            yField: 'value',
            seriesField: 'type',
            label: {
                position: 'middle',
                layout: [
                    { type: 'interval-adjust-position' },
                    { type: 'interval-hide-overlap' },
                    { type: 'adjust-color' },
                ],
            },
            xAxis: {
                label: {
                    formatter: (date) => {
                        const formattedDate = dayjs(date).format('dd\nD.M.');
                        return formattedDate;
                    },
                },
            },
        });

        stackedColumnPlot.render();
        chartRef.current = stackedColumnPlot;
    };

    useEffect(() => {
        updateChart();
    }, [startDateOffset, daysToShow, transactions, reversed]);

    const earliestTransactionDate = dayjs(transactions.reduce((earliest, transaction) => {
        return dayjs(transaction.transactionDate).isBefore(dayjs(earliest)) ? transaction.transactionDate : earliest;
    }, dayjs()));

    const handlePrevious = () => {
        setStartDateOffset(startDateOffset + daysToShow);
    };

    const handleNext = () => {
        setStartDateOffset(startDateOffset - daysToShow);
    };

    const isNextDisabled = startDateOffset === 0;
    const lastNDays = getLastNDays(daysToShow, startDateOffset + daysToShow);
    const isPreviousDisabled = lastNDays[lastNDays.length - 1] < earliestTransactionDate.format('YYYY-MM-DD');

    return (
        <div>
            <div>
                <button onClick={handlePrevious} disabled={isPreviousDisabled}>
                    Previous {daysToShow} Days
                </button>
                <button onClick={handleNext} disabled={isNextDisabled}>
                    Next {daysToShow} Days
                </button>
            </div>
            <div ref={containerRef} id="container"></div>
        </div>
    );
};

export default TransactionChart;
