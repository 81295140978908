import React from 'react';
import { useSelector } from 'react-redux';
import { Typography, Row, Col, Card, Progress, Statistic, Tooltip, theme, Menu, Button, Dropdown, Divider } from 'antd';
import { ArrowUpOutlined, ArrowDownOutlined, LikeOutlined, DislikeOutlined, EllipsisOutlined } from '@ant-design/icons';
import DefaultSettingsModal from '../PageSettings/DefaultSettingsModal';

function roundToTwo(num) {
    return +(Math.round(num + "e+2") + "e-2");
}

function absolute(num) {
    return Math.abs(num);
}

const NewSpentTracker = ({
    dailyLimit,
    defaultSettings,
    defaultCurrency,
    totalAmount,
    accounts,
    currentSum,
    extraChart,
    actionsButton
}) => {
    const { useToken } = theme;
    const { token } = useToken(); // get the token

    const twoColors = {
        // '0%': 'rgb(250, 173, 20)',
        // '100%': 'rgb(250, 173, 20)',
        '0%': 'orange',
        '60%': 'orange',
        '100%': '#ff4d4f',
    };

    const dailyLimitAbsoluteNegative = Math.abs(-dailyLimit) * -1;
    const percentage = roundToTwo((totalAmount / dailyLimitAbsoluteNegative) * 100)
    // Dropdown menu items
    const menu = (
        <Menu>
            {/* <Menu.Item key="1" > */}
            <DefaultSettingsModal showDailyLimits buttonText={'Change Daily Limits'} />
            {/* </Menu.Item> */}
        </Menu>
    );

    const mainContent = <>


        {/* Main content */}
        {dailyLimit === 0 ? (
            <>
                <Typography>Define your daily budget</Typography> + {actionsButton}
                {/* <DefaultSettingsModal showDailyLimits showIconInsteadOfTitle={'savings'} /> */}
            </>
        ) : (
            <>
                {/* Dropdown menu in top-right corner */}
                <div style={{ position: 'absolute', top: 0, right: 0 }}>
                    {actionsButton}
                </div>
                <Statistic
                    prefix={!dailyLimit ? null : dailyLimit && totalAmount == 0 ? <span style={{ color: token.colorInfo }}><LikeOutlined /> </span> :
                        totalAmount != 0 && totalAmount < dailyLimitAbsoluteNegative
                            ? <span style={{ color: token.colorError }}><DislikeOutlined /> </span>
                            : <span style={{ color: token.colorSuccess }}><LikeOutlined /> </span>}
                    value={`${percentage} %`}
                />
                {dailyLimit && <Progress showInfo={false} percent={percentage} strokeColor={percentage <= 100 ? '#52c41a' : twoColors} status={percentage <= 100 ? 'active' : 'exception'} />}
                {dailyLimit && totalAmount == 0 && <Typography.Text type="secondary">Untouched :) Budget is {roundToTwo(absolute(dailyLimitAbsoluteNegative))} {defaultCurrency}</Typography.Text>}
                {dailyLimit && totalAmount < 0 && <Typography.Text type="secondary"> Spent {roundToTwo(absolute(totalAmount))} {defaultCurrency} from {absolute(dailyLimitAbsoluteNegative)}  {defaultCurrency} budget.</Typography.Text>}
                <br />
                {totalAmount != 0 && totalAmount < dailyLimitAbsoluteNegative && <Typography.Text type="secondary">Budget exceeded by {roundToTwo(absolute(totalAmount - dailyLimitAbsoluteNegative))} {defaultCurrency}.</Typography.Text>}
                {totalAmount != 0 && totalAmount > dailyLimitAbsoluteNegative && <Typography.Text type="secondary">{roundToTwo(totalAmount - dailyLimitAbsoluteNegative)} {defaultCurrency} left in budget.</Typography.Text>}
            </>
        )}
    </ >

    return (
        <>
            {!extraChart ? <Card bordered={false}>
                {mainContent}
            </Card> : <Card bordered={false}>
                <Row gutter={[16, 16]}>
                    <Col md={24} sm={24} xs={12}>
                        {extraChart}
                        {/* <br /> */}
                    </Col>
                    <Col md={24} sm={24} xs={0}>
                        {/* empty space shows on very small screens when chart and specs shwos ebllow eachother on small screens otherwise they are too close */}
                    </Col>
                    <Col md={24} sm={24} xs={12}>
                        {mainContent}
                    </Col>
                </Row>
            </Card>
            }
        </>
    );
}

export default NewSpentTracker;
