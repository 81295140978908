import React, { useEffect, useRef, useState } from "react";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { deleteExpenseData } from "../../../../actions/expensesActions";
import { Card, Button, List, Typography, Divider, Row, Col, theme, Dropdown, Input, Space, Avatar } from "antd";
import { DownOutlined, DeleteOutlined, FormOutlined, MoreOutlined, CopyOutlined, ArrowRightOutlined } from "@ant-design/icons";
import { SwipeAction } from 'antd-mobile';

const Expense = ({ expenseData, setCurrentExpenseID, setVisible, currentId, darkMode, tabID, treeTabs, distinguishBackground, requestedNoteId, noteRef, showVisitFolderLink }) => {
    const dispatch = useDispatch();
    const [showNoEditor, setShowNoEditor] = useState(false);

    const { useToken } = theme;
    const { token } = useToken(); // get the token
    const categories = useSelector((state) => state.expenseverseCategories); // Get categories from Redux store

    const tabId = tabID ? `/${tabID}/` : ''; // unfortunatelly bad workaround ...check line bellow
    // to store tabID as tabId because if I was overritting variable tabID than in note form the tab was not preffilled correctly

    const handleshowNoEditor = (v) => {
        setShowNoEditor((v) => !v);
        console.log("v is now: " + showNoEditor);
    };

    const deleteNote = (id) => {
        if (window.confirm("Delete the item?")) {
            dispatch(deleteExpenseData(expenseData._id));
        }
    };

    const [menuVisible, setMenuVisible] = useState(false);
    const handleMenuClick = (e) => {
        if (e.key != 7) {
            setMenuVisible(false);
        }
    };

    const items = [{
        key: "1",
        label: (
            <a type="text" onClick={() => (setCurrentExpenseID(expenseData._id), setVisible(true))}>
                {" "}
                Edit
            </a>
        ),
        icon: <FormOutlined />,
    }, {
        key: "2",
        label: (
            <a type="text" onClick={() => deleteNote(expenseData._id)}>
                {" "}
                Delete
            </a>
        ),
        icon: <DeleteOutlined />,
        danger: true,
    }];

    const leftActions = [
        { key: 'pin', text: 'Pin', color: 'primary' },
    ];
    const rightActions = [
        { key: 'edit', text: <FormOutlined />, color: 'light', onClick: () => (setCurrentExpenseID(expenseData._id), setVisible(true)) },
        {
            key: 'delete', text: <DeleteOutlined />, color: 'danger', onClick: () => deleteNote(expenseData._id)
        },
    ];

    const getCategoryIcon = (categoryId) => {
        const category = categories.find(cat => cat._id === categoryId);
        return category?.icon || 'question_mark';
    };

    return (
        <SwipeAction style={{
            borderRadius: token.borderRadius,
            backgroundColor: token.colorBgContainer
        }}
            key={expenseData._id}
            rightActions={rightActions}
        >
            <Card bordered={false}
                ref={expenseData._id === requestedNoteId ? noteRef : null}
                style={{
                    background: distinguishBackground
                        ? token.colorBgLayout
                        : token.colorBg,
                }}
                title={
                    <Space>
                        {expenseData.category && expenseData.category.map(catId => (
                            <Avatar key={catId} shape="circle" style={{ background: 'none', border: '1px solid rgba(123,123,123,.7)', color: 'rgba(123,123,123,.7)' }} icon={
                                <span className="material-symbols-rounded" style={{
                                    fontVariationSettings: "'FILL' 1",
                                    fontSize: 20, verticalAlign: 'middle', opacity: .7,
                                }}>
                                    {getCategoryIcon(catId)}
                                </span>
                            } />
                        ))}
                        {expenseData.note}
                    </Space>
                }
                extra={`${expenseData.amount} ${expenseData?.currency}`}
            >
                <Typography.Text type='secondary'>transactionDate: {moment(expenseData.transactionDate).format("ddd, DD MMM YY HH:mm")}</Typography.Text>
                <br />
            </Card>
        </SwipeAction>
    );
};

export default Expense;