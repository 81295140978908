import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Col, Spin, Space, Empty, Select, Row, Radio, Drawer, theme, Button } from 'antd';
import DevVarDisplay from '../../Other/DevVarDisplay';
import moment from 'moment'; // Import moment for date handling
import Expense from './Expense/Expense';
import { ArrowDownOutlined, ArrowUpOutlined } from '@ant-design/icons';

const Expenses = ({ currentExpenseID, setCurrentExpenseID, setVisible,
    receivedCollection, darkMode, tabID, treeTabs, distinguishBackground, requestedNoteId, noteRef, showVisitFolderLink,
    showAsDrawer = false }) => {
    const [sortBy, setSortBy] = useState(['transactionDate']);
    const [ascending, setAscending] = useState(false);
    const getExpenseData = useSelector((state) => state.expensesCollection).sort((a, b) => ascending ? moment(a[sortBy]).diff(b[sortBy]) : moment(b[sortBy]).diff(a[sortBy]));;
    const expenseData = receivedCollection ? receivedCollection : getExpenseData;
    const [openTransactionDrawer, setOpenTransactionDrawer] = useState(false);

    // const classes = useStyles();
    const { useToken } = theme;
    const { token } = useToken(); // get the token
    console.log('expenseData', expenseData);



    // Parts of UI

    const filters = <Space>
        <Select
            name="sortBy"
            value={sortBy}
            popupMatchSelectWidth={false}
            onChange={(value) => setSortBy(value)}
            options={[
                {
                    label: 'Transaction Date',
                    value: 'transactionDate'
                },
                {
                    label: 'Log Date',
                    value: 'logDate'
                },
                {
                    label: 'Bank Processed Date',
                    value: 'bankProcessedDate'
                },
            ]}
        />
        <Radio.Group onChange={(value) => setAscending(value.target.value)} defaultValue={ascending}>
            <Radio.Button type='dashed' value={true}><ArrowUpOutlined /></Radio.Button>
            <Radio.Button value={false}><ArrowDownOutlined /></Radio.Button>
        </Radio.Group>
    </Space>

    const content = !expenseData.length ? <Empty description="Add first expense!" image={Empty.PRESENTED_IMAGE_SIMPLE} /> : (
        <Space direction='vertical' size='large' style={{ width: '100%' }}>
            {expenseData.map((expenseData) => (
                <div key={expenseData._id} >
                    <Expense expenseData={expenseData} currentExpenseID={currentExpenseID} setCurrentExpenseID={setCurrentExpenseID} setVisible={setVisible}
                        darkMode={darkMode} tabID={tabID} treeTabs={treeTabs} distinguishBackground={distinguishBackground}
                        requestedNoteId={requestedNoteId} noteRef={noteRef} showVisitFolderLink={showVisitFolderLink} />
                </div>
            ))}
        </Space>
    )

    return showAsDrawer ? <>
        <Button onClick={() => setOpenTransactionDrawer(true)} > <span className="material-symbols-rounded" style={{
            fontVariationSettings: "'FILL' 1",
            fontSize: 20, verticalAlign: 'middle', opacity: .7,
            color: token.colorTextDescription,
        }}>text_select_move_back_word
        </span> Transactions</Button>
        <Drawer open={openTransactionDrawer} width={600} style={{ backgroundColor: token.colorBgLayout }}
            onClose={() => setOpenTransactionDrawer(false)}
            extra={filters}>
            {content}
        </Drawer></>
        : <>
            <Row gutter={8} style={{
                marginBottom: 16,
                alignItems: 'center'
            }}>
                <Col xs={5} >
                    Sort by
                </Col>
                <Col xs={19} style={{ textAlign: 'right' }} >
                    {filters}
                </Col>
            </Row>  {content}
        </>
}

export default Expenses;
