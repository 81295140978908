




import React, { useEffect, useState } from "react";
// import { Auth } from "firebase/auth";
// import { GoogleAuthProvider } from "firebase/auth";
import { doPasswordReset } from "../../../firebase/auth";
import { Button, Col, Flex, Form, Input, Row, Typography, message } from 'antd'
import { MailOutlined } from "@ant-design/icons";
import '../styles.css';
import { Link } from "react-router-dom";
import AuthModal from "../AuthModal";
const { Text, Title } = Typography;

function ForgotPassword() {

    const [errorMessage, setErrorMessage] = useState('');

    const onSubmit = async (e) => {
        setErrorMessage(false)
        // e.preventDefault()
        console.log(e);
        doPasswordReset(e.email).then(() => {
            // notification.success({ message: 'Email was sent!' })
            message.success('Email was sent!')
        }).catch(err => {
            const userFriendlyMessage = err.code || 'An unknown error occurred. Please try again.'
            setErrorMessage(userFriendlyMessage);
            // notification.error({ message: err.code || 'An unknown error occurred. Please try again.' })
            message.error(err.code || 'An unknown error occurred. Please try again.')
            console.log(err);
        })
    }

    // Page Title visible in browser tabs
    useEffect(() => {
        document.title = 'Password Recovery';
    }, []);

    return (
        <AuthModal>


            <Title >Forgot Password</Title>
            <Text type='secondary' >
                Enter your email and we will send you instructions to reset your password.
                <br />
                <br />
            </Text>
            <Form
                name="normal_login"
                className="login-form"
                onFinish={onSubmit}
                layout="vertical"
                requiredMark="optional"
            >
                <Form.Item
                    name={'email'}
                    rules={[
                        {
                            type: 'email',
                            required: true,
                            message: "Please input your Email!",
                        },
                    ]}
                >
                    <Input autoComplete='email'
                        prefix={<MailOutlined className="site-form-item-icon" />}
                        placeholder="Email"
                    />
                </Form.Item>
                {errorMessage && (
                    <Text type="danger">{errorMessage}</Text>
                )}
                <Row gutter={20}>
                    <Col flex={3}><Form.Item>
                        <Button type="primary" htmlType="submit"
                            className="login-form-button">
                            Send email with instructions
                        </Button>

                    </Form.Item></Col>
                    <Col flex={3}><Form.Item>
                        <Link to={'/login'} className="hover:underline font-bold">
                            <Button
                                className="login-form-button">
                                Go to Login
                            </Button>
                        </Link>
                    </Form.Item></Col>
                </Row>
            </Form>
        </AuthModal>
    )
}

export default ForgotPassword