import ReactApexChart from 'react-apexcharts';

const CategoryPieChartPanel = ({ chartData, separateCharts }) => {
    // Prepare the series and labels for the ApexChart
    const labels = chartData.map(item => item.name);
    const totalSeries = chartData.map(item => item.total);
    const incomeSeries = chartData.map(item => item.income);
    const outcomeSeries = chartData.map(item => Math.abs(item.outcome)); // Absolute for outcome

    // Calculate the total values for each pie chart's center label
    const totalValue = totalSeries.reduce((acc, curr) => acc + curr, 0);
    const totalIncome = incomeSeries.reduce((acc, curr) => acc + curr, 0);
    const totalOutcome = outcomeSeries.reduce((acc, curr) => acc + curr, 0);

    const getChartConfig = (series, totalValue) => ({
        series: series,
        options: {
            chart: {
                type: 'donut',
            },
            labels: labels,
            legend: {
                position: 'bottom'
            },
            responsive: [{
                breakpoint: 560,
                options: {
                    chart: {
                        width: "100%"
                    },
                    legend: {
                        position: 'bottom'
                    }
                }
            }],
            plotOptions: {
                pie: {
                    donut: {
                        labels: {
                            show: true,
                            total: {
                                show: true,
                                label: 'Total',
                                formatter: () => totalValue.toString(),
                            }
                        }
                    }
                }
            },
            dataLabels: {
                enabled: true,
                formatter: (val, opts) => {
                    return opts.w.config.series[opts.seriesIndex];
                },
                dropShadow: {
                    enabled: false,
                }
            }
        }
    });

    return (
        chartData.length > 0 ? (
            separateCharts ? (
                <div>
                    {/* Income Pie Chart */}
                    <h3 style={{ textAlign: 'center' }}>Income</h3>
                    <ReactApexChart height={300}
                        className="bar-chart"
                        options={getChartConfig(incomeSeries, totalIncome).options}
                        series={getChartConfig(incomeSeries, totalIncome).series}
                        type="donut"
                    />

                    {/* Outcome Pie Chart */}
                    <h3 style={{ textAlign: 'center' }}>Outcome</h3>
                    <ReactApexChart height={300}
                        className="bar-chart"
                        options={getChartConfig(outcomeSeries, totalOutcome).options}
                        series={getChartConfig(outcomeSeries, totalOutcome).series}
                        type="donut"
                    />
                </div>
            ) : (
                // Combined Pie Chart
                <ReactApexChart height={300}
                    className="bar-chart"
                    options={getChartConfig(totalSeries, totalValue).options}
                    series={getChartConfig(totalSeries, totalValue).series}
                    type="donut"
                />
            )
        ) : 'No data'
    );
};

export default CategoryPieChartPanel;
