import React, { useState, useEffect } from 'react';
import { Input, List, Tooltip, Button } from 'antd';
import iconsData from './Icons/material_symbols.json';

const IconPicker = ({ value, onChange }) => {
    const [search, setSearch] = useState('');
    const [filteredIcons, setFilteredIcons] = useState([]);
    const [loadedIcons, setLoadedIcons] = useState([]);
    const [currentIndex, setCurrentIndex] = useState(0);
    const [hasMore, setHasMore] = useState(true);
    const chunkSize = 200; // Number of icons to load at a time

    useEffect(() => {
        const lowerCaseSearch = search.toLowerCase();
        const icons = iconsData.icons.filter(icon => icon.toLowerCase().includes(lowerCaseSearch));
        setFilteredIcons(icons);
        setLoadedIcons(icons.slice(0, chunkSize));
        setCurrentIndex(chunkSize);
        setHasMore(icons.length > chunkSize);
    }, [search]);

    const loadMoreIcons = () => {
        const nextIndex = currentIndex + chunkSize;
        const newIcons = filteredIcons.slice(currentIndex, nextIndex);
        setLoadedIcons(prevIcons => [...prevIcons, ...newIcons]);
        setCurrentIndex(nextIndex);
        if (nextIndex >= filteredIcons.length) {
            setHasMore(false);
        }
    };

    const handleIconClick = (icon) => {
        onChange(icon);
    };

    return (
        <div style={{ width: 400, maxHeight: 500, overflowY: 'auto' }}>
            <div style={{ position: 'sticky', top: 0, backgroundColor: 'white', zIndex: 1 }}>
                <Input
                    placeholder="Search Icons"
                    value={search}
                    onChange={(e) => setSearch(e.target.value.toLowerCase())}
                    style={{ marginBottom: 8 }}
                    allowClear
                />
            </div>
            <List
                grid={{ gutter: 16, column: 6 }}
                dataSource={loadedIcons}
                renderItem={icon => (
                    <List.Item>
                        <Tooltip title={icon}>
                            <div onClick={() => handleIconClick(icon)} style={{ cursor: 'pointer' }}>
                                <span className="material-symbols-rounded" style={{ fontSize: 24, fontVariationSettings: "'FILL' 1" }}>
                                    {icon}
                                </span>
                            </div>
                        </Tooltip>
                    </List.Item>
                )}
            />
            {hasMore && (
                <Button type="primary" onClick={loadMoreIcons} style={{ display: 'block', margin: '10px auto' }}>
                    Load More
                </Button>
            )}
        </div>
    );
};

export default IconPicker;