import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { deleteFolderCollection } from "../../actions/folderCollections";
import './MenuNav.css';
import { FolderOutlined } from '@ant-design/icons';
import { Button, Col, Descriptions, Dropdown, Menu, Popover, Row, Segmented } from 'antd';
import {
    EditOutlined,
    DeleteOutlined,
    InfoCircleOutlined,
    BarsOutlined,
    AppstoreOutlined,
    ArrowRightOutlined
} from '@ant-design/icons';
import moment from 'moment';
const { SubMenu } = Menu;

const ParentComponent = ({ setCurrentIdFolder }) => {
    const [view, setView] = useState('grid')
    console.log('view je: ' + view);
    return (
        <>
            {/* <ViewSwitcher view={view} setView={setView} /> */}
            <MenuNav view={view} setView={setView} setCurrentIdFolder={setCurrentIdFolder} />
        </>
    );
};

const CustomTitle = ({ folderDetails, title, setCurrentIdFolder }) => {
    
    const dispatch = useDispatch();

    const deleteFolder = (id) => {
        if (window.confirm('Delete the item?')) {
            dispatch(deleteFolderCollection(id));
        }
    };
    const itemsPopover = [
        {
            key: '1',
            label: 'folderID',
            children: folderDetails._id,
        },
        {
            key: '2',
            label: 'created',
            children: folderDetails.createdAt,
        },
        {
            key: '3',
            label: 'readable',
            children: moment(folderDetails.createdAt).fromNow(),
        },
        // {
        //     key: '4',
        //     label: 'parents',
        //     children: (parentReadable + ' (' + folderCollection.folderParents + ')'),
        // }
    ];
    
    const items = [
        {
            label: <Link to={`/folders/${folderDetails._id}`}>Go to folder</Link>,
            key: '1',
            icon: <ArrowRightOutlined />
          },
        {
          label: <a onClick={() => setCurrentIdFolder(folderDetails._id)}>Edit</a>, 
          key: '2',
          icon: <EditOutlined />
        },
        {
          label:  <Popover 
          trigger={'click'}
          arrow={false}
          content={<Descriptions
            style={{ maxWidth: '340px' }}
            title={folderDetails.folderName}
            items={itemsPopover}
            size="small"
            column={1}
        />}
        >
           Details</Popover>,
          key: '3',
          icon: <InfoCircleOutlined />
        },
        {
            label: <a onClick={() => deleteFolder(folderDetails._id)}>Delete</a>,
            key: '4',
            type: 'primary',
            icon: <DeleteOutlined />
          },
      ];

      
    return (
        <div >
            <span style={{
                marginRight: 'auto',
                // flex: 2,
                // textAlign: 'left'
            }}
            >{title}
            <Dropdown
            menu={{
              items,
            }}
            trigger={'click'}
          >
            <a  >
            <span class="material-icons-outlined" style={{ fontSize: 20, verticalAlign: 'middle', opacity: .7 }}>pending</span>
            </a>
          </Dropdown>  
                             </span>
   
    </div>
    );
};

const ViewSwitcher = ({ view, setView, children }) => {
    return (
        view === 'grid' ? <Col xs={24} sm={12} md={12} lg={8} xl={8}>
            {children}
        </Col> : <>{children}</>
    )
};


const MenuNav = ({ theme, view, setView, setCurrentIdFolder }) => {
    const folders = useSelector((state) => state.folderCollections);
    const location = useLocation();


    const renderMenuItems = (parentId) => {
        return folders
            .filter((folder) => folder.folderParents.includes(parentId))
            .map((folder) => {
                const hasChildren = folders.some((f) =>
                    f.folderParents.includes(folder._id)
                );
                if (hasChildren) {
                    return (
                        <SubMenu
                            key={`/folders/${folder._id}`}
                            title={<CustomTitle
                                folderDetails={folder}
                                setCurrentIdFolder={setCurrentIdFolder}
                                title={<Link to={`/folders/${folder._id}`}>{folder.folderName}</Link>} />
                            }
                            icon={<FolderOutlined />}
                        >
                            {renderMenuItems(folder._id)}
                        </SubMenu>
                    );
                }
                return (
                    <Menu.Item
                        // style={{ paddingRight: 34 }}
                        key={`/folders/${folder._id}`}
                        icon={<FolderOutlined />}
                    >
                        <CustomTitle
                            folderDetails={folder}
                            setCurrentIdFolder={setCurrentIdFolder}
                            title={<Link
                                className='custom-link'
                                to={`/folders/${folder._id}`}>{folder.folderName}</Link>} />
                    </Menu.Item>
                );
            });
    };

    console.log(location.pathname);

    // filter the folders by the ones that have an empty folderParents array
    // const rootFolders = folders.filter(folder => folder.folderParents === 0);
    const rootFolders = folders.filter(folder => !folder.folderParents || !folder.folderParents.length || (folder.folderParents.length === 1 && folder.folderParents[0] === ""));


    // console.log('Folders v tejto komponente: ', folders)
    // console.log('rootFolders v tejto komponente: ', rootFolders)
    // console.log(folder._id, folder.folderParents, folder.folderParents.length === 0);

    return (
        <>
            <div
                style={{
                    textAlign: "center", marginTop: "2rem", marginBottom: "2rem"
                }}>
                <Segmented
                    options={[
                        {
                            value: 'list',
                            icon: <BarsOutlined />,
                        },
                        {
                            value: 'grid',
                            icon: <AppstoreOutlined />,
                        },
                    ]}
                    value={view}
                    onChange={setView}
                />
            </div>
            <Row gutter={[10, 10]} >
                {/* map over the rootFolders and create a Menu component for each one */}
                {rootFolders.map((rootFolder) => {
                    // check if the root folder has children
                    const hasChildren = folders.some((f) =>
                        f.folderParents.includes(rootFolder._id)
                    );
                    const listChildren = folders.filter((folder) => folder.folderParents.includes(rootFolder._id));
                    const childrenPrefixed = listChildren.map(v => `/folders/${v._id}`);
                    const parentAndKids = `/folders/${rootFolder._id},` + childrenPrefixed;
                    // const childrenPrefixed = listChildren.map((v) => v._id);
                    // console.log('folder kids:', parentAndKids);
                    // return a Menu component with the root folder and its children
                    return (
                        <ViewSwitcher view={view} key={rootFolder._id}>
                            <Menu
                                selectable={false}
                                // expandIcon={<FolderFilled />}
                                inlineIndent={10}
                                style={{
                                    // width: 300,
                                    // marginBottom: 10,
                                    borderRadius: '8px'
                                }}
                                key={rootFolder._id}
                                mode="inline"
                                theme={theme}
                            // defaultOpenKeys={parentAndKids}
                            // selectedKeys={[location.pathname]}
                            >
                                {/* if the root folder has children, render it as a SubMenu item */}
                                {hasChildren ? (
                                    <SubMenu
                                        key={`/folders/${rootFolder._id}`}
                                        title={<CustomTitle
                                            folderDetails={rootFolder}
                                            setCurrentIdFolder={setCurrentIdFolder}
                                            title={<Link to={`/folders/${rootFolder._id}`}>{rootFolder.folderName}</Link>} />
                                        }
                                        icon={<FolderOutlined />}
                                    >
                                        {renderMenuItems(rootFolder._id)}
                                    </SubMenu>
                                ) : (
                                    // if the root folder does not have children, render it as a Menu item
                                    <Menu.Item
                                        // style={{ paddingRight: 34 }}
                                        key={`/folders/${rootFolder._id}`}
                                        icon={<FolderOutlined />}
                                    >
                                        <CustomTitle
                                            setCurrentIdFolder={setCurrentIdFolder}
                                            folderDetails={rootFolder}
                                            title={<Link className='custom-link' to={`/folders/${rootFolder._id}`}>{rootFolder.folderName}</Link>}
                                        />
                                    </Menu.Item>
                                )}
                            </Menu>
                        </ViewSwitcher>);
                })}
            </Row>
        </>
    );
};

export default ParentComponent;

