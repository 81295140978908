// tagsUtils.js

function getTagsOptions(dataCollection) {
  const allTagOptions = [
    ...new Set(
      dataCollection
        .flatMap((item) => item.tags.map((tag) => tag.trim()))
        .filter((tag) => tag)
        .sort()
    ),
  ];

  const allTags = allTagOptions?.map((tag) => ({
    label: tag,
    value: tag,
  }));

  return allTags;
}

// function can be used to create groups of all other tags and contained tags for example in folder or even in tab

function generateOptions(array1, array2) {
  // Remove elements from array2 that are contained in array1
  array2 = array2.filter((item) => !array1.includes(item));

  return [
    {
      label: "Existing tags in this folder",
      options: array1.map((item) => ({
        label: item.label,
        value: item.label,
      })),
    },
    {
      label: "All Other Tags",
      options: array2.map((item) => ({
        label: item.label,
        value: item.label,
      })),
    },
  ];
}

module.exports = { generateOptions, getTagsOptions };
