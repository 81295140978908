import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import { legacy_createStore as createStore } from 'redux'
import reducers from './reducers';
import App from './App';

import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { AuthProvider } from './contexts/authContext';
import Login from './components/Auth/Login/index.jsx';
import Register from './components/Auth/Register';
import PrivateRoute from './components/Auth/PrivateRoute.js';
import ForgotPassword from './components/Auth/ForgotPassword/index.js';

const store = createStore(reducers, compose(applyMiddleware(thunk)));

ReactDOM.render(
    <AuthProvider>
        <Provider store={store}>
            <Router>
                <Routes>
                    <Route path="/login" element={<Login />} />
                    <Route path="/register" element={<Register />} />
                    <Route path="/reset" element={<ForgotPassword />} />
                    <Route path='*' element={<PrivateRoute />}>
                        <Route path='*' element={<App />} />
                    </Route>
                </Routes>
            </Router>
        </Provider>
    </AuthProvider>,
    document.getElementById('root')
);
