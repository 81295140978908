import dayjs from 'dayjs';
import isoWeek from 'dayjs/plugin/isoWeek';
dayjs.extend(isoWeek);

export const getDateRangeFromParams = (params) => {
    const year = params.get('year');
    const month = params.get('month');
    const week = params.get('week');
    const quarter = params.get('quarter');
    const day = params.get('day');

    if (day) {
        const date = dayjs(day);
        return {
            type: 'Day',
            range: [date.startOf('day'), date.endOf('day')],
        };
    }

    if (year && quarter) {
        const start = dayjs().year(year).quarter(quarter).startOf('quarter');
        const end = dayjs().year(year).quarter(quarter).endOf('quarter');
        return {
            type: 'Quarter',
            range: [start, end],
        };
    }

    if (year && month) {
        const start = dayjs(`${year}-${month}-01`);
        const end = start.endOf('month');
        return {
            type: 'Month',
            range: [start, end],
        };
    } else if (year && week) {
        const start = dayjs().year(year).isoWeek(week).startOf('isoWeek');
        const end = dayjs().year(year).isoWeek(week).endOf('isoWeek');
        return {
            type: 'Week',
            range: [start, end],
        };
    } else if (year) {
        const start = dayjs(`${year}-01-01`);
        const end = start.endOf('year');
        return {
            type: 'Year',
            range: [start, end],
        };
    }

    const startDate = params.get('startDate');
    const endDate = params.get('endDate');

    if (startDate && endDate) {
        return {
            type: 'Custom',
            range: [dayjs(startDate), dayjs(endDate)],
        };
    }

    return {
        type: 'Week',
        range: [dayjs().startOf('week'), dayjs().endOf('week')],
    };
};
